import { css } from '@emotion/react';
import {
  fontSizeS,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import { AP_CDN_URL } from '../../../../../../dashboard/constants/routes';

export const headerStyle = (isOpen: boolean) =>
  css({
    ':after': {
      backgroundImage: `url(${AP_CDN_URL}/caretRight-24.svg)`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      content: '""',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: `rotate(${isOpen ? '-90deg' : '90deg'})`,
      transition: 'all .2s',
      width: SpacingInRemBySize.M,
    },
    cursor: 'pointer',
    margin: 0,
    position: 'relative',
    textAlign: 'left',
  });

export const contentStyle = css({
  fontSize: fontSizeS,
  paddingRight: SpacingInRemBySize['2XL'],
});

export const listStyle = css({
  marginTop: SpacingInRemBySize.M,
  paddingInlineStart: rem(1.25),
  paddingLeft: SpacingInRemBySize.XL,
  textAlign: 'left',
});
