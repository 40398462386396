import { getHeadspaceGlobalEnvironment } from '@headspace/build-scripts';

export interface EnvironmentVariables {
  API_HOST: string;
  AUTH_TOKEN_COOKIE_NAME: string;
  DASHBOARD_ADMIN_AUTH0_COOKIE: {
    COOKIE_NAME: string;
    COOKIE_CONFIGURATION: {
      domain: string;
      expires: number;
      path: string;
      secure: boolean;
    };
  };
  AUTH0_CLIENT_ID: string;
  AUTH0_CONNECTION: string;
  AUTH0_DOMAIN: string;
  CROCPIT_WEB_HOST: string;
  FEATURE_FLAGS: string[];
  HOST: string;
  NODE_ENV?: string;
  PAYMENT_TYPE_VOUCHERS: string[];
  PAYPAL_REDIRECT_HOST: string;
  SCHEDULER_URL: string;
  SENTRY_DSN: string;
  SSO_CLIENTS: {
    connection: string;
    domains: string[];
  }[];
  SSO_TEST_MATCH: RegExp;
  SSO_TEST_CONNECTION: string;
  TELETHERAPY_HOST: string;
  WEB_APP: string;
  WEBSITE_HOST: string;
  ZUORA_PAGE_URL: string;
  ZUORA_SCRIPT_SRC: string;
  STATSIG_SDK_KEY: string;
  ELIGIBILITY_FILE_HOST: string;
  ONBOARDING_SURVEY_ID: string;
  CHALLENGES_START_DATE_VALIDATION: boolean;
  LOCATION_API: string;
  ENROLLMENT_AUTH0_COOKIE: {
    COOKIE_CONFIGURATION: {
      domain: string;
      expires: number;
      path: string;
      secure: boolean;
    };
  };
  GINGER_WEB_API: {
    // Ginger-web only has staging and production environments as of 8/23
    enabled: boolean;
    url: string;
  };
  EXISTING_ACCOUNT_ORG_ID_ALLOWLIST: number[];
  BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST: number[];
}

export default getHeadspaceGlobalEnvironment<EnvironmentVariables>();
