import {
  Blue,
  fontSizeXS,
  fontSizeS,
  fontSizeM,
  fontSizeL,
  lineHeightBody,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
  borderWidthXS,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { resetStyle } from '../UnifiedEligibilityViews/VerificationPage/styles/verificationPageStyleUmdWfm';
import { bold } from '../../../dashboard/styles/fonts';
import {
  alignItemsCenter,
  flex,
  justifyContentCenter,
} from '../../../dashboard/styles/flex';
import { AP_CDN_URL } from '../../../dashboard/constants/routes';

export const pillStyle = css(flex, alignItemsCenter, {
  border: `solid ${WarmGrey[200]} ${borderWidthXS}`,
  borderRadius: rem(2.5),
  height: SpacingInRemBySize['2XL'],
  justifyContent: 'space-around',
  margin: 'auto',
  marginBottom: rem(1.25),
  paddingLeft: SpacingInRemBySize.S,
  paddingRight: SpacingInRemBySize.S,
  width: rem(7.5),
});

export const numericListStyle = css({
  '& li': {
    counterIncrement: 'item',
    marginBottom: SpacingInRemBySize.L,
    paddingLeft: SpacingInRemBySize['2XL'],
    position: 'relative',
  },
  '& li::before': {
    background: Blue[200],
    borderRadius: '50%',
    color: White['000'],
    content: 'counter(item)',
    fontSize: '1.2em',
    left: 0,
    marginRight: SpacingInRemBySize.M,
    position: 'absolute',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '1.2em',
  },
  counterReset: 'item',
  listStyle: 'none',
  marginTop: SpacingInRemBySize.L,
  paddingInlineStart: rem(1.25),
  paddingLeft: 0,
  textAlign: 'left',
});

export const collapsibleInfoExpandedStyle = css({
  fontSize: fontSizeS,
  paddingRight: SpacingInRemBySize['2XL'],
});

export const collapsibleInfoListStyle = css({
  marginTop: SpacingInRemBySize.M,
  paddingInlineStart: rem(1.25),
  paddingLeft: SpacingInRemBySize.XL,
  textAlign: 'left',
});

export const collapsibleInfoStyle = (isExpanded: boolean) =>
  css(resetStyle, {
    ':after': {
      backgroundImage: `url(${AP_CDN_URL}/caretRight-24.svg)`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      content: '""',
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      transform: `rotate(${isExpanded ? '-90deg' : 0})`,
      transition: 'all .2s',
      width: SpacingInRemBySize.M,
    },
    cursor: 'pointer',
    position: 'relative',
  });

export const linkHelpEmailStyle = () =>
  css({
    ':hover': {
      textDecoration: 'underline',
    },
    marginLeft: rem(0.25),
    textDecoration: 'none',
  });

export const outerContainerStyle = css({
  '@media(max-width: 550px)': {
    maxWidth: rem(15.625),
  },
  color: WarmGrey[500],
  fontSize: fontSizeM,
  margin: rem(3.125),
  marginTop: 0,
  textAlign: 'center',
});

export const accountStatusHolderNameStyle = css(bold, {
  color: WarmGrey[500],
  fontSize: fontSizeL,
});

export const accountStatusConnectionStyle = css({
  color: WarmGrey[500],
  fontSize: fontSizeS,
  fontWeight: 'lighter',
  lineHeight: lineHeightBody,
  paddingBottom: SpacingInRemBySize.L,
});

export const accountStatusContainerStyle = css({
  paddingBottom: SpacingInRemBySize.L,
  paddingTop: SpacingInRemBySize.L,
});

export const accountStatusPillStyle = css(pillStyle, {
  fontSize: fontSizeXS,
  height: SpacingInRemBySize.L,
  marginBottom: 0,
  paddingLeft: SpacingInRemBySize.XS,
  paddingRight: SpacingInRemBySize.XS,
  width: rem(5),
});

export const careLogoContainer = css(
  flex,
  alignItemsCenter,
  justifyContentCenter,
  {
    height: SpacingInRemBySize['4XL'],
  },
);

export const careConfirmationFooter = css({
  fontSize: fontSizeS,
});

export const careConfirmationLinkFooter = css({
  marginLeft: rem(0.25),
});
