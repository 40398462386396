import { useCallback, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TableSectionProps } from '../../../shared-components/tables/types';
import { Container } from '../../../shared-components/container/Container';
import { selectOrganizationAction } from '../../../state/actions/organizationsActions';
import { fetchAdminOrganizationsAction } from '../../../state/actions/adminOrganizationsActions';
import { OrganizationsTitle } from '../OrganizationsTableView/OrganizationsTitle';
import { generateHeaderRow } from '../OrganizationsTableView/generateHeaderRow';
import { tableStyle } from '../../../shared-components/tables/styles';
import { noResultsFoundStyle } from '../OrganizationsTableView/styles/organizationsPageStyle';
import { SpinnerWithBackGround } from '../../../shared-components/Spinner';
import { TableSection } from '../../../shared-components/tables/TableSection';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { rendererRow } from '../../../shared-components/tables/utils';
import { generateRows } from '../OrganizationsTableView/generateRows';
import { Toast } from '../../../shared-components/Toast';
import { getOrganizations } from '../../../state/selectors/organizationSelectors';
import { getChallengeCreated } from '../../../state/selectors/challengesSelectors';
import { useTranslate } from '../../../hooks/useTranslate';
import { Header } from '../Header/Header';
import { getInfo } from '../../../state/selectors/userSelectors';
import { COLUMN_WIDTH_OFFSET, HEIGHT_OFFSET } from '../constants';

const searchOrganizations = (dispatch) =>
  dispatch(
    fetchAdminOrganizationsAction({
      refresh: true,
    }),
  );

export const AdminOrganizationsTableView: FC = () => {
  const { windowInnerHeight } = useWindowResize();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const challengeCreated = useSelector(getChallengeCreated);
  const {
    isFetching,
    totalOrgCount,
    OrganizationList: organizations = [],
  } = useSelector(getOrganizations);
  const { orgIds } = useSelector(getInfo);

  const handleSelectOrganization = useCallback(
    (orgId: number) => dispatch(selectOrganizationAction(orgId)),
    [dispatch],
  );

  const loadMoreRows = useCallback(
    ({ startIndex: offset }: { startIndex: number }) => {
      searchOrganizations(dispatch);
    },
    [dispatch, translate],
  );

  const rows = generateRows({
    handleSelectOrganization,
    organizations,
    t: translate,
  });
  const rowRenderer = (data: any) => rendererRow({ ...data });

  const tableSectionProps: TableSectionProps = {
    columnWidthOffset: COLUMN_WIDTH_OFFSET,
    headerRow: generateHeaderRow(translate),
    loadMoreRows,
    rowRenderer,
    rows,
  };

  useEffect(() => {
    const [orgId] = orgIds || [];
    if (orgIds?.length === 1 && orgId) {
      navigate(`/orgs/${orgId}/members`);
    } else {
      searchOrganizations(dispatch);
    }
  }, [dispatch]);

  return (
    <>
      <Header />
      <div
        className="admin-organizations"
        data-testid="admin-organizations-container"
      >
        <OrganizationsTitle
          title={translate('organizations.title')}
          subtitle={`${totalOrgCount} ${translate('organizations.total')}`}
        />
        <Container data-testid="admin-organizations-table">
          <div
            className="table"
            css={tableStyle({ height: windowInnerHeight - HEIGHT_OFFSET })}
          >
            {isFetching && <SpinnerWithBackGround height="70px" width="70px" />}
            {rows.length ? (
              <TableSection {...tableSectionProps} />
            ) : (
              <div css={noResultsFoundStyle}>{translate('noResultsFound')}</div>
            )}
          </div>
        </Container>
        {challengeCreated && <Toast text={challengeCreated} />}
      </div>
    </>
  );
};
