import { EAP_PROVIDERS } from '../apps/headspaceHub/constants/eapMemberDetails';

export enum SupportedLanguageCode {
  en = 'en',
  es = 'es',
  de = 'de',
  fr = 'fr',
  pt = 'pt',
}

export enum HubSupportedLanguageCode {
  zh_CN = 'zh_CN',
  de = 'de',
  ms = 'ms',
  cs = 'cs',
  hi = 'hi',
  pl = 'pl',
  nl = 'nl',
  hu = 'hu',
  pt = 'pt',
  en = 'en',
  it = 'it',
  ru = 'ru',
  fr = 'fr',
  ja = 'ja',
  es = 'es',
}

export enum CCAApprovedLanguageCode {
  en = 'en',
  es = 'es',
}

export type CCAApprovedLanguageCodeType = typeof CCAApprovedLanguageCode;

export type SupportedLanguagesType = typeof SupportedLanguageCode;

export type HubSupportedLanguagesType = typeof HubSupportedLanguageCode;

export type AvailableLanguagesType =
  | SupportedLanguagesType
  | HubSupportedLanguagesType
  | CCAApprovedLanguageCodeType;

export const getAllAvailableLanguages = (
  shouldShowExpansionLanguages: boolean,
  provider: EAP_PROVIDERS | null,
): AvailableLanguagesType => {
  const isOnboarding = /onboarding/.test(window.location.pathname);
  if (isOnboarding) {
    return SupportedLanguageCode;
  }

  if (provider === EAP_PROVIDERS.CCA) {
    return CCAApprovedLanguageCode;
  }

  if (shouldShowExpansionLanguages) {
    return HubSupportedLanguageCode;
  }

  return SupportedLanguageCode;
};

export const CountryCodeToLanguageCode = {
  AR: SupportedLanguageCode.en,
  BR: SupportedLanguageCode.pt,
  CL: SupportedLanguageCode.es,
  DE: SupportedLanguageCode.en,
  ES: SupportedLanguageCode.es,
  GB: SupportedLanguageCode.en,
  IE: SupportedLanguageCode.en,
  IT: SupportedLanguageCode.en,
  MX: SupportedLanguageCode.es,
  US: SupportedLanguageCode.en,
} as const;
