import * as React from 'react';
import { MEMBER_STATUS } from '../../../constants';
import { LinkToCrocPit } from '../LinkTo/LinkToCrocPit';
import { LinkToDashboards } from '../LinkTo/LinkToDashboards';

import { StatusText } from '../../../../../shared-components/text/StatusText';

const {
  ORPHAN,
  WAITLISTED,
  ELIGIBLE,
  REMOVED,
  EXPIRED,
  PENDING_REMOVAL,
  INVITED,
} = MEMBER_STATUS;

type Color = 'red' | 'yellow' | 'grey' | 'green' | 'blue';

/**
 * Converts the status to a CSS color style.
 *
 * @param status
 */
const getStatusColor = (status: string): Color => {
  if (status === WAITLISTED) {
    return 'yellow';
  }
  if ([ELIGIBLE, INVITED].includes(status)) {
    return 'blue';
  }
  if (status === ORPHAN) {
    return 'grey';
  }
  if ([EXPIRED, REMOVED, PENDING_REMOVAL].includes(status)) {
    return 'red';
  }

  return 'green';
};

export interface StatusAndToolsLinkProps {
  status: string;
  t: I18next['t'];
  isCSM: boolean;
  hsUserId: string | null;
  isFusion: boolean;
}

export const StatusAndToolsLink: React.FC<StatusAndToolsLinkProps> = ({
  status,
  t,
  isCSM,
  hsUserId,
  isFusion,
}) => {
  return (
    <div>
      <StatusText
        color={getStatusColor(status)}
        status={t(`members.statuses.${status}`)}
      />

      {isFusion ? (
        <LinkToDashboards isCSM={isCSM} hsUserId={hsUserId} />
      ) : (
        <LinkToCrocPit isCSM={isCSM} hsUserId={hsUserId} />
      )}
    </div>
  );
};
