import { FC, useCallback, useState } from 'react';
import Collapsible from 'react-collapsible';
import { HorizontalDivider } from '../../../shared-components/Divider/Divider';
import { contentStyle, headerStyle, listStyle } from './styles/ConsentStyle';
import { useTranslate } from '../../../../../../hooks/useTranslate';

interface ConsentListProps {
  permissions: string[];
}

export const ConsentList: FC<ConsentListProps> = ({ permissions }) => {
  const { translate } = useTranslate();
  const [open, setOpen] = useState(false);
  const handleOpening = useCallback(() => setOpen(true), []);
  const handleClosing = useCallback(() => setOpen(false), []);

  return (
    <div>
      <HorizontalDivider marginTop={10} marginBottom={10} />
      <Collapsible
        open={open}
        transitionTime={200}
        lazyRender={true}
        onTriggerOpening={handleOpening}
        onTriggerClosing={handleClosing}
        trigger={
          <p css={headerStyle(open)}>
            {translate('onboarding.partnerConsent.dataAccess')}
          </p>
        }
      >
        <div css={contentStyle}>
          <ul css={listStyle}>
            {permissions.map((permission: string) => (
              <li key={permission}>{permission}</li>
            ))}
          </ul>
        </div>
      </Collapsible>
      <HorizontalDivider marginTop={10} marginBottom={10} />
    </div>
  );
};
