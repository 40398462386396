import { css } from '@emotion/react';
import {
  PrimaryButton,
  useActiveBreakpoint,
  Breakpoint,
  TextLink,
} from '@headspace/web-ui-components';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  ContainerStyle,
  centerElementStyle,
  downloadHeadspaceMobile,
  downloadHeadspace,
  onlyOnMobile,
  supportMobile,
} from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { QRCode } from '../../../../../shared-components/qrcode';
import { PageWrapper } from '../Onboarding/onboarding-shared-components/OnboardingPageWrapper';
import { Product } from '../../../constants';
import { checkIfMobileOrTablet } from '../utils/deviceUtils';
import { useGetAppStoreLinks } from '../../../../../hooks/useAppStoreLinks';
import {
  existingAccountButtonClickthroughAction,
  existingAccountScreenViewAction,
} from '../../../../../analytics/events/enrollmentEvents';

const ChunkUpdate = (chunks: ReactNode) => (
  <TextLink to="mailto:teamsupport@headspace.com" isNewWindow={true}>
    {chunks}
  </TextLink>
);

export const ExistingAccountPage = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);

  const { appStoreLink, trackableAppStoreLink } = useGetAppStoreLinks({
    app: Product.HEADSPACE,
  });

  const isMobileOrTablet = checkIfMobileOrTablet();

  useEffect(() => {
    dispatch(existingAccountScreenViewAction());
  }, [dispatch]);

  const handleClick = useCallback(
    (event) => {
      event.stopPropagation();
      const buttonText = event.target.textContent;
      dispatch(existingAccountButtonClickthroughAction(buttonText));
    },
    [dispatch],
  );

  const contactSupport = (
    <FormattedMessage
      id="enrollment.existingAccount.support"
      values={{
        a: ChunkUpdate,
      }}
    />
  );

  return (
    <PageWrapper
      progress={100}
      header={translate('enrollment.existingAccount.header')}
      subHeader={translate('enrollment.existingAccount.subheader')}
      subHeaderElement={contactSupport}
      hideTitleSection={isMobileView}
    >
      <div css={ContainerStyle} data-testid="next-steps-page-container">
        <h1 css={css(centerElementStyle, onlyOnMobile)}>
          {translate('enrollment.existingAccount.header')}
        </h1>
        {isMobileOrTablet ? (
          <div css={downloadHeadspaceMobile}>
            <a
              css={css({
                margin: 'auto',
              })}
              href={appStoreLink}
              target="_blank"
              rel="noreferrer"
            >
              <PrimaryButton
                onClick={handleClick}
                dataTestIdPrefix="mobile-download"
              >
                {translate('onboarding.recommend.headspace.button')}
              </PrimaryButton>
            </a>
          </div>
        ) : (
          <div css={downloadHeadspace} data-testid="download-headspace-qrcode">
            <QRCode
              data={trackableAppStoreLink}
              id="download-headspace-app"
              filename="download-headspace-app"
              margin={3}
              scale={3}
            />
          </div>
        )}
        <div css={css(supportMobile)}>{contactSupport}</div>
      </div>
    </PageWrapper>
  );
};
