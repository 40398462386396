import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  desktopContainerCss,
  desktopSubtitleStyle,
  desktopTitleStyle,
  mainContainerCss,
} from '../styles';

export const FinishPageWrapper = ({ children }) => {
  const { translate } = useTranslate();
  const desktopTitle = translate(
    'enrollment.skipOnboarding.nextStepsPage.desktopTablet.title',
  );
  const desktopSubtitle = translate(
    'enrollment.skipOnboarding.nextStepsPage.desktopTablet.body',
  );

  return (
    <div css={mainContainerCss} data-testid="finish-page-wrapper">
      <div css={desktopContainerCss}>
        <h1 css={desktopTitleStyle} data-testid="finish-page-title">
          {desktopTitle}
        </h1>
        <p css={desktopSubtitleStyle} data-testid="finish-page-subtitle">
          {desktopSubtitle}
        </p>
      </div>
      <div>{children}</div>
    </div>
  );
};
