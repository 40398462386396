import { FC } from 'react';
import {
  checkMarkIconStyle,
  choiceContainerStyle,
  choiceIconContainerStyle,
  choiceIconStyle,
  choiceTextStyle,
  labelContainerStyle,
} from '../styles/choiceCardStyle';
import { CHOICE_ICONS } from './constants';
import { ENROLLMENT_CDN_URL } from '../../../../../constants/routes';

interface OnboardingMultipleChoiceCheckboxProps {
  text: string;
  id: string;
  isSelected: boolean;
  choicesIcons: Record<string, string>;
  handleCheckBoxSelect: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const OnboardingMultipleChoiceCheckbox: FC<OnboardingMultipleChoiceCheckboxProps> = ({
  text,
  id,
  isSelected,
  choicesIcons = CHOICE_ICONS,
  handleCheckBoxSelect,
}) => (
  <button
    css={choiceContainerStyle(isSelected)}
    onClick={handleCheckBoxSelect}
    id={id}
    type="button"
    role="checkbox"
    aria-checked={isSelected}
    data-testid="onboarding-multiple-choice-card"
  >
    <img
      src={`${ENROLLMENT_CDN_URL}/umd-onboarding-checkmark.png`}
      css={checkMarkIconStyle(isSelected)}
      alt={text}
    />
    <label htmlFor={text} css={labelContainerStyle}>
      <div css={choiceIconContainerStyle}>
        <img src={choicesIcons[id]} css={choiceIconStyle} alt={text} />
      </div>
      <span css={choiceTextStyle}>{text}</span>
    </label>
  </button>
);
