import * as R from 'ramda';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../../hooks/useTranslate';
import { Container } from '../../../../shared-components/container/Container';
import { createFilterDropdown } from '../../../../shared-components/tables/FilterDropdown';
import { FullTableWithScroll } from '../../../../shared-components/tables/FullTableWithScroll';
import { EventHandler } from '../../../../shared-components/tables/FullTableWithScroll/types';
import * as organizationActions from '../../../../state/actions/organizationActions';
import {
  getIsRestrictedByELF,
  getOrgManagement,
  isFusionEnabled,
  isUMDEnabled,
} from '../../../../state/selectors/organizationSelectors';
import { isCSM as getIsCSM } from '../../../../state/selectors/userSelectors';
import { ExportType, useExportMembers } from '../../hooks/useExportMembers';
import { getMembersDataThunk } from '../../../../state/slices/members/getMembers/getMembersThunk';
import { getMembersData } from '../../../../state/slices/members/membersSlice';
import { RemoveMembersPayloadParams } from '../../../../state/types/members';
import { RestrictBy } from '../../../../types/organization';
import { debounce } from '../../../../utils';
import { useUpdateEffect } from '../../../../utils/effects';
import {
  COMMON_MEMBER_STATUSES,
  ELIGIBILITY_FILE_MEMBER_STATUSES,
  MEMBER_PAY_MEMBER_STATUSES,
  MEMBER_STATUS,
} from '../../constants';
import { GenerateVerificationLinkModal } from '../SettingsView/components/shared-components/GenerateVerificationLinkModal.tsx/GenerateVerificationLinkModal';
import { useOpenVerificationLinkModal } from '../hooks/useOpenVerificationLinkModal';
import { GenerateRow, generateRows } from './generateRows';
import {
  ExportCSV,
  MembersProps,
  MembersWithScrollSelectorsProps,
} from './types';
import { getConfig } from '../../../../config';

const getSearchValue = R.pathOr('', ['target', 'value']);
const { ORPHAN, REMOVED } = MEMBER_STATUS;
const { BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST } = getConfig();

const DELAY = 500;

const searchMembers = (dispatch, options, orgId, offset = 0) =>
  dispatch(
    getMembersDataThunk({
      options: { ...options, offset },
      orgId,
    }),
  );

const searchMembersDebounced = debounce((dispatch, options, orgId) => {
  searchMembers(dispatch, options, orgId);
}, DELAY);

export const OrganizationMembersWithScrollView = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const {
    orgId,
    org_name,
    restrictBy,
    current_contract_voucher,
    future_contract_voucher,
  }: MembersWithScrollSelectorsProps = useSelector(getOrgManagement);

  const {
    filteredMembers,
    members,
    totalMembers,
    isFetchingMembers,
  }: MembersProps = useSelector(getMembersData);

  const isCSM: boolean = useSelector(getIsCSM);
  const isUMD = useSelector(isUMDEnabled);
  const isFusion = useSelector(isFusionEnabled);

  const eligibilityFileOrg: boolean = useSelector(getIsRestrictedByELF);

  const memberPayOrg: boolean = !!(
    current_contract_voucher || future_contract_voucher
  );

  const applicableStatuses = [
    ...COMMON_MEMBER_STATUSES,
    ...(memberPayOrg ? MEMBER_PAY_MEMBER_STATUSES : []),
    ...(eligibilityFileOrg ? ELIGIBILITY_FILE_MEMBER_STATUSES : []),
    ...(restrictBy === RestrictBy.SSO ? ['ELIGIBLE'] : []),
  ];

  const bypassStatusFilter = BYPASS_ENROLLMENT_FILTERING_ORG_ID_ALLOWLIST.includes(
    orgId,
  );
  const shouldShowMemberEnrollmentStatus =
    bypassStatusFilter || isCSM || (!isUMD && !isFusion);

  const headerRow = [
    {
      label: translate('name'),
      key: 'fullName',
      sortBy: 'alpha',
    },
    ...(shouldShowMemberEnrollmentStatus
      ? [
          {
            label: translate('members.status'),
            key: 'status',
            sortBy: 'alpha',
          },
          {
            label: translate('members.joinedOn'),
            key: 'activeSince',
            sortBy: 'datetime',
          },
        ]
      : []),
  ];

  const statusFilterProps = {
    columnKey: 'status',
    coverText: translate('members.statusFilter'),
    options: applicableStatuses.map((status: string) => {
      return {
        value: status,
        title: translate(`members.statuses.${status}`),
      };
    }),
    defaultSelected: applicableStatuses,
  };
  const [statusFilterSelectedValues, setStatusFilterSelectedValues] = useState<
    string[]
  >(statusFilterProps.defaultSelected);
  const [searchValue, setSearch] = useState('');
  const [sortValue, setSort] = useState([headerRow[0].key, 'ASC']);
  const [selectedRowIds, setSelectedRowIds] = useState([]);
  const {
    closeModal,
    isOpen,
    openModal,
    defaultEmail,
  } = useOpenVerificationLinkModal();

  const [sortBy, sortDirection] = sortValue;
  const options = {
    search: searchValue,
    sort: { field: sortBy, direction: sortDirection.toLowerCase() },
    ...(shouldShowMemberEnrollmentStatus
      ? { status: statusFilterSelectedValues }
      : {}),
  };

  const getMembers = ({ startIndex: offset }: { startIndex?: number } = {}) =>
    searchMembers(dispatch, options, orgId, offset);

  const onRemoveMembers = (
    params: RemoveMembersPayloadParams,
    restrictedByEligibility: boolean,
  ) =>
    dispatch(
      organizationActions.removeMembersWithScroll({
        params,
        restrictedByEligibility,
      }),
    );

  useEffect(() => {
    getMembers();
  }, [sortValue, statusFilterSelectedValues]);

  useUpdateEffect(() => {
    searchMembersDebounced(dispatch, options, orgId);
  }, [searchValue]);

  const handleSearch: EventHandler = (event) => {
    const search = event ? getSearchValue(event) : '';
    setSearch(search);
  };

  const removeMembers = (memberIds: MemberIds, statusModifier: string) => {
    if (members) {
      const params = { memberIds, options, orgId, statusModifier };

      onRemoveMembers(params, eligibilityFileOrg);
      setSelectedRowIds([]);
    }
  };

  const removeSelectedMembers = (
    memberIds: MemberIds,
    statusModifier: string,
  ) => {
    const response = window.confirm(translate('members.removeSelectedMembers'));
    if (response) {
      removeMembers(memberIds, statusModifier);
    }
  };

  const { mutate: exportMembers, isLoading, isError } = useExportMembers();
  const exportCSV: ExportCSV = (options, exportType: ExportType) => {
    exportMembers({
      exportType,
      options,
      orgId,
      orgName: org_name,
    });
  };

  if (!orgId) return null;

  const isSSORestrictedBy = restrictBy === RestrictBy.SSO;

  const rows: GenerateRow[] = generateRows({
    isCSM,
    t: translate,
    members,
    openVerificationLinkModal: openModal,
    removeMembers,
    restrictBy,
    statusModifier: memberPayOrg ? ORPHAN : REMOVED,
    isFusion,
  });

  return (
    <>
      <Container data-testid="members-view">
        <FullTableWithScroll
          heightOffset={345}
          loadMoreRows={getMembers}
          isFetching={isFetchingMembers}
          totalMembers={totalMembers}
          filteredMembers={filteredMembers}
          sort={[sortValue, setSort]}
          search={[searchValue, handleSearch]}
          status={statusFilterSelectedValues}
          selectRows={[selectedRowIds, setSelectedRowIds]}
          columnFilters={
            shouldShowMemberEnrollmentStatus
              ? createFilterDropdown({
                  filterProps: statusFilterProps,
                  filterSelectedValues: statusFilterSelectedValues,
                  setFilterSelectedValues: setStatusFilterSelectedValues,
                })
              : []
          }
          showToastAction={!isSSORestrictedBy}
          toastAction={{
            text: translate('members.deactivate'),
            action: (memberIds: MemberIds) =>
              removeSelectedMembers(memberIds, memberPayOrg ? ORPHAN : REMOVED),
          }}
          headerRow={headerRow}
          rows={rows}
          datasetActions={{
            all: {
              action: exportCSV,
              isDisabled: isLoading,
              label: translate('members.allMembers'),
            },
            filtered: {
              action: exportCSV,
              isDisabled: isLoading || !shouldShowMemberEnrollmentStatus,
              label: translate('members.filtered'),
            },
            selected: {
              action: exportCSV,
              isDisabled: isLoading,
              label: translate('members.selected'),
            },
            actionText: translate('members.export'),
          }}
        />
      </Container>
      {isOpen ? (
        <GenerateVerificationLinkModal
          onClose={closeModal}
          defaultEmail={defaultEmail}
          disabled={true}
        />
      ) : null}
    </>
  );
};
