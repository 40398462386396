import { WarmGrey, fontSize2XL } from '@headspace/web-ui-components';
import { FC } from 'react';
import { AuthConnections } from '@headspace/web-auth';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { CheckIcon } from '../../../../../../shared-components/Icons/CheckIcon';
import { ConfiguredLinkIdentitiesForm } from '../../../AuthComponent';
import { CollapsibleInfo } from '../../../PersonifyCare/Components/CollapsibleInfo';
import { HorizontalDivider } from '../../../shared-components/Divider/Divider';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import {
  activityDataSectionStyle,
  containerStyle,
  customIdentitiesComponentStyle,
  logo,
  logoContainer,
  sectionHeaderStyle,
  subHeaderStyle,
} from './styles/preLinkPageStyle';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

interface ConsentProps {
  consentDetails?: string[];
  description?: string;
  subtitle?: string;
  bigLogo?: boolean;
}

interface TitleProps {
  title?: string;
}

const PreLinkPageMessageBody: FC<ConsentProps> = ({
  consentDetails,
  description,
  subtitle,
  bigLogo = false,
}) => {
  const { translate } = useTranslate();

  return (
    <div css={containerStyle} data-testid="prelink-message-body-container">
      <div css={subHeaderStyle(bigLogo)}>
        <p data-testid="prelink-sub-header-text">
          {description ||
            translate('ssoEnrollment.connectToPersonify.subHeader')}
        </p>
      </div>
      <div css={logoContainer(bigLogo)} data-testid="personify-logo">
        <img
          css={logo}
          src={`${ENROLLMENT_CDN_URL}/personify-logo.png`}
          alt="personify-logo"
        />
      </div>

      <div css={sectionHeaderStyle}>
        <p>
          {subtitle ||
            translate('ssoEnrollment.connectToPersonify.messageBody')}
        </p>
      </div>

      {consentDetails && consentDetails.length > 0 ? (
        <CollapsibleInfo
          title={translate('enrollment.personifyCareConsent.collapsibleTitle')}
          listItems={consentDetails}
        />
      ) : (
        <>
          <HorizontalDivider marginTop={20} marginBottom={5} />
          <div css={activityDataSectionStyle}>
            <p data-testid="data-access-text">
              {translate('ssoEnrollment.connectToPersonify.accessDataMessage')}
            </p>
            <CheckIcon color={WarmGrey[600]} height={12} width={12} />
          </div>
          <HorizontalDivider marginTop={5} marginBottom={1} />
        </>
      )}
    </div>
  );
};

type LinkIdentitiesFormOverrides = {
  connectButtonOverrides?: unknown;
  overrideManageIdentitiesDescription?: unknown;
};

const PreLinkComponent: FC<
  ConsentProps & LinkIdentitiesFormOverrides & TitleProps
> = ({
  title,
  consentDetails,
  description,
  subtitle,
  bigLogo,
  overrideManageIdentitiesDescription,
  connectButtonOverrides,
}) => {
  const { translate } = useTranslate();
  const consentProps = {
    title,
    consentDetails,
    description,
    subtitle,
    bigLogo,
  };
  const linkIdentitiesFormOverrides = {
    overrideManageIdentitiesDescription,
    connectButtonOverrides,
    thirdPartyConnections: [AuthConnections.PERSONIFYHEALTH],
  };
  const headerStyleOverrides = {
    fontSize: bigLogo ? fontSize2XL : undefined,
  };

  return (
    <FormContainer
      headerTag="h4"
      title={title || translate('ssoEnrollment.connectToPersonify.header')}
      headerStyleOverrides={headerStyleOverrides}
    >
      <PreLinkPageMessageBody {...consentProps} />
      <div css={customIdentitiesComponentStyle}>
        <ConfiguredLinkIdentitiesForm {...linkIdentitiesFormOverrides} />
      </div>
    </FormContainer>
  );
};

export default PreLinkComponent;
