import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { getEligibleCoaching } from '../../../state/slice/onboarding/onboardingSelector';
import { hasClinicalCoverage } from '../../../../../state/selectors/organizationSelectors';
import {
  mainContainerCss,
  moduleContainerCss,
  moduleHeaderCss,
} from './styles';
import { FinishPageWrapper } from './shared-components/FinishPageWrapper';
import { ConditionalNextStepsContent } from './shared-components/ConditionalNextStepsContent';
import { onboardingNextStepsViewAction } from '../../../../../analytics/events/onboardingEvents';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

export const NextStepsFinishPage = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onboardingNextStepsViewAction());
  }, [dispatch]);

  const isClinicalCovered = useSelector(hasClinicalCoverage);
  const isEligibileForCoaching = useSelector(getEligibleCoaching);

  return (
    <div css={mainContainerCss} data-testid="next-steps-page-container">
      <FinishPageWrapper>
        <div css={moduleContainerCss}>
          <div css={moduleHeaderCss}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-onboarding-happy-sun.png`}
              alt="module-icon"
              data-testid="happy-icon"
            />
            <h2>
              {translate('enrollment.skipOnboarding.nextStepsPage.title')}
            </h2>
          </div>
          <ConditionalNextStepsContent
            skipOnboarding={false}
            isEligibileForCoaching={isEligibileForCoaching}
            hasHubAccess={isClinicalCovered}
          />
        </div>
      </FinishPageWrapper>
    </div>
  );
};
