import type { FC } from 'react';
import {
  bodySCss,
  BreakpointToMinWidthInRem,
  cssWithCustomMq,
  LinkButton,
  rem,
  SpacingInRemBySize,
} from '@headspace/web-ui-components';
import facepaint from 'facepaint';
import { useTranslate } from '../../../../../hooks/useTranslate';

const downloadLink = 'https://headspace.app.link/download-headspace';

// Due to the text length and placement of the download banner we need to add some custom breakpoints
// to make it look good and not overlap with the org logos.
export const mq = facepaint([
  `@media(min-width: ${rem(47)})`, // 752px
  `@media(min-width: ${rem(66)})`, // 1056px
  `@media(min-width: ${rem(BreakpointToMinWidthInRem.DESKTOP)})`,
]);

const styles = cssWithCustomMq(mq, {
  display: ['none', 'flex', 'flex', 'flex'],
  alignItems: 'center',
  justifyContent: 'flex-end',
  // LinkButtons need box-sizing set
  '> a': {
    boxSizing: 'border-box',
  },
  marginRight: [0, 0, 0, SpacingInRemBySize.XL],
});
const labelStyles = cssWithCustomMq(mq, bodySCss, {
  display: ['none', 'none', 'block', 'block'],
  maxWidth: ['30vw', '30vw', '30vw', '40vw'],
  margin: [
    `0 ${SpacingInRemBySize.XS} 0 0`,
    `0 ${SpacingInRemBySize.XS} 0 0`,
    `0 ${SpacingInRemBySize.XS} 0 0`,
    `0 ${SpacingInRemBySize['2XL']} 0 0`,
  ],
});

export const DownloadHeadspaceBanner: FC = () => {
  const { translate } = useTranslate();

  return (
    <div css={styles} data-testid="download-headspace-banner">
      <p css={labelStyles}>
        {translate('enrollment.header.downloadHeadspaceBanner.text')}
      </p>
      <LinkButton
        to={downloadLink}
        dataTestIdPrefix="download-headspace"
        isDisabled={false}
        isLarge={false}
        variant="primary"
      >
        {translate('enrollment.header.downloadHeadspaceBanner.cta')}
      </LinkButton>
    </div>
  );
};
