/** Forked from react-virtualized defaultRowRenderer */
import classNames from 'classnames';
import * as React from 'react';
import { buildAllyProps } from '../utils';
import { selectRowStyle, rowMarginStyle } from './styles';
import { Icon } from '../../Icon';
import { TableCheckbox } from './Checkbox';
import { TableRowProps } from './types';
import { Dependents } from './Dependents';
import { AP_CDN_URL } from '../../../apps/dashboard/constants/routes';

export const TableRow = ({
  className,
  columns,
  columnWidth,
  expanded,
  index,
  selectedRowIds,
  onExpandClick,
  onRowClick,
  onRowDoubleClick,
  onRowMouseOut,
  onRowMouseOver,
  onRowRightClick,
  onSelect,
  rowData,
  style,
}: TableRowProps) => {
  const a11yProps = buildAllyProps({
    index,
    onRowClick,
    onRowDoubleClick,
    onRowMouseOut,
    onRowMouseOver,
    onRowRightClick,
    rowData,
  });
  const { action, id, dependents } = rowData;
  const hasDependents: boolean = !!(dependents && dependents.length);
  const selected = selectedRowIds.includes(id);
  const rowStyle = selected ? selectRowStyle : {};
  const handleSelect = () => (onSelect ? onSelect(id) : {});
  const handleExpand = () => (onExpandClick ? onExpandClick(index) : {});
  const handleExpandKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.keyCode === 13 && onExpandClick) {
      onExpandClick(index);
    }
  };

  a11yProps['aria-label'] = 'row';
  a11yProps.tabIndex = 0;
  const wrapperStyle = {
    overflow: style.overflow,
    width: style.width,
  };

  return (
    <div
      {...a11yProps}
      className={classNames(className, { expanded })}
      key={id}
      role="row"
      style={wrapperStyle}
      css={rowStyle}
      data-testid="table-row"
    >
      <div className="subRow">
        <span className="leftRowMargin" css={rowMarginStyle}>
          {hasDependents ? (
            <div
              aria-expanded={expanded}
              className="toggle"
              data-testid="expand-toggle"
              onClick={handleExpand}
              onKeyDown={handleExpandKeyDown}
              role="button"
              tabIndex={0}
            >
              <Icon
                src={`${AP_CDN_URL}/caretDownTundora.svg`}
                width={12}
                height={12}
              />
            </div>
          ) : null}
          <TableCheckbox id={id} selected={selected} onSelect={handleSelect} />
        </span>
        {columns}
        <span className="rightRowMargin" css={rowMarginStyle}>
          {action}
        </span>
      </div>
      <Dependents
        showWhen={!!expanded}
        dependents={dependents}
        columnWidth={columnWidth || 0}
      />
    </div>
  );
};
