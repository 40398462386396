import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { CircleComponent } from './CircleComponent';
import { matchPathname } from '../../../../../utils/matchPathname';
import { EnrollmentPathname } from '../../../../../utils/constants';
import { stepperStyle } from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';

const loginOrSignUpPath = [
  EnrollmentPathname.LOGIN,
  EnrollmentPathname.SIGNUP,
  EnrollmentPathname.PERSONIFY_LAND,
  EnrollmentPathname.PERSONIFY_SSO_LINK,
];
const stepOneCompleted = [
  EnrollmentPathname.VERIFY,
  EnrollmentPathname.VERIFIED_ACCOUNT,
  EnrollmentPathname.FINISH,
  EnrollmentPathname.PAYMENT,
  EnrollmentPathname.PROCESSED_PAYMENT,
];
const verificationStep = [
  EnrollmentPathname.VERIFY,
  EnrollmentPathname.VERIFIED_ACCOUNT,
];
const paymentStep = [
  EnrollmentPathname.PAYMENT,
  EnrollmentPathname.PROCESSED_PAYMENT,
];
const finishStep = [EnrollmentPathname.FINISH];

const StepComponent: FC = () => {
  const { pathname } = useLocation();

  const { translate } = useTranslate();
  return (
    <div css={stepperStyle}>
      <CircleComponent
        step="1"
        label={translate('stepper.logIn')}
        isActive={matchPathname(pathname, loginOrSignUpPath)}
        isDone={matchPathname(pathname, stepOneCompleted)}
        dataTestId="login-step"
      />
      <CircleComponent
        step="2"
        label={translate('stepper.verify')}
        isActive={matchPathname(pathname, verificationStep)}
        isDone={matchPathname(pathname, paymentStep.concat(finishStep))}
        dataTestId="verify-step"
      />
      <CircleComponent
        step="3"
        label={translate('stepper.finish')}
        isActive={matchPathname(pathname, paymentStep)}
        isDone={matchPathname(pathname, finishStep)}
        dataTestId="finish-step"
      />
    </div>
  );
};

export default StepComponent;
