import { EnrollmentPathname, STATIC_CDN_URL } from '../../../utils/constants';
import env from '../../../config/env';

export const Routes = {
  GINGER_SOLERA_ERROR: EnrollmentPathname.GINGER_SOLERA_ERROR,
  GINGER_SOLERA_FINISH: EnrollmentPathname.GINGER_SOLERA_FINISH,
  GINGER_SOLERA_LAND: EnrollmentPathname.GINGER_SOLERA_LAND,
  GYMPASS_LAND: EnrollmentPathname.GYMPASS_LAND,
  HEADSPACE_APP: `${env.WEB_APP}`,
  HEADSPACE_CARE_SOLERA_ERROR: EnrollmentPathname.HEADSPACE_CARE_SOLERA_ERROR,
  HEADSPACE_CARE_SOLERA_FINISH: EnrollmentPathname.HEADSPACE_CARE_SOLERA_FINISH,
  HEADSPACE_CARE_SOLERA_LAND: EnrollmentPathname.HEADSPACE_CARE_SOLERA_LAND,
  HEADSPACE_LOGIN: `${env.WEBSITE_HOST}/login`,
  LOGOUT_CALLBACK_REDIRECT: (slug: string): string =>
    `${env.HOST}/member-enroll/callback?${slug}`,
  PERSONIFY_CARE_CONFIRMATION: EnrollmentPathname.PERSONIFY_CARE_CONFIRMATION,
  PERSONIFY_CARE_CONSENT: EnrollmentPathname.PERSONIFY_CARE_CONSENT,
  PERSONIFY_CARE_ERROR: EnrollmentPathname.PERSONIFY_CARE_ERROR,
  PERSONIFY_CARE_LAND: EnrollmentPathname.PERSONIFY_CARE_LAND,
  PERSONIFY_LAND: EnrollmentPathname.PERSONIFY_LAND,
  PERSONIFY_SELECT_ORG: EnrollmentPathname.PERSONIFY_SELECT_ORG,
  PERSONIFY_SSO_LINK: EnrollmentPathname.PERSONIFY_SSO_LINK,
  VP_CARE_LAND: EnrollmentPathname.VP_CARE_LAND,
  VP_LAND: EnrollmentPathname.VP_LAND,
  SOLERA_LAND: EnrollmentPathname.SOLERA_LAND,
  SSO_LOGOUT_CALLBACK_REDIRECT: (healthProvider: string): string =>
    `${env.HOST}/member-enroll/callback?healthProvider=${healthProvider}`,
} as const;

export const getUnifiedEligibilityRoutes = (slug?: string) => {
  const BASE_PATH = `/v3/${slug}/member-enroll`;

  return {
    ENROLLMENT_LOADING_PATH: `${BASE_PATH}/enrollment-loading`,
    ERROR_PATH: `${BASE_PATH}/error`,
    EXISTING_ACCOUNT: `${BASE_PATH}/existing-account`,
    EXIT_ONBOARDING_PATH: `${BASE_PATH}/exit-onboarding`,
    EXPIRED_CONTRACT: `${BASE_PATH}/expired-contract`,
    EXPIRED_DEPENDENT_LINK: `${BASE_PATH}/expired-dependent-link`,
    EXPIRED_LINK: `${BASE_PATH}/expired-link`,
    LOADING_PATH: `${BASE_PATH}/loading`,
    NO_SEATS: `${BASE_PATH}/no-seats`,
    ONBOARDING_CONSENT_PATH: `${BASE_PATH}/onboarding/consent`,
    ONBOARDING_GINGER_RECOMMENDED_PATH: `${BASE_PATH}/onboarding/download-ginger`,
    ONBOARDING_NEXT_STEPS_PATH: `${BASE_PATH}/onboarding/next-steps`,
    ONBOARDING_PATH: `${BASE_PATH}/onboarding`,
    ONBOARDING_QUESTIONS_PATH: `${BASE_PATH}/onboarding/questions`,
    ONBOARDING_WELCOME_PATH: `${BASE_PATH}/onboarding/welcome`,
    SCHEDULING_PATH: `${BASE_PATH}/onboarding/scheduling`,
    V3_LOGIN_PATH: `${BASE_PATH}/login`,
    VERIFY_IDENTITY_ERROR_PATH: `${BASE_PATH}/verify-identity-error`,
    VERIFY_IDENTITY_PATH: `${BASE_PATH}/verify-identity`,
    VERIFY_PATH: `${BASE_PATH}/verify`,
  } as const;
};

export const ENROLLMENT_CDN_URL = `${STATIC_CDN_URL}/b2b-dashboard/memberEnrollmentV2`;
