import { css } from '@emotion/react';
import {
  Blue,
  bodyMCss,
  borderRadiusM,
  borderRadiusXS,
  borderWidthS,
  borderWidthXS,
  colorTextLink,
  colorTextLinkStrong,
  cssWithMq,
  fontSize2XL,
  fontSize3XL,
  fontSize5XL,
  fontSizeL,
  fontSizeM,
  fontSizeS,
  fontSizeXL,
  fontWeightBold,
  Grey,
  headingMCss,
  letterSpacingTight,
  lineHeightBody,
  lineHeightHeading,
  linkSCss,
  rem,
  SpacingInRemBySize,
  titleLCss,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { pageContainerStyle } from '../../styles/pageStyles';
import { WebUIComponentsBreakPoint } from '../../../../constants/breakpoint';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

export const marketingMessageStyle = cssWithMq({
  display: 'flex',
  flexDirection: 'column',
  height: rem(40.938),
  justifyContent: 'center',
  letterSpacing: letterSpacingTight,
  maxWidth: [rem(30.5), rem(30.5), rem(40)],
  padding: `0 ${rem(2.25)} 0 ${rem(11.875)}`,

  [WebUIComponentsBreakPoint.Tablet]: {
    height: 'unset',
    maxWidth: rem(34.875),
    padding: '0',
    textAlign: 'center',
  },
});

export const titleStyle = cssWithMq({
  fontSize: fontSize5XL,
  margin: [
    `${SpacingInRemBySize['4XL']} 0 ${SpacingInRemBySize.XS} 0`,
    `0 0 ${SpacingInRemBySize.XS} 0`,
  ],
  [WebUIComponentsBreakPoint.Tablet]: {
    margin: `${SpacingInRemBySize['4XL']} 0 ${SpacingInRemBySize.XS} 0`,
  },
});

export const messageStyle = css({
  color: WarmGrey[600],
  fontSize: fontSize3XL,
  fontWeight: fontWeightBold,
  margin: 0,
});

export const learnMoreMessageStyle = cssWithMq({
  a: {
    color: Blue[200],
    textDecoration: 'none',
  },
  color: WarmGrey[500],
  fontSize: [fontSizeS, fontSizeL, fontSizeL],
  lineHeight: lineHeightBody,
  margin: `${SpacingInRemBySize.XS} 0 0 0`,
});

export const layoutContainer = css({ height: '100vh', width: '100vw' });

export const iconGroupContainerStyle = cssWithMq({
  alignItems: 'flex-start',
  backgroundColor: White['000'],
  display: 'flex',
  flexDirection: ['column', 'row'],
  gap: [0, rem(1.875)],
  justifyContent: 'center',
  margin: 0,
  paddingLeft: [rem(1.25), 0],
  width: [rem(19.813), 'auto'],
});

export const iconGroupStyle = cssWithMq({
  '::before': {
    backgroundColor: WarmGrey[800],
    borderRadius: '50%',
    content: '""',
    display: ['block', 'none'],
    height: SpacingInRemBySize['2XS'],
    left: `-${SpacingInRemBySize.M}`,
    position: 'absolute',
    top: SpacingInRemBySize.S,
    width: SpacingInRemBySize['2XS'],
  },
  display: 'flex',
  flexDirection: ['row', 'column'],
  margin: 0,
  paddingTop: [0, SpacingInRemBySize.L],
  position: 'relative',
  textAlign: ['left', 'center'],
  width: ['inherit', rem(12.813), rem(18.75)],
});

export const iconStyle = cssWithMq({
  display: ['none', 'block'],
  margin: [0, 'auto'],
  paddingRight: [SpacingInRemBySize.M, 'inherit'],
  width: ['auto', rem(6.875), rem(8.75)],
});

export const centeredButtonStyle = cssWithMq({
  display: 'flex',
  justifyContent: 'center',
  margin: [
    `${SpacingInRemBySize.M} ${SpacingInRemBySize.XL} ${rem(2.375)} ${
      SpacingInRemBySize.XL
    }`,
    `${SpacingInRemBySize.M} ${SpacingInRemBySize.XL} ${SpacingInRemBySize['4XL']} ${SpacingInRemBySize.XL}`,
  ],
});

export const centeredContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: rem(90),
  textAlign: 'center',
});

export const subHeaderStyle = cssWithMq(titleLCss, {
  backgroundColor: [WarmGrey[100], White['000'], White['000']],
  margin: [0, 'auto', 'auto'],
  marginBottom: [0],
  marginTop: [null, SpacingInRemBySize.XL, SpacingInRemBySize['3XL']],
  textAlign: 'center',
  width: [null, '100vw', '80vw'],
});

export const landingPageHeroStyle = cssWithMq(pageContainerStyle(), {
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  marginTop: 0,
  overflow: 'visible',
  [WebUIComponentsBreakPoint.Mobile]: {
    paddingBottom: 'none',
  },
  [WebUIComponentsBreakPoint.Desktop]: {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero.svg)`,
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero-tablet.svg)`,
    display: 'block',
  },
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    backgroundPositionY: 'center',
    minHeight: rem(46.25),
  },
  [WebUIComponentsBreakPoint.DesktopWide]: {
    backgroundPositionY: 'bottom',
  },
});

export const landingPageHeroMobileStyle = css({
  alignItems: 'center',
  backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero-mobile.svg)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  display: 'flex',
  flexDirection: 'column',
  height: rem(27),
  maxHeight: '50vh',
  overflow: 'visible',
  paddingBottom: SpacingInRemBySize.XL,
  width: '100vw',

  [WebUIComponentsBreakPoint.Mobile]: {
    backgroundPosition: 'center',
    backgroundPositionY: 'bottom',
  },
});

export const landingPageHeroHeaderMobileStyle = css({
  fontSize: fontSize3XL,
  letterSpacing: letterSpacingTight,
  margin: `${rem(2.625)} 0 ${SpacingInRemBySize.XS} 0`,
  maxWidth: rem(17.625),
  padding: 0,
  textAlign: 'center',
});

export const landingPageHeroSubHeaderMobileStyle = css({
  color: WarmGrey[700],
  fontSize: fontSizeXL,
  fontWeight: fontWeightBold,
  margin: `0 0 ${rem(1.25)} 0`,
  maxWidth: rem(17.625),
  padding: 0,
  textAlign: 'center',
});

export const bundledInfoPicture = cssWithMq({
  margin: ['auto', SpacingInRemBySize.XL, SpacingInRemBySize.XL],
  marginTop: ['auto', SpacingInRemBySize.XL, 0],
  maxWidth: [rem(20.625), rem(21.75)],

  [WebUIComponentsBreakPoint.Tablet]: {
    margin: '0 auto',
  },
});

export const bundledInfoImage = cssWithMq({
  borderRadius: borderRadiusM,
  width: '100%',
});

export const bundledInfoCopy = cssWithMq(headingMCss, {
  '.Collapsible': {
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  alignSelf: ['center', 'center', 'flex-start'],
  color: WarmGrey[500],
  fontSize: [fontSizeXL, fontSize3XL],
  marginRight: [0, SpacingInRemBySize['3XL']],
  maxWidth: [rem(18.75), rem(28.625)],

  [WebUIComponentsBreakPoint.Tablet]: {
    marginRight: 0,
    textAlign: 'center',
  },

  textAlign: ['center', 'start'],
});

export const bundledInfoCopyTitle = cssWithMq(headingMCss, {
  color: WarmGrey[500],
  fontSize: [fontSizeS, fontSizeXL],
  marginTop: [SpacingInRemBySize.L, 0, rem(1.25)],
  textTransform: 'uppercase',
  width: [rem(18.75), rem(28.625)],

  [WebUIComponentsBreakPoint.Tablet]: {
    marginTop: SpacingInRemBySize['3XL'],
  },
});

export const arrowIcon = (isExpanded) =>
  css(bodyMCss, {
    ':after': {
      backgroundImage: `url(${ENROLLMENT_CDN_URL}/arrow-down.svg)`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      content: '""',
      display: 'inline-block',
      height: SpacingInRemBySize.XS,
      position: 'absolute',
      right: `-${SpacingInRemBySize.L}`,
      top: '50%',
      transform: `translateY(-35%) rotate(${isExpanded ? '180deg' : 0})`,
      transition: 'transform 0.3s ease-in-out',
      width: rem(0.875),
    },
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `${borderWidthXS} solid ${Blue[300]}`,
    color: Blue[300],
    cursor: 'pointer',
    letterSpacing: letterSpacingTight,
    lineHeight: lineHeightHeading,
    padding: 0,
    position: 'relative',
    textDecoration: 'none',
    textTransform: 'none',
  });

export const bundledInfoCopyAltText = css({ color: WarmGrey[700] });

export const bundledInfoCopyLink = css(linkSCss, {
  '&:active': { color: colorTextLinkStrong },
  '&:focus': {
    border: `${borderWidthS} solid ${colorTextLink}`,
    borderRadius: borderRadiusXS,
  },
  '&:hover': { color: colorTextLinkStrong },
  display: 'inline-block',
});

export const bundledItemRow = cssWithMq({
  display: 'flex',
  flexDirection: ['column-reverse', 'row'],
  justifyContent: 'center',
  margin: 'auto',
  maxWidth: rem(90),
  paddingTop: [SpacingInRemBySize['3XL'], rem(5.5), rem(6.625)],

  [WebUIComponentsBreakPoint.Tablet]: {
    flexDirection: 'column-reverse',
  },
});

export const faqWrapperStyle = css(centeredContainerStyle, {
  backgroundColor: WarmGrey[100],
  boxSizing: 'border-box',
  maxWidth: '100%',
});

export const faqContainerStyle = cssWithMq(centeredContainerStyle, {
  marginTop: [SpacingInRemBySize['2XL'], rem(3.75), rem(3.75)],
  maxWidth: [rem(20.375), rem(37.813)],
  textAlign: 'start',
});

export const faqTitleStyle = cssWithMq({
  fontSize: [fontSize2XL, fontSize3XL],
  fontWeight: fontWeightBold,
  letterSpacing: letterSpacingTight,
  margin: '0 auto',
});

export const resetStyle = css({
  margin: 0,
  padding: 0,
});

export const faqMessageStyle = cssWithMq({
  '.Collapsible__trigger.is-open p:after': {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/faq-minus.svg)`,
    transform: 'rotate(180deg)',
  },
  'a, div': {
    fontSize: [fontSizeM, fontSizeL],
  },
  color: WarmGrey[700],
  fontSize: [fontSizeM, fontSizeL],
  listStyle: 'none',

  span: {
    color: 'inherit',
    display: 'block',
    marginTop: rem(1.875),
  },
});

export const expandComponentStyle = css(resetStyle, {
  ':after': {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/faq-plus.svg)`,
    backgroundPosition: 'center',
    backgroundSize: `${SpacingInRemBySize.M}, ${SpacingInRemBySize.M}`,
    content: '""',
    height: SpacingInRemBySize.M,
    position: 'absolute',
    right: 0,
    top: 0,
    transition: 'all .2s',
    width: SpacingInRemBySize.M,
  },
  color: Grey['900'],
  cursor: 'pointer',
  padding: `0 ${SpacingInRemBySize['3XL']} 0 0`,
  position: 'relative',
});
