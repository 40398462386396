import { css } from '@emotion/react';
import {
  Divider,
  Modal,
  Orange,
  Red,
  SpacingInRemBySize,
  WarmGrey,
  White,
  bodyMCss,
  headingSCss,
} from '@headspace/web-ui-components';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import {
  CountryCode,
  CountryCodes,
} from '../../../../../../../../../constants/country';
import { useTranslate } from '../../../../../../../../../hooks/useTranslate';
import { Input } from '../../../../../../../../../shared-components/Input';
import { TextButton } from '../../../../../../../../../shared-components/buttons/TextButton';
import { getOrgMappingKey } from '../../../../../../../../../state/selectors/organizationSelectors';
import { isValidOrgMappingValue } from '../../../../../../../../../utils/validations';
import {
  flex,
  justifyContentSpaceBetween,
} from '../../../../../../../styles/flex';

const rowStyle = css(flex, justifyContentSpaceBetween, {
  '> div': {
    flexBasis: '48%',
  },
  width: '100%',
});

const cancelButtonStyle = css({
  ':hover': {
    backgroundColor: `${WarmGrey[100]} !important`,
    color: `${Orange[200]} !important`,
  },
  backgroundColor: `${White['000']} !important`,
  color: `${Orange[200]} !important`,
  marginRight: SpacingInRemBySize.M,
});

const modalContainerStyle = css({
  minWidth: '70vw',
  padding: SpacingInRemBySize.L,
});

const modalBodyStyle = css({
  maxHeight: '70vh',
  overflowY: 'scroll',
});

const modalFooterStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
});

const subtitleStyle = css(bodyMCss, {
  '> em': {
    color: Red[300],
    fontStyle: 'normal',
  },
  whiteSpace: 'pre-line',
});

export const ImportGingerChildOrgsModal = ({
  onCloseModal,
  onContinue,
  parentOrg,
}) => {
  const { translate } = useTranslate();
  const handleOnCloseModal = useCallback(() => {
    onCloseModal(false);
  }, [onCloseModal]);
  const [childOrgs, setChildOrgs] = useState(parentOrg.children);
  const [childOrgsErrors, setChildOrgsErrors] = useState<{
    [key: string]: { error: boolean; name: string; errorKey: string };
  }>(
    childOrgs.reduce(
      (o, key) => ({ ...o, [key.id]: { error: false, name: key.name } }),
      {},
    ),
  );

  const parentOrgMappingKey = useSelector(getOrgMappingKey);
  const [hasButtonValidationErrors, setHasButtonValidationErrors] = useState(
    true,
  );

  const handleOnChange = useCallback(
    (e, org) => {
      const { id, orgMappingValue, ...rest } = org;

      const orgIndex = childOrgs.findIndex((x) => x.id === id);
      const updated = [...childOrgs];

      updated[orgIndex] = {
        id,
        orgMappingValue: e.target.value,
        ...rest,
      };

      setChildOrgs(updated);

      const isEmpty = e.target.value.length > 0;

      const hasError =
        parentOrgMappingKey?.toLowerCase() === 'country' &&
        !CountryCodes.includes(e.target.value as CountryCode) &&
        isEmpty;

      const allMappingValues = childOrgs.map((org) => org.orgMappingValue);
      const hasDuplicates = allMappingValues.includes(e.target.value);

      const updatedChildOrgsErrors = { ...childOrgsErrors };
      updatedChildOrgsErrors[id].error = hasError || hasDuplicates;
      updatedChildOrgsErrors[id].errorKey = hasDuplicates
        ? 'validationErrors.uniqueValueError'
        : 'validationErrors.orgMappingValueError';

      setChildOrgsErrors(updatedChildOrgsErrors);
    },
    [childOrgs, isValidOrgMappingValue],
  );

  const handleOnContinue = useCallback(() => {
    handleOnCloseModal();
    onContinue(childOrgs);
  }, [childOrgs, onContinue, handleOnCloseModal]);

  useEffect(() => {
    const emptyValues = childOrgs.filter(
      (x) => x.orgMappingValue === undefined || x.orgMappingValue === '',
    );
    const hasErrors = Object.values(childOrgsErrors).filter((x) => x.error);

    setHasButtonValidationErrors(
      emptyValues.length > 0 || hasErrors.length > 0,
    );
  }, [childOrgs, childOrgsErrors]);

  return (
    <Modal
      dataTestIdPrefix="import-ginger-child-orgs"
      body={
        <div css={modalContainerStyle}>
          <h2 css={headingSCss} data-testid="import-ginger-modal-title">
            <FormattedMessage id="organizationSettings.orgHierarchy.import.title" />
          </h2>

          <p css={subtitleStyle} data-testid="import-ginger-modal-subtitle">
            <FormattedMessage id="organizationSettings.orgHierarchy.import.instruction" />
            <br />
            <FormattedMessage
              id="organizationSettings.orgHierarchy.import.numberOfChildOrgs"
              values={{
                childOrgTotal: parentOrg.children.length,
                parentOrgName: parentOrg.name,
                em: (((chunks) => <em>{chunks}</em>) as unknown) as ReactNode,
              }}
            />
          </p>

          <div css={modalBodyStyle}>
            {childOrgs.map((org) => {
              const { name, country, id, orgMappingValue } = org;
              return (
                <div key={`${name}-${id}`}>
                  <div css={rowStyle}>
                    <Input
                      name={`ginger_id_${id}`}
                      label={translate(
                        'organizationSettings.orgHierarchy.import.org.id',
                      )}
                      disabled={true}
                      value={id}
                    />

                    <Input
                      type="text"
                      name={`org_name_${id}`}
                      label={translate(
                        'organizationSettings.orgHierarchy.import.org.name',
                      )}
                      value={name}
                      disabled={true}
                    />
                  </div>
                  <div css={rowStyle}>
                    <Input
                      type="text"
                      name={`org_name_${country}`}
                      label={translate(
                        'organizationSettings.orgHierarchy.import.org.country',
                      )}
                      value={country}
                      disabled={true}
                    />
                    <Input
                      name={`org_mapping_key_${id}`}
                      label={`${translate(
                        'organizationSettings.orgHierarchy.import.org.mapping',
                      )} ${parentOrgMappingKey}`}
                      value={orgMappingValue}
                      onChange={(e) => handleOnChange(e, org)}
                      hasError={childOrgsErrors[id]?.error}
                      errorMessage={
                        childOrgsErrors[id]?.errorKey
                          ? translate(childOrgsErrors[id]?.errorKey, {
                              inputValue: orgMappingValue,
                            })
                          : ''
                      }
                    />
                  </div>
                  <Divider />
                </div>
              );
            })}
          </div>
        </div>
      }
      onClose={handleOnCloseModal}
      lockParentScroll={true}
      footer={
        <div css={modalFooterStyle}>
          <TextButton
            lg={true}
            dataTestId="cancel-import"
            value={translate('cancel')}
            css={cancelButtonStyle}
            onClick={handleOnCloseModal}
          />

          <TextButton
            lg={true}
            dataTestId="continue-import"
            value={translate('continue')}
            onClick={handleOnContinue}
            disabled={hasButtonValidationErrors}
          />
        </div>
      }
    />
  );
};
