import { css } from '@emotion/react';
import {
  borderRadiusM,
  cssWithMq,
  fontSize3XL,
  fontSize5XL,
  headingMCss,
  rem,
  SpacingInRemBySize,
  titleLCss,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { pageContainerStyle } from '../../styles/pageStyles';
import { WebUIComponentsBreakPoint } from '../../../../constants/breakpoint';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

export const marketingMessageStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `0 ${rem(2.25)}`,
  maxWidth: '40vw',
  height: rem(40.938),
  a: {
    textDecoration: 'none',
    marginLeft: rem(0.3),
  },

  [WebUIComponentsBreakPoint.Tablet]: {
    height: 'unset',
    maxWidth: rem(34.875),
    textAlign: 'center',
    padding: '0',
  },
});

export const titleStyle = css({
  [WebUIComponentsBreakPoint.Tablet]: {
    fontSize: fontSize5XL,
  },
});

export const messageStyle = css({
  [WebUIComponentsBreakPoint.Tablet]: {
    color: WarmGrey[700],
  },
});

export const layoutContainer = css({ width: '100vw' });

export const iconGroupContainerStyle = cssWithMq({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: White['000'],
  flexDirection: ['column', 'row', 'row'],
});

export const iconGroupStyle = cssWithMq({
  display: 'flex',
  flexDirection: ['row', 'column', 'column'],
  width: ['inherit', rem(25), rem(25)],
  textAlign: ['left', 'center', 'center'],
  margin: SpacingInRemBySize.M,
  paddingTop: [0, SpacingInRemBySize['5XL'], SpacingInRemBySize['5XL']],
});

export const iconStyle = cssWithMq({
  width: [SpacingInRemBySize['6XL'], rem(12), rem(12)],
  paddingRight: [SpacingInRemBySize.M, 'inherit', 'inherit'],
  margin: 'auto',
});

export const centeredButtonStyle = css({
  display: 'flex',
  justifyContent: 'center',
  margin: SpacingInRemBySize.XL,
});

export const centeredContainerStyle = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  maxWidth: rem(90),
  margin: 'auto',
});

export const subHeaderStyle = cssWithMq(titleLCss, {
  textAlign: 'center',
  backgroundColor: [WarmGrey[100], White['000'], White['000']],
  marginBottom: [0],
  width: [null, '100vw', '80vw'],
  margin: [null, 'auto', 'auto'],
  marginTop: [null, SpacingInRemBySize.XL, SpacingInRemBySize['3XL']],
});

export const landingPageHeroStyle = cssWithMq(pageContainerStyle(), {
  marginTop: SpacingInRemBySize.M,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  overflow: 'visible',
  paddingBottom: SpacingInRemBySize.XL,
  [WebUIComponentsBreakPoint.Mobile]: {
    paddingBottom: 'none',
  },
  [WebUIComponentsBreakPoint.Desktop]: {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero.svg)`,
  },
  [WebUIComponentsBreakPoint.Tablet]: {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero-tablet.svg)`,
  },
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    minHeight: rem(46.25),
    backgroundPositionY: 'center',
  },
  [WebUIComponentsBreakPoint.DesktopWide]: {
    backgroundPositionY: 'bottom',
  },
});

export const landingPageHeroMobileStyle = css({
  backgroundImage: `url(${ENROLLMENT_CDN_URL}/umd-landing-page-hero-mobile.svg)`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  height: rem(27),
  width: '100vw',
  overflow: 'visible',
  paddingBottom: SpacingInRemBySize.XL,
  maxHeight: '50vh',

  [WebUIComponentsBreakPoint.Mobile]: {
    backgroundPosition: 'center',
    backgroundPositionY: 'bottom',
  },
});

export const landingPageHeroHeaderMobileStyle = css({
  textAlign: 'center',
  position: 'absolute',
  top: rem(0.938),
  fontSize: fontSize3XL,
  paddingLeft: SpacingInRemBySize.M,
  paddingRight: SpacingInRemBySize.M,
});

export const landingPageFooterStyle = cssWithMq({
  justifyContent: 'center',
  display: 'flex',
  flexDirection: 'column',
});

export const landingPageMobileFooterStyle = cssWithMq({
  justifyContent: 'center',
  display: ['flex', 'none', 'none'],
  position: 'relative',
  marginTop: SpacingInRemBySize.XL,
});

export const landingPageMobileFooterHeaderStyle = css({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  marginTop: SpacingInRemBySize.XL,
});

export const landingPageFooterIcon = cssWithMq({
  display: ['none', 'flex', 'flex'],
});

export const bundledInfoImage = cssWithMq({
  borderRadius: borderRadiusM,
  margin: ['auto', SpacingInRemBySize.XL, SpacingInRemBySize.XL],
});

export const bundledInfoCopy = cssWithMq(headingMCss, {
  width: ['92vw', '100vw', '30vw'],
  textAlign: 'center',
  alignSelf: 'center',
  color: WarmGrey[500],
  maxWidth: ['100vw', rem(34.375), rem(34.375)],
});

export const bundledInfoCopyAltText = css({ color: WarmGrey[800] });

export const bundledItemRow = cssWithMq({
  display: 'flex',
  justifyContent: 'center',
  ':nth-of-type(odd)': {
    flexDirection: ['column-reverse', 'row', 'row'],
  },
  ':nth-of-type(even)': {
    flexDirection: ['column', 'row', 'row'],
  },
  img: {
    minWidth: ['inherit', rem(10), 'inherit'],
    maxWidth: ['90vw', rem(18.75), rem(31.25)],
  },
});
