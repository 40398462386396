import { ReactElement, ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

type InterpolationObj = Record<
  string,
  string | number | null | ((str: ReactNode) => ReactElement | string)
>;

export type TranslateFn = (
  stringPath: string,
  interpolationObj?: InterpolationObj,
) => string;

// Used within react components. If you need to translate outside of a component use ./i18n/utils/translate
export const useTranslate = () => {
  const { formatMessage } = useIntl();

  const translate: TranslateFn = useCallback(
    (stringPath, interpolationObj?) =>
      (formatMessage(
        { id: stringPath },
        interpolationObj,
      ) as unknown) as string,
    [formatMessage],
  );

  return {
    translate,
  };
};
