import { useEffect, FC } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { css } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@headspace/web-ui-components';

import * as organizationActions from '../../state/actions/organizationActions';
import ConditionalRender from './views/ConditionalPageRender';
import { Footer } from '../../shared-components/Footer/Footer';
import { EnrollmentHeader } from './views/shared-components/Header';
import { orgsWithEnrollmentRedirect } from './enrollment-config/org-redirect-config';
import { setMParticleIdentity } from '../../analytics/events/enrollmentEvents';
import {
  alignItemsCenter,
  alignItemsSpaceBetween,
  columnFlex,
  justifyContentCenter,
  justifyContentSpaceBetween,
} from '../dashboard/styles/flex';
import { useUpdateDocumentTitle } from '../../hooks/useUpdateDocumentTitle';
import {
  getActiveOrg,
  getIsV3Enrollment,
} from './state/selectors/orgSectionSelector/orgSectionSelector';
import { getIsVerified } from './state/selectors/geolocationSelector/geolocationSelectors';
import { getHsUserId } from './state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getUnifiedEligibilityRoutes } from './constants/routes';

const outerContainerStyle = css(
  columnFlex,
  alignItemsSpaceBetween,
  justifyContentSpaceBetween,
  {
    height: '100%',
  },
);

const spinnerContainerStyle = css(
  columnFlex,
  alignItemsCenter,
  justifyContentCenter,
  {
    height: '100vh',
    flex: 1,
  },
);

const uuidV4Regex = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const V2MemberEnrollmentRoutes: FC = () => {
  const { slugOrHeadspaceHealthId } = useParams<{
    slugOrHeadspaceHealthId: string;
  }>();
  const dispatch = useDispatch();
  const activeOrg = useSelector(getActiveOrg);
  const isVerified = useSelector(getIsVerified);
  const HSUserId = useSelector(getHsUserId);
  const redirectSlug: string | undefined =
    slugOrHeadspaceHealthId &&
    orgsWithEnrollmentRedirect[slugOrHeadspaceHealthId.toLowerCase()];
  const navigate = useNavigate();
  const isV3Enrollment = useSelector(getIsV3Enrollment);

  // URL rewrite `/:headspaceHealthId/member-enroll` to '/:slug/member-enroll'
  const activeOrgSlug = activeOrg?.enrollment?.slug;

  useUpdateDocumentTitle('Headspace');

  useEffect(() => {
    if (redirectSlug !== undefined) {
      window.location.replace(`/${redirectSlug}/member-enroll`);
    }

    if (!activeOrg) {
      if (
        slugOrHeadspaceHealthId &&
        uuidV4Regex.test(slugOrHeadspaceHealthId)
      ) {
        dispatch(
          organizationActions.getOrgByHeadspaceHealthId(
            slugOrHeadspaceHealthId,
          ),
        );
      } else if (slugOrHeadspaceHealthId) {
        dispatch(organizationActions.getOrgBySlug(slugOrHeadspaceHealthId));
      }
    }
  }, [activeOrg, dispatch, redirectSlug, slugOrHeadspaceHealthId]);

  useEffect(() => {
    if (activeOrgSlug && activeOrgSlug !== slugOrHeadspaceHealthId) {
      if (isV3Enrollment) {
        const { VERIFY_PATH } = getUnifiedEligibilityRoutes(activeOrgSlug);
        navigate(VERIFY_PATH);
      } else {
        navigate(`/${activeOrgSlug}/member-enroll`);
      }
    }
  }, [activeOrgSlug, navigate, isV3Enrollment, slugOrHeadspaceHealthId]);

  useEffect(() => {
    if (HSUserId) {
      setMParticleIdentity(HSUserId);
    }
  }, [HSUserId]);

  if (activeOrg) {
    const { hasSeats } = activeOrg;

    return (
      <div css={outerContainerStyle}>
        <EnrollmentHeader showStepComponent={hasSeats} />
        <ConditionalRender activeOrg={activeOrg} isVerified={isVerified} />
        <Footer />
      </div>
    );
  }

  return (
    <div css={spinnerContainerStyle}>
      <Spinner />
    </div>
  );
};

export const RedirectJoinToMemberEnroll: FC = () => {
  const { slugOrHeadspaceHealthId } = useParams<{
    slugOrHeadspaceHealthId: string;
  }>();
  return (
    <Navigate to={`/${slugOrHeadspaceHealthId}/member-enroll`} replace={true} />
  );
};
