import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import * as R from 'ramda';
import { determineHealthProviderByPathname } from '../../../../../utils/healthProvider';
import { Icon } from '../../../../../shared-components/Icon';
import { ActiveOrgState } from '../../../../../state/types/organization';
import { matchPathname } from '../../../../../utils/matchPathname';
import { EnrollmentPathname } from '../../../../../utils/constants';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  textContainer,
  joinMessageStyle,
  subHeaderStyle,
  headerStyle,
  additionalText,
} from './styles';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface JoinMessageProps {
  activeOrg?: ActiveOrgState;
}

const JoinMessage = memo(({ activeOrg }: JoinMessageProps) => {
  const { pathname, state } = useLocation();
  const { translate } = useTranslate();

  const name = state?.childData
    ? state?.childData.orgName
    : R.pathOr('Company Name', ['name'], activeOrg);
  const additionalCopy = R.pathOr(null, ['enrollment', 'body'], activeOrg);
  const voucher = R.pathOr('', ['partialPayVoucher'], activeOrg);
  const learnMoreUrl = R.pathOr(
    null,
    ['enrollment', 'learnMoreUrl'],
    activeOrg,
  );

  const personifySsoPathname = matchPathname(pathname, [
    EnrollmentPathname.PERSONIFY,
  ]);

  const healthProvider = determineHealthProviderByPathname(pathname);

  const soleraPathname = matchPathname(pathname, [
    EnrollmentPathname.SOLERA_LAND,
  ]);

  const subLabelMessage = () => {
    if (voucher !== '') {
      return translate('joinMessage.memberPaySubHeader', { companyName: name });
    }

    if (personifySsoPathname) {
      return translate('ssoEnrollment.personifyJoinMessage');
    }

    if (soleraPathname) {
      return translate('solera.joinMessage');
    }

    if (healthProvider) {
      return translate(`${healthProvider}.joinMessage`);
    }

    return translate('joinMessage.subHeader', { companyName: name });
  };

  return (
    <div css={joinMessageStyle}>
      <h1 css={headerStyle}>{translate('joinMessage.header')}</h1>
      <h2 css={subHeaderStyle} data-testid="join-message-sub-header">
        {subLabelMessage()}
      </h2>
      <div css={textContainer}>
        <Icon
          src={`${ENROLLMENT_CDN_URL}/light-gray-check.svg`}
          width={14}
          height={14}
        />
        <span>{translate('joinMessage.stressLessInJust10Days')}</span>
      </div>

      <div css={textContainer}>
        <Icon
          src={`${ENROLLMENT_CDN_URL}/light-gray-check.svg`}
          width={14}
          height={14}
        />
        <span>{translate('joinMessage.miniMeditationsForBusyDays')}</span>
      </div>

      <div css={textContainer}>
        <Icon
          src={`${ENROLLMENT_CDN_URL}/light-gray-check.svg`}
          width={14}
          height={14}
        />
        <span>{translate('joinMessage.reduceStress')}</span>
      </div>

      <div css={additionalText}>
        <span>
          {' '}
          {additionalCopy}
          {learnMoreUrl && (
            <a target="_blank" rel="noopener noreferrer" href={learnMoreUrl}>
              {' '}
              Learn more.
            </a>
          )}
        </span>
      </div>
    </div>
  );
});

export default JoinMessage;
