import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Orange } from '@headspace/web-ui-components';
import * as React from 'react';
import { css } from '@emotion/react';
import { AP_CDN_URL } from '../../../../../constants/routes';
import { IconButton } from '../../../../../../../shared-components/buttons/IconButton';

const clipBoardStyle = (view) =>
  css({
    alignItems: 'end',
    button: {
      alignItems: view ? 'flex-end' : 'center',
    },
    display: 'flex',
  });

const copiedTexStyle = css({
  color: Orange[200],
  marginBottom: 0,
});

interface CopyToClipboardProps {
  view?: boolean;
  enrollmentUrl: string;
}

export const CopyToClipboardWrapper: React.FC<CopyToClipboardProps> = ({
  view,
  enrollmentUrl,
}) => {
  const [copied, setCopyToClipboard] = React.useState(false);

  const handleSetCopyToClipboard = React.useCallback(() => {
    setCopyToClipboard(true);
  }, [setCopyToClipboard]);

  return (
    <div css={clipBoardStyle(view)}>
      <CopyToClipboard text={enrollmentUrl} onCopy={handleSetCopyToClipboard}>
        <IconButton
          fill={Orange[200]}
          src={`${AP_CDN_URL}/clipboard.svg`}
          background="none"
          hoverBackGroundColor="none"
          data-testid="copy-link-button"
        />
      </CopyToClipboard>
      {copied ? <span css={copiedTexStyle}>Copied.</span> : null}
    </div>
  );
};
