import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routes } from '../../../constants/routes';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { PersonifyCareWrapper } from '../../HealthHubViews/Personify/PersonifyCareWrapper/PersonifyCareWrapper';
import { ConfirmationComponent } from './ConfirmationComponent';

export const PersonifyCareConfirmation: FC = () => {
  const navigate = useNavigate();
  const hsUserId = useSelector(getHsUserId);

  useEffect(() => {
    if (!hsUserId) {
      navigate(Routes.PERSONIFY_CARE_LAND);
    }
  }, [hsUserId]);
  return (
    <PersonifyCareWrapper showConfirmationPage={true}>
      <ConfirmationComponent />
    </PersonifyCareWrapper>
  );
};
