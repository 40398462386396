import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { buttonClickthroughAction } from '../../../../../analytics/events/enrollmentEvents';
import { Icon } from '../../../../../shared-components/Icon';
import {
  ANDROID_APP_STORE,
  IOS_APP_STORE,
  ANDROID_LINK,
  IOS_LINK,
} from '../../../constants';
import { iconContainerStyle, visuallyHidden } from '../styles';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

export const AppLinks = () => {
  const dispatch = useDispatch();
  const handleClickAndroid = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(buttonClickthroughAction(ANDROID_APP_STORE));
      setTimeout(() => window.location.replace(ANDROID_LINK), 500);
    },
    [buttonClickthroughAction],
  );
  const handleClickIos = React.useCallback(
    (e) => {
      e.preventDefault();
      dispatch(buttonClickthroughAction(IOS_APP_STORE));
      setTimeout(() => window.location.replace(IOS_LINK), 500);
    },
    [buttonClickthroughAction],
  );

  const { translate } = useTranslate();

  return (
    <div css={iconContainerStyle}>
      <div>
        <a onClick={handleClickIos} href={IOS_LINK} data-testid="done-cta-ios">
          <Icon
            width={162}
            height={48}
            src={`${ENROLLMENT_CDN_URL}/app-store.svg`}
            aria-label={translate('finish.appStore')}
          />
          <span css={visuallyHidden}>
            {translate('finish.downloadAppStore')}
          </span>
        </a>
      </div>
      <div>
        <a
          onClick={handleClickAndroid}
          href={ANDROID_LINK}
          data-testid="done-cta-android"
        >
          <Icon
            width={162}
            height={48}
            src={`${ENROLLMENT_CDN_URL}/play-store.svg`}
            aria-label="finish.playStore"
          />
          <span css={visuallyHidden}>
            {translate('finish.downloadPlayStore')}
          </span>
        </a>
      </div>
    </div>
  );
};
