import { v4 as uuidv4 } from 'uuid';
import { ServiceRequestState } from '../state/slice/serviceRequestReducer';
import { EAP_PROVIDERS } from '../constants/eapMemberDetails';
import { ClientType, FORM_TYPE } from '../constants/eapServiceRequestConfig';

export const createUniqueKey = () => {
  const uuid = uuidv4();

  return `id-${uuid}`;
};

export const formatIntakeRequestData = (
  data: ServiceRequestState['formData'],
  clientType: ClientType,
  webAuthEmail: string,
  provider: EAP_PROVIDERS,
  serviceType: FORM_TYPE,
) => {
  const {
    clientServiceId,
    firstName,
    lastName,
    state,
    city,
    street,
    dob,
    email,
    company,
    gender,
    zipcode,
    phoneNumber,
    voicemailPreference,
    dayPreference,
    distanceToTravel,
    maritalStatus,
    needsTopic,
    preferredLanguage,
    therapistPreference,
    timePreference,
    wellnessQuestion1,
    wellnessQuestion2,
    wellnessQuestion3,
    wellnessQuestion4,
    weekdayTimePref,
    weekendTimePref,
  } = data;

  const newObj = {
    clientServiceId,
    company,
    dob,
    email: email || webAuthEmail,
    firstName,
    gender,
    lastName,
    phoneNumber: formatPhoneNumber(phoneNumber, provider),
    preferredLanguage,
    provider,
    serviceType,
    voicemailPreference,
  };

  if (serviceType === FORM_TYPE.WORK_AND_LIFE_SERVICES) {
    Object.assign(newObj, {
      weekdayTimePref,
      weekendTimePref,
    });
  } else {
    Object.assign(newObj, {
      city,
      clientType,
      dayPreference,
      maritalStatus,
      needsTopic,
      state,
      street,
      therapistPreference,
      timePreference,
      wellnessQuestion1: `Personal well-being: ${wellnessQuestion1}`,
      wellnessQuestion2: `Relationship connections: ${wellnessQuestion2}`,
      wellnessQuestion3: `Engagement in social roles: ${wellnessQuestion3}`,
      wellnessQuestion4: `Overall performance: ${wellnessQuestion4}`,
      zipcode,
    });
  }

  if (serviceType === FORM_TYPE.IN_PERSON_THERAPY) {
    Object.assign(newObj, {
      distanceToTravel,
    });
  }

  return newObj;
};

export const formatPhoneNumber = (phoneNumber: string, provider: string) => {
  const strippedPhoneNumber = phoneNumber.replace(/\D/g, '');
  if (strippedPhoneNumber.length < 1) return strippedPhoneNumber;
  let formattedNumber;
  if (provider === EAP_PROVIDERS.CCA) {
    if (
      strippedPhoneNumber.startsWith('1') &&
      strippedPhoneNumber.length === 11
    ) {
      formattedNumber = strippedPhoneNumber.replace(
        /(\d{1})(\d{3})(\d{3})(\d{4})/,
        '$2-$3-$4',
      );
    } else if (strippedPhoneNumber.charAt(0) !== '1') {
      formattedNumber = strippedPhoneNumber.replace(
        /(\d{3})(\d{3})(\d{4})/,
        '$1-$2-$3',
      );
    }
  } else if (provider === EAP_PROVIDERS.WPO) {
    formattedNumber = `+${strippedPhoneNumber}`;
  }

  return formattedNumber;
};

export const stripSpacesAndParentheses = (phoneNumber: string): string => {
  if (!phoneNumber) return phoneNumber;
  return phoneNumber.replace(/[\s()]/g, '');
};

export const isPhoneNumber = (phoneNumber: string): boolean => {
  return /^[\d\s\-().+]*$/.test(phoneNumber);
};
