import { ChangeEvent, FC, useCallback, useState } from 'react';
import * as Sentry from '@sentry/browser';
import * as R from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { FormContainer } from '../FormContainer/FormContainer';
import { Input } from '../../../../../shared-components/Input';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { logSentryRequestError } from '../../../../../utils/sentryHelpers';
import { containerStyle, buttonContainerStyle, subTitleStyle } from './styles';
import {
  postCancelV3AutoRenew,
  postCancelV2AutoRenew,
  postVerifyById,
} from '../../../rest-calls';
import {
  buttonClickthroughAction,
  EnrollmentEventNames,
} from '../../../../../analytics/events/enrollmentEvents';
import { ContractActivityStatus } from '../../../../../analytics/types';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  ChildOrganization,
  translatedErrors,
} from '../../../../../types/organization';
import {
  getActiveOrg,
  getSlug,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getHsUserId,
  getJWT,
  getPrivileges,
} from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getExternalSource,
  getIsV3Subscription,
  getSubscriptionId,
} from '../../../state/selectors/memberSubscriptionDetailsSelector/memberSubscriptionDetailsSelector';

interface VerifyByIdProps {
  companyName: string;
}

const VerifyById: FC<VerifyByIdProps> = ({ companyName }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const activeOrg = useSelector(getActiveOrg);
  const slug = useSelector(getSlug);
  const privileges = useSelector(getPrivileges);
  const userId = useSelector(getHsUserId);
  const token = useSelector(getJWT);
  const externalSource = useSelector(getExternalSource);
  const subscriptionId = useSelector(getSubscriptionId);
  const isV3Subscription = useSelector(getIsV3Subscription);
  const workIdFieldPlaceholderText = R.pathOr(
    null,
    ['enrollment', 'workIdFieldPlaceholderText'],
    activeOrg,
  );
  const partnerIdName =
    workIdFieldPlaceholderText || translate('verify.byId.partnerNumber');
  const [lastName, setLastName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [hasError, setHasError] = useState(false);
  const [idErrorMessage, setIdErrorMessage] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');

  const { partialPayVoucher } = activeOrg || {};
  const PAID_SUBSCRIBER = privileges.includes('SUBSCRIBER');
  const PAID_SUBSCRIBER_ZUORA = PAID_SUBSCRIBER && externalSource === 'ZUORA';
  const NOT_B2B_SUBSCRIBER = !R.contains('B2B_CONTENT', privileges);
  const verifyButtonLabel = translate('verify.byId.verify');

  const enroll = async () => {
    Sentry.addBreadcrumb({
      category: 'enroll callback',
      level: Sentry.Severity.Info,
      message: 'enroll postverifyById',
    });
    try {
      let childData: ChildOrganization | null = null;

      const data = await postVerifyById(
        token,
        { id: employeeId, name: lastName },
        slug,
      );

      const eligibleOrgId = data?.data?.eligibleOrgId;

      if (eligibleOrgId && eligibleOrgId !== activeOrg?.id) {
        childData = activeOrg?.childOrgs?.find(
          (child: ChildOrganization) => child.orgId === eligibleOrgId,
        );
      }

      if (PAID_SUBSCRIBER_ZUORA || (PAID_SUBSCRIBER && NOT_B2B_SUBSCRIBER)) {
        return navigate(`/${slug}/member-enroll/turn-off-auto-pay`);
      }
      if (partialPayVoucher) {
        return navigate(`/${slug}/member-enroll/payment`);
      }

      return navigate(`/${slug}/member-enroll/verified-account`, {
        state: {
          childData,
        },
      });
    } catch (e) {
      const responseErrorMessage = R.pathOr(
        e,
        ['response', 'data', 'errors', '0', 'data'],
        e,
      );

      setHasError(true);

      if (translatedErrors.includes(responseErrorMessage)) {
        setIdErrorMessage(
          translate(`errors.${responseErrorMessage}`, { partnerIdName }),
        );
      } else {
        setIdErrorMessage(responseErrorMessage);
      }
      logSentryRequestError(e, 'verifybyId');
      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
      return false;
    }
  };

  const enrollAndCancelAutoRenew = async () => {
    enroll()
      .then(() => {
        return isV3Subscription
          ? postCancelV3AutoRenew(token, subscriptionId).catch((e) =>
              logSentryRequestError(e, 'postCancelV3AutoRenew'),
            )
          : postCancelV2AutoRenew(token, userId, subscriptionId).catch((e) =>
              logSentryRequestError(e, 'postCancelV2AutoRenew'),
            );
      })
      .catch((e) => logSentryRequestError(e, 'enrollAndCancelAutoRenew'));
  };

  const handleVerification = () => {
    dispatch(buttonClickthroughAction(verifyButtonLabel));
    if (handleValidateInput()) {
      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
      return null;
    }
    if (PAID_SUBSCRIBER_ZUORA) {
      return enrollAndCancelAutoRenew();
    }
    return enroll();
  };

  const handleValidateInput = useCallback(() => {
    let hasError = false;
    if (employeeId === '') {
      setIdErrorMessage(
        translate('enrollment.errors.EMPTY_ID', { partnerIdName }),
      );
      hasError = true;
    } else {
      setIdErrorMessage('');
    }
    if (lastName === '') {
      setNameErrorMessage(translate('enrollment.errors.EMPTY_LAST_NAME'));
      hasError = true;
    } else {
      setNameErrorMessage('');
    }

    setHasError(hasError);
    return hasError;
  }, [employeeId, lastName, partnerIdName, translate]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setHasError(false);

    const {
      target: { id, value },
    } = e;
    if (id === 'last-name') {
      setNameErrorMessage('');
      setLastName(value);
    }

    if (id === 'id-number') {
      setIdErrorMessage('');
      setEmployeeId(value);
    }
  }, []);

  return (
    <FormContainer
      headerTag="h3"
      title={translate('verify.verifyYourAccess')}
      termsMessage={true}
      isTestVariant={true}
    >
      <div css={containerStyle(true)}>
        <div css={subTitleStyle()}>
          <p>
            {translate('verify.weNeedToVerifyAccess', {
              ORG_NAME: companyName,
            })}
          </p>
        </div>
        <div>
          <Input
            aria-label={translate(
              'enrollment.elfDependent.lastName.placeholder',
            )}
            data-testid="last-name-input"
            name="last-name"
            errorMessage={nameErrorMessage}
            hasError={hasError && nameErrorMessage.length > 0}
            onChange={handleChange}
            placeholder={translate(
              'enrollment.elfDependent.lastName.placeholder',
            )}
            shouldFocusError={true}
            type="text"
          />
          <Input
            aria-label={partnerIdName}
            data-testid="id-number-input"
            errorMessage={idErrorMessage}
            hasError={hasError && idErrorMessage.length > 0}
            name="id-number"
            onChange={handleChange}
            placeholder={partnerIdName}
            shouldFocusError={true}
            type="text"
          />
          <div css={buttonContainerStyle()}>
            <TextButton
              textColor="#FFFF"
              hoverColor="#0884FF"
              backgroundColor="#0884FF"
              dataTestId="verify-submit-button"
              value={verifyButtonLabel}
              pill={true}
              width="100%"
              onClick={handleVerification}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default VerifyById;
