import { useCallback, useEffect } from 'react';
import * as React from 'react';
import {
  FormType,
  LOGIN_FORM_TYPE,
  SIGNUP_FORM_TYPE,
} from '@headspace/web-auth';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { YesOrNoCard } from '../../shared-components/YesOrNoCard';
import {
  buttonClickthroughAction,
  existingAccountPromptScreenViewAction,
} from '../../../../../analytics/events/enrollmentEvents';
import { determineHealthProviderByPathname } from '../../../../../utils/healthProvider';
import { useTranslate } from '../../../../../hooks/useTranslate';

interface ExistingAccountCardProps extends ExistingAccountCardLabels {
  handleClick: (formToShow: FormType) => void;
}

export interface ExistingAccountCardLabels {
  titleOverride?: string;
  yesButtonOverride?: string;
  noButtonOverride?: string;
}

export const ExistingAccountCard: React.FC<ExistingAccountCardProps> = ({
  handleClick,
  titleOverride,
  yesButtonOverride,
  noButtonOverride,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const healthProvider = determineHealthProviderByPathname(pathname);
  const planId = searchParams.get('planId') ?? undefined;
  const { translate } = useTranslate();

  const titleTextValue = translate(
    'ssoEnrollment.accountLinkOrLogin.existing-account-question',
  );
  const yesButtonTextValue = translate(
    'ssoEnrollment.accountLinkOrLogin.yes-existing-account',
  );
  const noButtonTextValue = translate('joinMessage.noAccount');
  const handleYesClick = useCallback(() => {
    dispatch(buttonClickthroughAction(yesButtonTextValue));
    handleClick(LOGIN_FORM_TYPE);
  }, [handleClick]);
  const handleNoClick = useCallback(() => {
    dispatch(buttonClickthroughAction(noButtonTextValue));
    handleClick(SIGNUP_FORM_TYPE);
  }, [handleClick]);

  useEffect(() => {
    dispatch(existingAccountPromptScreenViewAction(healthProvider, planId));
  }, [dispatch, healthProvider, planId]);

  return (
    <YesOrNoCard
      titleTextValue={titleOverride || titleTextValue}
      yesButtonTextValue={yesButtonOverride || yesButtonTextValue}
      handleYesButtonClick={handleYesClick}
      noButtonTextValue={noButtonOverride || noButtonTextValue}
      handleNoButtonClick={handleNoClick}
    />
  );
};
