import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import {
  faqComponentStyles,
  termsComponentStyles,
} from '../../styles/verificationStyles';
import { FAQ_PAGE_LINK_PERSONIFY, FAQ_PAGE_LINK_V2 } from '../../constants';
import { HealthProvider } from '../../../../constants/healthProvider';
import { getHealthProvider } from '../../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { useTranslate } from '../../../../../../hooks/useTranslate';

interface TermsProps {
  faqLinkOverride?: string;
  showPrivacyAndTerms?: boolean;
}

export const Terms: FC<TermsProps> = ({
  faqLinkOverride,
  showPrivacyAndTerms = true,
}) => {
  const healthProvider = useSelector(getHealthProvider);
  const { translate } = useTranslate();
  let faqLink;

  if (faqLinkOverride) {
    faqLink = faqLinkOverride;
  } else if (healthProvider === HealthProvider.PERSONIFY_CARE) {
    faqLink = FAQ_PAGE_LINK_PERSONIFY;
  } else {
    faqLink = FAQ_PAGE_LINK_V2;
  }

  return (
    <>
      {showPrivacyAndTerms ? (
        <div css={termsComponentStyles}>
          <FormattedMessage
            id="enrollment.verify.supportMessage"
            values={{
              privacyLink: (chunks) => (
                <a
                  href={translate(
                    'headspaceHub.footer.legalFooter.links.privacyPolicy.url',
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
              termsLink: (chunks) => (
                <a
                  href={translate(
                    'headspaceHub.footer.legalFooter.links.termsAndConditions.url',
                  )}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>
              ),
            }}
          />
        </div>
      ) : null}
      <div css={faqComponentStyles} data-testid="terms-faq-component">
        <FormattedMessage
          id="enrollment.verify.faq"
          values={{
            link: (chunks) => (
              <a
                href={faqLink}
                target="_blank"
                rel="noopener noreferrer"
                data-testid="faq-link"
              >
                {chunks}
              </a>
            ),
          }}
        />
      </div>
    </>
  );
};
