import { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Blue, White } from '@headspace/web-ui-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { buttonClickthroughAction } from '../../../../../analytics/events/enrollmentEvents';
import { getLogoEnv } from '../../../../../utils/getLogo';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { Divider } from '../../shared-components/Divider/Divider';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { Icon } from '../../../../../shared-components/Icon';
import { useTranslate } from '../../../../../hooks/useTranslate';

import {
  containerStyle,
  emailTextStyle,
  emailSubLabelStyle,
  buttonContainerStyle,
  orgLogoStyle,
  logoContainer,
} from '../styles';
import { Organization } from '../../../../../types/organization';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface VerifiedAccountProps {
  activeOrg: Organization;
  email: string;
}

const VerifiedAccount: FC<VerifiedAccountProps> = ({ activeOrg, email }) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { state } = useLocation();
  const navigate = useNavigate();

  const {
    name: activeName,
    logo: activeLogo,
    enrollment: { slug },
  } = activeOrg;
  const name = state?.childData?.orgName ?? activeName;
  const logo = state?.childData?.orgLogo ?? activeLogo;

  const handleClick = () => {
    dispatch(buttonClickthroughAction(translate('next')));
    navigate(`/${slug}/member-enroll/finish`);
  };

  return (
    <FormContainer
      headerTag="h3"
      title={translate('verifiedAccount.formTitle')}
      termsMessage={true}
    >
      <div data-testid="verified-account-id" css={containerStyle}>
        <div>
          <p data-testid="verified-account-id-name">
            {translate('verifiedAccount.onTheTeam', { name })}
          </p>
          <div css={emailTextStyle}>{email}</div>
          <div css={emailSubLabelStyle}>
            {translate('verifiedAccount.headspaceAccount')}
          </div>
        </div>

        <Divider />

        <div>
          <div css={logoContainer}>
            {logo && (
              <img
                css={orgLogoStyle}
                src={getLogoEnv(logo)}
                data-testid="org-logo"
                alt="org-logo"
              />
            )}

            <Icon
              src={`${ENROLLMENT_CDN_URL}/green-circle-check.svg`}
              width={20}
              height={20}
            />
          </div>

          <div css={emailSubLabelStyle}>
            {translate('verifiedAccount.supportedBy')}
          </div>
          <div css={buttonContainerStyle}>
            <TextButton
              textColor={White['000']}
              hoverColor={Blue[200]}
              backgroundColor={Blue[300]}
              dataTestId="verified-next-button"
              value={translate('next')}
              pill={true}
              width="100%"
              onClick={handleClick}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};

export default VerifiedAccount;
