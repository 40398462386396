import * as React from 'react';
import { Orange, WarmGrey } from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';
import { SFTP_STEPS, STEP_STATUS } from '../../../../constants';
import { SFTPUploadButton } from './SFTPUploadButton';
import { Step } from '../../../../../../shared-components/Steps/Step';
import { activeChildrenWrapper, stepsWrapper } from './styles';
import { buildStepStatuses } from '../../../../../../shared-components/Steps/utils';
import { useTranslate } from '../../../../../../hooks/useTranslate';

export interface SFTPStepsProps {
  host: string;
  username: string;
  activeStep: number;
  handleNextStep(override?: number): void;
}

export const SFTPSteps: React.FC<SFTPStepsProps> = ({
  host,
  username,
  activeStep,
  handleNextStep,
}) => {
  const { translate } = useTranslate();
  const stepStatuses = buildStepStatuses(SFTP_STEPS, activeStep);
  const isFirstStepActive = stepStatuses[0].status === STEP_STATUS.ACTIVE;
  const handleUpload = React.useCallback(() => {
    if (isFirstStepActive) {
      handleNextStep();
    } else {
      handleNextStep(2);
    }
  }, [isFirstStepActive, handleNextStep]);
  const handleClick = React.useCallback(() => {
    handleNextStep();
  }, [handleNextStep]);
  const step2Children = (
    <ol css={activeChildrenWrapper}>
      <li>
        {`${translate('organizationSettingsSFTP.steps.step_2.bullet_1')} `}
        <strong>{host}</strong>
      </li>
      <li>
        <FormattedMessage
          id="organizationSettingsSFTP.steps.step_2.bullet_2"
          values={{
            strong: (((chunks: ReactNode) => (
              <strong>{chunks}</strong>
            )) as unknown) as ReactNode,
            username,
          }}
        />
      </li>
    </ol>
  );
  const step3Children = (
    <>
      <ol css={activeChildrenWrapper}>
        <li>
          <FormattedMessage
            id="organizationSettingsSFTP.steps.step_3.bullet_1"
            values={{
              strong: (((chunks: ReactNode) => (
                <strong>{chunks}</strong>
              )) as unknown) as ReactNode,
            }}
          />
        </li>
        <li>{translate('organizationSettingsSFTP.steps.step_3.bullet_2')}</li>
      </ol>
    </>
  );

  return (
    <div css={stepsWrapper(isFirstStepActive)}>
      <Step
        ctaText={translate('organizationSettingsSFTP.steps.next_step')}
        handleNextStep={handleClick}
        showCTA={false}
        showDivider={activeStep > 2}
        status={stepStatuses[0].status}
        step={stepStatuses[0].step}
        subtitle={translate('organizationSettingsSFTP.steps.step_1.subtitle')}
        title={translate('organizationSettingsSFTP.steps.step_1.title')}
      >
        <SFTPUploadButton
          text={translate('organizationSettingsSFTP.upload_key')}
          callback={handleUpload}
          thin={!isFirstStepActive}
          className="uploadButton"
          color={isFirstStepActive ? Orange[200] : WarmGrey[600]}
        />
      </Step>
      <Step
        activeChildren={step2Children}
        ctaText={translate('organizationSettingsSFTP.steps.next_step')}
        handleNextStep={handleClick}
        showCTA={true}
        showDivider={activeStep === 1 || activeStep > 3}
        status={stepStatuses[1].status}
        step={stepStatuses[1].step}
        subtitle={translate('organizationSettingsSFTP.steps.step_2.subtitle')}
        title={translate('organizationSettingsSFTP.steps.step_2.title')}
      />
      <Step
        activeChildren={step3Children}
        ctaText={translate('organizationSettingsSFTP.steps.next_step')}
        handleNextStep={handleClick}
        showCTA={true}
        showDivider={activeStep === 1 || activeStep === 2 || activeStep > 4}
        status={stepStatuses[2].status}
        step={stepStatuses[2].step}
        subtitle={translate('organizationSettingsSFTP.steps.step_3.subtitle')}
        title={translate('organizationSettingsSFTP.steps.step_3.title')}
      />
      <Step
        ctaText={translate('organizationSettingsSFTP.steps.finish')}
        handleNextStep={handleClick}
        showCTA={true}
        status={stepStatuses[3].status}
        step={stepStatuses[3].step}
        subtitle={
          <FormattedMessage
            id="organizationSettingsSFTP.steps.step_4.subtitle"
            values={{
              strong: (((chunks: ReactNode) => (
                <strong>{chunks}</strong>
              )) as unknown) as ReactNode,
            }}
          />
        }
        title={translate('organizationSettingsSFTP.steps.step_4.title')}
      />
    </div>
  );
};
