import { FC, PropsWithChildren, useCallback, useMemo } from 'react';
import {
  useActiveBreakpoint,
  Breakpoint,
  Divider,
  MeditationPlayer,
} from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  audioPlayerContainer,
  audioSectionStyle,
  footerContainerStyle,
  sectionContainerStyle,
  dividerStyle,
} from './styles/pageWrapperStyle';

import {
  onboardingContentConsumedEvent,
  OnboardingEventNames,
} from '../../../../../../analytics/events/onboardingEvents';
import { ContractActivityStatus } from '../../../../../../analytics/types';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { SupportedLanguageCode } from '../../../../../../types/language';
import { ImmediateCrisisSupportSection } from './ImmediateCrisisSupportSection';
import { useFusionImmediateCrisisSupport } from '../../../../../../featureFlags/fusionImmediateCrisisSupport';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';

interface OnboardingFooterProps extends PropsWithChildren {
  RightColumn?: FC;
}

const ENGLISH_MEDITATION_AUDIO =
  'https://assets.ctfassets.net/51rv07bk89f0/HS20200910094800003054/13c26c75a9a0f34b1a4dc5bdc75fec37/single-sos-feeling_overwhelmed-3m-en-170627__1508283269894.mp3';
const SPANISH_MEDITATION_AUDIO =
  'https://assets.ctfassets.net/51rv07bk89f0/HS20200910094800003057/16c7c130b144cd15665c8254748129f7/intl-sos-feeling_overwhelmed-3m-l_es_-g_m_-n_javi_-20190731_finalmix__1564690265794.mp3';

export const OnboardingFooter: FC<OnboardingFooterProps> = ({
  RightColumn,
  children,
}) => {
  const [fusionImmediateCrisisSupport] = useFusionImmediateCrisisSupport();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const activeBreakpoint = useActiveBreakpoint();
  const isDesktopView = useMemo(() => activeBreakpoint === Breakpoint.DESKTOP, [
    activeBreakpoint,
  ]);
  const isTabletView = useMemo(() => activeBreakpoint === Breakpoint.TABLET, [
    activeBreakpoint,
  ]);
  const isTabletOrDesktopView = isTabletView || isDesktopView;
  const showImmediateCrisisSection =
    fusionImmediateCrisisSupport && isTabletOrDesktopView;

  const audioFile = {
    file: {
      url:
        language === SupportedLanguageCode.es
          ? SPANISH_MEDITATION_AUDIO
          : ENGLISH_MEDITATION_AUDIO,
    },
    title: translate('onboarding.footer.meditation'),
  };

  const handleAudioStart = useCallback(() => {
    dispatch(
      onboardingContentConsumedEvent(
        OnboardingEventNames.onboardingReliefMeditationStart,
        ContractActivityStatus.start,
      ),
    );
  }, [dispatch]);

  const handleAudioComplete = useCallback(() => {
    dispatch(
      onboardingContentConsumedEvent(
        OnboardingEventNames.onboardingReliefMeditationComplete,
        ContractActivityStatus.complete,
      ),
    );
  }, [dispatch]);

  const MemoizedMeditationPlayer = useMemo(
    () => (
      <MeditationPlayer
        audioFile={audioFile}
        onComplete={handleAudioComplete}
        onStart={handleAudioStart}
      />
    ),
    [audioFile, handleAudioComplete, handleAudioStart],
  );

  return (
    <div css={sectionContainerStyle}>
      <Divider css={dividerStyle} />
      <div css={footerContainerStyle(isTabletOrDesktopView)}>
        {!fusionImmediateCrisisSupport && (
          <div css={audioSectionStyle(isTabletOrDesktopView)}>
            <div>
              <h4>{translate('onboarding.footer.needHelp')}</h4>
            </div>
            <div css={audioPlayerContainer}>{MemoizedMeditationPlayer}</div>
          </div>
        )}
        {showImmediateCrisisSection && <ImmediateCrisisSupportSection />}
        <div>{isTabletOrDesktopView && RightColumn && <RightColumn />}</div>
      </div>
      {children}
    </div>
  );
};
