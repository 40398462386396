import { CoolGrey } from '@headspace/web-ui-components';
import { ReactNode } from 'react';
import { distanceInWords, format } from '../../../../utils/dates';
import {
  ACTIONABLE_MEMBER_STATUSES,
  MEMBER_STATUS,
  VERIFICATION_LINK_RESTRICT_BY_TYPES,
} from '../../constants';
import { MediumLabelSublabel } from '../../../../shared-components/text/MediumLabelSublabel';
import { SmallLabelSublabel } from '../../../../shared-components/text/SmallLabelSublabel';
import { ActionDropdown } from '../../../../shared-components/dropdowns/ActionDropdown';
import {
  MemberSearchEntry,
  MembersSortIndex,
  MemberStatus,
} from '../../../../rest/members';
import { StatusAndToolsLink } from './StatusAndToolsLink/StatusAndToolsLink';
import { RestrictBy } from '../../../../types/organization';
import { AP_CDN_URL } from '../../constants/routes';

const setDeactivationButton = (
  t,
  removeMembers,
  recastMemberId,
  statusModifier,
  isCSM,
  restrictBy,
  actions,
) => {
  const unRestricted =
    restrictBy === RestrictBy.UNRESTRICTED ||
    restrictBy === RestrictBy.EMAIL_DOMAIN;
  if (unRestricted || isCSM) {
    actions.push({
      action: () => {
        const response = window.confirm(t('members.removeMember'));
        if (response) {
          removeMembers([recastMemberId], statusModifier);
        }
      },
      display: t('members.deactivate'),
    });
  }
};

export function getActionItems({
  t,
  removeMembers,
  openVerificationLinkModal,
  recastMemberId,
  statusModifier,
  member,
  isCSM,
  restrictBy,
}): { action: () => void; display: string }[] {
  const actions: { action: () => void; display: string }[] = [];

  switch (member.status) {
    case MemberStatus.ACTIVE:
      setDeactivationButton(
        t,
        removeMembers,
        recastMemberId,
        statusModifier,
        isCSM,
        restrictBy,
        actions,
      );
      break;
    case MemberStatus.ELIGIBLE:
      if (isCSM) {
        actions.push({
          action: () => {
            openVerificationLinkModal(member.workEmail);
          },
          display: t('organizationSettings.enrollment.generateActivationLink'),
        });
      }
      break;
    default:
      return actions;
  }

  return actions;
}

export function getMemberSubLabel(
  restrictBy: string,
  workId: string | null | undefined,
  workEmail: string | undefined,
) {
  return (restrictBy === RestrictBy.EMPLOYEE_ID ||
    restrictBy === RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE) &&
    workId
    ? workId
    : workEmail;
}

export interface GenerateRowsOptions {
  members?: MemberSearchEntry[];
  statusModifier: string;
  removeMembers: (ids: number[], string) => void;
  openVerificationLinkModal: (email) => void;
  restrictBy: RestrictBy;
  t: I18next['t'];
  isCSM: boolean;
  isFusion: boolean;
}

type ActionOptions = {
  t: I18next['t'];
  removeMembers: (ids: number[], string) => void;
  openVerificationLinkModal: (email) => void;
  recastMemberId: number;
  statusModifier: string;
  member: MemberSearchEntry;
  isCSM: boolean;
  restrictBy: RestrictBy;
};

export interface GenerateRow {
  member: MemberSearchEntry;
  sortIndex: MembersSortIndex;
  id: number;
  fullName: ReactNode;
  status: ReactNode | null;
  activeSince: ReactNode | null;
  action: ReactNode | null;
}

export const generateRows = ({
  members,
  statusModifier,
  removeMembers,
  openVerificationLinkModal,
  restrictBy,
  t,
  isCSM,
  isFusion,
}: GenerateRowsOptions): GenerateRow[] => {
  return members
    ? members.map((member: MemberSearchEntry, idx: number) => {
        const {
          firstName,
          lastName,
          workId,
          workEmail,
          status = '',
          activeSince,
          memberId,
          hsUserId,
          id,
        } = member;

        const splitId = `${memberId || id}`.split(':');
        const recastMemberId = parseInt(splitId[splitId.length - 1], 10);
        const actionable =
          ACTIONABLE_MEMBER_STATUSES.includes(status) ||
          (VERIFICATION_LINK_RESTRICT_BY_TYPES.includes(restrictBy) &&
            status === MEMBER_STATUS.ELIGIBLE &&
            isCSM);
        const memberSubLabel = getMemberSubLabel(restrictBy, workId, workEmail);
        const isLastMember = members.length - 1 === idx && idx > 0;

        const actionOptions: ActionOptions = {
          isCSM,
          member,
          openVerificationLinkModal,
          recastMemberId,
          removeMembers,
          restrictBy,
          statusModifier,
          t,
        };
        const actionItems = getActionItems(actionOptions);

        const sortIndex: MembersSortIndex = {
          activeSince,
          fullName: `${firstName} ${lastName} ${workEmail}`,
          name: `${firstName} ${lastName} ${workEmail}`,
          status,
        };
        let nameLabel = `${firstName || ''} ${lastName || ''}`.trim();
        if (!nameLabel.length) {
          nameLabel = 'Member';
        }
        return {
          member,
          sortIndex,
          action:
            actionable && actionItems.length > 0 ? (
              <ActionDropdown
                items={actionItems}
                position={isLastMember ? 'topRight' : 'right'}
                icon={`${AP_CDN_URL}/dots-24.svg`}
                iconWidth={24}
                iconHeight={24}
                ctaColor={CoolGrey[500]}
              />
            ) : null,
          activeSince: activeSince ? (
            <div>
              <SmallLabelSublabel
                key={workEmail}
                label={format(activeSince, 'MMM D, YYYY')}
                sublabel={distanceInWords(
                  new Date(),
                  new Date(activeSince),
                  true,
                )}
              />
            </div>
          ) : null,
          fullName: (
            <MediumLabelSublabel
              key={workEmail}
              label={nameLabel}
              sublabel={memberSubLabel || ''}
            />
          ),
          id: recastMemberId,
          status: status
            ? StatusAndToolsLink({ hsUserId, isCSM, isFusion, status, t })
            : null,
        };
      })
    : [];
};
