import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import React from 'react';
import { getOrgManagement } from '../../../../../state/selectors/organizationSelectors';
import { Assets } from './components/Assets';

const rightPanelStyle = css({
  flexShrink: 1,
});

export const ComposedRightPanel: React.FC = () => {
  const {
    current_contract_primary_product,
    current_contract_eap_type,
  } = useSelector(getOrgManagement);

  return (
    <div css={rightPanelStyle}>
      <Assets
        primaryProduct={current_contract_primary_product}
        eapType={current_contract_eap_type}
      />
    </div>
  );
};
