import { White } from '@headspace/web-ui-components';
import { AP_CDN_URL } from '../../../apps/dashboard/constants/routes';
import { regular } from '../../../apps/dashboard/styles/fonts';
import { Icon } from '../../Icon';
import {
  closeToastStyle,
  rowsCountStyle,
  toastGroupStyle,
  toastStyle,
  toastWrapperStyle,
  unselectRowsStyle,
} from '../styles';
import { TableToastProps } from './types';
import { noop } from '../../../utils';

export const TableToast = ({
  selectedRowIds,
  onUnselect,
  toastAction,
  toastActionText,
  showToastAction,
}: TableToastProps) => {
  const rowsCount = selectedRowIds.length;
  return (
    <div
      data-testid="shared-table-scroll-toast"
      className="showSelectedToast"
      css={toastWrapperStyle}
    >
      <div css={[toastStyle, regular]}>
        <div css={toastGroupStyle}>
          <div
            onClick={onUnselect}
            onKeyDown={noop}
            tabIndex={0}
            role="button"
            css={closeToastStyle}
          >
            <Icon
              width={16}
              height={16}
              fill={White['000']}
              src={`${AP_CDN_URL}/close-16.svg`}
            />
          </div>
          <div css={toastGroupStyle}>
            <div
              data-testid="shared-table-scroll-toast-selected-count"
              css={rowsCountStyle}
            >
              {rowsCount}
            </div>
            <div>selected</div>
          </div>
        </div>
        {toastAction && showToastAction && (
          <div
            className="showSelectedToastAction"
            onClick={toastAction}
            onKeyDown={noop}
            tabIndex={0}
            role="button"
            css={[unselectRowsStyle, regular]}
          >
            {toastActionText}
          </div>
        )}
      </div>
    </div>
  );
};
