import { FC, PropsWithChildren } from 'react';
import { panelStyle } from '../../styles';
import { regular } from '../../../../../styles/fonts';

interface PanelWrapperProps extends PropsWithChildren {
  dataTestId?: string;
}

export const PanelWrapper: FC<PanelWrapperProps> = ({
  children,
  dataTestId,
}) => (
  <div css={[panelStyle, regular]} data-testid={dataTestId}>
    {children}
  </div>
);
