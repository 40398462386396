import * as React from 'react';
import { FC } from 'react';
import {
  AutoSizer as _AutoSizer,
  InfiniteLoader as _InfiniteLoader,
  Column as _Column,
  Table as _Table,
  AutoSizerProps,
  InfiniteLoaderProps,
  ColumnProps,
  TableProps,
} from 'react-virtualized';
import { headerRowStyle } from './styles';
import { TableSectionProps } from './types';

// This is intended to fix the issue where elements from react-virtualized
// cannot be used as JSX components due to incompatibilities with newer React types
// https://github.com/bvaughn/react-virtualized/issues/1739
export const AutoSizer = (_AutoSizer as unknown) as FC<AutoSizerProps>;
export const InfiniteLoader = (_InfiniteLoader as unknown) as FC<InfiniteLoaderProps>;
export const Column = (_Column as unknown) as FC<ColumnProps>;
export const Table = (_Table as unknown) as FC<TableProps>;

export const TableSection: React.FC<TableSectionProps> = ({
  rows,
  headerRow,
  columnWidthOffset,
  loadMoreRows,
  rowRenderer,
}) => {
  return (
    <InfiniteLoader
      threshold={1}
      isRowLoaded={({ index }) => !!rows[index]}
      loadMoreRows={loadMoreRows}
      rowCount={1000000}
    >
      {({ onRowsRendered, registerChild }) => (
        <AutoSizer>
          {({ width, height }) => (
            <Table
              ref={registerChild}
              onRowsRendered={onRowsRendered}
              width={width > 0 ? width : 900}
              height={height > 0 ? height : 700}
              headerHeight={76}
              rowHeight={96}
              rowCount={rows.length}
              headerStyle={{ headerRowStyle }}
              rowGetter={({ index }) => rows[index]}
              rowRenderer={rowRenderer}
            >
              {headerRow &&
                headerRow.map((col: any, i: number) => {
                  return (
                    <Column
                      label={col.label}
                      dataKey={col.key}
                      key={col.key}
                      disableSort={!col.sortBy}
                      width={Math.floor(
                        (width - columnWidthOffset) / headerRow.length,
                      )}
                      cellRenderer={({ cellData }) => cellData}
                    />
                  );
                })}
            </Table>
          )}
        </AutoSizer>
      )}
    </InfiniteLoader>
  );
};
