import * as React from 'react';
import { useSelector } from 'react-redux';
import { Blue, WarmGrey, White } from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import { TextButton } from '../../../../../../shared-components/buttons/TextButton';

import {
  outerContainerStyle,
  paddingStyle,
  continueButtonPaddingStyle,
} from './styles';
import { HealthProvider } from '../../../../constants/healthProvider';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getSSOOrgs } from '../../../../state/selectors/ssoSelector/ssoSelector';

export const SelectOrgPage: React.FC = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const ssoSlugs = useSelector(getSSOOrgs);
  const [selectedOrgSlug, setSelectedOrgSlug] = React.useState(null);

  const handleSelectedOrgSlug = (orgSlug) => (): void =>
    setSelectedOrgSlug(orgSlug);

  const handleRedirectToVerifyPage = () => {
    navigate(`/${selectedOrgSlug}/member-enroll/verify`);
  };

  return (
    <PageWrapper healthProvider={HealthProvider.PERSONIFY}>
      <FormContainer
        headerTag="h4"
        title={translate('ssoEnrollment.selectOrg.header')}
      >
        <div
          css={outerContainerStyle}
          data-testid="connect-personify-component-container"
        >
          <p>{translate('ssoEnrollment.selectOrg.subHeader')}</p>
          {ssoSlugs &&
            ssoSlugs.map(({ name, slug }, index) => (
              <div css={paddingStyle} key={slug}>
                <TextButton
                  dataTestId={`select-org-${index}`}
                  borderColor={WarmGrey[200]}
                  textColor={
                    slug === selectedOrgSlug ? White['000'] : WarmGrey[800]
                  }
                  hoverColor={
                    slug === selectedOrgSlug ? WarmGrey[900] : WarmGrey[200]
                  }
                  backgroundColor={
                    slug === selectedOrgSlug ? WarmGrey[800] : White['000']
                  }
                  pill={true}
                  width="100%"
                  lg={true}
                  value={name}
                  onClick={handleSelectedOrgSlug(slug)}
                />
              </div>
            ))}
          <div css={continueButtonPaddingStyle}>
            <TextButton
              dataTestId="select-org-continue-button"
              textColor={White['000']}
              hoverColor={!selectedOrgSlug ? WarmGrey[600] : Blue[200]}
              backgroundColor={!selectedOrgSlug ? WarmGrey[600] : Blue[200]}
              pill={true}
              width="100%"
              lg={true}
              value={translate('ssoEnrollment.selectOrg.continue')}
              disabled={!selectedOrgSlug}
              onClick={handleRedirectToVerifyPage}
            />
          </div>
        </div>
      </FormContainer>
    </PageWrapper>
  );
};
