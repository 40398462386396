import * as React from 'react';
import { css } from '@emotion/react';
import {
  borderWidthS,
  borderWidthXS,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontFamilyCss,
  fontSizeM,
  lineHeightBody,
  Orange,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

const tabNavigationStyle = css({
  borderBottom: `${borderWidthXS} solid ${WarmGrey[200]}`,
  display: 'flex',
  flexDirection: 'row',
  fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
  height: rem(3),
  outline: 'none',
  width: '100%',
});

const tabNavigationItemStyle = (isSelected: boolean) =>
  css(fontFamilyCss, {
    ':hover': {
      color: isSelected ? Orange[200] : WarmGrey[600],
    },
    ':last-of-type': {
      marginRight: 0,
    },
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: isSelected
      ? `${borderWidthS} solid ${Orange[200]}`
      : undefined,
    color: isSelected ? Orange[200] : WarmGrey[500],
    cursor: 'pointer',
    fontSize: fontSizeM,
    fontWeight: isSelected
      ? FONT_WEIGHT_TO_NUMERIC_VALUE.bold
      : FONT_WEIGHT_TO_NUMERIC_VALUE.normal,
    lineHeight: lineHeightBody,
    marginRight: SpacingInRemBySize.M,
    padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.M} ${SpacingInRemBySize.M} ${SpacingInRemBySize.M}`,
  });

export interface TabNavigationProps {
  links: {
    display: string;
    action: () => void;
    selected: boolean;
  }[];
}

export const TabNavigation: React.FC<TabNavigationProps> = ({ links }) => {
  const isOneItemSelected = links.filter((link) => link.selected).length === 1;

  return (
    <div css={tabNavigationStyle}>
      {links.map((item) => {
        const isActive = isOneItemSelected && item.selected;
        const onKeyUp = (e) => {
          if (isActive && e.key === 'Enter') {
            item.action();
          }
        };

        return (
          <button
            key={item.display}
            type="button"
            onClick={isActive ? undefined : () => item.action()}
            onKeyUp={onKeyUp}
            css={tabNavigationItemStyle(item.selected)}
            data-testid={`${item.display.toLocaleLowerCase()}-tab`}
          >
            {item.display}
          </button>
        );
      })}
    </div>
  );
};
