import { useEffect, FC, useMemo, useState, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  ResponsiveGrid,
  useActiveBreakpoint,
  Breakpoint,
} from '@headspace/web-ui-components';
import { pathOr } from 'ramda';

import { layoutContainer } from './styles/verificationPageStyle';
import { landingPageHeroStyle } from './styles/verificationPageStyleUmdWfm';
import { handleLogoutFactory } from '../../shared-components/Header/handleLogoutFactory';
import {
  LANDING_PAGE_ASSETS,
  usePreCacheImages,
} from '../../../../../hooks/usePreCacheImages';
import { BundledAppInfoUmdWfm } from './components/BundledAppInfoUmdWfm';
import { Header } from './components/Header';
import { LandingCardSet } from './components/LandingCardSet';
import { LandingFooter } from './components/LandingFooter';
import {
  getActiveOrg,
  getHealthHub,
  getSlug,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getHealthProvider } from '../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { LandingMobileHero } from './components/LandingMobileHero';

interface VerificationPageProps {
  children: ReactNode;
  showFAQ?: boolean;
  showPersonifyCareContent?: boolean;
  skipMobileHero: boolean;
  skipLogout?: boolean;
}

export const VerificationPage: FC<VerificationPageProps> = ({
  children,
  skipMobileHero,
  skipLogout = false,
  showFAQ = true,
  showPersonifyCareContent = false,
}) => {
  const dispatch = useDispatch();
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const activeOrg = useSelector(getActiveOrg);
  const campaignDisplayName: string = pathOr(
    null,
    ['campaignDisplayName'],
    activeOrg,
  );

  const slug = useSelector(getSlug);
  const hsUserId = useSelector(getHsUserId);
  const healthHub = useSelector(getHealthHub);
  const healthProvider = useSelector(getHealthProvider);

  const handleLogout = handleLogoutFactory(
    dispatch,
    slug,
    hsUserId,
    healthHub,
    healthProvider,
  );

  const [showVerifyPage, setShowVerifyPage] = useState(skipMobileHero);

  useEffect(() => {
    if (isMobileView === false) {
      setShowVerifyPage(false);
    }
  }, [isMobileView]);

  useEffect(() => {
    if (hsUserId && !skipLogout) {
      handleLogout();
    }

    // We only want this to be triggered once hence the empty dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  usePreCacheImages(LANDING_PAGE_ASSETS);

  return (
    <div data-testid="umd-verify-page" css={layoutContainer}>
      <ResponsiveGrid tagName="div" css={landingPageHeroStyle}>
        <Header
          isMobileView={isMobileView}
          campaignDisplayName={campaignDisplayName}
          showPersonifyCareSubHeader={showPersonifyCareContent}
        >
          {isMobileView && !showVerifyPage ? (
            <LandingMobileHero
              onClick={setShowVerifyPage}
              campaignDisplayName={campaignDisplayName}
            />
          ) : (
            children
          )}
        </Header>
      </ResponsiveGrid>

      {!showVerifyPage ? (
        <>
          <LandingCardSet isMobileView={isMobileView} />
          {showFAQ && <BundledAppInfoUmdWfm />}
          <LandingFooter isMobile={isMobileView} />
        </>
      ) : null}
    </div>
  );
};
