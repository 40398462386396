import { css } from '@emotion/react';
import {
  fontFamilyCss,
  FontStyle,
  fontWeightBold,
  fontWeightRegular,
} from '@headspace/web-ui-components';

export const regular = css(fontFamilyCss, {
  fontStyle: FontStyle.NORMAL,
  fontWeight: fontWeightRegular,
});

export const bold = css(fontFamilyCss, {
  fontWeight: fontWeightBold,
});
