import * as React from 'react';
import { SerializedStyles } from '@emotion/react';
import { bodyMCss, labelXXSCss } from '@headspace/web-ui-components';
import {
  dropdownDrawerStyles,
  inputOptions,
  inputStyles,
  listItemCardStyles,
  listItemIconStyles,
  selectInputLabelStyles,
  selectInputStyles,
  selectStyles,
  width100Style,
} from './styles';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';

export interface ListInputProps {
  onChange(): void;
  addItem(event: any): void;
  deleteItem?(event: any): void;
  id: string;
  coverValue?: string;
  value: string;
  addItemText: string;
  disabled?: boolean;
  hasError: boolean;
  placeholder?: string;
  customInputStyles: SerializedStyles;
  list: string[];
}

export const ListInput: React.FC<ListInputProps> = ({
  onChange,
  addItem,
  id,
  coverValue,
  placeholder,
  value,
  addItemText,
  hasError,
  disabled = false,
  customInputStyles,
  deleteItem = () => {},
  list,
}) => {
  const node = React.useRef();
  const [open, setOpen] = React.useState(false);
  const closeDoveGrey = `${AP_CDN_URL}/closeDoveGrey.svg`;
  const handleClick = (event: ExtendedEvent) => {
    const { target } = event;
    const selectors =
      target &&
      target.className &&
      target.className.split &&
      target.className.split(' ');
    const validTarget = selectors && typeof target.id === 'string';
    // @ts-ignore
    if (validTarget && node.current && node.current.contains(target)) {
      if (selectors && selectors.includes('listItemAction')) {
        deleteItem({ target: { id, value: target.id } });
      }
      if (selectors && selectors.includes('addItemButton')) {
        addItem({ target: { id, value: target.id } });
      }
      return;
    }
    setOpen(false);
  };
  const onKeyPressDeleteItem = (e) => {
    if (e.key === 'Enter') {
      deleteItem(e);
    }
  };
  const onKeyPressSetOpen = (e) => {
    if (e.key === 'Enter') {
      setOpen(!open);
    }
  };
  const onKeyPressAddItem = (e) => {
    if (e.key === 'Enter') {
      addItem(e);
    }
  };
  useOutsideClick(handleClick);

  return (
    // @ts-ignore
    <div css={width100Style} ref={node}>
      <div
        onClick={() => setOpen(!open)}
        onKeyUp={() => onKeyPressSetOpen}
        role="button"
        tabIndex={0}
        css={[inputStyles(hasError, disabled), selectStyles(hasError)]}
        className={`list${open ? ' open' : ''}`}
      >
        {coverValue}
      </div>
      {open && (
        <div css={dropdownDrawerStyles}>
          <input
            onChange={onChange}
            id={id}
            value={value}
            placeholder={placeholder}
            autoComplete="off"
            className="listInput"
            css={[
              bodyMCss,
              inputStyles(hasError, disabled),
              selectInputStyles(),
              inputOptions(customInputStyles),
            ]}
          />
          <label
            htmlFor={id}
            css={[labelXXSCss, selectInputLabelStyles]}
            className="addItemButton"
            // TODO: disabled in this element refers to the add button on the dropdown input.
            // this neither matches other input behaviors nor is intuitive. Refactor.
            onClick={disabled ? () => {} : addItem}
            onKeyUp={disabled ? () => {} : onKeyPressAddItem}
            role="presentation"
          >
            {addItemText}
          </label>
          {list.map((listItem, i) => (
            <div css={listItemCardStyles} key={listItem} className="listItem">
              <div>{listItem}</div>
              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <div
                css={listItemIconStyles(closeDoveGrey)}
                id={i.toString()}
                onClick={deleteItem}
                onKeyUp={onKeyPressDeleteItem}
                role="button"
                tabIndex={0}
                className="listItemAction"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
