import { useCallback } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Orange, Spinner } from '@headspace/web-ui-components';
import {
  createNewSurveyButtonStyle,
  createSurveySectionStyle,
} from '../../styles/styles';
import { AP_CDN_URL } from '../../../../constants/routes';
import { HollowButton } from '../../../../../../shared-components/buttons/HollowButton';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useCreateSurvey } from '../../../hooks/surveys/useCreateSurvey';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';

export const CreateNewSurveySection: React.FC = () => {
  const { orgId } = useSelector(getOrgManagement);
  const { translate } = useTranslate();
  const { mutate: createSurvey, isLoading } = useCreateSurvey();

  const handleCreateSurvey = useCallback(() => {
    createSurvey(orgId);
  }, []);

  return (
    <div>
      <Divider />
      <div css={createSurveySectionStyle}>
        {isLoading ? (
          <Spinner size={20} />
        ) : (
          <HollowButton
            dataTestId="create-survey-btn"
            color={Orange[200]}
            onClick={handleCreateSurvey}
            icon={{ iconSrc: `${AP_CDN_URL}/plus.svg` }}
            isLoading={isLoading}
            css={createNewSurveyButtonStyle}
          >
            {translate('organizationSettings.survey.createAdditionalSurvey')}
          </HollowButton>
        )}
      </div>
    </div>
  );
};
