import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { addDays, formatUTC } from '../../../../../../../utils/dates';
import { BasicTableCell } from '../../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableRow';
import {
  ctaContainerStyle,
  challengeTableRowStyle,
  cellStyle,
} from '../styles';
import { ChallengeReceivedType } from '../../../types';
import { ChallengeDropdown } from './ChallengeDropdown';
import { endDate, setChallengeStatus } from '../../../../../utils/challenge';
import {
  handleCancelChallenge,
  handleDownloadChallengeReport,
} from '../../../../../../../state/actions/challengesActions';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';

interface ChallengesTablePropTypes {
  challenges: ChallengeReceivedType[];
}

enum goalTypeValue {
  contentStarts = 'Content Starts',
  minutes = 'Minutes',
}

export const ChallengesTable: React.FC<ChallengesTablePropTypes> = ({
  challenges,
}) => {
  const dispatch = useDispatch();
  const { orgId } = useSelector(getOrgManagement);

  const handleClose = useCallback((id) => {
    dispatch(handleCancelChallenge(orgId, id));
  }, []);

  const handleReportDownload = useCallback(() => {
    dispatch(handleDownloadChallengeReport());
  }, []);

  return (
    <>
      {challenges.map((challenge) => {
        const {
          id,
          challengeType,
          durationInWeeks,
          goalTarget,
          goalType,
          startDate,
          status,
        } = challenge;
        const startInThreeWeeks = addDays(startDate, 21);
        // UTC format to avoid showing inconsistent dates due to browser timezone format.
        const endDateOutput = formatUTC(
          endDate(startInThreeWeeks, durationInWeeks),
          'MM/DD/YYYY',
        );
        const startDateOutput = formatUTC(startInThreeWeeks, 'MM/DD/YYYY');
        const goalTypeOutput = goalTypeValue[goalType];
        const goal = goalTarget.toLocaleString();
        const challengeGoal = `${goal} ${goalTypeOutput}`;

        return (
          <BasicTableRow key={id} css={challengeTableRowStyle}>
            <BasicTableCell
              data-testid="challenges-table-list-id"
              css={cellStyle}
            >
              {id}
            </BasicTableCell>
            <BasicTableCell
              data-testid="challenges-table-list-startDate"
              css={cellStyle}
            >
              {startDateOutput}
            </BasicTableCell>
            <BasicTableCell
              data-testid="challenges-table-list-endDate"
              css={cellStyle}
            >
              {endDateOutput}
            </BasicTableCell>
            <BasicTableCell
              data-testid="challenges-table-list-challengeType"
              css={cellStyle}
            >
              {challengeType}
            </BasicTableCell>
            <BasicTableCell
              data-testid="challenges-table-list-goal"
              css={cellStyle}
            >
              {challengeGoal}
            </BasicTableCell>
            <BasicTableCell
              data-testid="challenges-table-list-status"
              css={cellStyle}
            >
              {setChallengeStatus(startDateOutput, endDateOutput, status)}
            </BasicTableCell>
            <BasicTableCell css={ctaContainerStyle}>
              {status !== 'CANCELLED' && (
                <ChallengeDropdown
                  challengeId={id}
                  handleClose={handleClose}
                  handleReportDownload={handleReportDownload}
                  status={status}
                  brazeStartDate={startDate}
                />
              )}
            </BasicTableCell>
          </BasicTableRow>
        );
      })}
    </>
  );
};
