import { useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDeauthenticate } from '@headspace/web-auth';
import { HealthProvider } from '../../../constants/healthProvider';
import { Routes } from '../../../constants/routes';
import {
  HealthProviderErrorTypes,
  SoleraErrorTypes,
} from '../../../constants/errorTypes';
import { headerTextStyle, subHeaderStyle } from '../../FinishPage/styles';
import { containerStyle } from './styles';
import { SoleraErrorGoToButton } from './SoleraErrorGoToButton';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { getHealthProviderErrorCode } from '../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { PageWrapper } from '../Personify/PageWrapper/PageWrapper';

const logoutAndRedirectToHeadspaceLogin = [
  SoleraErrorTypes.SOLERA_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER,
  SoleraErrorTypes.DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_SOLERA_USER,
  HealthProviderErrorTypes.HEALTH_PROVIDER_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER,
  HealthProviderErrorTypes.DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_HEALTH_PROVIDER_USER,
];

const keepLoggedInAndRedirectToHeadspaceApp = [
  SoleraErrorTypes.DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION,
  HealthProviderErrorTypes.DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION,
];

const SoleraErrorPage: FC = () => {
  const healthProviderErrorCode = useSelector(getHealthProviderErrorCode);
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const shouldShowErrorButton =
    healthProviderErrorCode !==
      HealthProviderErrorTypes.SOMETHING_WENT_WRONG_DURING_SOLERA_CONNECTION &&
    healthProviderErrorCode !==
      HealthProviderErrorTypes.SOMETHING_WENT_WRONG_DURING_HEALTH_PROVIDER_CONNECTION;

  const redirectUser = useCallback(() => {
    if (logoutAndRedirectToHeadspaceLogin.includes(healthProviderErrorCode)) {
      dispatch(requestDeauthenticate(Routes.HEADSPACE_LOGIN));

      return;
    }

    if (
      keepLoggedInAndRedirectToHeadspaceApp.includes(healthProviderErrorCode)
    ) {
      window.location.href = 'mailto:teamsupport@headspace.com';
    }
  }, [healthProviderErrorCode, dispatch]);

  return (
    <PageWrapper healthProvider={HealthProvider.SOLERA} showJoinMessage={false}>
      <div css={containerStyle} data-testid="finish-error-page">
        <h1 css={headerTextStyle}>
          {translate(`solera.errors.${healthProviderErrorCode}.primary`)}
        </h1>
        <h4 css={subHeaderStyle}>
          {translate(`solera.errors.${healthProviderErrorCode}.secondary`)}
        </h4>
        {shouldShowErrorButton ? (
          <SoleraErrorGoToButton
            value={translate(`solera.errors.${healthProviderErrorCode}.goTo`)}
            onClick={redirectUser}
          />
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default SoleraErrorPage;
