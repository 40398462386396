import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AP_CDN_URL } from '../../../../../constants/routes';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { ActionDropdown } from '../../../../../../../shared-components/dropdowns/ActionDropdown';
import { isPast } from '../../../../../../../utils/dates';

interface ChallengeDropdownPropTypes {
  challengeId: number;
  handleClose: (id) => void;
  handleReportDownload: () => void;
  status: string;
  brazeStartDate: string;
}

enum challengeStatus {
  QUEUED = 'QUEUED',
  SCHEDULED = 'SCHEDULED',
  CANCELLED = 'CANCELLED',
}

export const ChallengeDropdown: React.FC<ChallengeDropdownPropTypes> = ({
  challengeId,
  handleClose,
  handleReportDownload,
  status,
  brazeStartDate,
}) => {
  const { translate } = useTranslate();
  const [isCancelEnabled, setCancelEnabled] = useState<boolean>(false);
  const [isReportDownloadEnabled, setReportDownloadEnabled] = useState<boolean>(
    false,
  );
  const [
    isReportDownloadDisplayed,
    setReportDownloadDisplayed,
  ] = useState<boolean>(false);

  useEffect(() => {
    const isDateInThePast = isPast(brazeStartDate);
    setCancelEnabled(status !== challengeStatus.QUEUED && !isDateInThePast);
  }, [status]);

  useEffect(() => {
    setReportDownloadEnabled(status === challengeStatus.SCHEDULED);
  }, [status]);

  const close = useCallback(() => handleClose(challengeId), []);

  const items = useMemo(() => {
    const items = [
      {
        display: translate('challenges.challengesList.challengeCancelButton'),
        action: close,
        selectedMembers: +isCancelEnabled,
      },
    ];

    if (isReportDownloadDisplayed) {
      items.push({
        display: translate('challenges.downloadReport.label'),
        action: handleReportDownload,
        selectedMembers: +isReportDownloadEnabled,
      });
    }

    return items;
  }, [isReportDownloadEnabled, isCancelEnabled]);

  return (
    <div data-testid="challenge-dropdown">
      <ActionDropdown
        position="right"
        icon={`${AP_CDN_URL}/meatball-menu.svg`}
        items={items}
      />
    </div>
  );
};
