import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@headspace/web-ui-components';
import { PageWrapper } from './PageWrapper';
import { HealthProvider } from '../../constants/healthProvider';
import { getGingerSoleraConnectionState } from '../../state/selectors/solera/gingerSoleraConnectionStateSelector';
import { setGingerSoleraConnectionInit } from '../../state/actions/soleraActions/gingerSoleraActions';

export const GingerSoleraLandingPage = () => {
  const isConnectionInProgress = useSelector(getGingerSoleraConnectionState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setGingerSoleraConnectionInit());
  }, [setGingerSoleraConnectionInit]);

  return (
    <PageWrapper healthProvider={HealthProvider.SOLERA}>
      {isConnectionInProgress && <Spinner />}
    </PageWrapper>
  );
};
