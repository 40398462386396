import * as React from 'react';
import { css } from '@emotion/react';
import { fontWeightBold, Orange } from '@headspace/web-ui-components';
import { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { upperCaseFirst } from 'change-case';
import { useSelector } from 'react-redux';
import {
  Dropdown,
  DropdownItem,
} from '../../../../../../../shared-components/dropdowns/Dropdown';
import { uuid } from '../../../../../../../state/models/organization/Survey';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { AP_CDN_URL, qualitricsRoutes } from '../../../../../constants/routes';
import { useDownloadSurveyResults } from '../../../../hooks/surveys/useDownloadSurveyResults';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';

const DATA_TEST_ID = 'survey-dropdown';

const copiedLinkStyle = css({
  color: Orange[100],
  float: 'right',
  fontWeight: fontWeightBold,
});

interface SurveyDropDownPropTypes {
  onSelectSurvey: (surveyId: uuid) => void;
  surveyId: uuid;
  showSendSurveySelection: boolean;
  externalId: string;
  surveyName: string;
}

export const SurveyDropDown = ({
  onSelectSurvey,
  surveyId,
  showSendSurveySelection,
  externalId,
  surveyName,
}: SurveyDropDownPropTypes) => {
  const { orgId } = useSelector(getOrgManagement);
  const { translate } = useTranslate();
  const [copiedLink, setCopyLink] = useState<null | string>(null);
  const urlLink = `${qualitricsRoutes.BASE_URL}${qualitricsRoutes.SURVEY(
    externalId,
  )}`;

  const COPIED_TEXT = translate('copied');
  const isUrlLinkCopied = copiedLink === urlLink;

  const handleSendSurvey = React.useCallback(() => {
    onSelectSurvey(surveyId);
  }, [onSelectSurvey, surveyId]);

  const handleCopyLink = useCallback(
    (e) => {
      const copiedUrl = e.currentTarget?.getAttribute('data-url');
      setCopyLink(copiedUrl);
    },
    [setCopyLink],
  );

  const { mutate: downloadSurvey } = useDownloadSurveyResults();
  const handleDownloadSurvey = useCallback(() => {
    downloadSurvey({ orgId, surveyId, surveyName });
  }, [surveyId]);

  const sendSurveyLabel = translate(
    'organizationSettings.survey.dropdown.sendSurvey',
  );
  const downloadSurveyResultsLabel = translate(
    'organizationSettings.survey.dropdown.downloadResults',
  );

  return (
    <Dropdown
      position="right"
      icon={`${AP_CDN_URL}/meatball-menu.svg`}
      testId={DATA_TEST_ID}
    >
      <DropdownItem
        onClick={handleCopyLink}
        data-url={urlLink}
        data-testid="copy-link-tab"
      >
        <CopyToClipboard text={urlLink}>
          <div>
            <span>
              {translate('organizationSettings.survey.dropdown.copyLink')}
            </span>
            {isUrlLinkCopied && (
              <span css={copiedLinkStyle}>{upperCaseFirst(COPIED_TEXT)}</span>
            )}
          </div>
        </CopyToClipboard>
      </DropdownItem>
      {showSendSurveySelection ? (
        <DropdownItem onClick={handleSendSurvey} data-testid="send-survey-tab">
          {sendSurveyLabel}
        </DropdownItem>
      ) : null}
      <DropdownItem
        onClick={handleDownloadSurvey}
        data-testid="download-survey-tab"
      >
        {downloadSurveyResultsLabel}
      </DropdownItem>
    </Dropdown>
  );
};
