import { ThunkMiddleware } from 'redux-thunk';
import { Dispatch } from 'redux';

import { CURRENT } from '../../../apps/dashboard/constants';
import { GetState } from '../../types';
import {
  OrganizationActionsTypes,
  SaveOrganizationBasicAction,
} from '../../types/organization';
import { ModalActionsTypes } from '../../types/modal';
import { Contract } from '../../../apps/dashboard/types';
import { makeFriendlyDate, parseDateUtc } from '../../../utils/dates';
import { hydrate } from './hydrate';
import {
  addOrganizationSubscription,
  updateOrganizationSubscription,
} from '../../../rest/org-subscriptions/org-subscriptions.api';
import { organizationContractDependentValuesToParams } from '../../utils/dataFormatters';
import {
  organizationContractDependentParamsToValues,
  organizationOfferingTypesToPrimaryProduct,
  organizationPrimaryProductToOfferingTypes,
} from '../../utils/dataParsers';
import { ErrorResponse, parseError } from '../../../utils/parseError';
import { OrgSubscriptionBase } from '../../../rest/org-subscriptions/org-subscriptions.types';

const { SAVE_ORGANIZATION_CONTRACT } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;

export const saveOrganizationContract = (
  contract: Contract,
  dateInUtc = false,
): ThunkMiddleware & SaveOrganizationBasicAction => {
  // @ts-ignore
  return async (dispatch: Dispatch, getState: GetState) => {
    const {
      manageOrganization: { cachedOrg, orgId },
    } = getState();

    const {
      eapType,
      id,
      term,
      start_date,
      end_date,
      is_pilot: isPilot,
      seats,
      elfDependentsEnabled,
      opportunity_id: opportunityId,
      primary_product,
      voucher,
      payment_type,
      family_members_per_employee,
      number_of_family_members_seats,
      fusionEnabled,
      clinicalCoverageType,
    } = contract;

    const startDate = (dateInUtc
      ? new Date(start_date)
      : parseDateUtc(start_date)
    ).toISOString();
    const endDate = (dateInUtc
      ? new Date(end_date)
      : parseDateUtc(end_date)
    ).toISOString();
    const offerings = organizationPrimaryProductToOfferingTypes(
      primary_product,
    );

    const primaryProduct = organizationOfferingTypesToPrimaryProduct(
      offerings,
      fusionEnabled,
    );

    try {
      const subscriptionPayload: OrgSubscriptionBase = {
        clinicalCoverageType,
        eapType,
        elfDependentsEnabled,
        endDate,
        fusionEnabled,
        isPilot,
        offerings,
        opportunityId,
        partialPayVoucher: payment_type ? voucher : '',
        primaryProduct,
        seats,
        startDate,
        ...organizationContractDependentValuesToParams(
          family_members_per_employee,
          number_of_family_members_seats,
        ),
      };

      const contractResponse = id
        ? await updateOrganizationSubscription(id, orgId, subscriptionPayload)
        : await addOrganizationSubscription(orgId, subscriptionPayload);

      const nextContract = {
        ...cachedOrg?.[`${term}Contract`],
        ...contractResponse,
      };

      const cachedContracts = cachedOrg?.contracts || [];
      const nextContracts =
        term === CURRENT
          ? [nextContract].concat(cachedContracts.slice(1))
          : cachedContracts.slice(0, 1).concat(nextContract);

      const updatedDependentValues = organizationContractDependentParamsToValues(
        contractResponse.dependentsPerMember,
        contractResponse.dependentSeats,
      );

      dispatch({
        type: SAVE_ORGANIZATION_CONTRACT,
        payload: {
          cachedOrg: {
            ...cachedOrg,
            contracts: nextContracts,
            [`${term}Contract`]: nextContract,
          },
          [`${term}_contract_id`]: contractResponse.id,
          [`${term}_contract_seats`]: contractResponse.seats,
          [`${term}_contract_start_date`]: makeFriendlyDate(
            contractResponse.startDate,
          ),
          [`${term}_contract_end_date`]: makeFriendlyDate(
            contractResponse.endDate,
          ),
          [`${term}_contract_is_pilot`]: contractResponse.isPilot,
          [`${term}_contract_opportunity_id`]: contractResponse.opportunityId,
          [`${term}_contract_primary_product`]: organizationOfferingTypesToPrimaryProduct(
            contractResponse.offerings,
            contractResponse.fusionEnabled,
          ),
          [`${term}_contract_voucher`]: voucher,
          [`${term}_contract_payment_type`]: payment_type,

          [`${term}_contract_eligibility_file_based_dependents`]: contractResponse.elfDependentsEnabled,
          [`${term}_contract_family_members_per_employee`]: updatedDependentValues.dependentsPerMember,
          [`${term}_contract_number_of_family_members_seats`]: updatedDependentValues.dependentSeats,
          [`${term}_contract_eap_type`]: subscriptionPayload.eapType,
          [`${term}_contract_clinical_coverage_type`]: subscriptionPayload.clinicalCoverageType,
        },
      });
    } catch (err) {
      const { message } = parseError(err as ErrorResponse);
      dispatch({
        type: OPEN_MODAL,
        payload: {
          message,
        },
      });

      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
