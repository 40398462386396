import { RestrictBy } from '../../../types/organization';
import { STATIC_CDN_URL } from '../../../utils/constants';

export enum ORG_CREATION_STEPS {
  BASIC = 'BASIC',
  ORG_HIERARCHY = 'ORG_HIERARCHY',
  CONTRACT = 'CONTRACT',
  ADMINISTRATORS = 'ADMINISTRATORS',
  ENROLLMENT = 'ENROLLMENT',
  REVIEW = 'REVIEW',
  SUPPLEMENTAL_DETAILS = 'SUPPLEMENTAL_DETAILS',
}

export enum EAP_SERVICE_TYPES {
  PSYCHIATRY = 'PSYCHIATRY',
  THERAPY = 'THERAPY',
  PSYCHIATRY_THERAPY = 'PSYCHIATRY_THERAPY',
  NONE = '',
}

export const EAP_COVERAGE_TOTAL_DEFAULT = 0;

export type OrgCreationStepsUnion = keyof typeof ORG_CREATION_STEPS;

export type OrgCreationSteps = {
  [key in OrgCreationStepsUnion]: {
    name: string;
    optional: boolean;
    editable: boolean;
    prevStep: ORG_CREATION_STEPS | null;
    nextStep: ORG_CREATION_STEPS | null;
  };
};

export const ALL_STEPS: OrgCreationSteps = {
  [ORG_CREATION_STEPS.BASIC]: {
    editable: true,
    name: 'basic',
    nextStep: ORG_CREATION_STEPS.CONTRACT,
    optional: false,
    prevStep: null,
  },
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: {
    editable: true,
    name: 'orgHierarchy',
    nextStep: null,
    optional: true,
    prevStep: null,
  },
  [ORG_CREATION_STEPS.CONTRACT]: {
    editable: true,
    name: 'contract',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: false,
    prevStep: ORG_CREATION_STEPS.BASIC,
  },
  [ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS]: {
    editable: true,
    name: 'supplementalDetails',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: true,
    prevStep: ORG_CREATION_STEPS.CONTRACT,
  },
  [ORG_CREATION_STEPS.ADMINISTRATORS]: {
    editable: true,
    name: 'administrators',
    nextStep: ORG_CREATION_STEPS.ENROLLMENT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.CONTRACT,
  },
  [ORG_CREATION_STEPS.ENROLLMENT]: {
    editable: true,
    name: 'enrollment',
    nextStep: ORG_CREATION_STEPS.REVIEW,
    optional: true,
    prevStep: ORG_CREATION_STEPS.ADMINISTRATORS,
  },
  [ORG_CREATION_STEPS.REVIEW]: {
    editable: false,
    name: 'review',
    nextStep: null,
    optional: false,
    prevStep: ORG_CREATION_STEPS.ENROLLMENT,
  },
};

export const {
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: omitted1,
  ...DEFAULT_STEPS
} = ALL_STEPS;

export const PARENT_ORG_STEPS: OrgCreationSteps = {
  ...ALL_STEPS,
  [ORG_CREATION_STEPS.BASIC]: {
    editable: true,
    name: 'basic',
    nextStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
    optional: false,
    prevStep: null,
  },
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: {
    editable: true,
    name: 'orgHierarchy',
    nextStep: ORG_CREATION_STEPS.CONTRACT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.BASIC,
  },
  [ORG_CREATION_STEPS.CONTRACT]: {
    editable: true,
    name: 'contract',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: false,
    prevStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
  },
};

export const PARENT_ORG_EAP_STEPS: OrgCreationSteps = {
  ...ALL_STEPS,
  [ORG_CREATION_STEPS.BASIC]: {
    editable: true,
    name: 'basic',
    nextStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
    optional: false,
    prevStep: null,
  },
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: {
    editable: true,
    name: 'orgHierarchy',
    nextStep: ORG_CREATION_STEPS.CONTRACT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.BASIC,
  },
  [ORG_CREATION_STEPS.CONTRACT]: {
    editable: true,
    name: 'contract',
    nextStep: ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS,
    optional: false,
    prevStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
  },
  [ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS]: {
    editable: true,
    name: 'supplementalDetails',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: true,
    prevStep: ORG_CREATION_STEPS.CONTRACT,
  },
  [ORG_CREATION_STEPS.ADMINISTRATORS]: {
    editable: true,
    name: 'administrators',
    nextStep: ORG_CREATION_STEPS.ENROLLMENT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS,
  },
};

const {
  [ORG_CREATION_STEPS.CONTRACT]: omitted2,
  ...CHILD_ORG_STEPS_BASE
} = PARENT_ORG_STEPS;
export const CHILD_ORG_STEPS: OrgCreationSteps = {
  ...CHILD_ORG_STEPS_BASE,
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: {
    editable: true,
    name: 'orgHierarchy',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: true,
    prevStep: ORG_CREATION_STEPS.BASIC,
  },
  [ORG_CREATION_STEPS.ADMINISTRATORS]: {
    editable: true,
    name: 'administrators',
    nextStep: ORG_CREATION_STEPS.ENROLLMENT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
  },
};

export const CHILD_ORG_EAP_STEPS: OrgCreationSteps = {
  ...ALL_STEPS,
  [ORG_CREATION_STEPS.BASIC]: {
    editable: true,
    name: 'basic',
    nextStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
    optional: false,
    prevStep: null,
  },
  [ORG_CREATION_STEPS.ORG_HIERARCHY]: {
    editable: true,
    name: 'orgHierarchy',
    nextStep: ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS,
    optional: true,
    prevStep: ORG_CREATION_STEPS.BASIC,
  },
  [ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS]: {
    editable: true,
    name: 'supplementalDetails',
    nextStep: ORG_CREATION_STEPS.ADMINISTRATORS,
    optional: true,
    prevStep: ORG_CREATION_STEPS.ORG_HIERARCHY,
  },
  [ORG_CREATION_STEPS.ADMINISTRATORS]: {
    editable: true,
    name: 'administrators',
    nextStep: ORG_CREATION_STEPS.ENROLLMENT,
    optional: true,
    prevStep: ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS,
  },
};

export const CURRENT = 'current';
export const FUTURE = 'future';

export const EDITABLE_STEP_COUNT = 4;

export const ORGANIZATION_DETAILS = 0;
export const ORGANIZATION_HIERARCHY = 1;
export const ORGANIZATION_CURRENT_CONTRACT_DETAILS = 2;
export const ORGANIZATION_SUPPLEMENTAL_DETAILS = 3;
export const ORGANIZATION_FUTURE_CONTRACT_DETAILS = 4;
export const ORGANIZATION_ADMINS = 5;
export const ORGANIZATION_ENROLLMENT = 6;
export const ORGANIZATION_ELIGIBILITY_FILES = 7;
export const ORGANIZATION_WELCOME = 8;
export const ORGANIZATION_DELETE = 9;

/* Tick the step count below when you add a panel above
 */
export const VIEW_PANEL_COUNT = EDITABLE_STEP_COUNT + 5;

export const VALIDATION_DELAY = 500;

export const OVERLAP_DATE_INPUTS = [
  'future_contract_start_date',
  'current_contract_end_date',
];

export const OPTIONAL_FIELDS = [
  'body',
  'campaignDisplayName',
  'campaignLaunchDate',
  'overflow_url',
  'learn_more_url_path',
  'admin_title',
  'add_domain',
  'logoFile',
  'eligibilityFile',
  'gingerId',
  'parentOrgId',
  'orgMappingValue',
  'overlappedCareId',
];

export const MEMBER_STATUS = {
  ACTIVE: 'ACTIVE',
  ELIGIBLE: 'ELIGIBLE',
  EXPIRED: 'EXPIRED',
  INVITED: 'INVITED',
  ORPHAN: 'ORPHAN',
  PENDING: 'PENDING',
  PENDING_REMOVAL: 'PENDING_REMOVAL',
  REMOVED: 'REMOVED',
  WAITLISTED: 'WAITLISTED',
};

export const ALL_MEMBER_STATUSES = [
  'ACTIVE',
  'REMOVED',
  'ELIGIBLE',
  'ORPHAN',
  'EXPIRED',
  'WAITLISTED',
  'PENDING',
];

export const COMMON_MEMBER_STATUSES = [
  'ACTIVE',
  'EXPIRED',
  // 'WAITLISTED', TODO: Enable with Waitlist
];

export const MEMBER_PAY_MEMBER_STATUSES = ['PENDING', 'ORPHAN'];

export const ELIGIBILITY_FILE_MEMBER_STATUSES = ['ELIGIBLE', 'PENDING_REMOVAL'];

export const VISIBLE_MEMBER_STATUSES = [
  ...COMMON_MEMBER_STATUSES,
  ...MEMBER_PAY_MEMBER_STATUSES,
  ...ELIGIBILITY_FILE_MEMBER_STATUSES,
];

// Though currently a copy, this is a distinct entity from COMMON, used to determine behavior in tables
export const ACTIONABLE_MEMBER_STATUSES = [...COMMON_MEMBER_STATUSES];
export const VERIFICATION_LINK_RESTRICT_BY_TYPES = [
  RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
  RestrictBy.EMAIL_ELIGIBILITY_FILE,
  RestrictBy.EMAIL_DOMAIN,
];

export const ORG_TYPES = [
  'B2B',
  'SOCIAL_IMPACT',
  'PARTNERSHIP',
  'TEST',
  'HEALTH',
  'HEALTH_PARTNERSHIP',
] as const;

export enum PRIMARY_PRODUCT {
  UNIFIED = 'UNIFIED',
  HEADSPACE = 'HEADSPACE',
  GINGER = 'GINGER',
  FUSION = 'FUSION',
  CONNECTED = 'CONNECTED',
}

export const locale = 'organizationSettings.enrollment.';

export enum TranslationPath {
  UNRESTRICTED = 'organizationSettings.enrollment.UNRESTRICTED',
  EMAIL_ELIGIBILITY_FILE = 'organizationSettings.enrollment.EMAIL_ELIGIBILITY_FILE',
  EMPLOYEE_ID = 'organizationSettings.enrollment.EMPLOYEE_ID',
  EMAIL_DOMAIN = 'organizationSettings.enrollment.EMAIL_DOMAIN',
  SSO = 'organizationSettings.enrollment.SSO',
  BUNDLED_EMAIL_ELIGIBILITY_FILE = 'organizationSettings.enrollment.BUNDLED_EMAIL_ELIGIBILITY_FILE',
  BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE = 'organizationSettings.enrollment.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE',
}

export enum STEP_STATUS {
  COMPLETE = 'COMPLETE',
  ACTIVE = 'ACTIVE',
  INCOMPLETE = 'INCOMPLETE',
}

export const SFTP_STEPS = {
  1: 'upload step',
  2: 'connect step',
  3: 'upload elf step',
  4: 'file feed step',
};

export const RESTRICT_BY_MIGRATION_STEPS = {
  1: 'file upload',
  2: 'review',
  3: 'confirm',
};

export const HTTP_STATUS = {
  OK: 200,
};

export enum ELF_STATUS {
  UPLOADED = 'UPLOADED',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
  VALIDATING = 'VALIDATING',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export enum PRE_UPDATE_STATUS {
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
}

export enum CONTRACT_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const ELF_STATUS_POLLING_INTERVAL = 15000;

export enum BreakPoint {
  Tablet = '@media (max-width: 768px)',
}

export const HEIGHT_OFFSET = 270;
export const COLUMN_WIDTH_OFFSET = 0;

export enum HEALTH_HUB {
  ACCOLADE = 'accolade',
  QUANTUM = 'quantum',
  PERSONIFY = 'personifyhealth',
}

export const IMG_CDN_URL_LOGO = `${STATIC_CDN_URL}/b2b-dashboard/logo`;

export const MAX_ENROLLMENT_PAGE_BODY_LENGTH = 1_000;
