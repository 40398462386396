import { ENROLLMENT_CDN_URL } from '../../../../../constants/routes';

const umdOnboardingCareer = `${ENROLLMENT_CDN_URL}/umd-onboarding-career.png`;
const umdOnboardingTerribleEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-terrible-emotion.png`;
const umdOnboardingBadEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-bad-emotion.png`;
const umdOnboardingOkayEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-okay-emotion.png`;
const umdOnboardingGreatEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-great-emotion.png`;
const umdOnboardingAmazingEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-amazing-emotion.png`;
const umdOnboardingFeelCalmAndRelax = `${ENROLLMENT_CDN_URL}/umd-onboarding-feel-calm-and-relax.png`;
const umdOnboardingManageAnxiety = `${ENROLLMENT_CDN_URL}/umd-onboarding-manage-anxiety.png`;
const umdOnboardingManageDepression = `${ENROLLMENT_CDN_URL}/umd-onboarding-manage-depression.png`;
const umdOnboardingProcessGrief = `${ENROLLMENT_CDN_URL}/umd-onboarding-process-grief.png`;
const umdOnboardingReduceStress = `${ENROLLMENT_CDN_URL}/umd-onboarding-reduce-stress.png`;
const umdOnboardingBeMindful = `${ENROLLMENT_CDN_URL}/umd-onboarding-be-mindful.png`;
const umdOnboardingSleepBetter = `${ENROLLMENT_CDN_URL}/umd-onboarding-sleep-better.png`;
const umdOnboardingIdentityBelonging = `${ENROLLMENT_CDN_URL}/umd-onboarding-identity-belonging.png`;
const umdOnboardingIncreaseSelfEsteem = `${ENROLLMENT_CDN_URL}/umd-onboarding-increase-self-esteem.png`;
const umdOnboardingManageRelationships = `${ENROLLMENT_CDN_URL}/umd-onboarding-manage-relationships.png`;
const umdOnboardingPersonalGoals = `${ENROLLMENT_CDN_URL}/umd-onboarding-personal-goals.png`;
const umdOnboardingReframeBodyImage = `${ENROLLMENT_CDN_URL}/umd-onboarding-reframe-body-image.png`;
const umdOnboardingSomethingElse = `${ENROLLMENT_CDN_URL}/umd-onboarding-something-else.png`;
const umdOnboardingFamily = `${ENROLLMENT_CDN_URL}/umd-onboarding-family.png`;
const umdOnboardingSchool = `${ENROLLMENT_CDN_URL}/umd-onboarding-school.png`;
const umdOnboardingSocialLife = `${ENROLLMENT_CDN_URL}/umd-onboarding-social-life.png`;
const umdOnboardingPhysicalHealth = `${ENROLLMENT_CDN_URL}/umd-onboarding-physical-health.png`;
const umdOnboardingHobbies = `${ENROLLMENT_CDN_URL}/umd-onboarding-hobbies.png`;
const umdOnboardingPersonalFinances = `${ENROLLMENT_CDN_URL}/umd-onboarding-personal-finances.png`;

export const SLIDER_STEPS = [
  {
    emotion: 'Terrible',
    icon: umdOnboardingTerribleEmotion,
  },
  {
    emotion: 'Bad',
    icon: umdOnboardingBadEmotion,
  },
  {
    emotion: 'Okay',
    icon: umdOnboardingOkayEmotion,
  },
  {
    emotion: 'Great',
    icon: umdOnboardingGreatEmotion,
  },
  {
    emotion: 'Amazing',
    icon: umdOnboardingAmazingEmotion,
  },
];

export const SLIDER_INITIAL_STEP = 2;

export const CHOICE_ICONS = {
  'choice-mood-0': umdOnboardingTerribleEmotion,
  'choice-mood-1': umdOnboardingBadEmotion,
  'choice-mood-2': umdOnboardingOkayEmotion,
  'choice-mood-3': umdOnboardingGreatEmotion,
  'choice-mood-4': umdOnboardingAmazingEmotion,
  'choice-help-0': umdOnboardingBeMindful,
  'choice-help-1': umdOnboardingSleepBetter,
  'choice-help-2': umdOnboardingReduceStress,
  'choice-help-3': umdOnboardingManageAnxiety,
  'choice-help-4': umdOnboardingManageDepression,
  'choice-help-5': umdOnboardingProcessGrief,
  'choice-help-6': umdOnboardingFeelCalmAndRelax,
  'choice-help-7': umdOnboardingManageRelationships,
  'choice-help-8': umdOnboardingIncreaseSelfEsteem,
  'choice-help-9': umdOnboardingReframeBodyImage,
  'choice-help-10': umdOnboardingIdentityBelonging,
  'choice-help-11': umdOnboardingSomethingElse,
  'choice-importance-0': umdOnboardingBeMindful,
  'choice-importance-1': umdOnboardingSleepBetter,
  'choice-importance-2': umdOnboardingReduceStress,
  'choice-importance-3': umdOnboardingManageAnxiety,
  'choice-importance-4': umdOnboardingManageDepression,
  'choice-importance-5': umdOnboardingProcessGrief,
  'choice-importance-6': umdOnboardingFeelCalmAndRelax,
  'choice-importance-7': umdOnboardingManageRelationships,
  'choice-importance-8': umdOnboardingIncreaseSelfEsteem,
  'choice-importance-9': umdOnboardingReframeBodyImage,
  'choice-importance-10': umdOnboardingIdentityBelonging,
  'choice-importance-11': umdOnboardingSomethingElse,
  'choice-life_impact-0': umdOnboardingFamily,
  'choice-life_impact-1': umdOnboardingCareer,
  'choice-life_impact-2': umdOnboardingSchool,
  'choice-life_impact-3': umdOnboardingSocialLife,
  'choice-life_impact-4': umdOnboardingPhysicalHealth,
  'choice-life_impact-5': umdOnboardingHobbies,
  'choice-life_impact-6': umdOnboardingPersonalFinances,
  'choice-life_impact-7': umdOnboardingPersonalGoals,
  'choice-life_impact-8': umdOnboardingSomethingElse,
};

export const TEXT_REPRESENTATION_SINGLE_CHOICE_IDS = ['question-4-feel'];
