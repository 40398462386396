import { useCallback, useMemo } from 'react';
import * as React from 'react';
import {
  ConfirmModal,
  CoolGrey,
  rem,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';
import { css, ClassNames } from '@emotion/react';
import { useDispatch, useSelector } from 'react-redux';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  closeSettingsModal,
  selectSettingsModal,
} from '../../../../../../../state/slices/settingsModal';
import {
  alignItemsCenter,
  columnFlex,
  flex,
  justifyContentCenter,
} from '../../../../../styles/flex';
import { useTranslate } from '../../../../../../../hooks/useTranslate';

interface SettingsConfirmationModalHeaderProps {
  title: string | null;
}

interface SettingsConfirmationModalBodyProps {
  message?: string | null;
}

interface SettingsConfirmationModalFooterProps {
  onConfirm?: () => void;
}

const modalStyle = css(flex, {
  position: 'fixed',
  width: '100%',
  height: '100vh',
  top: 0,
  right: 0,
  left: 0,
  bottom: 0,
});

const modalCardStyle = css(columnFlex, justifyContentCenter, alignItemsCenter, {
  minHeight: rem(18.75),
  width: rem(43.75),
});

const modalHeaderStyle = css({
  color: CoolGrey[500],
  paddingBottom: rem(1.25),
  paddingTop: SpacingInRemBySize['2XL'],
});

const modalBodyStyle = css({
  color: WarmGrey[600],
  marginLeft: '15%',
  marginRight: '15%',
  paddingBottom: rem(1.875),
  textAlign: 'center',
});

const ModalHeader: React.FC<SettingsConfirmationModalHeaderProps> = ({
  title,
}) => (
  <h2 css={modalHeaderStyle} data-testid="settings-confirmation-modal-header">
    {title}
  </h2>
);

const ModalBody: React.FC<SettingsConfirmationModalBodyProps> = ({
  message,
}) => (
  <div css={modalBodyStyle} data-testid="settings-confirmation-modal-body">
    {message}
  </div>
);

const ModalFooter: React.FC<SettingsConfirmationModalFooterProps> = ({
  onConfirm,
}) => {
  const { translate } = useTranslate();

  return (
    <div>
      <TextButton
        dataTestId="settings-modal-confirm-button"
        onClick={onConfirm}
        value={translate('settingsModal.confirmButtonText')}
      />
    </div>
  );
};

export const SettingsConfirmationModal: React.FC = () => {
  const { isOpen, message, title } = useSelector(selectSettingsModal);
  const dispatch = useDispatch();
  const onConfirm = useCallback(() => {
    dispatch(closeSettingsModal());
  }, [dispatch]);

  const header = useMemo(() => (title ? <ModalHeader title={title} /> : null), [
    title,
  ]);
  const body = useMemo(
    () => (message ? <ModalBody message={message} /> : null),
    [message],
  );

  return isOpen ? (
    <div css={modalStyle}>
      <ClassNames>
        {({ css }) => (
          <ConfirmModal
            header={header}
            footer={() => <ModalFooter onConfirm={onConfirm} />}
            body={body}
            showCloseButton={false}
            cardClassName={css(modalCardStyle)}
          />
        )}
      </ClassNames>
    </div>
  ) : null;
};
