import { css } from '@emotion/react';
import {
  fontSizeS,
  fontWeightBold,
  fontWeightRegular,
  lineHeightHeading,
  SpacingInRemBySize,
  TextLink,
} from '@headspace/web-ui-components';
import { FC, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Collapsible from 'react-collapsible';
import {
  childPhoneNumbersStyles,
  containerPhoneNumbersStyles,
  iconStyle,
  itemsFontStyle,
  phoneContainerStyle,
  phoneContextStyle,
  phoneIconStyle,
  phoneTitleStyle,
  rowItem,
} from '../styles/EAPStylesUmdWfm';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getClinicalCoverages } from '../../../../../../state/selectors/organizationSelectors';
import { arrowIcon } from '../styles/verificationPageStyleUmdWfm';
import { getPrimaryProduct } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { PRIMARY_PRODUCT } from '../../../../../dashboard/constants';
import { stripSpacesAndParentheses } from '../../../../../headspaceHub/common/utils';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const phoneIcon = `${ENROLLMENT_CDN_URL}/umd-landing-page-phone-icon.svg`;

const phoneMap = [
  {
    country: 'enrollment.landingPage.eap.contact.USA&Canada',
    phone: [
      {
        context: '',
        number: '888-936-8627',
      },
    ],
  },
  {
    country: 'enrollment.landingPage.eap.contact.UK',
    phone: [
      {
        context: 'enrollment.landingPage.eap.contact.freephone',
        number: '0800 243 458',
      },
      {
        context: 'enrollment.landingPage.eap.contact.directDial',
        number: '+44 20 8987 6230',
      },
    ],
  },
];

type EapPhoneNumberWfmProps = {
  clinicalResourcesSupported: boolean | null;
};

export const EapPhoneNumberWfm: FC<EapPhoneNumberWfmProps> = ({
  clinicalResourcesSupported,
}) => {
  const [isExpanded, setExpanded] = useState(false);
  const { translate } = useTranslate();

  const coverages = useSelector(getClinicalCoverages);
  const phoneNumberEAP = coverages[0]?.phoneNumber;
  const primaryProduct = useSelector(getPrimaryProduct);

  const isMultiPhoneNumbersEnable = useMemo(() => {
    const isPrimaryProductEnabled = primaryProduct === PRIMARY_PRODUCT.FUSION;
    const hasMultipleEapCoverages = coverages?.length > 1;
    return (
      isPrimaryProductEnabled &&
      hasMultipleEapCoverages &&
      clinicalResourcesSupported
    );
  }, [coverages.length, clinicalResourcesSupported, primaryProduct]);

  const handleOpenEAP = useCallback(() => setExpanded(true), []);
  const handleCloseEAP = useCallback(() => setExpanded(false), []);

  const multiplePhoneNumbersComponent = useMemo(() => {
    const ctaCopy = isExpanded
      ? translate(
          'enrollment.landingPage.eap.globalEnrollment.showLessPhoneNumbers',
        )
      : translate(
          'enrollment.landingPage.eap.globalEnrollment.showMorePhoneNumbers',
        );

    const phoneNumberArray = coverages.slice(1);

    return (
      <>
        <TextLink
          to={`tel:${stripSpacesAndParentheses(phoneNumberEAP ?? '')}`}
          css={itemsFontStyle(fontSizeS, fontWeightRegular, lineHeightHeading)}
        >
          <div data-testid="phoneNumberEAP">{phoneNumberEAP}</div>
        </TextLink>
        <Collapsible
          open={isExpanded}
          lazyRender={true}
          transitionTime={300}
          onTriggerOpening={handleOpenEAP}
          onTriggerClosing={handleCloseEAP}
          trigger={
            <button
              data-testid="collapsible-btn"
              css={[arrowIcon(isExpanded)]}
              type="button"
            >
              {ctaCopy}
            </button>
          }
        >
          <div
            css={childPhoneNumbersStyles}
            data-testid="multiple-phone-numbers"
          >
            {phoneNumberArray.map((item) => (
              <TextLink
                to={`tel:${stripSpacesAndParentheses(item.phoneNumber || '')}`}
                key={item.orgId}
                css={itemsFontStyle(
                  fontSizeS,
                  fontWeightRegular,
                  lineHeightHeading,
                )}
              >
                {`${item.phoneNumber} ${item.country || ''}`}
              </TextLink>
            ))}
          </div>
        </Collapsible>
      </>
    );
  }, [
    coverages,
    handleCloseEAP,
    handleOpenEAP,
    isExpanded,
    phoneNumberEAP,
    translate,
  ]);

  return (
    <div css={rowItem}>
      <img
        css={clinicalResourcesSupported ? iconStyle : phoneIconStyle}
        src={phoneIcon}
        role="presentation"
        alt=""
      />
      <div css={containerPhoneNumbersStyles}>
        <h2 css={phoneTitleStyle}>
          {clinicalResourcesSupported
            ? translate(
                'enrollment.landingPage.eap.globalEnrollment.contact.phoneLabel',
              )
            : translate('enrollment.landingPage.eap.contact.phoneLabel')}
        </h2>
        {clinicalResourcesSupported ? (
          <div css={phoneContainerStyle}>
            {isMultiPhoneNumbersEnable ? (
              multiplePhoneNumbersComponent
            ) : (
              <TextLink
                to={`tel:${stripSpacesAndParentheses(phoneNumberEAP || '')}`}
                css={css({
                  textDecoration: 'none',
                })}
              >
                <h2
                  data-testid="phoneNumberEAP"
                  css={css(
                    itemsFontStyle(
                      fontSizeS,
                      fontWeightBold,
                      lineHeightHeading,
                    ),
                    { marginTop: SpacingInRemBySize['2XS'] },
                  )}
                >
                  {phoneNumberEAP}
                </h2>
              </TextLink>
            )}
          </div>
        ) : (
          <div css={phoneContainerStyle}>
            {phoneMap.map((item) => (
              <div
                key={item.country}
                css={css({
                  ':not(:last-of-type)': {
                    marginBottom: SpacingInRemBySize.L,
                  },
                })}
              >
                <div css={itemsFontStyle()}>{translate(item.country)}</div>
                {item.phone.map((phone) => (
                  <div
                    key={phone.number}
                    css={{
                      display: 'flex',
                    }}
                  >
                    <TextLink
                      to={`tel:${stripSpacesAndParentheses(phone.number)}`}
                      css={css({
                        textDecoration: 'none',
                      })}
                    >
                      <h2 css={css(itemsFontStyle(), { margin: 0 })}>
                        {phone.number}
                      </h2>
                    </TextLink>
                    {phone.context && (
                      <span css={phoneContextStyle}>
                        {`(${translate(phone.context)})`}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
