import { pathOr, includes } from 'ramda';
import { RootState } from '../../../../../state/types';
import { OfferingType } from '../../../../../types/organization';
import { PRIMARY_PRODUCT } from '../../../../dashboard/constants';

export const getActiveOrg = (state: RootState) =>
  pathOr(null, ['activeOrg'], state);

export const getPartnerConsent = (state: RootState) =>
  pathOr(null, ['activeOrg', 'partnerConsent'], state);

export const getOrgRestrictedBy = (state: RootState) =>
  pathOr(null, ['activeOrg', 'enrollment', 'restrictBy'], state);

export const getCompanyName = (state: RootState): string =>
  pathOr('', ['activeOrg', 'name'], state);

export const getIsGingerBundlingEnabled = (state: RootState): boolean =>
  pathOr(false, ['activeOrg', 'gingerBundlingEnabled'], state);

export const getPrimaryProduct = (state: RootState): PRIMARY_PRODUCT =>
  pathOr(PRIMARY_PRODUCT.HEADSPACE, ['activeOrg', 'primaryProduct'], state);

export const getVoucher = (state: RootState): string | null =>
  pathOr(null, ['activeOrg', 'partialPayVoucher'], state);

export const getOrgId = (state: RootState) =>
  pathOr(null, ['activeOrg', 'id'], state);

export const getSlug = (state: RootState) =>
  pathOr(null, ['activeOrg', 'enrollment', 'slug'], state);

export const getWorkIdFieldPlaceholderText = (
  state: RootState,
): string | undefined =>
  pathOr(
    undefined,
    ['activeOrg', 'enrollment', 'workIdFieldPlaceholderText'],
    state,
  );

export const getHealthHub = (state: RootState) =>
  pathOr(null, ['activeOrg', 'healthHub'], state);

export const getHealthHubExternalId = (state: RootState) =>
  pathOr(null, ['activeOrg', 'healthHubExternalId'], state);

export const getHasDependentProgram = (state: RootState) =>
  pathOr(null, ['activeOrg', 'hasDependentProgram'], state);

export const getDependentsPerMember = (state: RootState) =>
  pathOr(null, ['activeOrg', 'dependentsPerMember'], state);

export const getIsElfDependentsEnabled = (state: RootState): boolean =>
  pathOr(false, ['activeOrg', 'elfDependentsEnabled'], state);

type GetConnection = (state: RootState) => null | string;

export const getConnection: GetConnection = (state) =>
  pathOr(null, ['activeOrg', 'ssoConnections', 0], state);

export const getMfaEnabled = (state: RootState) =>
  pathOr(null, ['activeOrg', 'mfaEnabled'], state);

export const getProductOfferings = (state: RootState): string[] =>
  pathOr([], ['activeOrg', 'offerings'], state);

export const getHasUnifiedOfferings = (state: RootState): boolean => {
  const productOfferings = getProductOfferings(state);

  return (
    includes(OfferingType.HEADSPACE, productOfferings) &&
    includes(OfferingType.GINGER, productOfferings)
  );
};

/**
 * V3 enrollment aka umd flow
 * must have both product offerings
 * org must be set up with eligibility file methods
 * and NOT ginger bundling - this condition might change/remove in the future.
 */
export const getIsV3Enrollment = (state: RootState): boolean => {
  const primaryProduct = getPrimaryProduct(state);

  return (
    primaryProduct === PRIMARY_PRODUCT.UNIFIED ||
    primaryProduct === PRIMARY_PRODUCT.FUSION
  );
};
