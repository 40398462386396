import { fontSizeM, fontWeightBold } from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { format, distanceInWords } from '../../../utils/dates';
import { RegularLabelSublabel } from '../../../shared-components/text/RegularLabelSublabel';
import { assureNumberValue } from '../../../utils/typeMassage';
import { Tooltip } from '../../../shared-components/Tooltip';
import { GenerateRows } from './types';
import { Organization, Subscription } from '../../../types/organization';
import { AP_CDN_URL } from '../constants/routes';

const orgNameStyle = css({
  fontSize: fontSizeM,
  fontWeight: fontWeightBold,
});

const defaultCalculateActiveMembersPercentage = (
  activeMembers: number,
  seats: number,
): string => {
  if (activeMembers) {
    const percentage = Math.round((activeMembers / seats) * 1000) / 10; // round to one decimal place
    return `${activeMembers} (${percentage}%)`;
  }
  return '0';
};

export const contractRows = (
  contract: Subscription,
  org: Organization,
  calculateActiveMembersPercentage: (
    activeMembers: number,
    seats: number,
  ) => string,
  handleSelectOrganization: (orgId: number) => void,
  t: I18next['t'],
): any => {
  const primaryProduct: string = org.contracts[0]?.primaryProduct ?? 'Unknown';
  const offering =
    primaryProduct[0].charAt(0).toUpperCase() +
    primaryProduct.substring(1).toLowerCase();
  const seats = assureNumberValue(contract.seats);
  const members = assureNumberValue(org.activeMembers);
  return {
    icon: `${AP_CDN_URL}/caretRightTundora.svg`,
    id: org.id,
    launch: (
      <RegularLabelSublabel
        key={org.name}
        label={format(contract.startDate, 'MMM D, YYYY')}
        sublabel={distanceInWords(new Date(), contract.startDate, true)}
      />
    ),
    members: calculateActiveMembersPercentage(members, seats),
    name: (
      <Tooltip message={org.name}>
        <div css={orgNameStyle} key={org.name}>
          {org.name}
        </div>
      </Tooltip>
    ),
    offering,
    onRowClick: () => handleSelectOrganization(org.id),
    seats,
    sortIndex: {
      launch: contract.startDate,
      members,
      name: org.name,
      offering,
      seats,
    },
  };
};

export const inactivePaymentRows = (
  org: Organization,
  handleSelectOrganization: (orgId: number) => void,
  t: I18next['t'],
): any => ({
  icon: `${AP_CDN_URL}/caretRightTundora.svg`,
  id: org.id,
  launch: null,
  members: null,
  name: (
    <Tooltip message={org.name}>
      <div css={orgNameStyle} key={org.name}>
        {org.name}
      </div>
    </Tooltip>
  ),
  offering: t('organizationSettings.contract.paymentOptions.inactive'),
  onRowClick: () => handleSelectOrganization(org.id),
  seats: null,
  sortIndex: {
    launch: '1970-01-01T00:00:00.000Z',
    members: -1,
    name: org.name,
    offering: t('organizationSettings.contract.paymentOptions.inactive'),
    seats: -1,
  },
});

export const generateRows = ({
  organizations,
  handleSelectOrganization,
  t,
}: GenerateRows): any =>
  organizations.map((org: Organization) => {
    const orgContract = org.contracts
      ? org.contracts.find((contract) => contract !== null)
      : null;

    return orgContract
      ? contractRows(
          orgContract,
          org,
          defaultCalculateActiveMembersPercentage,
          handleSelectOrganization,
          t,
        )
      : inactivePaymentRows(org, handleSelectOrganization, t);
  });
