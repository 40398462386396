import * as React from 'react';
import { Button, Green, White, WarmGrey } from '@headspace/web-ui-components';
import { Icon } from '../../../../../shared-components/Icon';
import {
  cardStyle,
  imageStyle,
  listStyle,
  buttonContainerStyle,
  imageContainerStyles,
} from './bundle-card-styles';
import { AP_CDN_URL } from '../../../../dashboard/constants/routes';

interface BundleCardProps {
  imageSrc: string;
  logo: string;
  onHandleClick: () => void;
  bulletList: {
    topic: string;
    message: string;
  }[];
  title: string;
  buttonValue: string;
}

export const BundleCard: React.FC<BundleCardProps> = ({
  logo,
  imageSrc,
  title,
  bulletList,
  onHandleClick,
  buttonValue,
}) => {
  return (
    <div css={cardStyle}>
      <div>
        <img src={imageSrc} alt="smiling-sun" css={imageStyle} />
        <img css={imageContainerStyles} src={logo} alt="logo" />
        <h2>{title}</h2>
      </div>
      <ul css={listStyle}>
        {Array.isArray(bulletList) &&
          bulletList.map(({ topic, message }) => (
            <li key={topic}>
              <Icon
                src={`${AP_CDN_URL}/checkThick-16.svg`}
                width={20}
                height={20}
                fill={Green[100]}
              />
              <div>
                <strong>{topic}</strong>
                <p>{message}</p>
              </div>
            </li>
          ))}
      </ul>
      <div css={buttonContainerStyle}>
        <Button
          onClick={onHandleClick}
          isBordered={true}
          borderColor={WarmGrey[400]}
          backgroundColor={White['000']}
          css={null}
        >
          {buttonValue}
        </Button>
      </div>
    </div>
  );
};
