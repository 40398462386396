import { FC, PropsWithChildren } from 'react';
import { css } from '@emotion/react';

import { columnStyle, contentContainerStyle } from '../../styles/pageStyles';
import { MarketingMessageUmd } from './MarketingMessageUmd';

interface HeaderProps extends PropsWithChildren {
  isMobileView: boolean;
  campaignDisplayName: string;
  showPersonifyCareSubHeader?: boolean;
}

const containerStyle = css(contentContainerStyle, {
  marginTop: 0,
});

export const Header: FC<HeaderProps> = ({
  campaignDisplayName,
  isMobileView,
  showPersonifyCareSubHeader = false,
  children,
}) => {
  return (
    <div css={containerStyle}>
      {isMobileView ? null : (
        <MarketingMessageUmd
          campaignDisplayName={campaignDisplayName}
          showPersonifyCareSubHeader={showPersonifyCareSubHeader}
        />
      )}
      <div css={columnStyle}>{children}</div>
    </div>
  );
};
