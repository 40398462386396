import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Button,
  Breakpoint,
  useActiveBreakpoint,
} from '@headspace/web-ui-components';
import { useNavigate } from 'react-router-dom';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  getUnifiedEligibilityRoutes,
  Routes,
  ENROLLMENT_CDN_URL,
} from '../../../../constants/routes';
import { getSlug } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { dashboardRoutes } from '../../../../../../root-component/Routes';
import { getEmail } from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  sectionBodyCss,
  sectionBoxCss,
  sectionTextCss,
  ctaButtonCss,
  dividerCss,
  sectionDisclaimerCss,
  sectionTitleCss,
  coachingSectionBodyCss,
  QRSectionCss,
  breakWordCss,
} from '../styles';
import { checkIfMobileOrTablet } from '../../utils/deviceUtils';
import { Product } from '../../../../constants';
import { onboardingButtonClickthrough } from '../../../../../../analytics/events/onboardingEvents';
import { headspaceHubRoutes } from '../../../../../headspaceHub/constants/routes';
import { useGetAppStoreLinks } from '../../../../../../hooks/useAppStoreLinks';
import { QRCode } from '../../../../../../shared-components/qrcode';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
}

export const ConditionalNextStepsContent = ({
  skipOnboarding,
  isEligibileForCoaching,
  hasHubAccess,
}) => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const slug = useSelector(getSlug);
  const email = useSelector(getEmail);
  const { SCHEDULING_PATH } = getUnifiedEligibilityRoutes(slug);
  const activeBreakpoint = useActiveBreakpoint();
  const isMobile = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const { trackableAppStoreLink } = useGetAppStoreLinks({
    app: Product.HEADSPACE,
  });

  const handleHubRouteOnClick = useCallback(
    (event) => {
      event.preventDefault();
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
      navigate(headspaceHubRoutes.BENEFITS_PATH);
    },
    [dispatch, navigate],
  );

  const handleMeetCoachOnClick = useCallback(
    (event) => {
      event.preventDefault();
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
      navigate(SCHEDULING_PATH);
    },
    [SCHEDULING_PATH, dispatch, navigate],
  );

  const handleDownloadHSOnClick = useCallback(() => {
    const isMobileOrTablet = checkIfMobileOrTablet();
    if (isMobileOrTablet) {
      navigate(dashboardRoutes.REDIRECT_TO_APP_STORE);
    } else {
      window.location.href = Routes.HEADSPACE_APP;
    }
  }, [navigate]);

  const shouldShowDividerAndSecondaryHeader =
    isEligibileForCoaching || hasHubAccess;

  return (
    <div data-testid="content-container">
      {/* Top Section: Render Hub Entry or QR Code based */}
      <h3 css={sectionTextCss}>
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.title',
        )}
      </h3>
      {skipOnboarding ? (
        <OnboardingSkippedHubSection
          email={email}
          translate={translate}
          handleHubRouteOnClick={handleHubRouteOnClick}
        />
      ) : isMobile ? (
        <NextStepsHSAppSection
          email={email}
          translate={translate}
          handleDownloadHSOnClick={handleDownloadHSOnClick}
        />
      ) : (
        <NextStepsQRCodeSection
          email={email}
          translate={translate}
          trackableAppStoreLink={trackableAppStoreLink}
        />
      )}

      {/* Divider between sections */}
      {shouldShowDividerAndSecondaryHeader && (
        <>
          <Divider css={dividerCss} />
          <h3 css={sectionTextCss}>
            {translate('enrollment.nextStepsPage.secondary.section.title')}
          </h3>
        </>
      )}

      {/* Coaching Eligibility Section */}
      {isEligibileForCoaching && (
        <CoachingSection
          translate={translate}
          handleMeetCoachOnClick={handleMeetCoachOnClick}
        />
      )}

      {/* Bottom Section: Download Headspace App or Hub Entr */}
      {skipOnboarding ? (
        <NextStepsHSAppSection
          email={email}
          translate={translate}
          handleDownloadHSOnClick={handleDownloadHSOnClick}
        />
      ) : (
        hasHubAccess && (
          <NextStepsHubSection
            translate={translate}
            handleHubRouteOnClick={handleHubRouteOnClick}
          />
        )
      )}
    </div>
  );
};

const OnboardingSkippedHubSection = ({
  email,
  translate,
  handleHubRouteOnClick,
}) => (
  <section data-testid="skip-onboarding-hub-section">
    <div css={sectionBodyCss}>
      <div>
        <img
          src={`${ENROLLMENT_CDN_URL}/umd-onboarding-blue-folder.svg`}
          alt="folder-icon"
        />
      </div>
      <div css={sectionBoxCss}>
        <div css={sectionTitleCss}>
          {translate(
            'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.subTitle',
          )}
        </div>
        <div css={sectionTextCss}>
          {translate(
            'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.body',
          )}
        </div>
        <p css={[sectionDisclaimerCss, breakWordCss]}>
          {translate(
            'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.disclaimer',
            { email },
          )}
        </p>
        <Button
          variant={ButtonType.Primary}
          css={ctaButtonCss}
          onClick={handleHubRouteOnClick}
          dataTestIdPrefix="next-step-section"
        >
          {translate(
            'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.buttonText',
          )}
        </Button>
      </div>
    </div>
  </section>
);

const NextStepsHSAppSection = ({
  email,
  translate,
  handleDownloadHSOnClick,
}) => (
  <section css={sectionBodyCss} data-testid="download-app-section">
    <div>
      <img
        src={`${ENROLLMENT_CDN_URL}/umd-onboarding-meditation.svg`}
        alt="section-icon"
      />
    </div>
    <div css={sectionBoxCss}>
      <div css={sectionTitleCss}>
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceApp.section.subTitle',
        )}
      </div>
      <div css={[sectionTextCss, breakWordCss]}>
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceApp.section.body',
          { email },
        )}
      </div>
      <p css={sectionDisclaimerCss}>
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceApp.section.disclaimer',
        )}
      </p>
      <Button
        variant={ButtonType.Tertiary}
        css={ctaButtonCss}
        onClick={handleDownloadHSOnClick}
        dataTestIdPrefix="next-step-section"
      >
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceApp.section.buttonText',
        )}
      </Button>
    </div>
  </section>
);

const NextStepsQRCodeSection = ({
  email,
  translate,
  trackableAppStoreLink,
}) => (
  <section css={QRSectionCss}>
    <div css={sectionBoxCss}>
      <div css={sectionTextCss}>
        {translate('onboarding.recommend.headspace.qrCodeHeader')}
      </div>
      <p css={[sectionDisclaimerCss, breakWordCss]}>
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.disclaimer',
          { email },
        )}
      </p>
    </div>
    <QRCode
      data={trackableAppStoreLink}
      id="download-headspace-app"
      filename="download-headspace-app"
      margin={2}
      scale={2}
    />
  </section>
);

const CoachingSection = ({ translate, handleMeetCoachOnClick }) => (
  <section css={coachingSectionBodyCss} data-testid="coaching-section">
    <div>
      <img
        src={`${ENROLLMENT_CDN_URL}/umd-onboarding-chat-icon.png`}
        alt="section-icon"
      />
    </div>
    <div css={sectionBoxCss}>
      <div css={sectionTitleCss}>
        {translate('onboarding.recommend.headspace.meetWithCoach.header')}
      </div>
      <div css={sectionTextCss}>
        {translate('onboarding.recommend.headspace.meetWithCoach.subHeader')}
      </div>
      <p css={sectionDisclaimerCss}>
        {translate('onboarding.recommend.headspace.meetWithCoach.linkInfo_v2')}
      </p>
      <Button
        variant={ButtonType.Primary}
        css={ctaButtonCss}
        onClick={handleMeetCoachOnClick}
        dataTestIdPrefix="coaching"
      >
        {translate('onboarding.recommend.headspace.meetWithCoach.link')}
      </Button>
    </div>
  </section>
);

const NextStepsHubSection = ({ translate, handleHubRouteOnClick }) => (
  <section css={sectionBodyCss} data-testid="secondary-hub-section">
    <div>
      <img
        src={`${ENROLLMENT_CDN_URL}/umd-onboarding-blue-folder.svg`}
        alt="section-icon"
      />
    </div>
    <div css={sectionBoxCss}>
      <div css={sectionTitleCss}>
        {translate('onboarding.recommend.headspace.moreForYou.subHeader')}
      </div>
      <div css={sectionTextCss}>
        {translate(
          'enrollment.nextStepsPage.secondary.headspaceHub.section.body',
        )}
      </div>
      <Button
        variant={ButtonType.Tertiary}
        css={ctaButtonCss}
        onClick={handleHubRouteOnClick}
        dataTestIdPrefix="next-step-section"
      >
        {translate(
          'enrollment.skipOnboarding.nextStepsPage.headspaceHub.section.buttonText',
        )}
      </Button>
    </div>
  </section>
);
