import { FC } from 'react';
import { BundleCard } from './BundleCard';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';
import { IMG_CDN_URL_LOGO } from '../../../../dashboard/constants';

interface HeadspaceCardProps {
  onHandleClick: () => void;
}

export const HeadspaceCard: FC<HeadspaceCardProps> = ({ onHandleClick }) => {
  const { translate } = useTranslate();

  const bulletList = [
    {
      message: translate('gingerBundle.headspaceCard.bullet1.message'),
      topic: translate('gingerBundle.headspaceCard.bullet1.topic'),
    },
    {
      message: translate('gingerBundle.headspaceCard.bullet2.message'),
      topic: translate('gingerBundle.headspaceCard.bullet2.topic'),
    },
    {
      message: translate('gingerBundle.headspaceCard.bullet3.message'),
      topic: translate('gingerBundle.headspaceCard.bullet3.topic'),
    },
  ];

  return (
    <BundleCard
      buttonValue={translate('gingerBundle.headspaceCard.buttonValue')}
      logo={`${IMG_CDN_URL_LOGO}/new-logo.svg`}
      imageSrc={`${ENROLLMENT_CDN_URL}/headspace-smile-v2.png`}
      title={translate('gingerBundle.headspaceCard.title')}
      bulletList={bulletList}
      onHandleClick={onHandleClick}
    />
  );
};
