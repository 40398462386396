import { CoolGrey } from '@headspace/web-ui-components';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';
import { Icon } from '../Icon';
import { TableFilterBarProps } from './types';
import { clearSearchStyle, searchInputStyle, searchStyle } from './styles';
import { noop } from '../../utils';
import { regular } from '../../apps/dashboard/styles/fonts';

export const TableFilterBar = ({
  handleSearch,
  searchValue,
  dataTestId,
  placeholder,
}: TableFilterBarProps) => {
  return (
    <div className="search" css={searchStyle}>
      <Icon
        width={24}
        height={24}
        fill={CoolGrey[500]}
        src={`${AP_CDN_URL}/search.svg`}
      />
      <input
        tabIndex={0}
        data-testid={dataTestId}
        type="text"
        placeholder={placeholder}
        onChange={handleSearch}
        value={searchValue}
        css={[searchInputStyle, regular]}
      />
      {searchValue ? (
        <div
          tabIndex={0}
          aria-label="Clear search"
          onClick={handleSearch}
          onKeyDown={noop}
          className="clearSearch"
          role="button"
          css={clearSearchStyle}
        >
          <Icon
            width={16}
            height={16}
            fill={CoolGrey[500]}
            src={`${AP_CDN_URL}/close-16.svg`}
          />
        </div>
      ) : null}
    </div>
  );
};
