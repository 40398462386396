export enum CONSENT_STATUS {
  ACCEPTED = 'ACCEPTED',
  DECLINED = 'DECLINED',
}

export enum SCOPE {
  INDIVIDUAL = 'INDIVIDUAL',
  UNIVERSAL = 'UNIVERSAL',
}

export interface Consent {
  id: number;
  code: string;
  name: string;
  description: string;
  version: number;
  active: boolean;
  title: string;
  body: string;
  mediaItemId: number;
  createdAt: Date;
  updatedAt: Date;
  scope: SCOPE;
  permissions?: string[];
}

export interface GetUserConsentsResponse {
  consents: Consent[];
}
