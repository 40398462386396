import * as React from 'react';
import env from '../../../../config/env';
import { HealthProvider } from '../../constants/healthProvider';
import { Routes } from '../../constants/routes';

export function getRedirectUrlForHealthProvider(healthProvider = ''): string {
  const defaultRedirectUrl = `${env.WEBSITE_HOST}/login`;

  const healthProviderRoutes = new Map<string, string>([
    [HealthProvider.PERSONIFY, `${env.HOST}${Routes.PERSONIFY_LAND}`],
    [HealthProvider.PERSONIFY_CARE, `${env.HOST}${Routes.PERSONIFY_CARE_LAND}`],
    [HealthProvider.SOLERA, `${env.WEBSITE_HOST}/login`],
  ]);

  return healthProviderRoutes.get(healthProvider) ?? defaultRedirectUrl;
}

const AuthLogoutCallbackPage: React.FC = () => {
  const orgSlug = window.location.search.substring(1);
  const healthProvider = new URLSearchParams(window.location.search).get(
    'healthProvider',
  );

  let redirectUrl;
  if (healthProvider !== null) {
    redirectUrl = getRedirectUrlForHealthProvider(healthProvider);
  } else if (orgSlug) {
    redirectUrl = `${env.HOST}/${orgSlug}/member-enroll/login`;
  } else {
    redirectUrl = '/login';
  }

  React.useEffect(() => window.location.replace(redirectUrl), [redirectUrl]);

  return <div data-testid="auth-logout-callback-page" />;
};

export default AuthLogoutCallbackPage;
