import * as React from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { CLINICAL_COVERAGE_TYPE, EapTypes } from '../../types/organization';
import { ExtendedInputType, Input } from '../Input';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../constants/featureFlags';
import { ADD_ON_OPTIONS } from './constants';

type AddOnOptionsInputProps = {
  deprecatedValue: EapTypes;
  value: CLINICAL_COVERAGE_TYPE;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable?: boolean;
  term: string;
  type?: ExtendedInputType;
};

export const AddOnOptionsInput = ({
  deprecatedValue = EapTypes.NONE,
  value = CLINICAL_COVERAGE_TYPE.NONE,
  onChange,
  disable,
  term,
  type = 'select',
}: AddOnOptionsInputProps) => {
  const { translate } = useTranslate();
  let name;
  let newValue;

  const [isHubAccessForIntlEnable] = useFeatureFlag(
    FEATURE_FLAG.HUB_ACCESS_FOR_INTL_CARE_PACKAGE,
  );

  if (isHubAccessForIntlEnable) {
    name = `${term}_contract_clinical_coverage_type`;
    newValue = value;
  } else {
    name = `${term}_contract_eap_type`;
    newValue = deprecatedValue;
  }

  const label = translate('organizationSettings.enrollment.addOnsLabel');

  const filteredOptions = ADD_ON_OPTIONS.filter(
    (option) =>
      isHubAccessForIntlEnable ||
      option.value !== CLINICAL_COVERAGE_TYPE.INTL_THERAPY,
  );

  const translatedOptions = filteredOptions.map((option) => ({
    ...option,
    title: translate(option.titleKey),
  }));

  return (
    <div>
      <Input
        name={name}
        type={type}
        label={label}
        value={newValue}
        onChange={onChange}
        options={translatedOptions}
        disabled={disable}
      />
    </div>
  );
};
