import { FC, MouseEvent } from 'react';
import { CloseButton } from '@headspace/web-ui-components';
import { TextButton } from '../buttons/TextButton';
import {
  backgroundStyle,
  modalStyle,
  messageStyle,
  titleStyle,
  buttonStyle,
  actionButtonsSectionStyle,
  confirmButtonStyle,
  cancelButtonStyle,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  title?: string;
  message?: string;
  additionalMessage?: string;
  handleConfirm: (event: MouseEvent) => void;
  handleCancel?: ((event?: MouseEvent) => void) | null;
}

export const Modal: FC<ModalProps> = ({
  isOpen,
  title,
  message,
  additionalMessage,
  handleConfirm,
  handleCancel,
}) => {
  return isOpen ? (
    <div css={backgroundStyle} data-testid="modal" className="modal">
      <div css={modalStyle}>
        <div css={buttonStyle}>
          {handleCancel && <CloseButton onClick={handleCancel} />}
        </div>

        <h2 data-testid="modal-title" css={titleStyle}>
          {title}
        </h2>
        <div data-testid="modal-message" css={messageStyle}>
          {message}
        </div>
        <div>{additionalMessage || null}</div>

        <div css={actionButtonsSectionStyle}>
          {handleCancel ? (
            <TextButton
              className="cancel-button"
              id="cancel-delete-button"
              onClick={handleCancel}
              value="cancel"
              dataTestId="cancel-delete-button"
              css={cancelButtonStyle}
              backgroundColor="FAF7F2"
              hoverColor="#EBE8E4"
              textColor="#F47D31"
            />
          ) : null}
          {handleConfirm && (
            <TextButton
              css={confirmButtonStyle}
              id="confirm-delete-button"
              className="continue-button"
              onClick={handleConfirm}
              value="continue"
              dataTestId="confirm-delete-button"
            />
          )}
        </div>
      </div>
    </div>
  ) : null;
};
