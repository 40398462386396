import {
  useRef,
  useCallback,
  useEffect,
  useState,
  SyntheticEvent,
  FocusEvent,
  useContext,
} from 'react';
import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import { useSelector, useDispatch } from 'react-redux';
import { postChangeLanguage } from '../../../../rest-calls';
import {
  buttonStyle,
  caretPadding,
  languageButtonStyle,
  languageMenuContainer,
  languageTabStyle,
  mobileSignOutStyle,
} from './styles';
import { Icon } from '../../../../../../shared-components/Icon';
import { setLanguage } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import { isTargetChildOfParent } from '../../../../../../utils/isTargetChildOfParent';
import { initializePayment } from '../../../../state/actions/paymentActions/paymentActions';
import { handleLogoutFactory } from '../handleLogoutFactory';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  SupportedLanguageCode,
  getAllAvailableLanguages,
  HubSupportedLanguageCode,
  CCAApprovedLanguageCode,
} from '../../../../../../types/language';
import {
  getHsUserId,
  getJWT,
} from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getHealthHub,
  getPrimaryProduct,
  getSlug,
} from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getHealthProvider } from '../../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';
import { PRIMARY_PRODUCT } from '../../../../../dashboard/constants';
import { hasClinicalCoverage } from '../../../../../../state/selectors/organizationSelectors';
import { HubLangContext } from '../../../../../../root-component/IntlProvider';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';
import { LanguageDropdown } from '../../../../../../shared-components/dropdowns/LanguageDropdown/LanguageDropdown';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const DATA_TEST_ID_PREFIX = 'translationTab';

export const TranslationTab = () => {
  const menuRef = useRef(null);
  const JWT = useSelector(getJWT);
  const hsUSerId = useSelector(getHsUserId);
  const slug = useSelector(getSlug);
  const healthHub = useSelector(getHealthHub);
  const healthProvider = useSelector(getHealthProvider);
  const dispatch = useDispatch();
  const primaryProduct = useSelector(getPrimaryProduct);
  const [isLanguageExpansionEnabled] = useFeatureFlag(
    FEATURE_FLAG.LANGUAGE_EXPANSION,
  );
  const hasHubAcces = useSelector(hasClinicalCoverage);

  const shouldShowExpansionLanguages =
    isLanguageExpansionEnabled &&
    hasHubAcces &&
    primaryProduct === PRIMARY_PRODUCT.FUSION;
  const languageSelection = getAllAvailableLanguages(
    shouldShowExpansionLanguages,
    null,
  );

  const [isOpen, setOpenTab] = useState(false);
  const selectorLanguage = useSelector(getLanguage);
  const { hubLangCookie, setHubLangCookie } = useContext(HubLangContext);
  const { translate } = useTranslate();

  const selectedLanguage = languageSelection[hubLangCookie] ?? selectorLanguage;

  useEffect(() => {
    dispatch(setLanguage(selectorLanguage));
  }, [dispatch, selectorLanguage]);

  useEffect(() => {
    // This is needed to update the Zendesk widget language.
    // https://developer.zendesk.com/documentation/classic-web-widget-sdks/chat-widget/customising-the-widget/changing-the-widget-language/

    if (global.$zopim) {
      global.$zopim.livechat.setLanguage(selectorLanguage);
    }
  }, [selectorLanguage]);

  const handleLogout = handleLogoutFactory(
    dispatch,
    slug,
    hsUSerId,
    healthHub,
    healthProvider,
  );

  const handleOpenTab = useCallback(() => {
    setOpenTab(!isOpen);
  }, [setOpenTab, isOpen]);

  // Close the drowdown menu when it loses focus
  const handleBlur = useCallback(
    (event: FocusEvent) => {
      if (
        !isTargetChildOfParent(menuRef.current, event.relatedTarget as Element)
      ) {
        setOpenTab(false);
      }
    },
    [setOpenTab, menuRef],
  );

  const handleLanguageSelect = useCallback(
    (
      languageCode:
        | SupportedLanguageCode
        | HubSupportedLanguageCode
        | CCAApprovedLanguageCode,
    ) => async () => {
      handleOpenTab();

      setHubLangCookie(languageCode);

      const isAppSupportedLanguage = SupportedLanguageCode[languageCode];

      if (!isAppSupportedLanguage) {
        return;
      }
      dispatch(setLanguage(languageCode as SupportedLanguageCode));

      const fullLangCode = `${languageCode}-${getCookie('countryCode')}`;

      if (hsUSerId) {
        await postChangeLanguage(JWT, hsUSerId, fullLangCode);
      }
      if (window.location.pathname.match('/member-enroll/payment')) {
        dispatch(initializePayment());
      }
    },
    [JWT, dispatch, handleOpenTab, hsUSerId],
  );

  // Fix for IE.
  const handlePreventDefault = useCallback((event: SyntheticEvent) => {
    event.preventDefault();
  }, []);

  const languageKeys = Object.keys(languageSelection) as Array<
    SupportedLanguageCode | HubSupportedLanguageCode
  >;

  return (
    <div onBlur={handleBlur}>
      {shouldShowExpansionLanguages ? (
        <div css={languageMenuContainer}>
          <LanguageDropdown
            dataTestId={DATA_TEST_ID_PREFIX}
            selectedLanguage={selectedLanguage}
            handleClick={handleLanguageSelect}
            languages={languageSelection}
            featureFlagEnabled={isLanguageExpansionEnabled}
            isLanguageMenuSeparated={false}
          />
        </div>
      ) : (
        <>
          <button
            type="button"
            css={buttonStyle}
            onClick={handleOpenTab}
            data-testid="language-dropdown"
            aria-expanded={isOpen}
          >
            <p>{translate(`enrollment.language.${selectedLanguage}`)}</p>
            <div css={caretPadding}>
              <Icon
                src={`${ENROLLMENT_CDN_URL}/caret-down.svg`}
                width={20}
                height={20}
                fill="#67626E"
                data-testid="hs-logo"
              />
            </div>
          </button>
          <ul role="menu" css={languageTabStyle(isOpen)} ref={menuRef}>
            <li css={[mobileSignOutStyle(hsUSerId), languageButtonStyle()]}>
              <button type="button" onClick={handleLogout}>
                <p>{translate('signOut')}</p>
              </button>
            </li>
            {languageKeys.map((lang) => (
              <li
                key={lang}
                css={languageButtonStyle(selectedLanguage === lang)}
              >
                <button
                  type="button"
                  data-testid={lang}
                  onMouseDown={handlePreventDefault}
                  onClick={handleLanguageSelect(lang)}
                >
                  <p>{translate(`enrollment.language.${lang}`)}</p>
                </button>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
