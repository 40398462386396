import { FinishPageWrapper } from './shared-components/FinishPageWrapper';
import { ConditionalNextStepsContent } from './shared-components/ConditionalNextStepsContent';
import {
  mainContainerCss,
  moduleContainerCss,
  moduleHeaderCss,
} from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

export const SkipOnboardingFinishPage = () => {
  const { translate } = useTranslate();

  return (
    <div css={mainContainerCss}>
      <FinishPageWrapper>
        <div css={moduleContainerCss}>
          <div css={moduleHeaderCss}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-onboarding-happy-sun.png`}
              alt="module-icon"
              data-testid="module-icon"
            />
            <h2 data-testid="next-steps-module-title">
              {translate('enrollment.skipOnboarding.nextStepsPage.title')}
            </h2>
          </div>
          <ConditionalNextStepsContent
            skipOnboarding={true}
            isEligibileForCoaching={false}
            hasHubAccess={true}
          />
        </div>
      </FinishPageWrapper>
    </div>
  );
};
