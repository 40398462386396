import { css } from '@emotion/react';
import {
  borderRadiusCircle,
  borderRadiusXS,
  borderWidthXS,
  CoolGrey,
  FONT_WEIGHT_TO_NUMERIC_VALUE,
  fontFamilyCss,
  fontSizeM,
  fontSizeS,
  rem,
  SpacingInRemBySize,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';

export const textStyle = css({
  paddingRight: SpacingInRemBySize.XS,
});

export const dropdownItemStyle = css(fontFamilyCss, {
  '&:hover': {
    backgroundColor: WarmGrey[100],
    cursor: 'pointer',
  },
});

export const disablePointerEventsStyle = css({
  backgroundColor: WarmGrey[200],
  opacity: 0.7,
  pointerEvents: 'none',
});

export const leftStyle = css({
  left: 0,
});

export const rightStyle = css({
  right: 0,
});

export const topRightStyle = css({
  right: 0,
  top: 0,
  transform: 'translate(0, -100%)',
});

export const middleStyle = css({
  left: '50%',
});

export const positionStyle = (position: string) => {
  switch (position) {
    case 'left':
      return leftStyle;
    case 'right':
      return rightStyle;
    case 'topRight':
      return topRightStyle;
    default:
      return middleStyle;
  }
};

export const dropdownLinkStyle = css({
  color: CoolGrey[500],
  display: 'block',
  fontSize: fontSizeM,
  padding: rem(1.125),
  textDecoration: 'none',
});

export const dropdownItemsStyle = () =>
  css({
    backgroundColor: White['000'],
    border: `${borderWidthXS} solid ${WarmGrey[200]}`,
    borderRadius: borderRadiusXS,
    marginTop: SpacingInRemBySize.XS,
  });

export const dropdownContentStyle = (options: { minWidth: number | string }) =>
  css({
    background: 'transparent',
    display: 'none',
    minWidth: options.minWidth,
    position: 'absolute',
    zIndex: 1,
  });

export const dropdownStyle = ({
  ctaHoverBackgroundColor,
  isIconButton,
}: {
  ctaHoverBackgroundColor: string;
  isIconButton: boolean;
}) =>
  css({
    '&:hover .dropdownContent': {
      display: 'block',
    },
    '&:hover .dropdownCta': {
      backgroundColor: ctaHoverBackgroundColor,
      borderRadius: isIconButton ? borderRadiusCircle : borderRadiusXS,
    },
    cursor: 'pointer',
    display: 'inline-block',
    position: 'relative',
  });

export const dropdownCtaStyle = (options: {
  ctaColor: string;
  ctaHoverBackgroundColor: string;
  letterSpacing?: boolean;
  uppercase?: boolean;
  isIconButton?: boolean;
}) =>
  css({
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    color: options.ctaColor,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: fontSizeS,
    fontWeight: FONT_WEIGHT_TO_NUMERIC_VALUE.bold,
    justifyContent: 'center',
    letterSpacing: options.letterSpacing ? rem(0.156) : 0,
    padding: options.isIconButton
      ? SpacingInRemBySize.S
      : `${SpacingInRemBySize.S} ${SpacingInRemBySize.L}`,
    textTransform: options.uppercase ? 'uppercase' : 'none',
  });
