import type { FC } from 'react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container } from '../../../../../../shared-components/container/Container';
import { OrganizationActionPanel } from '../shared-components/ActionPanel/OrganizationActionPanel';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import { deleteOrganization } from '../../../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';

export const DeleteOrgPanel: FC = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const isCSM = useSelector(getIsCSM);
  const { eligibilityFiles } = useSelector(getOrgManagement);

  const isAnyEligibilityFileProcessing = eligibilityFiles.some(
    (file) => file.status === 'PROCESSING',
  );

  const handleDeleteOrg = useCallback(() => {
    dispatch(deleteOrganization());
  }, [dispatch]);

  return (
    <Container>
      <OrganizationActionPanel
        title={translate('organizationSettings.delete.title')}
        available={isCSM}
        buttonAction={handleDeleteOrg}
        buttonTestId="organization-delete-button"
        buttonText={translate('delete')}
        dataTestId="organization-delete-panel"
        isButtonDisabled={isAnyEligibilityFileProcessing}
      />
    </Container>
  );
};
