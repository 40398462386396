import { FC, useCallback, useEffect, useMemo } from 'react';
import { PrimaryButton, SecondaryButton } from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import { OnboardingFooter } from '../onboarding-shared-components/OnboardingFooter';
import { ConsentList } from './ConsentList';
import {
  actionsStyle,
  buttonDesktopStyle,
  descriptionStyle,
  footerActionsStyle,
  headerStyle,
  hintStyle,
  iconStyle,
  orgLogoStyle,
  panelStyle,
} from './styles/ConsentPageStyle';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  getActiveOrg,
  getSlug,
} from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  fetchOnboardingUserConsents,
  markConsentPageAsSeen,
  updateConsent,
} from '../../../../state/slice/onboarding/onboardingReducer';
import { CONSENT_STATUS } from '../../../../models/Consent';
import { getOnboardingConsents } from '../../../../state/slice/onboarding/onboardingSelector';
import { getLogoEnv } from '../../../../../../utils/getLogo';
import {
  ENROLLMENT_CDN_URL,
  getUnifiedEligibilityRoutes,
} from '../../../../constants/routes';
import {
  onboardingButtonClickthrough,
  onboardingConsentScreenViewAction,
} from '../../../../../../analytics/events/onboardingEvents';

const INITIAL_FLOW_COMPLETION_PROGRESS = 75;

export const ConsentPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const activeOrg = useSelector(getActiveOrg);
  const consents = useSelector(getOnboardingConsents);
  const slug = useSelector(getSlug);
  const { ONBOARDING_NEXT_STEPS_PATH } = getUnifiedEligibilityRoutes(slug);

  useEffect(() => {
    dispatch(onboardingConsentScreenViewAction());
    dispatch(markConsentPageAsSeen());
    dispatch(fetchOnboardingUserConsents());
  }, [dispatch]);

  const currentConsent = useMemo(() => {
    return consents.find(
      (partnerConsent) =>
        partnerConsent.code === activeOrg.partnerConsent.code &&
        partnerConsent.version === activeOrg.partnerConsent.version,
    );
  }, [activeOrg.partnerConsent, consents]);

  const trackButtonClick = useCallback(
    (buttonText) => dispatch(onboardingButtonClickthrough(buttonText)),
    [dispatch],
  );

  const handleAccept = useCallback(
    (event) => {
      if (currentConsent) {
        trackButtonClick(event.target.innerText);
        dispatch(
          updateConsent({
            consentId: currentConsent.id,
            status: CONSENT_STATUS.ACCEPTED,
          }),
        );
        navigate(ONBOARDING_NEXT_STEPS_PATH);
      }
    },
    [
      dispatch,
      trackButtonClick,
      navigate,
      currentConsent,
      ONBOARDING_NEXT_STEPS_PATH,
    ],
  );

  const handleDecline = useCallback(
    (event) => {
      if (currentConsent) {
        trackButtonClick(event.target.innerText);
        dispatch(
          updateConsent({
            consentId: currentConsent.id,
            status: CONSENT_STATUS.DECLINED,
          }),
        );
        navigate(ONBOARDING_NEXT_STEPS_PATH);
      }
    },
    [
      dispatch,
      trackButtonClick,
      navigate,
      currentConsent,
      ONBOARDING_NEXT_STEPS_PATH,
    ],
  );

  return (
    <div>
      <PageWrapper
        progress={INITIAL_FLOW_COMPLETION_PROGRESS}
        icon={
          <div css={iconStyle}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-onboarding-consent.svg`}
              alt="panel-illustration"
              width="100%"
            />
          </div>
        }
        footer={
          <OnboardingFooter
            RightColumn={() => (
              <div css={footerActionsStyle}>
                <SecondaryButton
                  css={buttonDesktopStyle}
                  isBlock={true}
                  onClick={handleDecline}
                >
                  {translate('onboarding.partnerConsent.decline')}
                </SecondaryButton>
                <PrimaryButton
                  css={buttonDesktopStyle}
                  isBlock={true}
                  onClick={handleAccept}
                >
                  {translate('onboarding.partnerConsent.accept')}
                </PrimaryButton>
              </div>
            )}
          />
        }
      >
        <div css={panelStyle}>
          <div css={headerStyle}>
            <p>{activeOrg.partnerConsent.title}</p>
          </div>
          {activeOrg.logo && (
            <img
              css={orgLogoStyle}
              src={getLogoEnv(activeOrg.logo)}
              alt="org-logo"
            />
          )}
          <div css={descriptionStyle}>
            {activeOrg.partnerConsent.body.split('\n').map((line: string) => (
              <p key={line}>{line}</p>
            ))}
          </div>
          <ConsentList
            permissions={activeOrg.partnerConsent?.permissions ?? []}
          />
          <div css={hintStyle}>
            <p>{translate('onboarding.partnerConsent.hint')}</p>
          </div>
        </div>
        <div css={actionsStyle}>
          <PrimaryButton isBlock={true} onClick={handleAccept}>
            {translate('onboarding.partnerConsent.accept')}
          </PrimaryButton>
          <SecondaryButton isBlock={true} onClick={handleDecline}>
            {translate('onboarding.partnerConsent.decline')}
          </SecondaryButton>
        </div>
      </PageWrapper>
    </div>
  );
};
