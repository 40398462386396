import { FC } from 'react';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  CollapsibleInfo,
  ContactHelpEmail,
} from '../../../PersonifyCare/Components/CollapsibleInfo';
import { subTitleStyle } from '../../../shared-components/VerifyBy/styles';

const HealthProviderWorkIdGuidance: FC = () => {
  const { translate } = useTranslate();
  return (
    <div css={subTitleStyle()}>
      <CollapsibleInfo
        title={translate('enrollment.healthProviderCareCollapsibleInfo.title')}
        listItems={[
          translate('enrollment.healthProviderCareCollapsibleInfo.listItem1'),
          translate('enrollment.healthProviderCareCollapsibleInfo.listItem2'),
          translate('enrollment.healthProviderCareCollapsibleInfo.listItem3'),
        ]}
      >
        <ContactHelpEmail />
      </CollapsibleInfo>
    </div>
  );
};

export default HealthProviderWorkIdGuidance;
