export const EMAIL_VERIFICATION_STEPS = {
  RESEND_EMAIL: 2,
  VERIFY_EMAIL: 1,
};

export const FAQ_PAGE_LINK =
  'https://help.headspace.com/hc/en-us/categories/360000026128-Headspace-for-Enterprise';

export const FAQ_PAGE_LINK_V2 =
  'https://help.headspace.com/hc/en-us/sections/19822287253915';

export const FAQ_PAGE_LINK_DOB_AND_COUNTRY =
  'https://help.headspace.com/hc/en-us/sections/19822287253915-Enrollment';

export const FAQ_PAGE_LINK_PERSONIFY =
  'https://help.headspace.com/hc/en-us/sections/19497944278555';

export const FAQ_PAGE_ELF_DEPENDENTS_LINK =
  'https://help.headspace.com/hc/en-us/sections/19822287253915-Enrollment';

export const PRIVACY_PAGE_LINK = 'https://www.headspace.com/privacy-policy';

export const TERMS_AND_CONDITIONS_LINK =
  'https://www.headspace.com/terms-and-conditions';

export type FaqCommonQuestionsProps = {
  answer: string;
  link: string | null;
  question: string;
};

export const FAQ_COMMON_QUESTIONS_V2: Array<FaqCommonQuestionsProps> = [
  {
    answer: 'enrollment.landingPage.faq.message1_v2',
    link: 'mailto:teamsupport@headspace.com',
    question: 'enrollment.landingPage.faq.question1',
  },
  {
    answer: 'enrollment.landingPage.faq.message2_v2',
    link: null,
    question: 'enrollment.landingPage.faq.question2',
  },
  {
    answer: 'enrollment.landingPage.faq.message3_v2',
    link:
      'https://help.headspace.com/hc/en-us/articles/11405410912027-How-do-I-add-my-family-members-dependents-to-this-benefit-',
    question: 'enrollment.landingPage.faq.question3',
  },
];

export const FAQ_FUSION_QUESTIONS: Array<FaqCommonQuestionsProps> = [
  {
    answer: 'enrollment.landingPage.fusion.faq.message1',
    link: 'mailto:teamsupport@headspace.com',
    question: 'enrollment.landingPage.fusion.faq.question1',
  },
  {
    answer: 'enrollment.landingPage.fusion.faq.message2',
    link: null,
    question: 'enrollment.landingPage.fusion.faq.question2',
  },
  {
    answer: 'enrollment.landingPage.fusion.faq.message3',
    link:
      'https://help.headspace.com/hc/en-us/articles/11405410912027-How-do-I-add-my-family-members-dependents-to-this-benefit-',
    question: 'enrollment.landingPage.fusion.faq.question3',
  },
];
