import { AuthConnections } from '@headspace/web-auth';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { accountLinkingScreenViewAction } from '../../../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../../../constants/healthProvider';
import { Routes } from '../../../../constants/routes';
import { fetchSSOEnrollmentsStatusAction } from '../../../../state/actions/ssoEnrollmentActions/fetchSSOEnrollmentStatusAction';
import {
  getAuthConnections,
  getHsUserId,
} from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getIsEnrolled } from '../../../../state/selectors/ssoSelector/ssoSelector';
import { PageWrapper } from '../PageWrapper/PageWrapper';
import PreLinkComponent from './PreLinkComponent';

export const LinkPage = () => {
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);
  const isEnrolled = useSelector(getIsEnrolled);
  const userConnections = useSelector(getAuthConnections);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!HS_USER_ID) {
      navigate(Routes.PERSONIFY_LAND);
    } else if (
      userConnections?.includes(AuthConnections.PERSONIFYHEALTH) &&
      isEnrolled
    ) {
      window.location.href = 'https://headspace.app.link/explore';
    }

    dispatch(fetchSSOEnrollmentsStatusAction());
  }, [HS_USER_ID, dispatch, navigate, userConnections, isEnrolled]);

  useEffect(() => {
    dispatch(accountLinkingScreenViewAction(HealthProvider.PERSONIFY));
  }, [dispatch]);

  return (
    <PageWrapper healthProvider={HealthProvider.PERSONIFY}>
      <PreLinkComponent />
    </PageWrapper>
  );
};
