export enum FEATURE_FLAG {
  AUTOMATE_SMB_MARKETING_CAMPAIGN = 'automate_smb_marketing', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/automate_smb_marketing
  LANGUAGE_EXPANSION = 'enrollment_hub_language_expansion', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/enrollment_hub_language_expansion
  ENABLE_SURVEY = 'b2b_dashboard_enable_surveys', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_enable_surveys
  HUB_ACCESS_FOR_INTL_CARE_PACKAGE = 'b2b_dashboard_hub_access_for_intl_care_pkg_members', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_hub_access_for_intl_care_pkg_members
  B2B_COE_UNIFIED = 'b2b_dashboard_coe_unified', //  https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_coe_unified
  IDP_CONNECTION_LOOKUP = 'b2b_idp_connection_lookup_enabled', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_idp_connection_lookup_enabled
  UNIFIED_ORG_CREATION = 'unified_org_creation', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/unified_org_creation
  OVERLAPPED_ORGS_UPGRADE = 'b2b_dashboard_overlapped_orgs_upgrade', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_overlapped_orgs_upgrade
  KP_SHOW_VERIFICATION_TYPE = 'kp-show-verification-type', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/kp-show-verification-type
  ENROLLMENT_DOWNLOAD_BANNER = 'enrollment-download-banner', // https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/enrollment-download-banne
}
