import axios, { AxiosPromise } from 'axios';
import { GetUserConsentsResponse, CONSENT_STATUS } from '../models/Consent';
import { API_HOST, createHeaders, GET, PUT } from '../../../utils/constants';

export const getOnboardingUserConsents = (
  JWT: string,
  userId: string,
): AxiosPromise<GetUserConsentsResponse> =>
  axios({
    headers: createHeaders(JWT),
    method: GET,
    url: `${API_HOST}/user/v1/user-consents?userId=${userId}&include=consent`,
  });

export const updateOnboardingConsent = (
  JWT: string,
  userId: string,
  status: CONSENT_STATUS,
  consentId: number,
): AxiosPromise<void> =>
  axios({
    data: {
      clientUpdatedAt: new Date().toISOString(),
      consentId,
      status,
      userId,
    },
    headers: createHeaders(JWT),
    method: PUT,
    url: `${API_HOST}/user/v1/user-consents`,
  });
