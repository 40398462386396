import * as React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { bodyXSCss } from '@headspace/web-ui-components';
import { PropsWithChildren } from 'react';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';
import { inputOptions, labelStyles, inputLabelStyle } from './styles';
import { Icon } from '../Icon';
import { Tooltip, TooltipParams } from '../Tooltip';

const infoIconCss = css({ verticalAlign: 'middle' });

export interface InputGroupProps extends PropsWithChildren {
  name: string;
  label?: string;
  tooltip?: TooltipParams;
  hasError?: boolean;
  customLabelStyles?: Record<string, unknown>;
  inputGroupCss?: SerializedStyles;
}

export const InputGroup: React.FC<InputGroupProps> = ({
  children,
  hasError,
  customLabelStyles,
  label,
  tooltip,
  inputGroupCss,
  name,
}) => (
  <div data-testid={name}>
    {(label || tooltip) && (
      <>
        <label
          css={[
            bodyXSCss,
            inputLabelStyle,
            labelStyles(hasError),
            inputOptions(customLabelStyles),
          ]}
          data-testid={`${name}-label`}
          htmlFor={name}
        >
          {label || ''}
          {tooltip && (
            <>
              <span>&nbsp;</span>
              <Tooltip
                message={tooltip.message}
                position={tooltip.position}
                offset={tooltip.offset}
                background={tooltip.background}
                color={tooltip.color}
              >
                <span css={infoIconCss}>
                  <Icon
                    src={`${AP_CDN_URL}/info.svg`}
                    width={14}
                    height={14}
                    inline={true}
                  />
                </span>
              </Tooltip>
            </>
          )}
        </label>
      </>
    )}
    <div css={inputGroupCss}>{children}</div>
  </div>
);
