import { FC, useCallback, useEffect } from 'react';
import {
  Blue,
  Button,
  SecondaryButton,
  White,
  WarmGrey,
} from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import {
  ENROLLMENT_CDN_URL,
  getUnifiedEligibilityRoutes,
} from '../../../../constants/routes';
import {
  contentContainerStyle,
  pngContainerStyle,
  buttonContainerStyle,
  submitButtonStyle,
  pageWrapperHeaderIconMobileStyle,
  subHeaderStyle,
  noteStyle,
} from './styles/schedulerStyles';
import { SCHEDULER_URL } from '../../../../../../utils/constants';
import {
  onboardingButtonClickthrough,
  onboardingSchedulingViewAction,
} from '../../../../../../analytics/events/onboardingEvents';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getActiveOrg } from '../../../../../../state/selectors/organizationSelectors';
import { getLogoEnv } from '../../../../../../utils/getLogo';
import {
  getCompanyName,
  getPartnerConsent,
  getPrimaryProduct,
  getSlug,
} from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  getFirstName,
  getJWT,
  getLastName,
} from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';
import { PRIMARY_PRODUCT } from '../../../../../dashboard/constants';
import { getHasSeenConsentPage } from '../../../../state/slice/onboarding/onboardingSelector';

const INITIAL_FLOW_COMPLETION_PROGRESS = 100;
const UNIFIED_PRODUCT = 'umd';

export const SchedulingPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slug = useSelector(getSlug);
  const JWT = useSelector(getJWT);
  const hasSeenConsentScreen = useSelector(getHasSeenConsentPage);
  const partnerConsent = useSelector(getPartnerConsent);
  const companyName = useSelector(getCompanyName);
  const firstName = useSelector(getFirstName) as string;
  const lastName = useSelector(getLastName) as string;
  const memberFullName = `${firstName} ${lastName}`;
  const language = useSelector(getLanguage);
  const primaryProduct = useSelector(getPrimaryProduct);
  const { translate } = useTranslate();

  const activeOrg = useSelector(getActiveOrg);
  const { logo } = activeOrg || {};
  const logoUrl = getLogoEnv(logo);

  const primaryProductFormatted =
    primaryProduct === PRIMARY_PRODUCT.UNIFIED
      ? UNIFIED_PRODUCT
      : primaryProduct;

  const {
    ONBOARDING_CONSENT_PATH,
    ONBOARDING_NEXT_STEPS_PATH,
  } = getUnifiedEligibilityRoutes(slug);

  // TODO: check if the user is logged in otherwise kick them out

  useEffect(() => {
    dispatch(onboardingSchedulingViewAction());
  }, [dispatch]);

  const trackButtonClick = useCallback(
    (buttonText) => dispatch(onboardingButtonClickthrough(buttonText)),
    [dispatch],
  );

  const handleClickLater = useCallback(
    (event) => {
      trackButtonClick(event.target.innerText);
      if (!hasSeenConsentScreen && partnerConsent?.showInOnboarding) {
        navigate(ONBOARDING_CONSENT_PATH);
      } else {
        navigate(ONBOARDING_NEXT_STEPS_PATH);
      }
    },
    [
      ONBOARDING_CONSENT_PATH,
      ONBOARDING_NEXT_STEPS_PATH,
      navigate,
      hasSeenConsentScreen,
      partnerConsent,
      trackButtonClick,
    ],
  );

  const handleScheduleSessionClick = useCallback(
    (event) => {
      trackButtonClick(event.target.innerText);
    },
    [trackButtonClick],
  );

  const action = SCHEDULER_URL;

  const onFormSubmit = () => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', action);
    xhr.send();
  };

  return (
    <div>
      <PageWrapper
        progress={INITIAL_FLOW_COMPLETION_PROGRESS}
        header={translate('onboarding.recommend.schedule.header')}
        subHeaderElement={
          <p css={subHeaderStyle}>
            {translate('onboarding.recommend.schedule.subHeader_v2')}
          </p>
        }
        icon={
          <div css={pageWrapperHeaderIconMobileStyle}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-finish-state-coach.png`}
              alt="panel-illustration"
              width="100%"
            />
          </div>
        }
      >
        <div
          css={contentContainerStyle}
          data-testid="scheduling-page-container"
        >
          <div css={pngContainerStyle}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-finish-state-coach.png`}
              alt="panel-illustration"
              width="100%"
            />
          </div>

          <p css={noteStyle}>
            {translate('onboarding.recommend.schedule.message_v2')}
          </p>

          <div css={buttonContainerStyle}>
            <form method="POST" action={action} onSubmit={onFormSubmit}>
              <input
                type="hidden"
                id="auth_token"
                name="auth_token"
                value={JWT}
              />
              <input
                type="hidden"
                id="company_name"
                name="company_name"
                value={companyName}
              />
              <input
                type="hidden"
                id="member_name"
                name="member_name"
                value={memberFullName}
              />
              <input
                type="hidden"
                id="language"
                name="language"
                value={language}
              />
              <input
                type="hidden"
                id="enrollment_flow"
                name="enrollment_flow"
                value={primaryProductFormatted}
              />
              <input type="hidden" id="logo" name="logo" value={logoUrl} />
              <Button
                // @ts-ignore
                type="submit"
                css={submitButtonStyle}
                fontColor={White['000']}
                backgroundColor={Blue[200]}
                backgroundColorHover={Blue[400]}
                fontColorHover={White['000']}
                onClick={handleScheduleSessionClick}
                dataTestIdPrefix="schedule"
              >
                {translate('onboarding.recommend.schedule.button_schedule')}
              </Button>
            </form>
            <SecondaryButton
              isBlock={true}
              onClick={handleClickLater}
              borderColor={WarmGrey[200]}
              dataTestIdPrefix="postpone"
            >
              {translate('onboarding.recommend.schedule.button_postpone')}
            </SecondaryButton>
          </div>
        </div>
      </PageWrapper>
    </div>
  );
};
