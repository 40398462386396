import { labelSCss } from '@headspace/web-ui-components';
import { Icon } from '../Icon';
import { dayPickerNavbarStyle } from './styles';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';

export interface NavbarInputProps {
  onPreviousClick?(): void;
  onNextClick?(): void;
  month?: string;
  localeUtils?: any;
}

export const DateInputNavBar = ({
  onPreviousClick,
  onNextClick,
  month,
  localeUtils,
}: NavbarInputProps): JSX.Element => {
  const navMonth = localeUtils
    .formatMonthTitle(month)
    .split(' ')
    .reduce((acc: string[], v: string, i: number) => {
      let newVal = v;
      if (i === 0) {
        newVal = v.slice(0, 3);
      }
      acc.push(newVal);
      return acc;
    }, [])
    .join(' ');
  const onKeyUpPreviousClick = (e) => {
    if (e.key === 'Enter' && onPreviousClick) {
      onPreviousClick();
    }
  };
  const onKeyUpNextClick = (e) => {
    if (e.key === 'Enter' && onNextClick) {
      onNextClick();
    }
  };
  return (
    <div css={dayPickerNavbarStyle}>
      {onPreviousClick && (
        <div
          onClick={() => onPreviousClick()}
          onKeyUp={() => onKeyUpPreviousClick}
          role="button"
          tabIndex={0}
        >
          <Icon
            height={8}
            width={8}
            src={`${AP_CDN_URL}/caretLeftTundora.svg`}
          />
        </div>
      )}
      <div css={labelSCss}>{navMonth}</div>
      {onNextClick && (
        <div
          onClick={() => onNextClick()}
          onKeyUp={() => onKeyUpNextClick}
          role="button"
          tabIndex={0}
        >
          <Icon
            height={8}
            width={8}
            src={`${AP_CDN_URL}/caretRightTundora.svg`}
          />
        </div>
      )}
    </div>
  );
};
