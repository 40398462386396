import { Dispatch } from 'redux';
import { resetError } from '../../../utils/messageDefaults';
import { OrganizationActionsTypes } from '../../types/organization';
import { processErrors } from './processErrors';

const { HANDLE_EMAIL_CAMPAIGN_LOGO_FILE } = OrganizationActionsTypes;

export const handleEmailCampaignLogoFileInput = (files: FileList) => {
  return (dispatch: Dispatch) => {
    const emailCampaignLogoFile = files[0];
    const emailCampaignLogoFileName = emailCampaignLogoFile.name;
    const emailCampaignLogoFilePreview = window.URL.createObjectURL(
      emailCampaignLogoFile,
    );
    dispatch({
      type: HANDLE_EMAIL_CAMPAIGN_LOGO_FILE,
      payload: {
        emailCampaignLogoFileName,
        emailCampaignLogoFilePreview,
        emailCampaignLogoFile,
        emailCampaignLogoFileError: resetError(),
      },
    });
    dispatch(
      processErrors({
        target: {
          emailCampaignLogoFile,
          id: 'emailCampaignLogoFile',
          type: 'file',
        },
      }),
    );
  };
};
