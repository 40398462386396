import { FC } from 'react';
import { BundleCard } from './BundleCard';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface GingerCardProps {
  onHandleClick: () => void;
}

export const GingerCard: FC<GingerCardProps> = ({ onHandleClick }) => {
  const { translate } = useTranslate();
  const buttonValue = translate('gingerBundle.gingerCard.buttonValue_v2');

  const bulletList = [
    {
      message: translate('gingerBundle.gingerCard.bullet1.message'),
      topic: translate('gingerBundle.gingerCard.bullet1.topic'),
    },
    {
      message: translate('gingerBundle.gingerCard.bullet2.message'),
      topic: translate('gingerBundle.gingerCard.bullet2.topic'),
    },
    {
      message: translate('gingerBundle.gingerCard.bullet3.message'),
      topic: translate('gingerBundle.gingerCard.bullet3.topic'),
    },
  ];
  return (
    <BundleCard
      buttonValue={buttonValue}
      logo={`${ENROLLMENT_CDN_URL}/headspace-care.svg`}
      imageSrc={`${ENROLLMENT_CDN_URL}/headspace-care-card.png`}
      title={translate('gingerBundle.gingerCard.title')}
      bulletList={bulletList}
      onHandleClick={onHandleClick}
    />
  );
};
