import { css } from '@emotion/react';
import { fontSizeS } from '@headspace/web-ui-components';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { accountLinkingScreenViewAction } from '../../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../../constants/healthProvider';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { Routes } from '../../../constants/routes';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import PreLinkComponent from '../../HealthHubViews/Personify/LinkPage/PreLinkComponent';
import { PersonifyCareWrapper } from '../../HealthHubViews/Personify/PersonifyCareWrapper/PersonifyCareWrapper';

const linkComponentStyleOverride = css({
  'div[data-testid="link-identities-container-card"]': {
    paddingTop: 0,
  },
  'div[data-testid="manage-connected-identities-description"]': {
    fontSize: fontSizeS,
    textAlign: 'center',
  },
});

export const PersonifyCareConsent: FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);

  useEffect(() => {
    if (!HS_USER_ID) {
      navigate(Routes.PERSONIFY_CARE_LAND);
    }
  }, [HS_USER_ID, navigate]);

  const consentDetails = [
    translate('enrollment.personifyCareConsent.detail1'),
    translate('enrollment.personifyCareConsent.detail2'),
    translate('enrollment.personifyCareConsent.detail3'),
    translate('enrollment.personifyCareConsent.detail4'),
  ];

  useEffect(() => {
    dispatch(accountLinkingScreenViewAction(HealthProvider.PERSONIFY_CARE));
  }, [dispatch]);

  return (
    <PersonifyCareWrapper skipMobileHero={true} skipLogout={true}>
      <div css={linkComponentStyleOverride}>
        <PreLinkComponent
          overrideManageIdentitiesDescription={translate(
            'enrollment.personifyCareConsent.disconnectNotification',
          )}
          consentDetails={consentDetails}
          title={translate('enrollment.personifyCareConsent.title')}
          subtitle={translate('enrollment.personifyCareConsent.subtitle')}
          description={translate('enrollment.personifyCareConsent.description')}
          bigLogo={true}
        />
      </div>
    </PersonifyCareWrapper>
  );
};
