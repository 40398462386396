import { useEffect, useRef, ReactNode } from 'react';
import * as React from 'react';
import reactHtmlParser from 'react-html-parser';
import { css } from '@emotion/react';
import { bodyMCss, bodyXSCss } from '@headspace/web-ui-components';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';
import { CheckboxInput } from './CheckboxInput';
import { DateInput } from './DateInput';
import { ListInput } from './ListInput';
import {
  errorStyles,
  hintContainerStyle,
  hintTextStyles,
  inputOptions,
  inputStyles,
  labelStyles,
  reviewInputSubtextStyles,
  reviewInputValueStyles,
  selectStyles,
  textAreaStyles,
  inputLabelStyle,
} from './styles';
import { SelectInput } from './SelectInput';
import { Icon } from '../Icon';
import { Tooltip, TooltipParams } from '../Tooltip';

const infoIconCss = css({ verticalAlign: 'middle' });
const linkCss = css({ display: 'inline-block' });

export type InputEventHandler = (
  e: React.ChangeEvent<HTMLInputElement> & ExtendedEvent,
) => void;

export type FocusEventHandler = (
  event: React.FocusEvent & ExtendedEvent,
) => void;
export type ExtendedInputType =
  | 'select'
  | 'date'
  | 'textarea'
  | 'list'
  | 'checkbox'
  | 'review' // is none editable view
  | 'text'
  | 'url'
  | 'number'
  | 'password'
  | 'email';

export interface InputProps {
  type?: ExtendedInputType;
  name: string;
  label?: string;
  required?: boolean;
  value?: string | boolean | number;
  placeholder?: string;
  tabIndex?: string;
  autofocus?: boolean;
  disabled?: boolean;
  options?: Option[];
  onKeyDown?: any;
  onChange?: InputEventHandler;
  onClick?: InputEventHandler;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  errorMessage?: string;
  hasError?: boolean;
  hintLeft?: string;
  hintRight?: string;
  subtext?: string;
  coverValue?: string;
  addItem?: InputEventHandler;
  deleteItem?: InputEventHandler;
  addItemText?: string;
  list?: string[];
  open?: boolean;
  autoFocus?: boolean;
  customInputStyles?: Record<string, unknown>;
  customLabelStyles?: Record<string, unknown>;
  noLabel?: boolean;
  dataTestId?: string;
  maxLength?: string | number;
  tooltip?: TooltipParams;
  shouldFocusError?: boolean;
  isLoading?: boolean;
  isSearchable?: boolean;
  searchValue?: string;
  searchPlaceholder?: string;
  noResultsFound?: string;
  handleSearch?(event: any): void;
  pattern?: string;
  link?: ReactNode;
}

export interface InputState {
  focused: boolean;
}

const createInput = (input: any): JSX.Element => {
  const {
    type,
    name,
    hasError,
    customInputStyles,
    options = [],
    subtext,
    ...passedProps
  } = input;

  switch (type) {
    case 'select':
      return (
        <SelectInput
          id={name}
          name={name}
          options={options}
          customInputStyles={customInputStyles}
          css={[
            inputStyles(hasError, passedProps.disabled),
            selectStyles(hasError),
          ]}
          data-testid={`${name}-input`}
          {...passedProps}
        />
      );
    case 'date': {
      return (
        <DateInput
          id={name}
          hasError={hasError}
          css={[
            inputStyles(hasError, passedProps.disabled),
            inputOptions(customInputStyles),
          ]}
          data-testid={`${name}-input`}
          customInputStyles={customInputStyles}
          {...passedProps}
        />
      );
    }
    case 'textarea':
      return (
        <textarea
          id={name}
          type={type || 'text'}
          maxLength="2500"
          data-testid={passedProps['data-testid'] || passedProps.dataTestId}
          {...passedProps}
          css={[
            bodyMCss,
            inputStyles(hasError, passedProps.disabled),
            textAreaStyles(hasError, passedProps.disabled),
            inputOptions(customInputStyles),
          ]}
        />
      );
    case 'list':
      return (
        <ListInput
          id={name}
          hasError={hasError}
          {...passedProps}
          customInputStyles={customInputStyles}
        />
      );
    case 'checkbox':
      return (
        <CheckboxInput
          id={name}
          dataTestId={passedProps['data-testid'] || passedProps.dataTestId}
          {...passedProps}
          customInputStyles={customInputStyles}
        />
      );
    case 'review':
      return (
        <div
          id={name}
          css={[
            bodyMCss,
            reviewInputValueStyles,
            inputOptions(customInputStyles),
          ]}
          data-testid={passedProps['data-testid'] || passedProps.dataTestId}
        >
          {passedProps.value}
          {subtext && <div css={reviewInputSubtextStyles}>{subtext}</div>}
        </div>
      );
    default:
      return (
        <input
          id={name}
          name={name}
          type={type || 'text'}
          data-testid={`${name}-input`}
          {...passedProps}
          css={[
            inputStyles(hasError, passedProps.disabled),
            bodyMCss,
            inputOptions(customInputStyles),
          ]}
        />
      );
  }
};

export const Input: React.FC<InputProps> = ({
  hasError,
  errorMessage,
  customLabelStyles,
  hintLeft,
  hintRight,
  noLabel,
  tooltip,
  shouldFocusError = false,
  link,
  ...passedProps
}) => {
  const errorRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (shouldFocusError && hasError && errorRef && errorRef.current) {
      errorRef.current.focus();
    }
  }, [hasError, errorRef]);
  return (
    <div>
      {!noLabel && (
        <>
          <label
            css={[
              bodyXSCss,
              inputLabelStyle,
              labelStyles(hasError),
              inputOptions(customLabelStyles),
            ]}
            data-testid={`${passedProps.name}-label`}
            htmlFor={passedProps.name}
          >
            {passedProps.type !== 'checkbox' ? passedProps.label : ' '}
            {tooltip && (
              <>
                <span>&nbsp;</span>
                <Tooltip
                  message={tooltip.message}
                  position={tooltip.position}
                  offset={tooltip.offset}
                  background={tooltip.background}
                  color={tooltip.color}
                >
                  <span css={infoIconCss}>
                    <Icon
                      src={`${AP_CDN_URL}/info.svg`}
                      width={14}
                      height={14}
                      inline={true}
                    />
                  </span>
                </Tooltip>
              </>
            )}
            {link ? (
              <>
                <span>&nbsp;</span>
                <span css={linkCss}>{link}</span>
              </>
            ) : null}
          </label>
        </>
      )}
      <div css={hintContainerStyle}>
        {hintLeft && <span css={hintTextStyles('left')}>{hintLeft}</span>}
        {createInput({ hasError, ...passedProps })}
        {hintRight && <span css={hintTextStyles('right')}>{hintRight}</span>}
      </div>
      {hasError ? (
        <span
          data-testid={`${passedProps.name}-error`}
          css={errorStyles}
          tabIndex={-1}
          role="alert"
          ref={errorRef}
        >
          {reactHtmlParser(errorMessage)}
        </span>
      ) : null}
    </div>
  );
};
