import { css } from '@emotion/react';
import {
  borderRadiusM,
  borderRadiusS,
  borderRadiusXS,
  borderWidthXS,
  cssWithMq,
  fontFamilyCss,
  fontSizeM,
  fontSizeXL,
  fontWeightBold,
  lineHeightHeading,
  rem,
  WarmGrey,
  White,
} from '@headspace/web-ui-components';
import { boxSizingBorderBox } from '../../../apps/dashboard/styles';
import { columnFlex } from '../../../apps/dashboard/styles/flex';
import { IMG_CDN_URL } from '../../../apps/headspaceHub/constants/imageCdnUrl';

export const languageMenuContainerStyle = cssWithMq({
  position: ['static', 'relative'],
});

export const buttonStyle = (isMenuSeparate: boolean) =>
  cssWithMq(boxSizingBorderBox, {
    ':focus': {
      outline: 'none',
      padding: `0 ${rem(1)}`,
      width: 'fit-content',
    },
    ':not([disabled]):active': {
      backgroundColor: [
        isMenuSeparate
          ? `${WarmGrey[100]} !important`
          : 'transparent !important',
        `${WarmGrey[100]} !important`,
      ],
    },
    ':not([disabled]):hover': {
      backgroundColor: [
        isMenuSeparate ? `${WarmGrey[100]} !important` : 'transparent',
        WarmGrey[100],
      ],
    },
    alignItems: 'center',
    backgroundColor: [
      isMenuSeparate ? `${WarmGrey[100]}` : 'transparent',
      WarmGrey[100],
    ],
    border: 'none',
    display: 'flex',
    height: rem(2.5),
    justifyContent: 'center',
    outline: 'none',
    overflow: 'hidden',
    padding: 0,
    width: [isMenuSeparate ? rem(5) : 'auto', rem(5)],
  });

export const toggleButtonCss = css({
  alignItems: 'center',
  display: 'flex',
  gap: rem(0.5),
  justifyContent: 'center',
});

export const toggleButtonIconCss = (width, height) =>
  css({
    height,
    width,
  });

export const languageMenuStyle = (
  isLanguageExpansionEnabled = false,
  isLanguageMenuSeparated: boolean,
) =>
  cssWithMq(boxSizingBorderBox, {
    background: White['000'],
    border: ['none', `${borderWidthXS} solid ${WarmGrey[200]}`],
    borderRadius: [0, borderRadiusM],
    boxShadow: [
      'none',
      `0 ${rem(0.25)} ${rem(0.5)} 0px rgba(20, 19, 19, 0.20)`,
    ],
    minWidth: rem(8.375),
    padding: isLanguageExpansionEnabled
      ? [`0 ${rem(1.5)} ${rem(3.375)}`, rem(1.5)]
      : [`0 0 0 ${rem(3)}`, `${rem(0.5)} ${rem(1)}`],
    position: 'absolute',
    right: isLanguageMenuSeparated ? 0 : [`-${rem(1.2)}`, 0],
    top: isLanguageMenuSeparated
      ? [rem(4.688), rem(3.5)]
      : [rem(3), rem(3.313)],
    width: [isLanguageMenuSeparated ? '100%' : '100vw', 'auto'],
    zIndex: 2,
  });

export const languageListStyle = () =>
  cssWithMq(boxSizingBorderBox, columnFlex, {
    listStyle: 'none',
    margin: 0,
    padding: 0,
    rowGap: [rem(1.5), rem(0.5)],
  });

export const languageExpandedListStyle = (numListItems: number) =>
  cssWithMq({
    columnGap: rem(1.5),
    display: ['flex', 'grid'],
    flexDirection: ['column', 'initial'],
    gridTemplateColumns: `repeat(${numListItems > 2 ? 3 : 1}, ${rem(11.313)})`,
    listStyle: 'none',
    margin: [`${rem(1)} 0`, 0],
    padding: 0,
    rowGap: rem(0.5),
  });

export const languageButtonStyle = (isActive = false) =>
  cssWithMq(fontFamilyCss, {
    backgroundColor: isActive ? WarmGrey[100] : White['000'],
    border: 'none',
    borderRadius: ['initial', borderRadiusXS],
    color: isActive ? WarmGrey[600] : WarmGrey[400],
    cursor: 'pointer',
    fontSize: [fontSizeXL, fontSizeM],
    fontWeight: fontWeightBold,
    letterSpacing: [rem(-0.038)],
    p: {
      margin: `${rem(0.625)} 0`,
    },
    padding: [`0 ${rem(1.25)} 0 0`, 0],
    textAlign: ['right', 'center'],
    width: '100%',
  });

export const languageExpandedButtonStyle = (isActive = false) =>
  cssWithMq(fontFamilyCss, {
    '::before': {
      backgroundImage: isActive
        ? `url(${IMG_CDN_URL}/languageIconActive.svg)`
        : `url(${IMG_CDN_URL}/languageIconNonActive.svg)`,
      backgroundPosition: 'center',
      content: '""',
      backgroundRepeat: 'no-repeat',
      display: 'inline-block',
      backgroundSize: rem(1.5),
      height: rem(1.5),
      left: ['initial', rem(0.5)],
      position: 'absolute',
      right: [rem(0.5), 'initial'],
      top: `calc(50% - ${rem(1.5)} / 2)`,
      width: rem(1.5),
    },
    backgroundColor: isActive ? WarmGrey[100] : White['000'],
    border: 'none',
    borderRadius: borderRadiusS,
    ':hover': {
      backgroundColor: WarmGrey[100],
    },
    color: isActive ? WarmGrey[600] : WarmGrey[400],
    cursor: 'pointer',
    fontSize: [fontSizeXL, fontSizeM],
    fontWeight: fontWeightBold,
    letterSpacing: [rem(-0.038)],
    p: {
      margin: `${rem(0.625)} 0`,
    },
    padding: [`0 0 0 ${rem(1.25)}`, `0 0 0 ${rem(2.25)}`],
    position: 'relative',

    textAlign: 'left',
    width: '100%',
  });

export const languageTitleMobile = css(fontFamilyCss, {
  color: WarmGrey[600],
  fontSize: fontSizeXL,
  fontWeight: fontWeightBold,
  letterSpacing: rem(-0.038),
  lineHeight: lineHeightHeading,
});
