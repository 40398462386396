import { useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TranslationTab } from '../shared-components/Header/TranslationTab';
import { getLogoEnv } from '../../../../utils/getLogo';
import { buttonClickthroughAction } from '../../../../analytics/events/enrollmentEvents';
import { Icon } from '../../../../shared-components/Icon';
import {
  containerStyle,
  bodyContainerStyle,
  iconContainerStyle,
  headerStyle,
  verticalDividerStyle,
  orgLogoStyle,
  logosContainerStyle,
} from './download-ginger-page-style';
import { ANDROID_APP_STORE, IOS_APP_STORE } from '../../constants';
import { useTranslate } from '../../../../hooks/useTranslate';
import { getActiveOrg } from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import { ENROLLMENT_CDN_URL } from '../../constants/routes';

const GINGER_IOS_LINK = 'https://ginger.app.link/blLXPZW5xmb';
const GINGER_ANDROID_LINK = 'https://ginger.app.link/blLXPZW5xmb';

export const DownloadGingerPage: FC = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const activeOrg = useSelector(getActiveOrg);
  const customerLogo = activeOrg?.logo;
  const welcomeMessage = translate('gingerBundle.gingerDownload.welcome_v2');

  const subHeaderMessage = translate(
    'gingerBundle.gingerDownload.subHeader_v2',
  );

  const handleClickAndroid = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(buttonClickthroughAction(ANDROID_APP_STORE));
      setTimeout(() => window.location.replace(GINGER_ANDROID_LINK), 500);
    },
    [dispatch],
  );
  const handleClickIos = useCallback(
    (e) => {
      e.preventDefault();
      dispatch(buttonClickthroughAction(IOS_APP_STORE));
      setTimeout(() => window.location.replace(GINGER_IOS_LINK), 500);
    },
    [dispatch],
  );

  return (
    <div css={containerStyle}>
      <header css={headerStyle}>
        <div css={logosContainerStyle}>
          {customerLogo && (
            <>
              <img
                css={orgLogoStyle}
                src={getLogoEnv(customerLogo)}
                data-testid="org-logo"
                alt="org-logo"
                aria-hidden="true"
              />
              <div css={verticalDividerStyle} />
            </>
          )}
          <Icon
            src={`${ENROLLMENT_CDN_URL}/headspace-care.svg`}
            width={200}
            height={48}
            dataTestid="ginger-logo"
            fill="red"
          />
        </div>

        <TranslationTab />
      </header>
      <img
        src={`${ENROLLMENT_CDN_URL}/headspace-care-hero-image.png`}
        alt="panel-illustration"
        width="100%"
      />
      <div css={bodyContainerStyle}>
        <h1>{welcomeMessage}</h1>
        <h3>{subHeaderMessage}</h3>

        <div css={iconContainerStyle}>
          <a
            onClick={handleClickIos}
            href={GINGER_IOS_LINK}
            data-testid="done-cta-ios"
          >
            <Icon
              width={162}
              height={48}
              src={`${ENROLLMENT_CDN_URL}/app-store.svg`}
              aria-label="App store"
            />
            <span>
              {translate('gingerBundle.gingerDownload.downloadAppStore')}
            </span>
          </a>

          <a
            onClick={handleClickAndroid}
            href={GINGER_ANDROID_LINK}
            data-testid="done-cta-android"
          >
            <Icon
              width={162}
              height={48}
              src={`${ENROLLMENT_CDN_URL}/play-store.svg`}
              aria-label="Google store"
            />
            <span>
              {translate('gingerBundle.gingerDownload.downloadGoogleStore')}
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};
