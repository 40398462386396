import { camelCase } from 'change-case';
import {
  AnalyticAction,
  ExtraAttributes,
  mParticleEventAction,
} from '../analyticActions';
import {
  ContractActivityStatus,
  ContractTypes,
  MParticleEventType,
} from '../types';
import { HealthProvider } from '../../apps/memberEnrollmentV2/constants/healthProvider';

const { activity, b2b, web, screenView } = ContractTypes;

export enum EnrollmentScreenNames {
  enrollmentSuccess = 'b2b enrollment success',
  existingAccountPrompt = 'b2b enrollment existing account prompt',
  existingAccount = 'b2b enrollment existing account',
  flowStart = 'b2b enrollment flow start prompt',
  signUp = 'b2b enrollment sign up',
  partnershipExistingAccountPrompt = 'partnership enrollment existing account prompt',
  partnershipFlowStart = 'partnership enrollment flow start prompt',
  partnershipSignUp = 'partnership enrollment sign up',
  partnershipLinking = 'partnership enrollment link',
  partnershipLinkingSuccess = 'partnership enrollment link success',
  verification = 'b2b enrollment verification',
  verificationSuccess = 'b2b enrollment verification success',
  enrollmentDobCountry = 'b2b enrollment dob country screenview',
}

// Events - https://github.com/HeadspaceMeditation/hs-events/blob/master/events/B2B/EnterpriseEnrollment.client.md
export enum EnrollmentEventNames {
  buttonClickthrough = 'button clickthrough',
  enrollmentSuccessScreenView = 'b2b enrollment success screenview',
  existingAccountPromptScreenView = 'b2b enrollment existing account prompt screenview',
  existingAccountScreenView = 'b2b enrollment existing account screenview',
  flowStartScreenView = 'b2b enrollment flow start screenview',
  signUpScreenView = 'b2b enrollment sign up screenview',
  partnershipExistingAccountPromptScreenView = 'partnership enrollment existing account prompt screenview',
  partnershipFlowStartScreenView = 'partnership enrollment flow start screenview',
  partnershipSignUpScreenView = 'partnership enrollment sign up screenview',
  partnershipAccountLinkingScreenView = 'partnership enrollment link screenview',
  partnershipAccountLinkingSuccessScreenView = 'partnership enrollment link success screenview',
  verificationErrorView = 'b2b verification error view',
  resendVerificationErrorView = 'b2b resend verification error view',
  verificationScreenView = 'b2b enrollment verification screenview',
  verificationSuccessScreenView = 'b2b verification success screenview',

  enrollmentDobCountryScreenView = 'b2b enrollment dob country screenview',
  enrollmentDobCountryValidationScreenView = 'b2b enrollment dob country validation screenview',
}

const SUPPORTED_HEALTH_PROVIDERS: HealthProvider[] = [
  HealthProvider.SOLERA,
  HealthProvider.GYMPASS,
  HealthProvider.PERSONIFY,
  HealthProvider.PERSONIFY_CARE,
];

export const MULTIOFFER_HEALTH_PROVIDERS: HealthProvider[] = [
  HealthProvider.GYMPASS,
];

type ClickthroughEventName =
  | EnrollmentEventNames.buttonClickthrough
  | EnrollmentEventNames.verificationErrorView
  | EnrollmentEventNames.resendVerificationErrorView;

export const buttonClickthroughAction = (
  ctaLabel: string,
  eventName: ClickthroughEventName = EnrollmentEventNames.buttonClickthrough,
  activityStatus = ContractActivityStatus.complete,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, web],
    eventName,
    eventType: MParticleEventType.Other,
    extras: {
      activityStatus,
      ctaLabel,
    },
  });

export const optionSelectedAndConfirmed = (
  ctaLabel: string,
  selectedOption: string,
  optionsPrompt: string,
  eventName: ClickthroughEventName = EnrollmentEventNames.buttonClickthrough,
  activityStatus = ContractActivityStatus.complete,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [activity, b2b, web],
    eventName,
    eventType: MParticleEventType.Other,
    extras: {
      activityStatus,
      ctaLabel,
      surveyAnswer: selectedOption,
      surveyQuestion: optionsPrompt,
    },
  });

export const enrollmentScreenViewAction = (
  eventName: EnrollmentEventNames,
  extras?: ExtraAttributes,
): AnalyticAction =>
  mParticleEventAction({
    contractList: [b2b, web, screenView],
    eventName,
    eventType: MParticleEventType.Other,
    extras,
  });

export const enrollmentSuccessScreenViewAction = (): AnalyticAction =>
  enrollmentScreenViewAction(EnrollmentEventNames.enrollmentSuccessScreenView, {
    screenName: EnrollmentScreenNames.enrollmentSuccess,
  });

export const getHealthPartnerMetadata = (
  healthProvider: HealthProvider,
  planId?: string,
) => {
  const healthPartnerMetadata: Partial<Record<string, string>> = {};

  if (SUPPORTED_HEALTH_PROVIDERS.includes(healthProvider)) {
    healthPartnerMetadata.partnerName = camelCase(healthProvider);
    if (MULTIOFFER_HEALTH_PROVIDERS.includes(healthProvider)) {
      healthPartnerMetadata.partnerPlanId = planId;
    }
  }

  return healthPartnerMetadata;
};

export const existingAccountPromptScreenViewAction = (
  healthProvider?: HealthProvider,
  planId?: string,
): AnalyticAction => {
  let actionName = EnrollmentEventNames.existingAccountPromptScreenView;
  let payload = { screenName: EnrollmentScreenNames.existingAccountPrompt };

  if (healthProvider) {
    actionName =
      EnrollmentEventNames.partnershipExistingAccountPromptScreenView;
    payload = {
      screenName: EnrollmentScreenNames.partnershipExistingAccountPrompt,
      ...getHealthPartnerMetadata(healthProvider, planId),
    };
  }

  return enrollmentScreenViewAction(actionName, payload);
};

export const existingAccountScreenViewAction = (): AnalyticAction =>
  enrollmentScreenViewAction(EnrollmentEventNames.existingAccountScreenView, {
    screenName: EnrollmentScreenNames.existingAccount,
  });

export const existingAccountButtonClickthroughAction = (text: string) =>
  buttonClickthroughAction(text, EnrollmentEventNames.buttonClickthrough);

export const flowStartScreenViewAction = (
  healthProvider?: HealthProvider,
  planId?: string,
): AnalyticAction => {
  let actionName = EnrollmentEventNames.flowStartScreenView;
  let payload = { screenName: EnrollmentScreenNames.flowStart };

  if (healthProvider) {
    actionName = EnrollmentEventNames.partnershipFlowStartScreenView;
    payload = {
      screenName: EnrollmentScreenNames.partnershipFlowStart,
      ...getHealthPartnerMetadata(healthProvider, planId),
    };
  }

  return enrollmentScreenViewAction(actionName, payload);
};

export const signUpScreenViewAction = (
  healthProvider?: HealthProvider,
  planId?: string,
): AnalyticAction => {
  let actionName = EnrollmentEventNames.signUpScreenView;
  let payload = {
    screenName: EnrollmentScreenNames.signUp,
    screenVariant: 'sign up',
  };

  if (healthProvider) {
    actionName = EnrollmentEventNames.partnershipSignUpScreenView;
    payload = {
      ...payload,
      screenName: EnrollmentScreenNames.partnershipSignUp,
      ...getHealthPartnerMetadata(healthProvider, planId),
    };
  }

  return enrollmentScreenViewAction(actionName, payload);
};

export const loginScreenViewAction = (
  healthProvider?: HealthProvider,
  planId?: string,
): AnalyticAction => {
  let actionName = EnrollmentEventNames.signUpScreenView;
  let payload = {
    screenName: EnrollmentScreenNames.signUp,
    screenVariant: 'login',
  };

  if (healthProvider) {
    actionName = EnrollmentEventNames.partnershipSignUpScreenView;
    payload = {
      ...payload,
      screenName: EnrollmentScreenNames.partnershipSignUp,
      ...getHealthPartnerMetadata(healthProvider, planId),
    };
  }

  return enrollmentScreenViewAction(actionName, payload);
};

export const verificationScreenViewAction = (
  screenVariant: string,
): AnalyticAction =>
  enrollmentScreenViewAction(EnrollmentEventNames.verificationScreenView, {
    screenName: EnrollmentScreenNames.verification,
    screenVariant,
  });

export const verificationSuccessScreenViewAction = (): AnalyticAction =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.verificationSuccessScreenView,
    {
      screenName: EnrollmentScreenNames.verificationSuccess,
    },
  );

export function setMParticleIdentity(userId: string): void {
  const loginIdentityRequest = {
    userIdentities: {
      customerid: userId,
    },
  };
  if (window?.mParticle?.Identity?.login) {
    window.mParticle.Identity.login(loginIdentityRequest);
  }
}

export const dobCountryScreenViewAction = () =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.enrollmentDobCountryScreenView,
    {
      screenName: EnrollmentScreenNames.enrollmentDobCountry,
    },
  );

export const dobCountryValidationScreenViewAction = () =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.enrollmentDobCountryValidationScreenView,
  );

export const dobCountryValidationScreenViewError = (screenVariant: string) =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.enrollmentDobCountryValidationScreenView,
    {
      screenVariant,
    },
  );

export const buttonClickthroughBackToHomeAction = () =>
  buttonClickthroughAction(
    'Go back to home screen',
    EnrollmentEventNames.buttonClickthrough,
  );

export const selectAndConfirmElfMemberType = (selectedType) =>
  optionSelectedAndConfirmed(
    'Next',
    selectedType,
    'Am I a dependent or a primary member?',
  );

export const dependentPiiEnrollmentScreenViewAction = (
  variant: string,
): AnalyticAction =>
  enrollmentScreenViewAction(EnrollmentEventNames.verificationScreenView, {
    screenName: EnrollmentScreenNames.verification,
    screenVariant: `Dependent PII ${variant}`,
  });

export const accountLinkingScreenViewAction = (
  variant: string,
): AnalyticAction =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.partnershipAccountLinkingScreenView,
    {
      screenName: EnrollmentScreenNames.partnershipLinking,
      screenVariant: variant,
    },
  );

export const accountLinkingSuccessScreenViewAction = (
  variant: string,
): AnalyticAction =>
  enrollmentScreenViewAction(
    EnrollmentEventNames.partnershipAccountLinkingSuccessScreenView,
    {
      screenName: EnrollmentScreenNames.partnershipLinkingSuccess,
      screenVariant: variant,
    },
  );
