import { AuthConfig, AuthConnections } from '@headspace/web-auth';

import { WEBSITE_HOST } from '../../../utils/constants';
import { auth0Client } from './models';
import { ENV, PROD_JWT, ALL_OTHER_ENV_JWT } from '../constants';
import { getConfig } from '../../../config';
import { getAuthenticationSection } from '../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  DashboardMessagesKeys,
  webAuthMessages,
} from '../../../i18n/dashboardMessages';

function* onAuthSuccessSaga() {
  yield;
}

export const authConfig: AuthConfig & {
  customTranslations?: DashboardMessagesKeys;
} = {
  authEnvironment: ENV,
  enableCookieBackwardsCompatability: true,
  enableUserProfileManagement: true,
  pathToRequiredUserProfileFieldsCollectionForm: `${WEBSITE_HOST}/login`,
  redirectWhenMissingRequiredUserProfileFields: false,

  authCookieConfig: {
    ...getConfig().ENROLLMENT_AUTH0_COOKIE.COOKIE_CONFIGURATION,
    expires: 1,
    name: ENV === 'production' ? PROD_JWT : ALL_OTHER_ENV_JWT,
  },

  authClient: auth0Client,
  customTranslations: webAuthMessages,
  getAuthStateSelector: getAuthenticationSection,
  onAuthSuccess: onAuthSuccessSaga,
  pathToIconSprites: '/static/icons.svg',
  requiredUserProfileFields: ['firstName', 'lastName'],
};

export const LOGIN_THIRD_PARTY_CONNECTIONS = [
  AuthConnections.APPLE,
  AuthConnections.FACEBOOK,
  AuthConnections.SPOTIFY,
  AuthConnections.GOOGLE,
];

export const SIGNUP_THIRD_PARTY_CONNECTIONS = [
  AuthConnections.APPLE,
  AuthConnections.FACEBOOK,
  AuthConnections.GOOGLE,
];
