import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import * as Sentry from '@sentry/browser';
import { pathOr } from 'ramda';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { isPersonifyCareProvider } from '../../../../../../utils/healthProvider';
import { FormContainer } from '../../../shared-components/FormContainer/FormContainer';
import {
  buttonClickthroughAction,
  EnrollmentEventNames,
  verificationScreenViewAction,
} from '../../../../../../analytics/events/enrollmentEvents';
import { ContractActivityStatus } from '../../../../../../analytics/types';
import { logSentryRequestError } from '../../../../../../utils/sentryHelpers';
import { Input } from '../../../../../../shared-components/Input';
import {
  containerStyle,
  subTitleStyle,
} from '../../../shared-components/VerifyBy/styles';
import { ErrorResponse, parseError } from '../../../../../../utils/parseError';
import { Terms } from '../../VerificationPage/components/Terms';
import { FormHeader } from '../../VerificationPage/components/FormHeader';
import { SubmitButton } from '../../VerificationPage/components/SubmitButton';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { getActiveOrg } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getHealthProvider } from '../../../../state/selectors/healthProviderSelector/healthProviderSelector';
import HealthProviderWorkIdGuidance from './HealthProviderWorkIdGuidance';

interface EligibilityVerifyByWorkIdProps {
  handleVerify(employeeId: string, lastName: string): any;
  serverError: string;
  onChangeHandler(): any;
}

const EligibilityVerifyByWorkId: FC<EligibilityVerifyByWorkIdProps> = ({
  handleVerify,
  serverError,
  onChangeHandler,
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const activeOrg = useSelector(getActiveOrg);
  const healthProvider = useSelector(getHealthProvider);
  const workIdFieldPlaceholderText = pathOr(
    null,
    ['enrollment', 'workIdFieldPlaceholderText'],
    activeOrg,
  );
  const [lastName, setLastName] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [idHasError, setIdHasError] = useState(false);
  const [nameHasError, setNameHasError] = useState(false);
  const [idErrorMessage, setIdErrorMessage] = useState('');
  const [nameErrorMessage, setNameErrorMessage] = useState('');
  const verifyButtonLabel = isPersonifyCareProvider(healthProvider)
    ? translate('enrollment.personifyCareVerification.button')
    : translate('verify.byId.verify');
  const partnerIdName =
    workIdFieldPlaceholderText || translate('verify.byId.partnerNumber');
  const subtitle = isPersonifyCareProvider(healthProvider)
    ? translate('enrollment.personifyCareVerification.workIDLastName.subHeader')
    : translate('enrollment.verify.workId.message', { partnerIdName });
  const workIdPlaceholder = isPersonifyCareProvider(healthProvider)
    ? translate(
        'enrollment.personifyCareVerification.workIDLastName.idNumberPlaceholder',
      )
    : partnerIdName;

  useEffect(() => {
    if (serverError) {
      if (isPersonifyCareProvider(healthProvider)) {
        setNameErrorMessage(serverError);
      } else {
        setIdErrorMessage(serverError);
      }
      setIdHasError(true);
      setNameHasError(true);
    }
  }, [healthProvider, serverError]);

  const enroll = useCallback(async () => {
    Sentry.addBreadcrumb({
      category: 'enroll callback',
      level: Sentry.Severity.Info,
      message: 'enroll postverifyById',
    });
    try {
      await handleVerify(employeeId, lastName);
    } catch (e) {
      const { message } = parseError(e as ErrorResponse);

      if (isPersonifyCareProvider(healthProvider)) {
        setNameErrorMessage(message);
      } else {
        setIdErrorMessage(message);
      }
      setIdHasError(true);
      setNameHasError(true);

      logSentryRequestError(e, 'verifybyId');
      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
    }
  }, [
    dispatch,
    employeeId,
    handleVerify,
    healthProvider,
    lastName,
    verifyButtonLabel,
  ]);

  const handleValidateInput = useCallback(() => {
    let employeeIdError = false;
    let lastNameError = false;
    if (employeeId === '') {
      setIdErrorMessage(
        translate('enrollment.errors.EMPTY_ID', {
          partnerIdName: workIdPlaceholder,
        }),
      );
      employeeIdError = true;
    } else {
      setIdErrorMessage('');
    }
    if (lastName === '') {
      setNameErrorMessage(translate('enrollment.errors.EMPTY_LAST_NAME'));
      lastNameError = true;
    } else {
      setNameErrorMessage('');
    }

    setIdHasError(employeeIdError);
    setNameHasError(lastNameError);

    return employeeIdError || lastNameError;
  }, [employeeId, lastName, workIdPlaceholder, translate]);

  const handleVerification = useCallback(() => {
    dispatch(buttonClickthroughAction(verifyButtonLabel));
    if (handleValidateInput()) {
      dispatch(
        buttonClickthroughAction(
          verifyButtonLabel,
          EnrollmentEventNames.verificationErrorView,
          ContractActivityStatus.failed,
        ),
      );
      return null;
    }

    return enroll();
  }, [dispatch, verifyButtonLabel, handleValidateInput, enroll]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setIdHasError(false);
      setNameHasError(false);
      const {
        target: { id, value },
      } = e;

      if (id === 'last-name') {
        setNameErrorMessage('');
        setLastName(value);
      }

      if (id === 'id-number') {
        setIdErrorMessage('');
        setEmployeeId(value);
      }

      if (serverError) {
        onChangeHandler();
      }
    },
    [serverError, onChangeHandler],
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleVerification();
      }
    },
    [handleVerification],
  );

  useEffect(() => {
    dispatch(verificationScreenViewAction('WorkID-LastName'));
  }, [dispatch]);

  return (
    <div>
      <FormContainer
        headerComponentOverride={
          <FormHeader title={translate('enrollment.verify.workId.headline')} />
        }
        termsMessage={true}
        isTestVariant={true}
        termsComponentOverride={<Terms />}
      >
        <div
          css={css(containerStyle(true), {
            textAlign: 'left',
          })}
        >
          <div
            css={subTitleStyle(healthProvider)}
            data-testid="eligibility-verify-by-work-id-subtitle"
          >
            <p>{subtitle}</p>
          </div>
          {isPersonifyCareProvider(healthProvider) && (
            <HealthProviderWorkIdGuidance />
          )}
          <div>
            {isPersonifyCareProvider(healthProvider) && (
              <Input
                aria-label={workIdPlaceholder}
                data-testid="id-number-input"
                errorMessage={idErrorMessage}
                hasError={idHasError}
                name="id-number"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                placeholder={workIdPlaceholder}
                shouldFocusError={true}
                type="text"
              />
            )}
            <Input
              aria-label={translate(
                'enrollment.elfDependent.lastName.placeholder',
              )}
              data-testid="last-name-input"
              errorMessage={nameErrorMessage}
              hasError={nameHasError}
              name="last-name"
              onChange={handleChange}
              onKeyDown={handleKeyPress}
              placeholder={translate(
                'enrollment.elfDependent.lastName.placeholder',
              )}
              shouldFocusError={true}
              type="text"
            />
            {!isPersonifyCareProvider(healthProvider) && (
              <Input
                aria-label={workIdPlaceholder}
                data-testid="id-number-input"
                errorMessage={idErrorMessage}
                hasError={idHasError}
                name="id-number"
                onChange={handleChange}
                onKeyDown={handleKeyPress}
                placeholder={workIdPlaceholder}
                shouldFocusError={true}
                type="text"
              />
            )}
            <SubmitButton
              dataTestId="verify-submit-button"
              onClick={handleVerification}
              value={verifyButtonLabel}
              suppressLetterSpacing={true}
            />
          </div>
        </div>
      </FormContainer>
    </div>
  );
};

export default EligibilityVerifyByWorkId;
