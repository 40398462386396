import {
  cssWithMq,
  labelSCss,
  bodyXSCss,
  borderRadiusM,
  SpacingInRemBySize,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { css } from '@emotion/react';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { PRIVACY_PAGE_LINK } from '../../constants';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const NoticeStyle = cssWithMq({
  display: 'flex',
  backgroundColor: WarmGrey[100],
  width: ['80vw', rem(25), rem(25)],
  margin: `${SpacingInRemBySize.L} ${SpacingInRemBySize.XL}`,
  padding: `${SpacingInRemBySize.XS} ${SpacingInRemBySize.L}`,
  borderRadius: borderRadiusM,
});

const noticeIconStyle = cssWithMq({
  width: [rem(15), rem(10), rem(10)],
});

export const PrivacyNotice = () => {
  const { translate } = useTranslate();

  return (
    <div css={NoticeStyle}>
      <div>
        <h2
          css={css(labelSCss, {
            marginBottom: SpacingInRemBySize['2XS'],
          })}
        >
          {translate('enrollment.privacy.header')}
        </h2>
        <p
          css={css(bodyXSCss, {
            marginTop: SpacingInRemBySize['2XS'],
          })}
        >
          <FormattedMessage
            id="enrollment.privacy.subHeader"
            values={{
              link: (chunks) => (
                <a href={PRIVACY_PAGE_LINK} target="_blank" rel="noreferrer">
                  {chunks}
                </a>
              ),
            }}
          />
        </p>
      </div>

      <img
        css={noticeIconStyle}
        src={`${ENROLLMENT_CDN_URL}/umd-privacy-notice.svg`}
        alt=""
        role="presentation"
      />
    </div>
  );
};
