import React from 'react';
import { ClassNames } from '@emotion/react';
import { CoolGrey, WarmGrey } from '@headspace/web-ui-components';
import { Icon } from '../Icon';
import {
  dropdownContentStyle,
  dropdownCtaStyle,
  dropdownLinkStyle,
  dropdownItemsStyle,
  dropdownItemStyle,
  dropdownStyle,
  positionStyle,
  textStyle,
} from './styles';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';

export interface DropdownParams {
  ctaColor?: string;
  ctaHoverBackgroundColor?: string;
  letterSpacing?: boolean;
  uppercase?: boolean;
  minContentWidth?: number | string;
  icon?: any;
  iconHeight?: number;
  iconWidth?: number;
  position: 'left' | 'middle' | 'right' | 'topRight';
  testId?: string;
  text?: string;
  textProps?: Record<string, unknown>;
}

export interface DropdownProps extends DropdownParams {
  children: React.ReactNode;
}

export const Dropdown: React.FC<DropdownProps> = ({
  children,
  ctaColor = CoolGrey[500],
  ctaHoverBackgroundColor = WarmGrey[100],
  letterSpacing = false,
  uppercase = false,
  minContentWidth = 248,
  icon = `${AP_CDN_URL}/caretDown-16.svg`,
  iconHeight = 16,
  iconWidth = 16,
  position,
  testId,
  text,
  textProps = {},
}) => {
  const isIconButton = !text && icon;
  const dropdownContentCss = dropdownContentStyle({
    minWidth: minContentWidth,
  });

  return (
    <ClassNames>
      {({ css, cx }) => (
        <div css={dropdownStyle({ ctaHoverBackgroundColor, isIconButton })}>
          <div
            data-testid={testId}
            className={cx('dropdownCta')}
            css={dropdownCtaStyle({
              ctaColor,
              ctaHoverBackgroundColor,
              isIconButton,
              letterSpacing,
              uppercase,
            })}
          >
            {text ? (
              <div css={textStyle} {...textProps}>
                {text}
              </div>
            ) : null}
            <Icon
              width={iconWidth}
              height={iconHeight}
              fill={ctaColor}
              src={icon}
            />
          </div>
          <div
            className={cx('dropdownContent')}
            css={[positionStyle(position), dropdownContentCss]}
          >
            <div css={dropdownItemsStyle()}>{children}</div>
          </div>
        </div>
      )}
    </ClassNames>
  );
};

export interface DropdownLinkProps {
  href: string;
  children: string | JSX.Element;
}

export function DropdownLink(props: DropdownLinkProps): JSX.Element {
  const { href, children, ...others } = props;
  return (
    <a href={href} css={[dropdownLinkStyle, dropdownItemStyle]} {...others}>
      {children}
    </a>
  );
}

export interface DropdownItemProps {
  onClick: (e: React.MouseEvent) => void;
  children: string | JSX.Element;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  const { onClick, children, ...others } = props;
  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      onClick(e);
    }
  };
  return (
    <div
      className="dropdownItem"
      onClick={onClick}
      onKeyUp={onKeyUp}
      role="button"
      tabIndex={0}
      css={[dropdownLinkStyle, dropdownItemStyle]}
      {...others}
    >
      {children}
    </div>
  );
};
