import * as React from 'react';
import { Orange } from '@headspace/web-ui-components';
import { useEffect, useState } from 'react';
import { UIRegularL, TitleL } from '../../../shared-components/text';
import { Icon } from '../../../shared-components/Icon';
import { Input } from '../../../shared-components/Input';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import * as Validations from '../../../utils/validations';
import {
  marginBottomLarge,
  marginBottomXsmall,
  marginBottomMedium,
  twoThirdWidth,
  centerAlign,
  errorText,
  paddingMedium,
  errorPanel,
} from './styles';
import { alignItemsCenter, columnFlex } from '../styles/flex';
import { useTranslate } from '../../../hooks/useTranslate';
import { AP_CDN_URL } from '../constants/routes';

interface ResetPasswordSuccessProps {
  onBackToLogin: (e) => void;
}
export const ResetSuccess: React.FC<ResetPasswordSuccessProps> = ({
  onBackToLogin,
}) => {
  const { translate } = useTranslate();
  return (
    <div css={[columnFlex, alignItemsCenter]} data-testid="password-reset-form">
      <TitleL
        css={[marginBottomXsmall, centerAlign]}
        dataTestId="password-reset-success-title"
      >
        {translate('login.resetSuccessTitle')}
      </TitleL>
      <div css={twoThirdWidth}>
        <UIRegularL
          css={[marginBottomLarge, centerAlign, alignItemsCenter]}
          dataTestId="password-reset-success-description"
        >
          {translate('login.resetSuccessDescription')}
        </UIRegularL>
      </div>
      <TextButton
        lg={true}
        pill={true}
        value={translate('login.backToLogin')}
        width="100%"
        onClick={(e) => {
          onBackToLogin(e);
        }}
        dataTestId="password-reset-success-button"
      />
    </div>
  );
};

interface ResetPasswordFormProps {
  errors: Validations.ErrorResp;
  email: string;
  onChange: (e) => void;
  onSubmit: (e) => void;
  resetSuccess: boolean;
  onBackToLogin: (e) => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props) => {
  const { translate } = useTranslate();

  const {
    errors,
    email,
    onChange,
    onSubmit,
    resetSuccess,
    onBackToLogin,
  } = props;
  const emailError = Validations.getErrorMesssage(errors.email);
  const globalError = Validations.getErrorMesssage(errors.message);

  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [emailErrorValue, setEmailErrorValue] = useState<string | undefined>(
    '',
  );

  useEffect(() => {
    setEmailErrorValue(
      submitDisabled
        ? emailError && translate(`validationErrors.${emailError}`)
        : undefined,
    );
  }, [emailError, submitDisabled, translate]);

  if (resetSuccess) {
    return <ResetSuccess {...props} />;
  }

  const backToLogin = (e: React.SyntheticEvent<HTMLElement>) => {
    onBackToLogin(e);
  };

  const onKeyUp = (e) => {
    if (e.key === 'Enter') {
      backToLogin(e);
    }
  };

  const handleOnChange = (e) => {
    setSubmitDisabled(false);
    onChange(e);
  };

  const handleOnSubmit = (e) => {
    if (emailError) {
      setSubmitDisabled(true);
      return;
    }

    onSubmit(e);
  };

  return (
    <div data-testid="password-reset-form">
      <div
        css={marginBottomMedium}
        onClick={backToLogin}
        onKeyUp={onKeyUp}
        role="button"
        tabIndex={0}
        data-testid="password-reset-back-button"
      >
        {' '}
        <Icon
          width={24}
          height={24}
          fill={Orange[200]}
          src={`${AP_CDN_URL}/leftSmall.svg`}
        />
      </div>
      <TitleL
        css={[marginBottomXsmall, centerAlign]}
        dataTestId="password-reset-title"
      >
        {translate('login.resetTitle')}
      </TitleL>
      <UIRegularL
        css={[marginBottomLarge, centerAlign]}
        dataTestId="password-reset-description"
      >
        {translate('login.resetDescription')}
      </UIRegularL>
      <form>
        <div css={marginBottomMedium}>
          <Input
            errorMessage={emailErrorValue}
            hasError={!!emailErrorValue}
            name="email"
            label={translate('login.email')}
            value={email}
            onChange={handleOnChange}
            data-testid="password-reset-email"
          />
        </div>
        {globalError ? (
          <div
            css={[marginBottomMedium, centerAlign, paddingMedium, errorPanel]}
          >
            <UIRegularL css={errorText}>{globalError}</UIRegularL>
          </div>
        ) : null}
        <TextButton
          onClick={handleOnSubmit}
          lg={true}
          pill={true}
          className="submitResetPassword"
          value={translate('login.resetPassword')}
          width="100%"
          disabled={submitDisabled}
          dataTestId="password-reset-button"
        />
      </form>
    </div>
  );
};
