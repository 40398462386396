import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { flowStartScreenViewAction } from '../../../../analytics/events/enrollmentEvents';
import { HealthProvider } from '../../constants/healthProvider';
import { Routes } from '../../constants/routes';
import { getHsUserId } from '../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { PersonifyCareWrapper } from '../HealthHubViews/Personify/PersonifyCareWrapper/PersonifyCareWrapper';
import { MemberLogin } from '../MemberLoginPage';
import { PersonifyCareEnrollmentComponent } from './Components/PersonifyCareEnrollmentComponent';
import { PersonifyCareVerificationStep } from './Components/PersonifyCareVerificationStep';

enum PersonifyCareFlow {
  ENROLLMENT = 0,
  VERIFICATION = 1,
  LOGIN = 2,
}

export const PersonifyCareLandingPage: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const HS_USER_ID = useSelector(getHsUserId);
  const [currentStep, setCurrentStep] = useState(PersonifyCareFlow.ENROLLMENT);
  const showNextStep = useCallback(() => setCurrentStep(currentStep + 1), [
    currentStep,
  ]);

  useEffect(() => {
    if (HS_USER_ID && currentStep === 0) {
      navigate(Routes.PERSONIFY_CARE_CONSENT);
    }
  }, [HS_USER_ID]);

  useEffect(() => {
    dispatch(flowStartScreenViewAction(HealthProvider.PERSONIFY_CARE));
  }, []);

  return (
    <PersonifyCareWrapper
      skipLogout={currentStep !== PersonifyCareFlow.VERIFICATION}
    >
      {currentStep === PersonifyCareFlow.ENROLLMENT && (
        <PersonifyCareEnrollmentComponent onClick={showNextStep} />
      )}
      {currentStep === PersonifyCareFlow.VERIFICATION && (
        <PersonifyCareVerificationStep onComplete={showNextStep} />
      )}
      {currentStep === PersonifyCareFlow.LOGIN && <MemberLogin />}
    </PersonifyCareWrapper>
  );
};
