import {
  Breakpoint,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownRenderPropArgs,
  useActiveBreakpoint,
} from '@headspace/web-ui-components';
import React, { useCallback, useMemo, useRef } from 'react';
import { CaretDown, CaretUp, Globe } from '@headspace/react-icons';
import {
  buttonStyle,
  languageMenuContainerStyle,
  languageMenuStyle,
  languageTitleMobile,
  toggleButtonCss,
  languageButtonStyle,
  languageExpandedButtonStyle,
  languageExpandedListStyle,
  toggleButtonIconCss,
  languageListStyle,
} from './styles';
import {
  AvailableLanguagesType,
  CCAApprovedLanguageCode,
  HubSupportedLanguageCode,
  SupportedLanguageCode,
} from '../../../types/language';
import { languageTitleStyle } from '../../../apps/headspaceHub/views/shared-components/Navbar/styles';
import { useTranslate } from '../../../hooks/useTranslate';
import { useUserLanguageSelectButtonClickthrough } from '../../../hooks/useButtonClickThroughtEvent';

type LanguageDropdownProps = {
  dataTestId: string;
  selectedLanguage: string;
  handleClick: (
    lang:
      | SupportedLanguageCode
      | HubSupportedLanguageCode
      | CCAApprovedLanguageCode,
  ) => () => void;
  languages: AvailableLanguagesType;
  featureFlagEnabled: boolean;
  isLanguageMenuSeparated?: boolean;
};

export const LanguageDropdown = ({
  dataTestId,
  selectedLanguage,
  handleClick,
  languages,
  featureFlagEnabled = false,
  isLanguageMenuSeparated = true,
}: LanguageDropdownProps) => {
  const { translate } = useTranslate();
  const menuRef = useRef(null);
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const { triggerEvent } = useUserLanguageSelectButtonClickthrough();
  const toggleButtonGlobeCss = toggleButtonIconCss(20, 20);
  const toggleButtonCaretCss = toggleButtonIconCss(16, 16);

  const languageKeys = Object.keys(languages) as Array<
    HubSupportedLanguageCode | SupportedLanguageCode | CCAApprovedLanguageCode
  >;
  const menuStyle = featureFlagEnabled
    ? languageExpandedListStyle(languageKeys.length)
    : languageListStyle;
  const buttonLangStyle = (lang: boolean) =>
    featureFlagEnabled
      ? languageExpandedButtonStyle(lang)
      : languageButtonStyle(lang);

  const handleLanguageSelect = useCallback(
    (lang) => {
      const label = translate(`enrollment.language.${lang}`);
      triggerEvent(label);
      handleClick(lang)();
    },
    [handleClick, translate, triggerEvent],
  );
  return (
    <Dropdown dataTestIdPrefix={dataTestId} css={languageMenuContainerStyle}>
      {({
        isDropped,
        toggleDropdown,
        dataTestIdPrefix,
      }: DropdownRenderPropArgs) => (
        <>
          <Button
            css={buttonStyle(!isLanguageMenuSeparated)}
            onClick={toggleDropdown as () => unknown}
            isBlock={false}
            isBordered={true}
            dataTestIdPrefix={dataTestIdPrefix}
          >
            <span css={toggleButtonCss}>
              {isMobileView && isLanguageMenuSeparated ? (
                <span css={languageTitleMobile}>
                  {translate(`enrollment.language.${selectedLanguage}`)}
                </span>
              ) : (
                <Globe css={toggleButtonGlobeCss} />
              )}
              {isDropped ? (
                <CaretUp css={toggleButtonCaretCss} />
              ) : (
                <CaretDown css={toggleButtonCaretCss} />
              )}
            </span>
          </Button>
          <DropdownMenu
            isDropped={isDropped}
            isElevated={true}
            dataTestIdPrefix={dataTestId}
            css={languageMenuStyle(featureFlagEnabled, isLanguageMenuSeparated)}
          >
            {isMobileView &&
              (isLanguageMenuSeparated ? null : (
                <span
                  css={languageTitleStyle(true)}
                  data-testid="navbar-language-title"
                >
                  {translate('headspaceHub.navbar.mobile.languageTitle')}
                </span>
              ))}
            <ul ref={menuRef} css={menuStyle}>
              {languageKeys.map((lang) => (
                <li key={lang}>
                  <button
                    type="button"
                    data-testid={`${dataTestId}-${lang}`}
                    onClick={() => handleLanguageSelect(lang)}
                    css={buttonLangStyle(selectedLanguage === lang)}
                  >
                    <p>{translate(`enrollment.language.${lang}`)}</p>
                  </button>
                </li>
              ))}
            </ul>
          </DropdownMenu>
        </>
      )}
    </Dropdown>
  );
};
