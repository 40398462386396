import { css } from '@emotion/react';
import {
  Divider,
  Size,
  SpacingInRemBySize,
  Spinner,
  TextLink,
  rem,
  remToPx,
  usePreCacheImages,
} from '@headspace/web-ui-components';
import { FC, useCallback, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { Container } from '../../../../../../shared-components/container/Container';
import { FieldLabel } from '../../../../../../shared-components/text';
import {
  cancelOrganizationChanges,
  handleChange as handleChangeAction,
  saveOrganizationEnrollment,
  toggleEditPanelView,
} from '../../../../../../state/actions/organizationActions';
import {
  getOrgManagement,
  getOrgMappingKey,
  isOrgHierarchyChanged,
} from '../../../../../../state/selectors/organizationSelectors';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import {
  ORGANIZATION_HIERARCHY,
  PRIMARY_PRODUCT,
  VIEW_PANEL_COUNT,
} from '../../../../constants';
import { useCreateChildOrgs } from '../../../../hooks/useCreateChildOrgs';
import { useGetOrganizations } from '../../../../hooks/useGetOrganizations';
import { useImportChildOrgs } from '../../../../hooks/useImportChildOrgs';
import { ParentOrgInput } from '../shared-components/ParentOrgInput/ParentOrgInput';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import { labelStyle, panelTwoColumnStyle } from '../styles';
import { ChildOrgIds } from './components/ChildOrgIds';
import { ImportGingerChildOrgs } from './components/ImportGingerChildOrgs/ImportGingerChildOrgs';
import { OrgMappingKeyInput } from './components/OrgMappingKeyInput';
import { OrgMappingValueInput } from './components/OrgMappingValueInput';
import { saveOrganizationBasic } from '../../../../../../state/actions/organizationActions/saveOrganizationBasic';
import { AP_CDN_URL } from '../../../../constants/routes';

export const OrgHierarchyPanel: FC = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const scrollRef = useRef<null | HTMLDivElement>(null);

  const {
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    parentOrgId,
    childOrgs,
    parentOrgIdError,
    gingerId,
    current_contract_primary_product,
    future_contract_primary_product,
    orgMappingValueError,
  } = useSelector(getOrgManagement);

  const isPanelInView = !!viewPanels[ORGANIZATION_HIERARCHY];
  const settingsChanged = useSelector(isOrgHierarchyChanged);
  const isCSM = useSelector(getIsCSM);
  const orgMappingKey = useSelector(getOrgMappingKey);
  const hasError = parentOrgIdError.error || orgMappingValueError.error;
  const shouldShowOrgMappingValueInput: boolean =
    Boolean(parentOrgId) && Boolean(orgMappingKey);

  usePreCacheImages([`${AP_CDN_URL}/status-warning.svg`]);

  const handleChange = useCallback((e) => dispatch(handleChangeAction(e)), [
    dispatch,
  ]);
  const handleCancel = useCallback(
    () => dispatch(cancelOrganizationChanges()),
    [dispatch],
  );
  const handleSaveChanges = useCallback(() => {
    dispatch(saveOrganizationBasic());
    dispatch(saveOrganizationEnrollment());
  }, [dispatch]);
  const handleToggleView = useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const {
    mutate: createChildOrgs,
    isError: createChildOrgsIsError,
    isLoading: createChildOrgsIsLoading,
  } = useCreateChildOrgs();

  const {
    isError: importChildOrgsIsError,
    isRefetchError: importChildOrgsIsRefetchError,
    isFetching: importChildOrgsIsFetching,
    isSuccess: importChildOrgsIsSuccess,
    isFetched: importChildOrgsIsFetched,
    refetch: refetchImportChildOrgs,
    data: importChildOrgsData,
    remove: importChildOrgsDataRemoveFromCache,
  } = useImportChildOrgs(gingerId);

  const childOrgNames = importChildOrgsData?.children.map((x) => x.name) || [];

  const {
    data: getOrganizationsData,
    isSuccess: getOrganizationsIsSuccess,
    isError: getOrganizationsIsError,
    isRefetchError: getOrganizationsIsRefetchError,
    remove: getOrganizationsDataRemoveFromCache,
  } = useGetOrganizations(childOrgNames);

  const editMode = isCSM && !isPanelInView;

  const canImportChildOrgs =
    (current_contract_primary_product === PRIMARY_PRODUCT.FUSION ||
      future_contract_primary_product === PRIMARY_PRODUCT.FUSION) &&
    !parentOrgId &&
    orgMappingKey &&
    gingerId &&
    childOrgs.length === 0;

  const handleRefreshImportChildOrgs = useCallback(
    async (e) => {
      e.preventDefault();
      await refetchImportChildOrgs();
    },
    [refetchImportChildOrgs],
  );

  const panelMarginOverride = css({
    margin: `${SpacingInRemBySize.XS} 0 ${SpacingInRemBySize.XL}`,
  });

  const spinnerStyle = css({ marginLeft: rem(0.5) });
  const importLinkStyle = (isFetching) =>
    css({
      cursor: isFetching ? 'not-allowed' : 'hand',
    });

  return (
    <>
      <Container>
        <SettingsPanel
          dataTestId="org-hierarchy-panel"
          title={translate('organizationSettings.orgHierarchy.title')}
          view={isPanelInView}
          toggleView={handleToggleView}
          cancelChanges={handleCancel}
          saveChanges={handleSaveChanges}
          index={ORGANIZATION_HIERARCHY}
          displayEditButton={viewPanels.indexOf(false) < 0 && isCSM}
          settingsChanged={settingsChanged}
          hasErrors={hasError}
        >
          <div ref={scrollRef} data-testid="org-hierarchy-scroll-ref" />

          <div css={[panelTwoColumnStyle, panelMarginOverride]}>
            {(!!parentOrgId || isCSM) && (
              <div>
                <ParentOrgInput
                  editMode={editMode}
                  editingExistingOrg={true}
                  confirmationModal={true}
                />
              </div>
            )}
            {(!!childOrgs.length || isCSM) && (
              <div>
                <ChildOrgIds />
              </div>
            )}
          </div>
          <Divider size={Size.XS} />
          <div
            css={[
              panelTwoColumnStyle,
              css({ marginBottom: SpacingInRemBySize.XL }),
            ]}
          >
            <OrgMappingKeyInput
              editMode={editMode}
              onChange={handleChange}
              optional={false}
            />
            {shouldShowOrgMappingValueInput ? (
              <OrgMappingValueInput
                editMode={editMode}
                onChange={handleChange}
              />
            ) : null}
          </div>

          {canImportChildOrgs ? (
            <>
              <Divider />
              <div css={labelStyle} data-testid="import-child-header">
                <FieldLabel>
                  <FormattedMessage id="organizationSettings.orgHierarchy.import.label" />
                </FieldLabel>
              </div>

              <div>
                <TextLink
                  to=""
                  onClick={handleRefreshImportChildOrgs}
                  css={importLinkStyle(importChildOrgsIsFetching)}
                  dataTestIdPrefix="import-child-orgs"
                >
                  <FormattedMessage id="organizationSettings.orgHierarchy.import.begin" />
                </TextLink>

                {importChildOrgsIsFetching ? (
                  <Spinner size={remToPx(1)} css={spinnerStyle} />
                ) : null}
              </div>
            </>
          ) : null}
        </SettingsPanel>
      </Container>

      <ImportGingerChildOrgs
        refetchImportChildOrgs={refetchImportChildOrgs}
        getOrganizationsDataRemoveFromCache={
          getOrganizationsDataRemoveFromCache
        }
        importChildOrgsDataRemoveFromCache={importChildOrgsDataRemoveFromCache}
        createChildOrgs={createChildOrgs}
        importChildOrgsIsError={importChildOrgsIsError}
        importChildOrgsIsRefetchError={importChildOrgsIsRefetchError}
        getOrganizationsIsError={getOrganizationsIsError}
        getOrganizationsIsRefetchError={getOrganizationsIsRefetchError}
        getOrganizationsData={getOrganizationsData}
        importChildOrgsIsSuccess={importChildOrgsIsSuccess}
        importChildOrgsIsFetched={importChildOrgsIsFetched}
        getOrganizationsIsSuccess={getOrganizationsIsSuccess}
        createChildOrgsIsError={createChildOrgsIsError}
        importChildOrgsData={importChildOrgsData}
        createChildOrgsIsLoading={createChildOrgsIsLoading}
        scrollRef={scrollRef}
        isCSM={isCSM}
      />
    </>
  );
};
