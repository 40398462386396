import { White } from '@headspace/web-ui-components';
import { AP_CDN_URL } from '../../../apps/dashboard/constants/routes';
import { Icon } from '../../Icon';
import {
  tableCheckboxStyleChecked,
  tableCheckboxStyleUnchecked,
} from '../styles';
import { TableCheckboxProps } from './types';

export const TableCheckbox = ({
  selected,
  onSelect,
  id = '',
}: TableCheckboxProps) => {
  const onKeyUp = (e) => {
    if (e.key === 'Enter' && onSelect) {
      onSelect(e);
    }
  };
  return (
    <div
      role="checkbox"
      id={id}
      aria-checked={selected}
      tabIndex={0}
      // @ts-ignore
      onClick={onSelect}
      onKeyUp={onKeyUp}
      className="checkbox"
      css={selected ? tableCheckboxStyleChecked : tableCheckboxStyleUnchecked}
    >
      {selected ? (
        <Icon
          src={`${AP_CDN_URL}/checkThick-16.svg`}
          width={16}
          height={16}
          fill={White['000']}
        />
      ) : null}
    </div>
  );
};
