import {
  Blue,
  bodyMCss,
  borderRadiusM,
  cssWithMq,
  fontSizeM,
  fontSizeS,
  labelSCss,
  letterSpacingTight,
  lineHeightBody,
  rem,
  SpacingInRemBySize,
  TextLink,
  WarmGrey,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  iconStyle,
  itemsFontStyle,
  phoneTitleStyle,
  rowItem,
} from '../styles/EAPStylesUmdWfm';
import { EapPhoneNumberWfm } from './EapPhoneNumberWfm';
import { listMarker } from '../../styles/verificationStyles';
import { getPrimaryProduct } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { PRIMARY_PRODUCT } from '../../../../../dashboard/constants';
import env from '../../../../../../config/env';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const containerStyle = cssWithMq({
  alignItems: ['center', 'center', 'flex-start'],
  borderRadius: borderRadiusM,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  letterSpacing: 0,
  textAlign: 'start',
});

const containerItemStyle = cssWithMq({
  margin: ['0 auto', 0, 0],
  maxWidth: rem(18.75),
});

const servicesContainerStyle = cssWithMq({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: rem(18.75),
  textAlign: 'initial',
});

const servicesListStyle = css({
  backgroundColor: WarmGrey[100],
  borderRadius: borderRadiusM,
  letterSpacing: letterSpacingTight,
  padding: `${SpacingInRemBySize.M} ${SpacingInRemBySize.L} ${SpacingInRemBySize.M}`,
});

const servicesListStyleTitle = css(labelSCss, {
  color: WarmGrey[700],
  margin: 0,
});

const servicesListItemsStyle = css(bodyMCss, {
  color: WarmGrey[700],
  margin: 0,
  padding: 0,
  paddingLeft: SpacingInRemBySize.M,
});

const listItemStyles = css(listMarker, {
  fontSize: fontSizeS,
  lineHeight: lineHeightBody,
  listStyle: 'none',
  position: 'relative',
});

const webLabelStyle = css({
  fontSize: fontSizeM,
  letterSpacing: letterSpacingTight,
  lineHeight: '100%',
  margin: `${SpacingInRemBySize.XS} 0`,
});

const webCodeStyle = css({
  color: Blue[200],
  fontSize: fontSizeS,
  lineHeight: '100%',
  margin: `${SpacingInRemBySize.S} 0 ${SpacingInRemBySize.XS}`,
});

const webLinkStyles = css(itemsFontStyle(), {
  ':after': {
    backgroundImage: `url(${ENROLLMENT_CDN_URL}/arrow-right.svg)`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    content: '""',
    height: SpacingInRemBySize.XS,
    position: 'absolute',
    right: `-${rem(0.025)}`,
    top: rem(0.4),
    width: SpacingInRemBySize.S,
  },
  ':before': {
    backgroundColor: Blue[200],
    bottom: SpacingInRemBySize['3XS'],
    content: '""',
    height: rem(0.063),
    left: 0,
    position: 'absolute',
    right: 0,
  },
  marginBottom: 0,
  marginTop: 0,
  paddingRight: SpacingInRemBySize.M,
  position: 'relative',
  width: 'fit-content',
});

const servicesTranslationKeysGlobalEnrollment = [
  'enrollment.landingPage.eap.globalEnrollment.services.list.message1',
  'enrollment.landingPage.eap.globalEnrollment.services.list.message2',
  'enrollment.landingPage.eap.globalEnrollment.services.list.message3',
  'enrollment.landingPage.eap.globalEnrollment.services.list.message4',
  'enrollment.landingPage.eap.globalEnrollment.services.list.message5',
  'enrollment.landingPage.eap.globalEnrollment.services.list.message6',
];

type LandingEAPUmdWfmProps = {
  clinicalResourcesSupported: boolean | null;
};

export const LandingEAPUmdWfm: FC<LandingEAPUmdWfmProps> = ({
  clinicalResourcesSupported,
}) => {
  const { translate } = useTranslate();
  const primaryProduct = useSelector(getPrimaryProduct);
  const isPrimaryProductEnabled = primaryProduct === PRIMARY_PRODUCT.FUSION;

  const isEapV2 = isPrimaryProductEnabled && clinicalResourcesSupported;

  const webLabel = translate(
    isEapV2
      ? 'enrollment.landingPage.eap.globalEnrollment.contact.webLabelV2'
      : 'enrollment.landingPage.eap.globalEnrollment.contact.webLabel',
  );

  const webCode = translate(
    'enrollment.landingPage.eap.globalEnrollment.contact.webCode',
  );

  return (
    <div css={containerStyle} data-testid="landing-eap">
      <div css={servicesContainerStyle}>
        <div css={servicesListStyle}>
          <h3 css={servicesListStyleTitle}>
            {translate('enrollment.landingPage.eap.services.header')}
          </h3>
          <ul css={servicesListItemsStyle}>
            {servicesTranslationKeysGlobalEnrollment.map((service) => (
              <li key={service} css={listItemStyles}>
                {translate(service)}
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div css={containerItemStyle}>
        <div css={rowItem}>
          <img
            css={iconStyle}
            src={`${ENROLLMENT_CDN_URL}/umd-landing-page-globe-icon.svg`}
            role="presentation"
            alt=""
          />
          {clinicalResourcesSupported ? (
            <div>
              {isEapV2 ? (
                <>
                  <TextLink
                    to="https://helpwhereyouare.com/CompanyLogin/1006/headspace"
                    isNewWindow={true}
                    css={{
                      textDecoration: 'none',
                    }}
                  >
                    <h2 css={webLabelStyle}>{webLabel}</h2>
                  </TextLink>
                  {isEapV2 ? <p css={webCodeStyle}>{webCode}</p> : null}
                </>
              ) : (
                <TextLink
                  to={`${env.HOST}/hub`}
                  isNewWindow={true}
                  css={{
                    textDecoration: 'none',
                  }}
                >
                  <h2 css={webLabelStyle}>
                    {translate(
                      'enrollment.landingPage.eap.globalEnrollment.contact.webLabel',
                    )}
                  </h2>
                </TextLink>
              )}
            </div>
          ) : (
            <div>
              <h2 css={css(phoneTitleStyle, { marginBottom: 0, marginTop: 0 })}>
                {translate('enrollment.landingPage.eap.contact.webLabel')}
              </h2>
              <TextLink
                to="https://helpwhereyouare.com/CompanyLogin/1073/TMAP"
                isNewWindow={true}
                css={{
                  textDecoration: 'none',
                }}
              >
                <h2 css={webLinkStyles}>
                  {translate('enrollment.landingPage.eap.contact.visitWebsite')}
                </h2>
              </TextLink>
              <div css={itemsFontStyle()}>
                {translate('enrollment.landingPage.eap.contact.code')}
              </div>
            </div>
          )}
        </div>
        <EapPhoneNumberWfm
          clinicalResourcesSupported={clinicalResourcesSupported}
        />
      </div>
    </div>
  );
};
