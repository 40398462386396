import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import { useLocation, Navigate } from 'react-router-dom';
import { determineHealthProviderByPathname } from '../../../../../utils/healthProvider';
import { MemberLogin } from '../../MemberLoginPage';
import { getHealthProviderUserId } from '../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { setCountryCode } from '../../../state/actions/countryCodeActions/setCountryCode';
import { setLanguage } from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import { HealthProvider } from '../../../constants/healthProvider';
import { CountryCodeToLanguageCode } from '../../../../../types/language';
import { useConnectHealthProvider } from '../../../hooks/useConnectHealthProvider';
import {
  getEmail,
  getJWT,
} from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getUserIsLoggedIn } from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import { getLookupKey } from '../../../state/selectors/lookupKeySelector/lookupKeySelector';
import { PageWrapper } from '../Personify/PageWrapper/PageWrapper';

const HealthProviderLandingPageComponent: FC = () => {
  const dispatch = useDispatch();
  const isUserLoggedIn = useSelector(getUserIsLoggedIn);
  const email = useSelector(getEmail);
  const providerUserId = useSelector(getHealthProviderUserId);
  const lookupKey = useSelector(getLookupKey);
  const { pathname, search } = useLocation();
  const JWT = useSelector(getJWT);
  const parsedQuery = useMemo(() => queryString.parse(search), [search]);
  const { countryCode, planId } = parsedQuery;

  const healthProvider = useMemo(
    () => determineHealthProviderByPathname(pathname),
    [pathname],
  );

  const {
    mutate: connectHealthProvider,
    isError: isProviderConnectionFailed,
    isSuccess: isProviderConnected,
    isLoading: isProviderConnecting,
  } = useConnectHealthProvider();

  useEffect(() => {
    dispatch(setCountryCode(countryCode));
    if (countryCode && healthProvider === HealthProvider.GYMPASS) {
      const languageCode = CountryCodeToLanguageCode[`${countryCode}`];
      if (languageCode) {
        dispatch(setLanguage(languageCode));
      }
    }
  }, [countryCode, dispatch, healthProvider]);

  const handleConnection = useCallback(() => {
    const requiredConnectParams =
      JWT && healthProvider && email && isUserLoggedIn;
    const shouldConnectProvidedProvider =
      requiredConnectParams && providerUserId;
    const shouldConnectLookupProvider = requiredConnectParams && lookupKey;
    const shouldConnect =
      (shouldConnectProvidedProvider || shouldConnectLookupProvider) &&
      !isProviderConnecting &&
      !isProviderConnected &&
      !isProviderConnectionFailed;

    if (!shouldConnect) {
      return;
    }

    connectHealthProvider({
      JWT,
      data: {
        countryCode,
        email,
        partnerId: healthProvider,
        partnerUserId: providerUserId,
        planId,
        uniqueKey: lookupKey,
      },
    });
  }, [
    JWT,
    healthProvider,
    email,
    isUserLoggedIn,
    providerUserId,
    lookupKey,
    isProviderConnecting,
    isProviderConnected,
    isProviderConnectionFailed,
    connectHealthProvider,
    countryCode,
    planId,
  ]);

  useEffect(() => {
    handleConnection();
  }, [handleConnection]);

  if (!healthProvider) {
    return <Navigate to="/not-found" replace={true} />;
  }

  if (isProviderConnectionFailed) {
    return <Navigate to={`/${healthProvider}/land/error`} replace={true} />;
  }

  if (isProviderConnected) {
    return <Navigate to={`/${healthProvider}/land/finish`} replace={true} />;
  }

  return (
    <PageWrapper
      healthProvider={healthProvider}
      showJoinMessage={!isProviderConnecting}
    >
      <MemberLogin showSpinner={isProviderConnecting} />
    </PageWrapper>
  );
};
export const HealthProviderLandingPage = React.memo(
  HealthProviderLandingPageComponent,
);
