import { CoolGrey } from '@headspace/web-ui-components';
import { regular } from '../../../apps/dashboard/styles/fonts';
import { Icon } from '../../Icon';
import { TableFilterBarProps } from './types';
import { clearSearchStyle, searchInputStyle } from './styles';
import { searchStyle } from '../styles';
import { noop } from '../../../utils';
import { AP_CDN_URL } from '../../../apps/dashboard/constants/routes';

export const TableFilterBar = ({
  handleSearch,
  searchValue,
  dataTestId,
  placeholder,
}: TableFilterBarProps) => {
  return (
    <div className="search" css={searchStyle}>
      <Icon
        width={24}
        height={24}
        fill={CoolGrey[500]}
        src={`${AP_CDN_URL}/search.svg`}
      />
      <input
        tabIndex={0}
        data-testid={dataTestId}
        type="text"
        onChange={handleSearch}
        placeholder={placeholder}
        value={searchValue}
        css={[regular, searchInputStyle]}
      />
      {searchValue ? (
        <div
          tabIndex={0}
          aria-label="Clear search"
          onClick={handleSearch}
          onKeyDown={noop}
          className="clearSearch"
          role="button"
          css={clearSearchStyle}
        >
          <Icon
            width={16}
            height={16}
            src={`${AP_CDN_URL}/close-16.svg`}
            fill={CoolGrey[500]}
          />
        </div>
      ) : null}
    </div>
  );
};
