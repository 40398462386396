import {
  fontSizeM,
  letterSpacingWider,
  lineHeightHeading,
  SpacingInRemBySize,
  WarmGrey,
} from '@headspace/web-ui-components';

import { css } from '@emotion/react';

export const serviceLabelStyles = css({
  color: WarmGrey['800'],
  fontSize: fontSizeM,
  letterSpacing: letterSpacingWider,
  lineHeight: lineHeightHeading,
  margin: `${SpacingInRemBySize.M} 0 0`,
  textTransform: 'uppercase',
});

export const dividerStyles = css({
  marginTop: SpacingInRemBySize.XL,
});
