import { Orange, White } from '@headspace/web-ui-components';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { AP_CDN_URL } from '../../../../constants/routes';
import { IconButtonPronounced } from '../../../../../../shared-components/buttons/IconButtonPronounced';
import { buttonColumnStyle } from '../../../../../../shared-components/ReportButton/styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useCreateSurvey } from '../../../hooks/surveys/useCreateSurvey';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';

export const GenerateNewSurvey = () => {
  const { translate } = useTranslate();
  const { orgId } = useSelector(getOrgManagement);
  const { mutate: createSurvey, isLoading } = useCreateSurvey();

  const handleGenerateSurvey = useCallback(() => {
    if (isLoading) return;
    createSurvey(orgId);
  }, [createSurvey, isLoading, orgId]);

  const buttonLabel = translate('organizationSettings.survey.generateSurvey');

  return (
    <div css={buttonColumnStyle}>
      <IconButtonPronounced
        src={`${AP_CDN_URL}/plus.svg`}
        onClick={handleGenerateSurvey}
        color={Orange[200]}
        backgroundColor={Orange[200]}
        fill={White['000']}
        label={buttonLabel}
        cursor={undefined}
      />
    </div>
  );
};
