import { FC, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { HealthProvider } from '../../../../constants/healthProvider';
import { setHealthProvider } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import { EnrollmentHeader } from '../../../shared-components/Header';
import { VerificationPage } from '../../../UnifiedEligibilityViews/VerificationPage';
import { ConfirmationPage } from '../../../PersonifyCare/ConfirmationPage';
import { Footer } from '../../../../../../shared-components/Footer/Footer';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

interface PersonifyCareWrapperProps {
  showConfirmationPage?: boolean;
  skipMobileHero?: boolean;
  skipLogout?: boolean;
  children: ReactNode;
}

export const PersonifyCareWrapper: FC<PersonifyCareWrapperProps> = ({
  showConfirmationPage = false,
  children,
  skipMobileHero,
  skipLogout,
}) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setHealthProvider(HealthProvider.PERSONIFY_CARE));
  }, [dispatch]);

  return (
    <>
      <EnrollmentHeader
        showStepComponent={false}
        healthProviderLogo={`${ENROLLMENT_CDN_URL}/personify-logo.png`}
      />
      {showConfirmationPage ? (
        <ConfirmationPage>{children}</ConfirmationPage>
      ) : (
        <VerificationPage
          showFAQ={false}
          showPersonifyCareContent={true}
          skipMobileHero={!!skipMobileHero}
          skipLogout={!!skipLogout}
        >
          {children}
        </VerificationPage>
      )}
      <Footer />
    </>
  );
};
