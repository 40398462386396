import { AxiosPromise } from 'axios';
import * as Sentry from '@sentry/browser';
import { OrganizationQueryResult } from '../../state/middleware/Organization';
import { orgAPIAxios } from '../axios';
import {
  B2B_API,
  getB2BOrgAttributeUrl,
  getB2BOrgIdUrl,
  getB2BOrgSlugUrl,
  getClincalCoverageUrl,
} from '../../utils/urls';
import { createAuthorizationHeader } from '../../utils/auth';
import { responseType, HTTPMethod } from '../../utils/constants';
import {
  GetOrganizations,
  CreateSingleOrganizationPayload,
  RemoveOrganization,
  DeactivateMembers,
  CreateOrganizationResponse,
  UpdateOrganizationPayload,
  OrganizationResponse,
  OrganizationEnrollmentResponse,
  GetVerificationLinkResponse,
  UpdateOrganizationEAPCoveragePayload,
  CreateOrgClinicalCoverage,
  GetEAPCoverageApiResponse,
  GetOrganizationsResponse,
  PostOrgLogoRequest,
  PostOrgLogoResponse,
  AdminUpdateOrganizationPayload,
} from './organization.types';
import { ApiErrors } from '../../utils/parseError';

export const getOrgByAttribute = (
  attributeName: string,
  attributeValue: string,
): AxiosPromise<OrganizationQueryResult> =>
  orgAPIAxios({
    method: HTTPMethod.GET,
    url: getB2BOrgAttributeUrl(attributeName, attributeValue)
      .GET_ORG_BY_ATTRIBUTE,
  }).catch((e) => {
    Sentry.addBreadcrumb({
      message: `Failed in getOrgByAttribute with Error:${e}`,
    });
    throw e;
  });

export const getOrganizations: GetOrganizations = (
  query,
): AxiosPromise<GetOrganizationsResponse> =>
  orgAPIAxios({
    method: HTTPMethod.GET,
    url: getB2BOrgSlugUrl(query).GET_ORGANIZATIONS,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((e: ApiErrors) => {
      Sentry.addBreadcrumb({
        message: `Failed in getOrganizations with Error:${e}`,
      });
      return e;
    });

export const postOrgLogo = ({
  formData,
  orgId,
}: PostOrgLogoRequest): AxiosPromise<PostOrgLogoResponse> =>
  orgAPIAxios({
    method: HTTPMethod.POST,
    url: getB2BOrgIdUrl(orgId).UPLOAD_ORG_LOGO,
    data: formData,
    headers: createAuthorizationHeader(),
  });

export const createOrganization = (
  options: CreateSingleOrganizationPayload,
): AxiosPromise<CreateOrganizationResponse> =>
  orgAPIAxios({
    method: HTTPMethod.POST,
    url: B2B_API.CREATE_ORG,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const createOrgClinicalCoverage = (
  orgId: number,
  options: CreateOrgClinicalCoverage,
): AxiosPromise<CreateOrganizationResponse> => {
  return orgAPIAxios({
    data: options,
    headers: createAuthorizationHeader(),
    method: HTTPMethod.POST,
    responseType: responseType.JSON,
    url: getClincalCoverageUrl(orgId).CREATE_CLINICAL_COVERAGE,
  });
};

export const getOrgClinicalCoverage = (
  orgId: number,
): AxiosPromise<GetEAPCoverageApiResponse> =>
  orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    responseType: responseType.JSON,
    url: getClincalCoverageUrl(orgId).GET_CLINICAL_COVERAGE,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((e: ApiErrors) => {
      Sentry.addBreadcrumb({
        message: `Failed in getOrgEAPCoverage with Error:${e}`,
      });
      throw e;
    });

export const updateOrganizationEnrollment = (
  orgId: number,
  options,
): AxiosPromise<OrganizationEnrollmentResponse> =>
  orgAPIAxios({
    method: HTTPMethod.PUT,
    url: getB2BOrgIdUrl(orgId).UPDATE_ORG_ENROLLMENT,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const updateOrganization = (
  orgId: number,
  options: UpdateOrganizationPayload,
): AxiosPromise<OrganizationResponse> =>
  orgAPIAxios({
    method: HTTPMethod.PUT,
    url: getB2BOrgIdUrl(orgId).CSM_UPDATE_ORG,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const adminUpdateOrganization = (
  orgId: number,
  options: AdminUpdateOrganizationPayload,
): AxiosPromise<OrganizationResponse> =>
  orgAPIAxios({
    method: HTTPMethod.PUT,
    url: getB2BOrgIdUrl(orgId).ADMIN_UPDATE_ORG,
    data: options,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const updateOrganizationClinicalCoverage = (
  orgId: number,
  options: UpdateOrganizationEAPCoveragePayload,
): AxiosPromise<OrganizationResponse> =>
  orgAPIAxios({
    data: options,
    headers: createAuthorizationHeader(),
    method: HTTPMethod.PATCH,
    responseType: responseType.JSON,
    url: getClincalCoverageUrl(orgId).CREATE_CLINICAL_COVERAGE,
  });

export const removeOrg: RemoveOrganization = (orgId) =>
  orgAPIAxios({
    method: HTTPMethod.DELETE,
    url: getB2BOrgIdUrl(orgId).DELETE_ORG,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const deactivateMembers = ({
  orgId,
  memberIds,
}): AxiosPromise<DeactivateMembers> =>
  orgAPIAxios({
    method: HTTPMethod.POST,
    url: getB2BOrgIdUrl(orgId).DEACTIVATE_MEMBERS,
    data: { memberIds },
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const getVerificationLink = (
  orgId: number,
  email: string,
): AxiosPromise<GetVerificationLinkResponse> =>
  orgAPIAxios({
    headers: createAuthorizationHeader(),
    method: HTTPMethod.GET,
    responseType: responseType.JSON,
    url: `${getB2BOrgIdUrl(orgId).VERIFICATION_LINK}?email=${encodeURIComponent(
      email,
    )}`,
  });

export const getAdminOrganizations = () =>
  orgAPIAxios({
    method: HTTPMethod.GET,
    url: getB2BOrgSlugUrl().GET_ADMIN_ORGANIZATIONS,
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  })
    .then(({ data }) => {
      return data;
    })
    .catch((e: ApiErrors) => {
      Sentry.addBreadcrumb({
        message: `Failed in getAdminOrganizations with Error:${e}`,
      });
      return e;
    });

export const postOrganizationRollover = (orgId: number) =>
  orgAPIAxios.post<boolean>(getB2BOrgIdUrl(orgId).POST_ROLLOVER, null, {
    headers: createAuthorizationHeader(),
    responseType: responseType.JSON,
  });

export const updateOrgClinicalCoverage = (
  orgId: number,
  options: UpdateOrganizationEAPCoveragePayload,
): AxiosPromise<OrganizationResponse> =>
  orgAPIAxios({
    data: options,
    headers: createAuthorizationHeader(),
    method: HTTPMethod.PATCH,
    responseType: responseType.JSON,
    url: getClincalCoverageUrl(orgId).UPDATE_CLINICAL_COVERAGE,
  });
