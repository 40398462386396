import { FC } from 'react';
import {
  accountStatusContainerStyle,
  accountStatusConnectionStyle,
  accountStatusHolderNameStyle,
  accountStatusPillStyle,
} from '../styles';
import { Icon } from '../../../../../shared-components/Icon';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface AccountStatusProps {
  accountHolderName: string;
}
export const AccountStatus: FC<AccountStatusProps> = ({
  accountHolderName,
}) => {
  const { translate } = useTranslate();
  return (
    <div
      css={accountStatusContainerStyle}
      data-testid="account-status-container"
    >
      <div
        css={accountStatusHolderNameStyle}
        data-testid="account-status-holder-name"
      >
        {accountHolderName}
      </div>
      <div
        css={accountStatusConnectionStyle}
        data-testid="account-status-connection"
      >
        {translate('enrollment.personifyCareAccountStatus.connection')}
      </div>
      <div css={accountStatusPillStyle} data-testid="account-status-pill">
        {translate('enrollment.personifyCareAccountStatus.pill')}
        <Icon
          src={`${ENROLLMENT_CDN_URL}/green-circle-check.svg`}
          width={15}
          height={15}
        />
      </div>
    </div>
  );
};
