import env from '../config/env';

export const {
  API_HOST,
  HOST,
  TELETHERAPY_HOST,
  WEBSITE_HOST,
  WEB_APP,
  NODE_ENV,
  ONBOARDING_SURVEY_ID,
  SCHEDULER_URL,
  GINGER_WEB_API,
} = env;

export const POST = 'post';
export const GET = 'get';
export const PUT = 'put';

export const createHeaders = (JWT: string) => ({
  authorization: `Bearer ${JWT}`,
  'content-type': 'application/json; charset=utf-8',
});

export const LOGO_IMAGE_TYPES = [
  'image/jpeg',
  'image/jpg',
  'image/svg',
  'image/png',
  'image/svg+xml',
];

export const B_IN_MB = 1000000;
export const MIN_YEAR = 2000;
export const AUTH0 = {
  CLIENT_ID: env.AUTH0_CLIENT_ID,
  CONNECTION: env.AUTH0_CONNECTION,
  DOMAIN: env.AUTH0_DOMAIN,
};

export const EMAIL_DOMAIN = 'EMAIL_DOMAIN';
export const EMAIL_ADDRESS = 'EMAIL_ADDRESS';
export const SLUG = window.location.pathname.split('/')[1];
export const ORGS_WITH_SUBSCRIPTION_VALIDATION = ['lacdmhresidents'];

export enum EnrollmentPathname {
  FINISH = '/finish',
  GINGER_SOLERA_ERROR = '/ginger-solera/land/error',
  GINGER_SOLERA_FINISH = '/ginger-solera/land/finish',
  GINGER_SOLERA_LAND = '/ginger-solera/land',
  GYMPASS = '/gympass',
  GYMPASS_ERROR = '/gympass/land/error',
  GYMPASS_FINISH = '/gympass/land/finish',
  GYMPASS_LAND = '/gympass/land',
  HEADSPACE_CARE_SOLERA_ERROR = '/headspacecare-solera/land/error',
  HEADSPACE_CARE_SOLERA_FINISH = '/headspacecare-solera/land/finish',
  HEADSPACE_CARE_SOLERA_LAND = '/headspacecare-solera/land',
  JOIN = '/join',
  LOGIN = '/login',
  MEMBER_ENROLL = '/member-enroll',
  PAYMENT = '/payment',
  PERSONIFY = '/personifyhealth/',
  PERSONIFY_CARE_CONFIRMATION = '/personifyhealth/care/confirmation',
  PERSONIFY_CARE_CONSENT = '/personifyhealth/care/consent',
  PERSONIFY_CARE_ERROR = '/personifyhealth/care/error',
  PERSONIFY_CARE_LAND = '/personifyhealth/care/land',
  PERSONIFY_LAND = '/personifyhealth/land',
  PERSONIFY_SELECT_ORG = '/personifyhealth/select-org',
  PERSONIFY_SSO_LINK = '/personifyhealth/link',
  PROCESSED_PAYMENT = '/processed-payment',
  VP_CARE_LAND = '/vp/care/land',
  VP_LAND = '/vp/land',
  SIGNUP = '/sign-up',
  SOLERA = '/solera/',
  SOLERA_ERROR = '/solera/land/error',
  SOLERA_FINISH = '/solera/land/finish',
  SOLERA_LAND = '/solera/land',
  VERIFIED_ACCOUNT = '/verified-account',
  VERIFY = '/verify',
}

export enum DashboardPathname {
  CREATE_CHALLENGE = '/create-challenge',
  CREATE_ORG = '/create-org',
  ERROR_NOT_FOUND = '/404',
  LOGIN = '/login',
  LOGIN_RESET = '/login/reset',
  ORG_LIST = '/orgs',
  ADMIN_ORG_LIST = '/admin/orgs',
}

export const HEADER_PATHNAME = [
  DashboardPathname.LOGIN,
  DashboardPathname.CREATE_ORG,
  DashboardPathname.CREATE_CHALLENGE,
  DashboardPathname.ERROR_NOT_FOUND,
  EnrollmentPathname.JOIN,
  EnrollmentPathname.MEMBER_ENROLL,
  EnrollmentPathname.SOLERA,
  EnrollmentPathname.GYMPASS,
];

export const FETCH_ORGANIZATION_LIMIT = 10;

export enum responseType {
  JSON = 'json',
  BLOB = 'blob',
}

export enum HTTPMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
  PATCH = 'patch',
}

export enum RequestStatus {
  IDLE = 'idle',
  PENDING = 'pending',
  SUCCEEDED = 'succeeded',
  FAILED = 'failed',
}

export enum CookieKeys {
  GTMSESSION = '_sp_id.8a05',
  COUNTRY_CODE = 'countryCode',
  DEFAULT_COUNTRY_CODE = 'US',
  LANGUAGE = 'lang',
  HUB_LANGUAGE = 'hub_lang',
}

export const enum SERVICE_TYPES {
  COACHING = 'coaching',
  CLINICAL = 'clinical',
}

export const STATIC_CDN_URL = 'https://static.headspace.com';
