import { useEffect } from 'react';
import { ENROLLMENT_CDN_URL } from '../apps/memberEnrollmentV2/constants/routes';

// Error Pages
const UnifiedExceptionFooter = `${ENROLLMENT_CDN_URL}/unifed-exception-footer.svg`;

// UMD Landing/Verification page
const landingPageHeroSvg = `${ENROLLMENT_CDN_URL}/umd-landing-page-hero.svg`;
const landingPageHeroSvgMobile = `${ENROLLMENT_CDN_URL}/umd-landing-page-hero-mobile.svg`;
const landingPageHeroSvgTablet = `${ENROLLMENT_CDN_URL}/umd-landing-page-hero-tablet.svg`;
const landingPageIcon1 = `${ENROLLMENT_CDN_URL}/umd-landing-page-icon-1.svg`;
const landingPageIcon2 = `${ENROLLMENT_CDN_URL}/umd-landing-page-icon-2.svg`;
const landingPageIcon3 = `${ENROLLMENT_CDN_URL}/umd-landing-page-icon-3.svg`;

// Onboarding
const niceToMeetYouPng = `${ENROLLMENT_CDN_URL}/nice-to-meet-you.png`;
const smileIllustration = `${ENROLLMENT_CDN_URL}/umd-smile-greeting.png`;
const umdPlayButton = `${ENROLLMENT_CDN_URL}/umd-play-button.png`;
const umdOnboardingTerribleEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-terrible-emotion.png`;
const umdOnboardingBadEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-bad-emotion.png`;
const umdOnboardingOkayEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-okay-emotion.png`;
const umdOnboardingGreatEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-great-emotion.png`;
const umdOnboardingAmazingEmotion = `${ENROLLMENT_CDN_URL}/umd-onboarding-slider-amazing-emotion.png`;
const umdOnboardingBeMindful = `${ENROLLMENT_CDN_URL}/umd-onboarding-be-mindful.png`;
const umdOnboardingFeelCalmAndRelax = `${ENROLLMENT_CDN_URL}/umd-onboarding-feel-calm-and-relax.png`;
const umdOnboardingManageAnxiety = `${ENROLLMENT_CDN_URL}/umd-onboarding-manage-anxiety.png`;
const umdOnboardingManageDepression = `${ENROLLMENT_CDN_URL}/umd-onboarding-manage-depression.png`;
const umdOnboardingProcessGrief = `${ENROLLMENT_CDN_URL}/umd-onboarding-process-grief.png`;
const umdOnboardingReduceStress = `${ENROLLMENT_CDN_URL}/umd-onboarding-reduce-stress.png`;
const umdOnboardingSleepBetter = `${ENROLLMENT_CDN_URL}/umd-onboarding-sleep-better.png`;
const umdOnboardingSomethingElse = `${ENROLLMENT_CDN_URL}/umd-onboarding-something-else.png`;
const umdOnboardingFamily = `${ENROLLMENT_CDN_URL}/umd-onboarding-family.png`;
const umdOnboardingCareer = `${ENROLLMENT_CDN_URL}/umd-onboarding-career.png`;
const umdOnboardingSocialLife = `${ENROLLMENT_CDN_URL}/umd-onboarding-social-life.png`;
const umdOnboardingPhysicalHealth = `${ENROLLMENT_CDN_URL}/umd-onboarding-physical-health.png`;
const umdOnboardingHobbies = `${ENROLLMENT_CDN_URL}/umd-onboarding-hobbies.png`;
const umdOnboardingPersonalFinances = `${ENROLLMENT_CDN_URL}/umd-onboarding-personal-finances.png`;
const umdOnboardingRadiobutton = `${ENROLLMENT_CDN_URL}/umd-onboarding-radiobutton.png`;
const umdOnboardingCheckmark = `${ENROLLMENT_CDN_URL}/umd-onboarding-checkmark.png`;

// Recommendation Pages
const GingerIcon = `${ENROLLMENT_CDN_URL}/umd-ginger-recommended-ginger.svg`;
const FinishStateCoach = `${ENROLLMENT_CDN_URL}/umd-finish-state-coach.png`;
const TextWithCoachHero = `${ENROLLMENT_CDN_URL}/umd-ginger-recommended-text-with-coach.svg}`;
const ThoughtBubbles = `${ENROLLMENT_CDN_URL}/umd-ginger-recommended-thought-bubbles.svg`;
const ClockIcon = `${ENROLLMENT_CDN_URL}/umd-recommended-clock-icon.svg`;
const HeadspaceIcon = `${ENROLLMENT_CDN_URL}/umd-recommended-headspace.svg`;
const ProgramIcon = `${ENROLLMENT_CDN_URL}/umd-recommended-program-icon.svg`;

const poweredByGingerPng = `${ENROLLMENT_CDN_URL}/umd-powered-by-ginger.png`;
const privacyNoticeSvg = `${ENROLLMENT_CDN_URL}/umd-privacy-notice.svg`;
const loginCircle = `${ENROLLMENT_CDN_URL}/umd-login-circle.svg`;
const promptCircle = `${ENROLLMENT_CDN_URL}/umd-login-circle-prompt.svg`;

export const LANDING_PAGE_ASSETS = [
  landingPageHeroSvg,
  landingPageHeroSvgMobile,
  landingPageHeroSvgTablet,
  landingPageIcon1,
  landingPageIcon2,
  landingPageIcon3,
  UnifiedExceptionFooter,
  niceToMeetYouPng,
  smileIllustration,
];

export const ONBAORDING_PAGES_ASSETS = [
  umdPlayButton,
  umdOnboardingTerribleEmotion,
  umdOnboardingBadEmotion,
  umdOnboardingOkayEmotion,
  umdOnboardingGreatEmotion,
  umdOnboardingAmazingEmotion,
  umdOnboardingBeMindful,
  umdOnboardingFeelCalmAndRelax,
  umdOnboardingManageAnxiety,
  umdOnboardingManageDepression,
  umdOnboardingProcessGrief,
  umdOnboardingReduceStress,
  umdOnboardingSleepBetter,
  umdOnboardingSomethingElse,
  umdOnboardingFamily,
  umdOnboardingCareer,
  umdOnboardingSocialLife,
  umdOnboardingPhysicalHealth,
  umdOnboardingHobbies,
  umdOnboardingPersonalFinances,
  umdOnboardingRadiobutton,
  umdOnboardingCheckmark,
  GingerIcon,
  TextWithCoachHero,
  ThoughtBubbles,
  ClockIcon,
  HeadspaceIcon,
  ProgramIcon,
  FinishStateCoach,
  poweredByGingerPng,
  privacyNoticeSvg,
  loginCircle,
  promptCircle,
];

/**
 * This hook is intended to be used to precache the umd images to avoid image flickering when loading or navigation.
 * https://stackoverflow.com/questions/57653924/how-to-preload-local-images
 */
export const usePreCacheImages = (imageList: Array<any>) => {
  useEffect(() => {
    imageList.forEach((image) => {
      new Image().src = image;
    });
  }, []);
};
