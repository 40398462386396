import { CLINICAL_COVERAGE_TYPE } from '../../types/organization';

export const ADD_ON_OPTIONS = [
  {
    titleKey: 'organizationSettings.enrollment.addOnsValue.none',
    value: CLINICAL_COVERAGE_TYPE.NONE,
  },
  {
    titleKey: 'organizationSettings.enrollment.addOnsValue.eap',
    value: CLINICAL_COVERAGE_TYPE.EAP_V2,
  },
  {
    titleKey: 'organizationSettings.enrollment.addOnsValue.intlTherapy',
    value: CLINICAL_COVERAGE_TYPE.INTL_THERAPY,
  },
];
