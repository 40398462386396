import { useCallback } from 'react';
import { EventName, EventType, useGetLogEvent } from '@headspace/web-analytics';
import { ContractActivityStatus } from '../analytics/types';

type UseUserLanguageSelectButtonClickthrough = {
  triggerEvent: (
    label: string,
    activityStatus?: ContractActivityStatus,
  ) => void;
};

export const useUserLanguageSelectButtonClickthrough = (): UseUserLanguageSelectButtonClickthrough => {
  const logEvent = useGetLogEvent();

  const triggerEvent = useCallback(
    (
      label: string,
      activityStatus: ContractActivityStatus = ContractActivityStatus.complete,
    ) => {
      logEvent({
        eventName: EventName.ButtonClick,
        eventType: EventType.UserPreference,
        contracts: {
          activity: {
            activity_status: activityStatus,
            activity_type: 'cta',
            cta_label: label,
          },
        },
      });
    },
    [logEvent],
  );

  return { triggerEvent };
};
