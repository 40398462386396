import * as React from 'react';
import { WEBSITE_HOST } from '../../../../../utils/constants';
import { FinishCard } from './FinishCard';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface DependentsMessageProps {
  dependentsPerMember: number | null;
}

export const DependentsMessage: React.FC<DependentsMessageProps> = ({
  dependentsPerMember,
}) => {
  const { translate } = useTranslate();

  const buttonRedirectUrl = `${WEBSITE_HOST}/family/manage`;
  const subheaderText = translate(
    'dependentAccess.inviteFamilyCard.subHeader',
    {
      dependentsPerMember,
    },
  );

  return (
    <FinishCard
      buttonRedirectUrl={buttonRedirectUrl}
      buttonText={translate('dependentAccess.inviteFamilyCard.sendInvites')}
      dataTestId="dependents-card"
      headerText={translate('dependentAccess.inviteFamilyCard.header')}
      imageUrl={`${ENROLLMENT_CDN_URL}/family-illustration.svg`}
      subheaderText={subheaderText}
    />
  );
};
