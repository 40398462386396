import { FC } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { Icon } from '../../../../../shared-components/Icon';
import StepComponent from '../StepComponent/StepComponent';
import { TranslationTab } from './TranslationTab';
import LogOut from './LogOut';
import {
  centerStepperStyle,
  headerStyle,
  logoContainer,
  logoDividerStyle,
  orgLogoStyle,
  rightSideStyle,
} from './styles';
import { getLogoEnv } from '../../../../../utils/getLogo';
import SoleraStepComponent from '../StepComponent/SoleraStepComponent';
import { HealthProvider } from '../../../constants/healthProvider';
import { getActiveOrg } from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { IMG_CDN_URL_LOGO } from '../../../../dashboard/constants';
import { DownloadHeadspaceBanner } from './DownloadHeadspaceBanner';

interface EnrollmentHeaderProps {
  showStepComponent: boolean;
  showDownloadBanner?: boolean;
  healthProvider?: HealthProvider;
  healthProviderLogo?: string;
}

const SUPPORTED_HEALTH_PROVIDERS: HealthProvider[] = [
  HealthProvider.SOLERA,
  HealthProvider.GYMPASS,
];

const stepperWrapper = (healthProvider?: HealthProvider) => {
  if (healthProvider && SUPPORTED_HEALTH_PROVIDERS.includes(healthProvider)) {
    return <SoleraStepComponent />;
  }

  return <StepComponent />;
};

export const EnrollmentHeader: FC<EnrollmentHeaderProps> = ({
  showStepComponent,
  showDownloadBanner = false,
  healthProvider,
  healthProviderLogo,
}) => {
  const activeOrg = useSelector(getActiveOrg);
  const { logo } = activeOrg || {};
  const showLogo = healthProviderLogo ?? logo;

  return (
    <header css={headerStyle} data-testid="header-container">
      <div css={logoContainer}>
        <Icon
          src={
            showLogo
              ? `${IMG_CDN_URL_LOGO}/headspace-logo-circle.svg`
              : `${IMG_CDN_URL_LOGO}/new-logo.svg`
          }
          width={showLogo ? 24 : 100}
          height={24}
          dataTestid="hs-logo"
        />
        {showLogo && <div css={logoDividerStyle} />}

        <span
          css={css({
            display: 'flex',
          })}
        >
          {showLogo ? (
            <img
              css={orgLogoStyle}
              src={healthProviderLogo ?? getLogoEnv(logo)}
              data-testid="org-logo"
              alt="org-logo"
              aria-hidden="true"
            />
          ) : null}
        </span>
      </div>

      <div css={centerStepperStyle}>
        {showStepComponent && stepperWrapper(healthProvider)}
      </div>
      <div css={rightSideStyle}>
        {showDownloadBanner ? <DownloadHeadspaceBanner /> : null}
        <LogOut />
        <TranslationTab />
      </div>
    </header>
  );
};
