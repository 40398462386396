import * as React from 'react';
import { PropsWithChildren } from 'react';
import { colStyle, containerStyle } from './styles';

export interface ContainerProps {
  key?: string;
  children: React.ReactNode;
}

export const Container: React.FC<ContainerProps> = (
  props: PropsWithChildren,
) => {
  const { children } = props;
  return (
    <div css={containerStyle} {...props}>
      <div css={colStyle}>{children}</div>
    </div>
  );
};
