import { useCallback } from 'react';
import {
  Modal,
  bodyMCss,
  headingLCss,
  usePreCacheImages,
} from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { TextButton } from '../../../../../../../../../shared-components/buttons/TextButton';
import { useTranslate } from '../../../../../../../../../hooks/useTranslate';
import { getOrgManagement } from '../../../../../../../../../state/selectors/organizationSelectors';
import { AP_CDN_URL } from '../../../../../../../constants/routes';

const modalBodyStyle = css({
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '50vh',
  width: '50vw',
  justifyContent: 'center',
  textAlign: 'center',
  padding: 'inherit 2rem',
});

const iconStyle = css({
  width: '4rem',
  margin: '0 auto',
});

const titleStyle = css(headingLCss, {
  margin: 0,
});

const buttonGroupStyle = css({
  display: 'flex',
  justifyContent: 'center',
  '[data-testid="continue-import"]': {
    width: '15rem',
  },
});

const statusWarning = `${AP_CDN_URL}/status-warning.svg`;

export const ImportChildExceptionModal = ({ onCloseModal }) => {
  const { translate } = useTranslate();
  const { org_name: parentOrgName } = useSelector(getOrgManagement);
  const handleOnCloseModal = useCallback(() => {
    onCloseModal();
  }, [onCloseModal]);

  usePreCacheImages([statusWarning]);

  return (
    <Modal
      dataTestIdPrefix="import-ginger-exception"
      body={
        <div css={modalBodyStyle}>
          <img css={iconStyle} src={statusWarning} alt="" />

          <h2 css={titleStyle} data-testid="import-exception-title">
            <FormattedMessage id="organizationSettings.orgHierarchy.import.error.title" />
          </h2>
          <p css={bodyMCss} data-testid="import-exception-subtitle">
            <FormattedMessage
              id="organizationSettings.orgHierarchy.import.error.subtitle"
              values={{
                parentOrgName,
              }}
            />
          </p>

          <span css={buttonGroupStyle}>
            <TextButton
              lg={true}
              dataTestId="continue-import"
              value={translate('done')}
              onClick={handleOnCloseModal}
            />
          </span>
        </div>
      }
      onClose={handleOnCloseModal}
      lockParentScroll={true}
    />
  );
};
