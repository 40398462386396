import { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Route, useNavigate, Routes } from 'react-router-dom';
import { QuestionView } from './QuestionsView/QuestionView';
import { GreetingView } from './GreetingView/GreetingView';
import { RecommendGinger } from './RecommendationView/RecommendGinger';
import { NextStepsFinishPage } from '../NextStepsPage/NextStepsFinishPage';
import { SchedulingPage } from './Scheduling/SchedulingPage';
import { ConsentPage } from './Consent/ConsentPage';
import { useUnifiedEligibilityBasePath } from '../utils/routeUtils';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { getJWT } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getEnrollmentVerificationIsVerified } from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import { checkLanguageSupport } from '../../../../../utils/checkLanguageSupport';

export const OnBoardingPage: FC = () => {
  const navigate = useNavigate();
  const { slug } = useUnifiedEligibilityBasePath();
  const JWT = useSelector(getJWT);
  const isEnrollmentVerified = useSelector(getEnrollmentVerificationIsVerified);
  const { VERIFY_PATH, EXIT_ONBOARDING_PATH } = getUnifiedEligibilityRoutes(
    slug,
  );

  useEffect(() => {
    const isLanguageSupported = checkLanguageSupport();
    if (!isLanguageSupported) {
      navigate(EXIT_ONBOARDING_PATH);
    }

    if (!JWT || !isEnrollmentVerified) {
      navigate(VERIFY_PATH);
    }
  }, []);

  return (
    <Routes>
      <Route path="welcome" element={<GreetingView />} />
      <Route path="questions/*" element={<QuestionView />} />
      <Route path="download-ginger" element={<RecommendGinger />} />
      <Route path="next-steps" element={<NextStepsFinishPage />} />
      <Route path="scheduling" element={<SchedulingPage />} />
      <Route path="consent" element={<ConsentPage />} />
      <Route path="/" element={<GreetingView />} />
    </Routes>
  );
};
