import { css } from '@emotion/react';
import {
  borderWidthXS,
  fontSizeL,
  fontSize2XL,
  fontSizeXS,
  FontWeight,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';
import { WebUIComponentsBreakPoint } from '../../../../../constants/breakpoint';

export const iconStyle = css({
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'none',
  },
});

export const buttonDesktopStyle = css({
  [WebUIComponentsBreakPoint.Desktop]: {
    minWidth: rem(12.75),
  },
});

export const actionsStyle = css({
  [WebUIComponentsBreakPoint.Desktop]: {
    display: 'none',
  },
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: rem(7.5),
  justifyContent: 'space-between',
  marginTop: rem(2.5),
  width: rem(25),
});

export const footerActionsStyle = css({
  display: 'flex',
  gap: rem(1.75),
});

export const panelStyle = css({
  [WebUIComponentsBreakPoint.Desktop]: {
    border: `solid ${WarmGrey[200]} ${borderWidthXS}`,
    borderRadius: rem(1.5),
  },
  maxWidth: rem(23),
  padding: `0 ${rem(2.5)}`,
  textAlign: 'center',
});

export const headerStyle = css({
  fontSize: fontSize2XL,
  fontWeight: FontWeight.BOLDER,
});

export const descriptionStyle = css({
  fontSize: fontSizeL,
});

export const hintStyle = css({
  color: WarmGrey[600],
  fontSize: fontSizeXS,
  paddingBottom: rem(1.5),
});

export const orgLogoStyle = css({
  maxHeight: rem(10),
  maxWidth: rem(10),
});
