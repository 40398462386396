import { Blue, White } from '@headspace/web-ui-components';
import { FC } from 'react';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import { linkStyles } from '../../../../dashboard/LoginView/styles';
import { START_USING_HS_LINK } from '../../../constants';
import {
  logo,
  logoContainer,
} from '../../HealthHubViews/Personify/LinkPage/styles/preLinkPageStyle';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { numericListStyle, outerContainerStyle } from '../styles';
import { ENROLLMENT_CDN_URL } from '../../../constants/routes';

interface PersonifyCareEnrollmentComponentProps {
  onClick?();
}

export const PersonifyCareEnrollmentComponent: FC<PersonifyCareEnrollmentComponentProps> = ({
  onClick,
}) => {
  const { translate } = useTranslate();

  return (
    <FormContainer
      headerTag="h4"
      title={translate('ssoEnrollment.landingPage.header')}
      showTerms={false}
    >
      <div css={outerContainerStyle} data-testid="care-component-container">
        <p>{translate('enrollment.personifyCareComponent.subHeader')}</p>
        <div
          css={logoContainer(true)}
          data-testid="care-component-personify-logo-container"
        >
          <img
            css={logo}
            src={`${ENROLLMENT_CDN_URL}/personify-logo.png`}
            alt="care-component-personify-logo"
          />
        </div>
        <ol css={numericListStyle} data-testid="care-component-list">
          <li>
            {translate('enrollment.personifyCareComponent.list.message1')}
          </li>
          <li>
            {translate('enrollment.personifyCareComponent.list.message2')}
          </li>
          <li>
            {translate('enrollment.personifyCareComponent.list.message3')}
          </li>
        </ol>
        <TextButton
          dataTestId="care-component-start-now-button"
          textColor={White['000']}
          hoverColor={Blue[200]}
          backgroundColor={Blue[200]}
          pill={true}
          width="100%"
          lg={true}
          value={translate('enrollment.personifyCareComponent.button')}
          onClick={onClick}
          suppressLetterSpacing={true}
        />
        <p>
          {translate('enrollment.personifyCareComponent.alreadyEnrolled')}
          <a
            css={linkStyles}
            data-testid="care-start-using-link"
            href={START_USING_HS_LINK}
          >
            {translate('enrollment.personifyCareComponent.startUsing')}
          </a>
        </p>
      </div>
    </FormContainer>
  );
};
