import { HealthProvider } from '../apps/memberEnrollmentV2/constants/healthProvider';
import { Routes } from '../apps/memberEnrollmentV2/constants/routes';

export const determineHealthProviderByPathname = (
  pathname: string,
): HealthProvider | undefined => {
  let formattedPathname = pathname;

  if (pathname.endsWith('/')) {
    formattedPathname = pathname.slice(0, -1);
  }

  const routeToHealthProviderMap = new Map<string, HealthProvider>([
    [Routes.SOLERA_LAND, HealthProvider.SOLERA],
    [Routes.GINGER_SOLERA_LAND, HealthProvider.SOLERA],
    [Routes.HEADSPACE_CARE_SOLERA_LAND, HealthProvider.SOLERA],
    [Routes.GYMPASS_LAND, HealthProvider.GYMPASS],
    [Routes.PERSONIFY_LAND, HealthProvider.PERSONIFY],
    [Routes.PERSONIFY_CARE_LAND, HealthProvider.PERSONIFY_CARE],
  ]);

  return routeToHealthProviderMap.get(formattedPathname);
};

export const personifyHealthProviders = [
  HealthProvider.PERSONIFY,
  HealthProvider.PERSONIFY_CARE,
];

export const isOneOfPersonifyProviders = (
  healthProvider: HealthProvider,
): boolean => {
  return personifyHealthProviders.includes(healthProvider);
};

export const isPersonifyCareProvider = (
  healthProvider: HealthProvider,
): boolean => HealthProvider.PERSONIFY_CARE === healthProvider;
