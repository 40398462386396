import { FC, PropsWithChildren, ReactNode } from 'react';
import { css } from '@emotion/react';
import {
  SpacingInRemBySize,
  TextLink,
  WarmGrey,
  bodyMCss,
  cssWithMq,
  fontSize2XL,
  fontSize3XL,
  headingLCss,
  rem,
} from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { AP_CDN_URL } from '../../../../dashboard/constants/routes';

const pageContainerStyle = css({
  alignContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  height: ['100%', '100%', '90vh'],
  justifyContent: 'center',
  margin: `${rem(0.5)} ${rem(1)}`,
  textAlign: 'center',
});

const messageContainerStyle = css({
  margin: 'auto',
});

const iconStyle = cssWithMq({
  alignSelf: 'center',
  margin: 0,
  paddingLeft: rem(0.5),
  width: ['inherit', rem(10), rem(12)],
});

const titleStyle = cssWithMq(headingLCss, {
  color: WarmGrey['700'],
  fontSize: [fontSize2XL, fontSize3XL, fontSize3XL],
  margin: [
    `0 0 ${SpacingInRemBySize.M}`,
    `0 0 ${SpacingInRemBySize.M}`,
    'auto',
  ],
  paddingBottom: ['inherit', 'inherit', SpacingInRemBySize.XS],
});

const subtitleStyle = cssWithMq(bodyMCss, {
  color: WarmGrey['500'],
  margin: 'auto',
  width: ['inherit', '55%', rem(30)],
});

const supportMessageStyle = cssWithMq(bodyMCss, {
  color: WarmGrey['500'],
  width: ['inherit', '55%', rem(27)],
});

const ChunkUpdate = (chunks: ReactNode) => (
  <TextLink to="mailto:teamsupport@headspace.com" isNewWindow={true}>
    {chunks}
  </TextLink>
);

const LearnMoreChunkUpdate = (chunks: ReactNode, url = '') => (
  <TextLink to={url} isNewWindow={true}>
    {chunks}
  </TextLink>
);

interface BaseExceptionPageProps extends PropsWithChildren {
  additionalCopyKey?: string;
  additionalCopyLink?: string;
  dataTestIdPrefix?: string;
  subtitleKey: string | null;
  subtitleLearnMore?: string;
  titleKey: string;
}

export const BaseExceptionPage: FC<BaseExceptionPageProps> = ({
  additionalCopyKey,
  additionalCopyLink,
  children,
  dataTestIdPrefix,
  subtitleKey,
  subtitleLearnMore,
  titleKey,
}) => {
  return (
    <div
      data-testid={`${dataTestIdPrefix}-exception-page`}
      css={pageContainerStyle}
    >
      <div css={messageContainerStyle}>
        <img
          css={iconStyle}
          alt=""
          src={`${AP_CDN_URL}/dependent-broken-link.svg`}
        />
        <h1
          css={titleStyle}
          data-testid={`${dataTestIdPrefix}-exception-page-title`}
        >
          <FormattedMessage id={titleKey} />
        </h1>
        {subtitleKey ? (
          <p
            css={subtitleStyle}
            data-testid={`${dataTestIdPrefix}-exception-page-subtitle`}
          >
            <FormattedMessage
              id={subtitleKey}
              values={{
                a: ChunkUpdate,
                learnMore: (chunk) =>
                  LearnMoreChunkUpdate(chunk, subtitleLearnMore),
              }}
            />
          </p>
        ) : null}
      </div>
      {children}
      {additionalCopyKey ? (
        <p css={supportMessageStyle}>
          <FormattedMessage
            id={additionalCopyKey}
            values={{
              learnMore: (chunk) =>
                LearnMoreChunkUpdate(chunk, additionalCopyLink),
            }}
          />
        </p>
      ) : null}
      <p css={supportMessageStyle}>
        <FormattedMessage
          id="enrollment.errors.dependents.support"
          values={{
            a: ChunkUpdate,
          }}
        />
      </p>
    </div>
  );
};
