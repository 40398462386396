import { call, put, select } from 'redux-saga/effects';
import { isPersonifyCareProvider } from '../../../../../utils/healthProvider';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';
import { HealthProvider } from '../../../constants/healthProvider';
import { Routes } from '../../../constants/routes';
import { postEnrollMember } from '../../../rest-calls/postMemberEnroll';
import { fetchSSOEnrollmentsStatusAction } from '../../actions/ssoEnrollmentActions/fetchSSOEnrollmentStatusAction';
import { getEnrollMentVerificationSection } from '../../selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import {
  getEmail,
  getJWT,
} from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import {
  getHealthHubId,
  getIsEnrolled,
} from '../../selectors/ssoSelector/ssoSelector';
import { webAppRedirectSaga } from './webAppRedirectSaga';
import { router } from '../../../../../root-component/router';

export function* ssoRedirectSaga(healthProvider: HealthProvider) {
  yield put(fetchSSOEnrollmentsStatusAction());
  const enrolled = yield select(getIsEnrolled);
  const healthHubId = yield select(getHealthHubId);
  const ENROLLED_AND_CONNECTED_HEALTH_HUB = enrolled && healthHubId;

  if (ENROLLED_AND_CONNECTED_HEALTH_HUB) {
    yield call(webAppRedirectSaga);
    return;
  }

  if (healthProvider === HealthProvider.PERSONIFY) {
    yield router.navigate(Routes.PERSONIFY_SSO_LINK);
  }

  if (isPersonifyCareProvider(healthProvider)) {
    const JWT = yield select(getJWT);
    const {
      confirmation: {
        lastName,
        workId,
        preferredSlug,
        preferredCountry,
        preferredDob,
      },
    } = yield select(getEnrollMentVerificationSection);
    const hsUserAccountEmail = yield select(getEmail);
    yield call(postEnrollMember, preferredSlug, JWT, {
      enrollmentType: EnrollmentVerification.ID,
      hsUserAccountEmail,
      lastName,
      preferredCountry,
      preferredDob,
      workId,
    });

    yield router.navigate(Routes.PERSONIFY_CARE_CONSENT);
  }
}
