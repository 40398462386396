import * as React from 'react';
import { AP_CDN_URL } from '../../apps/dashboard/constants/routes';
import { STEP_STATUS } from '../../apps/dashboard/constants';
import { Icon } from '../Icon';

export interface StatusIndicatorProps {
  status: STEP_STATUS;
}
const statusActive = `${AP_CDN_URL}/status-active.svg`;
const statusComplete = `${AP_CDN_URL}/status-complete.svg`;
const statusIncomplete = `${AP_CDN_URL}/status-incomplete.svg`;

export const StatusIndicator: React.FC<StatusIndicatorProps> = ({ status }) => {
  let src = statusActive;
  if (status === STEP_STATUS.COMPLETE) {
    src = statusComplete;
  } else if (status === STEP_STATUS.INCOMPLETE) {
    src = statusIncomplete;
  }

  return <Icon width={16} height={16} src={src} />;
};
