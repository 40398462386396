import { CLINICAL_COVERAGE_PROVIDERS } from './eapMemberDetails';

export enum FORM_TYPE {
  IN_PERSON_THERAPY = 'in-person-therapy',
  VIRTUAL_THERAPY = 'virtual-therapy',
  WORK_AND_LIFE_SERVICES = 'work-life-services',
}

export enum INPUT_TYPES {
  text = 'text',
  dropdown = 'dropdown',
  text_multi = 'text_multi',
  calendar_single = 'calendar_single',
  calendar_multi = 'calendar_multi',
  datepicker = 'datepicker', // transform to plane text
  multiple_choice_checkbox = 'multiple_choice_checkbox',
  multiple_choice_radio = 'multiple_choice_radio',
  multiple_choice_image_button = 'multiple_choice_image_button',
  slider = 'slider', // slider
}

export enum OPTIONAL_INPUT_TYPES {
  THERAPIST_PREFERENCE = 'therapistPreference',
}

export enum MaritalStatus {
  SINGLE = 'Single',
  MARRIED = 'Married',
  WIDOWED = 'Widowed',
  DIVORCED = 'Divorced',
  SEPARATED = 'Separated',
}

export enum Gender {
  MALE = 'Male',
  FEMALE = 'Female',
  INTERSEX = 'Intersex',
  NON_BINARY = 'Non-Binary',
  NON_BINARY_NON_CONFORMING = 'Non-Binary or Gender Non-Confirming',
  TRANSGENDER = 'Transgender',
  TRANS = 'Trans',
  UNDISCLOSED = 'Undisclosed',
  OTHER = 'Other',
}

export enum ClientType {
  EMPLOYEE = 'Employee',
  SPOUSE = 'Spouse',
  FAMILY_MEMBER = 'Family Member',
  HOUSEHOLD_MEMBER = 'Household Member',
}

export enum NeedsTopic {
  SELF_GROWTH = 'Self Growth',
  TRAUMA = 'Trauma',
  STRESS = 'Stress',
  SUICIDE_RISK = 'Suicide Risk',
  DOMESTIC_VIOLENCE = 'Domestic Violence',
  GRIEF_LOSS = 'Grief/Loss',
  COUPLES_COUNSELING = 'Couples Counseling',
  WORKPLACE_VIOLENCE = 'Workplace Violence',
  SLEEP_HYGIENE = 'Sleep Hygiene',
  DEPRESSION = 'Depression',
  FAMILY_RELATIONSHIP_CONCERNS = 'Family/Relationship Concerns',
  PARENTING_ISSUES = 'Parenting Issues',
  EATING_DISORDER = 'Eating Disorder',
  EMOTIONAL_REGULATION = 'Emotional Regulation',
  ANXIETY = 'Anxiety',
  ALCOHOL_USE_OR_ABUSE = 'Alcohol Use or Abuse',
  MEDICAL_ISSUES = 'Medical Issues',
  WORKPLACE_CONFLICT = 'Workplace Conflict',
  ORGANIZATIONAL_CHANGE = 'Organizational Change',
  ELDER_ABUSE = 'Elder Abuse',
  PATIENT_VIOLENCE = 'Patient Violence',
  LGBTQIA_ISSUES = 'LGBTQIA+ Issues',
  CHILD_ABUSE = 'Child Abuse',
  INAPPROPRIATE_WORKPLACE_BEHAVIOR = 'Inappropriate Workplace Behavior',
  CARE_GIVER_SUPPORT = 'Care Giver Support',
  PREGNANCY_ISSUES = 'Pregnancy issues',
  SUBSTANCE_USE_OR_ABUSE = 'Substance Use or Abuse',
  ADJUSTMENT_CHANGE = 'Adjustment/Change',
  ADDICTION_CONCERNS = 'Addiction Concerns',
}

export interface RequestPage {
  title?: string;
  titleEyebrowText?: string;
  buttonText?: string;
  backButton?: string;
  content: CONTENT;
}

export interface InputValue {
  label?: string;
  subLabel?: string;
  key: string;
  value?: number | string | Date;
  infoMessage?: string;
}

export interface RadioInputValue {
  label: string;
  subLabel?: string;
  key: string;
  value: string;
  infoMessage?: string;
}

export interface QUESTION {
  type: INPUT_TYPES;
  questionText: string;
  questionSubtext?: string[];
  inputValues: InputValue[];
}

export interface CONTENT {
  header?: string;
  body?: string;
  buttonText?: string;
  questions: QUESTION[];
}

// Form questions
const personalDataQuestions = (type: string): QUESTION => ({
  inputValues: [
    {
      key: 'firstName',
      label: `headspaceHub.forms.${type}.demographic.question1.field.firstName`,
    },
    {
      key: 'lastName',
      label: `headspaceHub.forms.${type}.demographic.question1.field.lastName`,
    },
    {
      infoMessage: 'organizationSettings.contract.datePlaceholder',
      key: 'dob',
      label: `headspaceHub.forms.${type}.demographic.question1.field.DOB`,
    },
    {
      key: 'company',
      label: `headspaceHub.forms.${type}.demographic.question1.field.company`,
    },
  ],
  questionSubtext: [
    `headspaceHub.forms.${type}.demographic.question1.description`,
  ],
  questionText: `headspaceHub.forms.${type}.demographic.question1`,
  type: INPUT_TYPES.text,
});

const genderQuestions = (type: string): QUESTION => ({
  inputValues: [
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.female`,
      value: Gender.FEMALE,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.male`,
      value: Gender.MALE,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.nonBinary`,
      value:
        type === 'inPersonTherapy'
          ? Gender.NON_BINARY
          : Gender.NON_BINARY_NON_CONFORMING,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.transgender`,
      value: Gender.TRANS,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.intersex`,
      value: Gender.INTERSEX,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.other`,
      value: Gender.OTHER,
    },
    {
      key: 'gender',
      label: `headspaceHub.forms.inPersonTherapy.demographic.question2.selection.undisclosed`,
      value: Gender.UNDISCLOSED,
    },
  ],
  questionText: `headspaceHub.forms.${type}.demographic.question2`,
  type: INPUT_TYPES.multiple_choice_radio,
});

const maritalStatusQuestions: QUESTION = {
  inputValues: [
    {
      key: 'maritalStatus',
      label:
        'headspaceHub.forms.inPersonTherapy.demographic.question3.selection.single',
      value: MaritalStatus.SINGLE,
    },
    {
      key: 'maritalStatus',
      label:
        'headspaceHub.forms.inPersonTherapy.demographic.question3.selection.married',
      value: MaritalStatus.MARRIED,
    },
    {
      key: 'maritalStatus',
      label:
        'headspaceHub.forms.inPersonTherapy.demographic.question3.selection.divorced',
      value: MaritalStatus.DIVORCED,
    },
    {
      key: 'maritalStatus',
      label:
        'headspaceHub.forms.inPersonTherapy.demographic.question3.selection.separated',
      value: MaritalStatus.SEPARATED,
    },
    {
      key: 'maritalStatus',
      label:
        'headspaceHub.forms.inPersonTherapy.demographic.question3.selection.widowed',
      value: MaritalStatus.WIDOWED,
    },
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.demographic.question3',
  type: INPUT_TYPES.multiple_choice_radio,
};
const languageQuestions: QUESTION = {
  inputValues: [
    {
      key: 'preferredLanguage',
      label: 'headspaceHub.navbar.mobile.languageTitle',
    },
  ],
  questionSubtext: [
    'headspaceHub.forms.inPersonTherapy.demographic.question4.description',
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.demographic.question4',
  type: INPUT_TYPES.dropdown,
};

const needsQuestions: QUESTION = {
  inputValues: [
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.stress',
      value: NeedsTopic.STRESS,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.anxiety',
      value: NeedsTopic.ANXIETY,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.depression',
      value: NeedsTopic.DEPRESSION,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.grief',
      value: NeedsTopic.GRIEF_LOSS,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.relationship',
      value: NeedsTopic.FAMILY_RELATIONSHIP_CONCERNS,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.adjustment',
      value: NeedsTopic.ADJUSTMENT_CHANGE,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.workplace',
      value: NeedsTopic.WORKPLACE_CONFLICT,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.addition',
      value: NeedsTopic.ADDICTION_CONCERNS,
    },
    {
      key: 'needsTopic',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question1.selection.other',
      value: NeedsTopic.SELF_GROWTH,
    },
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.needs.question1',
  type: INPUT_TYPES.multiple_choice_radio,
};

const addressQuestions = (type: string): QUESTION => ({
  inputValues: [
    {
      key: 'street',
      label:
        'headspaceHub.forms.inPersonTherapy.address.question1.field.street',
    },
    {
      key: 'city',
      label: 'headspaceHub.forms.inPersonTherapy.address.question1.field.city',
    },
    {
      key: 'state',
      label: 'headspaceHub.forms.inPersonTherapy.address.question1.field.state',
    },
    {
      key: 'zipcode',
      label:
        'headspaceHub.forms.inPersonTherapy.address.question1.field.zipCode',
    },
  ],
  questionSubtext:
    type === 'inPersonTherapy'
      ? [
          `headspaceHub.forms.${type}.address.question1.description`,
          `headspaceHub.forms.${type}.address.question1.disclaimer`,
        ]
      : [`headspaceHub.forms.${type}.address.question1.description`],
  questionText: 'headspaceHub.forms.inPersonTherapy.address.question1',
  type: INPUT_TYPES.text,
});

const therapistPreferenceQuestions: QUESTION = {
  inputValues: [
    {
      key: 'therapistPreference',
      label:
        'headspaceHub.forms.inPersonTherapy.needs.question2.field.preferences',
    },
  ],
  questionSubtext: [
    'headspaceHub.forms.inPersonTherapy.needs.question2.description.message1',
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.needs.question2',
  type: INPUT_TYPES.text_multi,
};

const contactQuestions: QUESTION = {
  inputValues: [
    {
      key: 'phoneNumber',
      label:
        'headspaceHub.forms.inPersonTherapy.contact.question1.field.phoneNumber',
    },
    {
      key: 'email',
      label: 'headspaceHub.forms.inPersonTherapy.contact.question1.field.email',
    },
  ],
  questionSubtext: [
    'headspaceHub.forms.inPersonTherapy.contact.question1.description',
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.contact.question1',
  type: INPUT_TYPES.text,
};

const voicemailPreferenceQuestions: QUESTION = {
  inputValues: [
    {
      key: 'voicemailPreference',
      label:
        'headspaceHub.forms.inPersonTherapy.contact.question2.selection.yes',
      value: 'yes',
    },
    {
      key: 'voicemailPreference',
      label:
        'headspaceHub.forms.inPersonTherapy.contact.question2.selection.no',
      value: 'no',
    },
  ],
  questionText: 'headspaceHub.forms.inPersonTherapy.contact.question2',
  type: INPUT_TYPES.multiple_choice_radio,
};

const timeQuestions = (type: string): QUESTION => ({
  inputValues: [
    {
      key: 'timePreference',
      label:
        'headspaceHub.forms.inPersonTherapy.time.question1.selection.morning',
      subLabel: '8:00 - 11:59 am',
      value: '8:00 - 11:59 am',
    },
    {
      key: 'timePreference',
      label:
        'headspaceHub.forms.inPersonTherapy.time.question1.selection.afternoon',
      subLabel: '12:00 pm - 4:59 pm',
      value: '12:00 pm - 4:59 pm',
    },
    {
      key: 'timePreference',
      label:
        'headspaceHub.forms.inPersonTherapy.time.question1.selection.evening',
      subLabel: '5:00 pm - 8:00 pm',
      value: '5:00 pm - 8:00 pm',
    },
    {
      key: 'timePreference',
      label:
        'headspaceHub.forms.inPersonTherapy.time.question1.selection.noPreference',
      value: 'n/a',
    },
  ],
  questionSubtext: [
    'headspaceHub.forms.inPersonTherapy.address.question2.description',
  ],
  questionText: `headspaceHub.forms.${type}.time.question1`,
  type: INPUT_TYPES.multiple_choice_checkbox,
});

// Form pages
const demographicPageQuestions: RequestPage = {
  backButton: 'back',
  buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
  content: {
    questions: [
      personalDataQuestions('inPersonTherapy'),
      genderQuestions('inPersonTherapy'),
      maritalStatusQuestions,
      languageQuestions,
    ],
  },
};

const needsPageQuestions: RequestPage = {
  backButton: 'back',
  buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
  content: {
    questions: [needsQuestions, therapistPreferenceQuestions],
  },
};

const wellnessPageQuestions = () => {
  return [1, 2, 3, 4].map(
    (i): RequestPage => {
      return {
        backButton: 'back',
        buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
        content: {
          questions: [
            {
              inputValues: [
                {
                  key: `wellnessQuestion${i}`,
                },
              ],
              questionSubtext: [
                `headspaceHub.forms.inPersonTherapy.wellness.question${i}.description`,
              ],
              questionText: `headspaceHub.forms.inPersonTherapy.wellness.question${i}`,
              type: INPUT_TYPES.slider,
            },
          ],
        },
      };
    },
  );
};

const contactPageQuestions: RequestPage = {
  backButton: 'back',
  buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
  content: {
    questions: [contactQuestions, voicemailPreferenceQuestions],
  },
};

const calendarPageQuestions = (type: string): RequestPage => ({
  backButton: 'back', // Back to Work and life assistance
  buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText', // Back to Work and life assistance
  content: {
    questions: [
      {
        inputValues: [{ key: 'dayPreference' }],
        questionSubtext: [
          'headspaceHub.forms.inPersonTherapy.calendar.question1.description',
        ],
        questionText: `headspaceHub.forms.${type}.calendar.question1`,
        type: INPUT_TYPES.calendar_multi,
      },
    ],
  },
});

const timePageQuestions = (type: string): RequestPage => ({
  backButton: 'back',
  buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
  content: {
    questions: [timeQuestions(type)],
  },
});

const distanceToTravelQuestions = (provider: CLINICAL_COVERAGE_PROVIDERS) => {
  const isWPO = provider === CLINICAL_COVERAGE_PROVIDERS.WPO;
  return {
    inputValues: [
      {
        key: 'distanceToTravel',
        label: isWPO
          ? 'headspaceHub.forms.inPersonTherapy.address.question2.selection.5kmOrLess'
          : 'headspaceHub.forms.inPersonTherapy.address.question2.selection.5MilesOrLess',
        value: isWPO ? '5 km or less' : '5 miles or less',
      },
      {
        key: 'distanceToTravel',
        label: isWPO
          ? 'headspaceHub.forms.inPersonTherapy.address.question2.selection.5To10km'
          : 'headspaceHub.forms.inPersonTherapy.address.question2.selection.5To10Miles',
        value: isWPO ? '5 to 10 km' : '5 to 10 miles',
      },
      {
        key: 'distanceToTravel',
        label: isWPO
          ? 'headspaceHub.forms.inPersonTherapy.address.question2.selection.10To25km'
          : 'headspaceHub.forms.inPersonTherapy.address.question2.selection.10To25Miles',
        value: isWPO ? '10 to 25 km' : '10 to 25 miles',
      },
      {
        key: 'distanceToTravel',
        label: isWPO
          ? 'headspaceHub.forms.inPersonTherapy.address.question2.selection.20To50km'
          : 'headspaceHub.forms.inPersonTherapy.address.question2.selection.20To50Miles',
        value: isWPO ? '20 to 50 km' : '20 to 50 miles',
      },
    ],
    questionText: 'headspaceHub.forms.inPersonTherapy.address.question2',
    type: INPUT_TYPES.multiple_choice_radio,
  };
};

const inPersonTherapyForm = (
  provider: CLINICAL_COVERAGE_PROVIDERS,
): RequestPage[] => [
  demographicPageQuestions,
  needsPageQuestions,
  ...wellnessPageQuestions(),
  {
    backButton: 'back',
    buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
    content: {
      questions: [
        addressQuestions('inPersonTherapy'),
        distanceToTravelQuestions(provider),
      ],
    },
  },
  calendarPageQuestions('inPersonTherapy'),
  timePageQuestions('inPersonTherapy'),
  contactPageQuestions,
];

const virtualTherapyForm: RequestPage[] = [
  demographicPageQuestions,
  needsPageQuestions,
  ...wellnessPageQuestions(),
  {
    backButton: 'back',
    buttonText: 'headspaceHub.forms.inPersonTherapy.next.buttonText',
    content: {
      questions: [addressQuestions('virtualTherapy')],
    },
  },
  contactPageQuestions,
  calendarPageQuestions('virtualTherapy'),
  timePageQuestions('virtualTherapy'),
];

export const ccaRequestForms: { [key: string]: RequestPage[] } = {
  [FORM_TYPE.IN_PERSON_THERAPY]: inPersonTherapyForm(
    CLINICAL_COVERAGE_PROVIDERS.CCA,
  ),
  [FORM_TYPE.VIRTUAL_THERAPY]: virtualTherapyForm,
};

export const wpoRequestForms: { [key: string]: RequestPage[] } = {
  [FORM_TYPE.IN_PERSON_THERAPY]: inPersonTherapyForm(
    CLINICAL_COVERAGE_PROVIDERS.WPO,
  ),
  [FORM_TYPE.VIRTUAL_THERAPY]: virtualTherapyForm,
  [FORM_TYPE.WORK_AND_LIFE_SERVICES]: [
    {
      backButton: 'back', // Back to Work and life assistance
      buttonText: 'headspaceHub.forms.workLifeAssistance.next.buttonText',
      content: {
        questions: [
          personalDataQuestions('workLifeAssistance'),
          genderQuestions('workLifeAssistance'),
          languageQuestions,
        ],
      },
    },
    {
      backButton: 'back', // Back to Work and life assistance
      buttonText: 'headspaceHub.forms.workLifeAssistance.next.buttonText', // Back to Work and life assistance
      content: {
        questions: [
          {
            inputValues: [
              {
                key: 'weekdayTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question1.selection.morning',
                subLabel: '8:00 - 11:59 am',
                value: '8:00 - 11:59 am',
              },
              {
                key: 'weekdayTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question1.selection.afternoon',
                subLabel: '12:00pm - 4:59pm',
                value: '12:00pm - 4:59pm',
              },
              {
                key: 'weekdayTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question1.selection.evening',
                subLabel: '5:00 - 8:00 pm',
                value: '5:00 - 8:00 pm',
              },
              {
                key: 'weekdayTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question1.selection.noPreference',
                subLabel: '',
                value: '0',
              },
            ],
            questionText:
              'headspaceHub.forms.workLifeAssistance.time.question1',
            type: INPUT_TYPES.multiple_choice_radio,
          },
          {
            inputValues: [
              {
                key: 'weekendTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question2.selection.morning',
                subLabel: '8:00 - 11:59 am',
                value: '8:00 - 11:59 am',
              },
              {
                key: 'weekendTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question2.selection.afternoon',
                subLabel: '12:00pm - 4:59pm',
                value: '12:00pm - 4:59pm',
              },
              {
                key: 'weekendTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question2.selection.evening',
                subLabel: '5:00 - 8:00 pm',
                value: '5:00 - 8:00 pm',
              },
              {
                key: 'weekendTimePref',
                label:
                  'headspaceHub.forms.workLifeAssistance.time.question2.selection.noPreference',
                subLabel: '',
                value: '0',
              },
            ],
            questionText:
              'headspaceHub.forms.workLifeAssistance.time.question2',
            type: INPUT_TYPES.multiple_choice_radio,
          },
        ],
      },
    },
    {
      backButton: 'back', // Back to Work and life assistance
      buttonText:
        'headspaceHub.forms.workLifeAssistance.confirmation.buttonText', // Back to Work and life assistance
      content: {
        questions: [contactQuestions, voicemailPreferenceQuestions],
      },
    },
  ],
};
