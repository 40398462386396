import * as R from 'ramda';
import { getOrgManagement, getCachedOrg } from '../../organizationSelectors';
import { makeFriendlyDate } from '../../../../utils/dates';
import type { RootState } from '../../../types';

export const getCachedCurrentContract = (state: RootState) => {
  const cachedOrg = getCachedOrg(state);
  const contracts = cachedOrg?.contracts;

  if (contracts) {
    return R.find(R.propEq('status', 'ACTIVE'))(contracts) || null;
  }

  return null;
};

export const getEditCurrentContractFormState = (state: RootState) => {
  const manageOrganization = getOrgManagement(state);
  return R.pick(
    [
      'current_contract_id',
      'current_contract_opportunity_id',
      'current_contract_primary_product',
      'current_contract_opportunity_idError',
      'current_contract_start_date',
      'current_contract_end_date',
      'current_contract_seats',
      'current_contract_is_pilot',
      'current_contract_payment_type',
      'current_contract_voucher',
      'current_contract_start_dateError',
      'current_contract_end_dateError',
      'current_contract_seatsError',
      'current_contract_family_members_per_employee',
      'current_contract_family_members_per_employeeError',
      'current_contract_number_of_family_members_seats',
      'current_contract_number_of_family_members_seatsError',
      'current_contract_eligibility_file_based_dependents',
      'current_contract_invite_based_dependents',
      'viewPanels',
      'current_contract_status',
      'current_contract_add_ons',
      'current_contract_clinical_coverage_type',
      'current_contract_eap_type',
    ],
    manageOrganization,
  );
};

const checkForEmptyValues = (state: RootState) => {
  const {
    current_contract_opportunity_id,
    current_contract_primary_product,
    current_contract_start_date,
    current_contract_end_date,
    current_contract_payment_type,
    current_contract_voucher,
  } = getEditCurrentContractFormState(state);
  return (
    R.isEmpty(current_contract_opportunity_id) ||
    R.isEmpty(current_contract_primary_product) ||
    R.isEmpty(current_contract_start_date) ||
    R.isEmpty(current_contract_end_date) ||
    // ensures if a payment type is selected to choose the proper voucher and is not an empty string
    (!R.isEmpty(current_contract_payment_type) &&
      R.isEmpty(current_contract_voucher))
  );
};

export const hasEditCurrentContractFormStateErrors = (
  state: RootState,
): boolean => {
  const currentContractFormState = getEditCurrentContractFormState(state);
  const currentContractFormErrors = R.pick(
    [
      'current_contract_opportunity_idError',
      'current_contract_start_dateError',
      'current_contract_end_dateError',
      'current_contract_seatsError',
      'current_contract_family_members_per_employeeError',
      'current_contract_number_of_family_members_seatsError',
    ],
    currentContractFormState,
  );

  const errorsArr = R.valuesIn(currentContractFormErrors).map(
    (errorObj: any) => errorObj.error,
  );
  const hasError = R.includes(true, errorsArr);
  const hasEmptyValues = checkForEmptyValues(state);

  return hasError || hasEmptyValues;
};

export const getCurrentContractChangedStatus = (state: RootState): boolean => {
  const cachedCurrentContract = getCachedCurrentContract(state);

  const {
    current_contract_id,
    current_contract_opportunity_id,
    current_contract_primary_product,
    current_contract_start_date,
    current_contract_end_date,
    current_contract_seats,
    current_contract_is_pilot,
    current_contract_voucher,
    current_contract_family_members_per_employee,
    current_contract_number_of_family_members_seats,
    current_contract_clinical_coverage_type,
  } = getEditCurrentContractFormState(state);

  const formatContractFormState = {
    clinicalCoverageType: current_contract_clinical_coverage_type,
    dependentSeats: parseInt(
      current_contract_number_of_family_members_seats,
      10,
    ),
    dependentsPerMember: parseInt(
      current_contract_family_members_per_employee,
      10,
    ),
    endDate: current_contract_end_date,
    id: current_contract_id,
    isPilot: current_contract_is_pilot,
    opportunityId: current_contract_opportunity_id,
    partialPayVoucher: current_contract_voucher,
    primaryProduct: current_contract_primary_product,
    seats: parseInt(current_contract_seats, 10),
    startDate: current_contract_start_date,
  };

  const formatCachedContract = {
    ...R.omit(['status', 'activeMembers'], cachedCurrentContract),
    endDate: makeFriendlyDate(cachedCurrentContract?.endDate || '') || '',
    startDate: makeFriendlyDate(cachedCurrentContract?.startDate || '') || '',
  };

  return !R.equals(formatCachedContract, formatContractFormState);
};
