import { useCallback, FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getCookie } from '@headspace/web-auth/dist/utils/cookie';
import { Icon } from '../../../../../shared-components/Icon';
import { FormContainer } from '../../shared-components/FormContainer/FormContainer';
import { TextButton } from '../../../../../shared-components/buttons/TextButton';
import {
  outerContainerStyle,
  rowStyle,
  instructionsStyle,
  buttonContainer,
} from './styles';
import env from '../../../../../config/env';
import { translatedLink } from '../../../constants';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { AP_CDN_URL } from '../../../../dashboard/constants/routes';

interface TurnOffAutoRenewProps {
  companyName: string;
  partialPayVoucher: string | null;
  requestDeauthenticate: (string) => void;
}

export const TurnOffAutoRenew: FC<TurnOffAutoRenewProps> = ({
  companyName,
  partialPayVoucher,
  requestDeauthenticate,
}) => {
  const countryCode: string = getCookie('lang') || 'en';
  const navigate = useNavigate();
  const { slugOrHeadspaceHealthId } = useParams<{
    slugOrHeadspaceHealthId: string;
  }>();
  const { translate } = useTranslate();

  const redirectToFinishPage = useCallback(() => {
    navigate(`/${slugOrHeadspaceHealthId}/member-enroll/finish`);
  }, [navigate, slugOrHeadspaceHealthId]);

  const handleVerify = () => {
    if (partialPayVoucher) {
      return requestDeauthenticate(
        `${env.HOST}/member-enroll/callback?${slugOrHeadspaceHealthId}`,
      );
    }
    return redirectToFinishPage();
  };

  return (
    <FormContainer
      headerTag="h4"
      title={translate('turnOffAutoPay.formTitle')}
      termsMessage={true}
    >
      <div css={outerContainerStyle}>
        <div>
          <p>
            {translate('turnOffAutoPay.currentlyPaying', {
              companyName,
            })}
          </p>
          <h4 css={instructionsStyle}>
            {translate('turnOffAutoPay.instructions')}
          </h4>
          <a
            href={`https://help.headspace.com/hc/${translatedLink[countryCode]}/articles/115008364888-How-can-I-turn-off-the-auto-renew-feature-for-my-subscription-`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div css={rowStyle}>
              <p> Apple Store</p>
              <Icon
                src={`${AP_CDN_URL}/caretRight-24.svg`}
                width={20}
                height={20}
                fill="#67626E"
              />
            </div>
          </a>
          <a
            href={`https://help.headspace.com/hc/${translatedLink[countryCode]}/articles/115008364888-How-can-I-turn-off-the-auto-renew-feature-for-my-subscription-`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div css={rowStyle}>
              <p>Google Play</p>
              <Icon
                src={`${AP_CDN_URL}/caretRight-24.svg`}
                width={20}
                height={20}
                fill="#67626E"
              />
            </div>
          </a>
          <a
            href={`https://help.headspace.com/hc/${translatedLink[countryCode]}/articles/360038732374-How-do-I-cancel-my-Headspace-Spotify-bundled-subscription-`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <div css={rowStyle}>
              <p> Spotify </p>
              <Icon
                src={`${AP_CDN_URL}/caretRight-24.svg`}
                width={20}
                height={20}
                fill="#67626E"
              />
            </div>
          </a>

          <div css={buttonContainer}>
            <TextButton
              dataTestId="next-button"
              textColor="#FFFF"
              hoverColor="#0884FF"
              backgroundColor="#0884FF"
              value={translate('next')}
              pill={true}
              width="100%"
              onClick={handleVerify}
            />
          </div>
        </div>
      </div>
    </FormContainer>
  );
};
