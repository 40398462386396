import LA_AND_VENTURA_COUNTY_POLYGONS from './la-and-ventura-counties-2023-census-polygons.json';

// [longitude, latitude] or if you brain gets confused like mine [x, y]
export type Coordinate = [number, number];
export type Polygon = Coordinate[];

const laPolygons: Polygon[] = LA_AND_VENTURA_COUNTY_POLYGONS[
  'Los Angeles County'
] as Polygon[];
const venturaPolygons = LA_AND_VENTURA_COUNTY_POLYGONS[
  'Ventura County'
] as Polygon[];
const laAndVenturaPolygons = [...laPolygons, ...venturaPolygons];

export const locationInLaOrVenturaCounty = (location: Coordinate): boolean =>
  pointInPolygons(location, laAndVenturaPolygons);

// Returns true if the given point is in any of the polygons
export const pointInPolygons = (
  point: Coordinate,
  polygons: Polygon[],
): boolean =>
  polygons.reduce(
    (acc, polygon) => acc || pointInPolygon(point, polygon),
    false,
  );

/**
 *  Calculates whether a point is inside a polygon using ray-casting. If a point intersects
 *  an odd number of edges it is inside the polygon.
 *  Based on this algorithm: https://rosettacode.org/wiki/Ray-casting_algorithm#JavaScript
 * */
export const pointInPolygon = (
  point: Coordinate,
  polygon: Polygon,
): boolean => {
  let count = 0;
  for (let b = 0; b < polygon.length; b++) {
    const vertex1 = polygon[b];
    const vertex2 = polygon[(b + 1) % polygon.length];

    if (west(vertex1, vertex2, point[0], point[1])) ++count;
  }

  return count % 2 !== 0;
};

// true if (x,y) is west of the line segment connecting A and B
function west(A: Coordinate, B: Coordinate, x: number, y: number): boolean {
  if (A[1] <= B[1]) {
    if (y <= A[1] || y > B[1] || (x >= A[0] && x >= B[0])) {
      return false;
    }
    if (x < A[0] && x < B[0]) {
      return true;
    }
    return (y - A[1]) / (x - A[0]) > (B[1] - A[1]) / (B[0] - A[0]);
  }
  return west(B, A, x, y);
}
