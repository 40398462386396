import { getCookie, removeCookie, setCookie } from '@headspace/web-auth';
import { parse } from 'query-string';
// @christopher-sancho TODO: remove old lib and update types & methods
import { WebAuth } from 'auth0-js';
import { Auth0Client, createAuth0Client } from '@auth0/auth0-spa-js';
import { jwtDecode } from 'jwt-decode';
import { AUTH0 } from '../utils/constants';
import env from '../config/env';

const {
  DASHBOARD_ADMIN_AUTH0_COOKIE: { COOKIE_CONFIGURATION, COOKIE_NAME },
} = env;

export enum AUTH_REDIRECT_PARAMS {
  REDIRECT_URL = 'redirectUrl',
  REDIRECT_URL_CALLBACK = 'redirectUrlCallback',
}

export default class AuthClass {
  public webAuth: any;
  public auth0Client: Auth0Client | null;

  constructor() {
    const { protocol, host } = window.location;

    this.webAuth = new WebAuth({
      domain: AUTH0.DOMAIN || '',
      clientID: AUTH0.CLIENT_ID || '',
      audience: 'http://work.headspace.com',
      redirectUri: `${protocol}//${host}/callback`,
    });

    this.auth0Client = null;
  }

  static get accessToken(): string | null {
    return getCookie(COOKIE_NAME);
  }

  static set accessToken(newToken: string | null) {
    if (newToken) {
      setCookie(COOKIE_NAME, newToken, null, COOKIE_CONFIGURATION);
    } else {
      removeCookie(COOKIE_NAME, null, COOKIE_CONFIGURATION);
    }
  }

  async initialize() {
    const { protocol, host } = window.location;

    this.auth0Client = await createAuth0Client({
      clientId: AUTH0.CLIENT_ID || '',
      domain: AUTH0.DOMAIN || '',
      authorizationParams: {
        audience: 'http://work.headspace.com',
        redirect_uri: `${protocol}//${host}/callback`,
      },
    });
    return this.auth0Client;
  }

  async getAuth0Client(): Promise<Auth0Client> {
    let client;
    if (!this.auth0Client) {
      client = await this.initialize();
    } else {
      client = this.auth0Client;
    }

    return client;
  }

  /**
   * @deprecated Updating auth0 library. This will be removed after auth0-spa-js
   * and admin port sso has safely launched.
   */
  login(email: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.webAuth.login(
        {
          email,
          password,
          responseType: 'token',
          realm: AUTH0.CONNECTION,
        },
        (err: ErrorMessage, resp: any) => {
          if (err) reject(err);
          resolve(resp);
        },
      );
    });
  }

  async loginV2(connection?: string): Promise<void> {
    const { protocol, host } = window.location;
    const connectionString = connection || '';
    const client = await this.getAuth0Client();
    const { REDIRECT_URL, REDIRECT_URL_CALLBACK } = AUTH_REDIRECT_PARAMS;

    const params = new URLSearchParams(document.location.search);
    const redirectURL = params.get(REDIRECT_URL) ?? '';
    const redirectUrlCallbackParam = redirectURL
      ? `${REDIRECT_URL_CALLBACK}=${encodeURIComponent(redirectURL)}`
      : '';

    await client.loginWithRedirect({
      authorizationParams: {
        connection: connectionString,
        redirect_uri: `${protocol}//${host}/callback?${redirectUrlCallbackParam}`,
      },
    });
  }

  /**
   * @deprecated Updating auth0 library. This will be removed after auth0-spa-js
   * and admin port sso has safely launched.
   */
  checkSession(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.webAuth.checkSession(
        {
          audience: 'http://work.headspace.com',
          responseType: 'token',
          realm: AUTH0.CONNECTION,
        },
        (err: ErrorMessage, resp: any) => {
          if (err) reject(err);
          resolve(resp);
        },
      );
    });
  }

  async handleRedirectAndGetToken(): Promise<string | null> {
    const client = await this.getAuth0Client();

    if (window.location.pathname.includes('callback')) {
      await client.handleRedirectCallback();
    }

    return this.getToken();
  }

  async getToken(): Promise<string | null> {
    const client = await this.getAuth0Client();
    const token = await client.getTokenSilently();

    AuthClass.accessToken = token;
    return token;
  }

  /**
   * @deprecated Updating auth0 library. This will be removed after auth0-spa-js
   * and admin port sso has safely launched.
   */
  authorize(connection: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.webAuth.authorize(
        {
          connection,
          responseType: 'token',
        },
        (err: ErrorMessage, resp: any) => {
          if (err)
            reject(new Error('Error getting login token - cannot log in.'));
          resolve(resp);
        },
      );
    });
  }

  // TODO: see if we can stub out some of the resp interface
  /**
   * @deprecated Updating auth0 library. This will be removed after auth0-spa-js
   * and admin port sso has safely launched.
   */
  resetPassword(email: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.webAuth.changePassword(
        {
          email,
          connection: AUTH0.CONNECTION,
        },
        (err: ErrorMessage, resp: any) => {
          if (err) reject(err);
          resolve(resp);
        },
      );
    });
  }

  /**
   * @deprecated Updating auth0 library. This will be removed after auth0-spa-js
   * and admin port sso has safely launched.
   */
  logout() {
    const { protocol, host } = window.location;
    this.webAuth.logout({
      clientID: AUTH0.CLIENT_ID,
    });
    AuthClass.accessToken = null;
    localStorage.clear();
    sessionStorage.clear();

    window.location.replace(`${protocol}//${host}`);
  }

  async logoutV2(): Promise<void> {
    const { protocol, host } = window.location;
    const client = await this.getAuth0Client();

    AuthClass.accessToken = null;
    localStorage.clear();
    sessionStorage.clear();
    await client.logout({
      logoutParams: { returnTo: `${protocol}//${host}/login` },
    });
  }
}

// use let to allow mocking in test
export const create = (): AuthClass => {
  return new AuthClass();
};
export const Constructor = AuthClass;
export const parseHash = (): any => {
  return parse(window.location.hash);
};

export const isValidJwtToken = (token: string): boolean => {
  try {
    const payload = jwtDecode(token);
    return !!payload;
  } catch (e) {
    return false;
  }
};
