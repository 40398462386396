import { useCallback, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Orange, CloseButton } from '@headspace/web-ui-components';
import { useNavigate, useParams } from 'react-router-dom';
import { ChallengeCreate } from './ChallengeCreate';
import { Icon } from '../../../shared-components/Icon';
import { Container } from '../../../shared-components/container/Container';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import { TitleL } from '../../../shared-components/text';
import {
  createSucceed,
  newChallenge,
  challengeReset,
} from '../../../state/actions/challengesActions';
import {
  setDefaultFields,
  validateForm,
  validateChange,
  validateBlur,
} from '../utils/challenge/fieldsConfig';
import {
  challengeCreateContainer,
  challengeCreateCrossCss,
  challengeCreateFormButtonCss,
  challengeCreateFormCss,
  challengeCreateFormSubtitleCss,
  challengeCreateFormTitleCss,
  challengeCreateHeaderCss,
  challengeCreateLogoCss,
  challengeCreateWrapperCss,
} from './styles';
import { useTranslate } from '../../../hooks/useTranslate';
import { getOrgManagement } from '../../../state/selectors/organizationSelectors';
import { useUpdateDocumentTitle } from '../../../hooks/useUpdateDocumentTitle';
import { IMG_CDN_URL_LOGO } from '../constants';

export const ChallengesCreate: FC = () => {
  useUpdateDocumentTitle('Create Challenge');
  const [fields, setFields] = useState(setDefaultFields());
  const [disableButton, setDisableButton] = useState(false);
  const { orgId } = useSelector(getOrgManagement);
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const params = useParams<{ id: string }>();
  const navigate = useNavigate();

  const handleCancel = useCallback(() => {
    const confirm = window.confirm(
      translate('challenges.create.confirmCancel'),
    );
    if (confirm) {
      navigate(-1);
      dispatch(challengeReset());
    }
  }, [dispatch, translate]);

  const handleBlur = useCallback(
    ({ target: { id, value } }: ExtendedEvent) => {
      const blurredField = fields[id];
      setFields({
        ...fields,
        [id]: {
          ...blurredField,
          ...validateBlur(blurredField, value),
        },
      });
    },
    [fields],
  );

  const handleChange = useCallback(
    ({ target: { id, value } }: ExtendedEvent) => {
      const changedField = fields[id];

      setFields({
        ...fields,
        [id]: {
          ...changedField,
          ...validateChange(changedField, value, fields),
          value,
        },
      });
    },
    [fields],
  );

  const handleCreate = useCallback(() => {
    setDisableButton(true);
    const id = orgId || params?.id;
    dispatch(
      newChallenge(
        id,
        {
          ...fields,
        },
        () => {
          dispatch(createSucceed(translate('challenges.create.created')));
          setTimeout(() => dispatch(challengeReset()), 5000);
          navigate(`/orgs/${id}/challenges`);
        },
        translate,
        setDisableButton,
      ),
    );
  }, [dispatch, fields, orgId, params.id, translate]);

  return (
    <div css={challengeCreateWrapperCss}>
      <div css={challengeCreateHeaderCss}>
        <div css={challengeCreateLogoCss}>
          <Icon
            width={160}
            height={34}
            fill={Orange[200]}
            src={`${IMG_CDN_URL_LOGO}/new-logo.svg`}
          />
        </div>
        <div css={challengeCreateCrossCss}>
          <CloseButton onClick={handleCancel} />
        </div>
      </div>
      <div css={challengeCreateFormCss}>
        <div css={challengeCreateFormTitleCss}>
          <TitleL>{translate(`challenges.create.title`)}</TitleL>
        </div>
        <div css={challengeCreateFormTitleCss}>
          <span css={challengeCreateFormSubtitleCss}>
            {translate(`challenges.create.instruction`)}
          </span>
        </div>
        <div css={challengeCreateContainer}>
          <ChallengeCreate
            fields={fields}
            handleChange={handleChange}
            handleError={handleBlur}
          />
        </div>
      </div>
      <Container css={challengeCreateFormButtonCss}>
        <div css={challengeCreateFormButtonCss}>
          <TextButton
            dataTestId="create-challenges-button"
            lg={true}
            value={translate('challenges.create.cta')}
            className="continue"
            disabled={validateForm(fields) || disableButton}
            onClick={handleCreate}
          />
        </div>
      </Container>
    </div>
  );
};
