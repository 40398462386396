import { take, select, call, put } from 'redux-saga/effects';
import { getCheckAutoRenewStatus } from '../../rest-calls';
import { subscriptionDetailsSuccess } from '../actions/memberEnrollActions/memberEnrollActions';
import { MemberEnrollActionTypes } from '../actions/memberEnrollActions/memberEnrollActionTypes';
import { logSentryRequestError } from '../../../../utils/sentryHelpers';
import { webAppRedirectSaga } from './redirects/webAppRedirectSaga';
import { getSlug } from '../selectors/orgSectionSelector/orgSectionSelector';
import {
  getEmail,
  getHsUserId,
  getJWT,
  getPrivileges,
} from '../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { router } from '../../../../root-component/router';

export function* redirectToLogin() {
  const slug = yield select(getSlug);
  yield router.navigate(`/${slug}/member-enroll/login`);
}
export function* verifyPageSaga() {
  yield take(MemberEnrollActionTypes.INITIALIZE_VERIFY_PAGE);
  const email = yield select(getEmail);
  const JWT = yield select(getJWT);
  if (!JWT && !email) {
    yield call(redirectToLogin);
    return;
  }
  // If email still does not exist wait for USER_IS_LOGGED_IN
  if (!email) {
    yield take(MemberEnrollActionTypes.USER_IS_LOGGED_IN);
  }

  const privileges = yield select(getPrivileges);
  const userId = yield select(getHsUserId);

  if (privileges.includes('SUBSCRIBER') && privileges.includes('B2B_CONTENT')) {
    yield call(webAppRedirectSaga);
    return;
  }

  // calling /user-subscriptions will give you extra info if they are coming from a b2c subscription.
  if (privileges.includes('SUBSCRIBER')) {
    try {
      const autoRenewStatusData = yield call(
        getCheckAutoRenewStatus,
        JWT,
        userId,
      );
      yield put(subscriptionDetailsSuccess(autoRenewStatusData.data));
    } catch (error) {
      yield put({
        type: MemberEnrollActionTypes.MEMBER_SUBSCRIPTION_DETAILS_ERROR,
        payload: error,
      });
      logSentryRequestError(error, ' getCheckAutoRenewStatus api call');
    }
  }
}
