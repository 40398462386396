import * as React from 'react';

import { Input } from '../../../../../../../shared-components/Input';
import { HOST, EnrollmentPathname } from '../../../../../../../utils/constants';
import { HEALTH_HUB, PRIMARY_PRODUCT } from '../../../../../constants';
import { CopyToClipboardWrapper } from './CopyToClipboardWrapper';

export interface HealthProviderInviteUrlProps {
  healthHub: HealthHub;
  primaryProduct: PRIMARY_PRODUCT;
}

const host = HOST ?? '';

const createHealthHubLandingUrl = (
  primaryProduct: PRIMARY_PRODUCT,
  healthHub: HealthHub,
) => {
  if (!healthHub) return '';
  const isFusion = primaryProduct === PRIMARY_PRODUCT.FUSION;
  const paths = {
    [HEALTH_HUB.PERSONIFY]: isFusion
      ? EnrollmentPathname.PERSONIFY_CARE_LAND
      : EnrollmentPathname.PERSONIFY_LAND,
  };

  return paths[healthHub] ? `${host}${paths[healthHub]}` : '';
};

export const HealthHubInviteUrl: React.FC<HealthProviderInviteUrlProps> = ({
  healthHub,
  primaryProduct,
}) => {
  const healthHubLandUrl = createHealthHubLandingUrl(primaryProduct, healthHub);
  return (
    <div css={{ display: 'flex' }} data-testid="hh-land-url">
      <Input
        label="Health Hub Invite URL"
        type="review"
        name="hh-url"
        value={healthHubLandUrl}
      />
      <CopyToClipboardWrapper view={true} enrollmentUrl={healthHubLandUrl} />
    </div>
  );
};
