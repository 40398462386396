import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { requestDeauthenticate } from '@headspace/web-auth';
import { Routes } from '../../../constants/routes';
import {
  getHealthProviderErrorCode,
  getHealthProvider,
} from '../../../state/selectors/healthProviderSelector/healthProviderSelector';
import { HealthProviderErrorTypes } from '../../../constants/errorTypes';
import { headerTextStyle, subHeaderStyle } from '../../FinishPage/styles';
import { SoleraErrorGoToButton as ErrorButton } from '../Solera/SoleraErrorGoToButton';
import { containerStyle } from './styles';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { PageWrapper } from '../Personify/PageWrapper/PageWrapper';

const logoutAndRedirect = [
  HealthProviderErrorTypes.HEALTH_PROVIDER_USER_ALREADY_CONNECTED_TO_ANOTHER_DOMAIN_USER,
  HealthProviderErrorTypes.DOMAIN_USER_ALREADY_CONNECTED_TO_ANOTHER_HEALTH_PROVIDER_USER,
];

const redirectToHeadspaceApp = [
  HealthProviderErrorTypes.DOMAIN_USER_HAS_ACTIVE_PAID_SUBSCRIPTION,
];

const HealthProviderErrorPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const errorCode = useSelector(getHealthProviderErrorCode);
  const healthProvider = useSelector(getHealthProvider);
  const primaryError = healthProvider
    ? `${healthProvider}.errors.${errorCode}.primary`
    : 'healthProviderDefault.errors.primary';
  const secondaryError = healthProvider
    ? `${healthProvider}.errors.${errorCode}.secondary`
    : 'healthProviderDefault.errors.secondary';

  const showErrorButton =
    errorCode !==
    HealthProviderErrorTypes.SOMETHING_WENT_WRONG_DURING_HEALTH_PROVIDER_CONNECTION;

  const redirectUser = useCallback(() => {
    if (logoutAndRedirect.includes(errorCode)) {
      dispatch(requestDeauthenticate(Routes.HEADSPACE_LOGIN));
      return;
    }

    if (redirectToHeadspaceApp.includes(errorCode)) {
      window.location.href = 'mailto:teamsupport@headspace.com';
    }
  }, [errorCode, dispatch]);

  return (
    <PageWrapper healthProvider={healthProvider} showJoinMessage={false}>
      <div css={containerStyle} data-testid="finish-error-page">
        <h1 css={headerTextStyle}>{translate(primaryError)}</h1>
        <h4 css={subHeaderStyle}>{translate(secondaryError)}</h4>
        {showErrorButton ? (
          <ErrorButton
            value={translate(`${healthProvider}.errors.${errorCode}.goTo`)}
            onClick={redirectUser}
          />
        ) : null}
      </div>
    </PageWrapper>
  );
};

export default HealthProviderErrorPage;
