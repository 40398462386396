import { Dispatch } from 'redux';

import { GetState } from '../../types';
import { OrganizationActionsTypes } from '../../types/organization';
import { ModalActionsTypes } from '../../types/modal';
import {
  updateOrganizationClinicalCoverage,
  createOrgClinicalCoverage,
} from '../../../rest';
import { hydrate } from './hydrate';
import { getOrgCustomWorkIdFieldPlaceholderText } from '../../selectors/organizationSelectors';
import {
  updateOrganization,
  postOrgLogo,
  adminUpdateOrganization,
} from '../../../rest/organization/organization.api';
import { isCSM } from '../../selectors/userSelectors';

const { SAVE_ORGANIZATION_BASIC } = OrganizationActionsTypes;
const { OPEN_MODAL } = ModalActionsTypes;

export const saveOrganizationBasic = () => {
  return async (dispatch: Dispatch, getState: GetState) => {
    const state = getState();
    const {
      manageOrganization: {
        orgId,
        orgCountry: country,
        org_name: name,
        org_salesforce_id: salesforceId,
        org_type: type,
        sso_enabled: ssoEnabled,
        healthHub,
        healthHubExternalId,
        no_promo_emails: noPromoEmails,
        emailCampaignLogoFile,
        emailCampaignLogo,
        logoFile,
        logo,
        cachedOrg,
        gingerId,
        gingerBundlingEnabled,
        mfaEnabled,
        parentOrgId,
        orgMappingKey,
        orgMappingValue,
        overlappedCareId,
        useSingleEnrollmentLink,
        uploadElfAtChildEnabled,
        phoneNumber,
        externalOrgId,
        pii_enrollment_enabled,
        csvDelimiter,
        serviceType,
        coverageTotal,
        provider,
      },
    } = state;

    const workIdFieldPlaceholderText = getOrgCustomWorkIdFieldPlaceholderText(
      state,
    );
    const formData = new FormData();
    formData.append('logo', logoFile ?? '');
    formData.append('isEmailCampaignLogo', 'false');

    const emailCampaignLogoFormData = new FormData();
    emailCampaignLogoFormData.append('logo', emailCampaignLogoFile ?? '');
    emailCampaignLogoFormData.append('isEmailCampaignLogo', 'true');

    const updateOrgOptions = {
      country,
      csvDelimiter,
      dependentsPiiEnrollmentEnabled: !!pii_enrollment_enabled,
      gingerBundlingEnabled,
      gingerId: gingerId || null,
      healthHub: healthHub || null,
      healthHubExternalId:
        !!healthHubExternalId && !!healthHub ? healthHubExternalId : null,
      mfaEnabled,
      name: `${name}`,
      noPromoEmails,
      orgMappingKey,
      orgMappingValue: orgMappingValue || null,
      overlappedCareId,
      parentOrgId,
      salesforceId: salesforceId ? `${salesforceId}` : null,
      ssoEnabled,
      type,
      uploadElfAtChildEnabled,
      useSingleEnrollmentLink,
    };

    const clinicalCoverageOptions = {
      coverageTotal,
      externalOrgId,
      phoneNumber,
      provider,
      serviceType,
    };

    if (isCSM(state)) {
      await updateOrganization(orgId, updateOrgOptions);
    } else {
      await adminUpdateOrganization(orgId, { country, name });
    }

    if (externalOrgId && !cachedOrg?.externalOrgId) {
      await createOrgClinicalCoverage(orgId, clinicalCoverageOptions);
    } else if (externalOrgId) {
      await updateOrganizationClinicalCoverage(orgId, clinicalCoverageOptions);
    }

    try {
      let nextLogo = logo;
      if (logoFile) {
        const logoResponse = await postOrgLogo({ formData, orgId });
        nextLogo = logoResponse?.data?.data?.logo ?? '';
      }

      if (emailCampaignLogoFile) {
        const emailCampaignLogoResponse = await postOrgLogo({
          formData: emailCampaignLogoFormData,
          orgId,
        });
        // Update org after email logo uploads so that url is present in update event.
        await updateOrganization(orgId, updateOrgOptions);
      }

      dispatch({
        payload: {
          cachedOrg: {
            ...cachedOrg,
            gingerBundlingEnabled,
            gingerId,
            healthHub,
            healthHubExternalId,
            logo: nextLogo,
            name,
            noPromoEmails,
            orgMappingKey,
            orgMappingValue,
            overlappedCareId,
            parentOrgId,
            salesforceId,
            ssoEnabled,
            type,
            uploadElfAtChildEnabled,
            useSingleEnrollmentLink,
            workIdFieldPlaceholderText,
          },
          logo: nextLogo,
          logoFile: null,
          logoFileName: '',
          logoFilePreview: '',
        },
        type: SAVE_ORGANIZATION_BASIC,
      });
    } catch (err) {
      dispatch({
        payload: {
          message: `${err}`,
        },
        type: OPEN_MODAL,
      });
      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
