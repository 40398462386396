import * as React from 'react';
import { IconButton } from '../IconButton';
import {
  iconButtonPronouncedTextStyle,
  iconButtonPronouncedStyle,
} from './styles';

export interface IconButtonPronouncedTypes {
  color: string;
  backgroundColor: string;
  fill: string;
  label: string;
  src: any;
  onClick?(): void;
  cursor?: string;
}

export const IconButtonPronounced: React.FC<IconButtonPronouncedTypes> = ({
  color,
  backgroundColor,
  label,
  src,
  fill,
  onClick,
  cursor,
}) => {
  const onKeyUp = (e) => {
    if (e.key === 'Enter' && onClick) {
      onClick();
    }
  };
  return (
    <div
      css={iconButtonPronouncedStyle(color, backgroundColor, cursor)}
      onClick={onClick}
      onKeyUp={onKeyUp}
      role="button"
      tabIndex={0}
      data-testid="icon-button"
    >
      <IconButton src={src} fill={fill} sm={true} />
      <div css={iconButtonPronouncedTextStyle}>
        <div>{label}</div>
      </div>
    </div>
  );
};
